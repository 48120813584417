import { filter } from 'rxjs/operators';
import { NotificationItemModel } from './models/notification-item';
import { NotificationServices } from "src/app/shared/services/notification.service";

import {
  createAction,
  createFeature,
  createReducer,
  createSelector,
  on,
  props,
  Action,
} from "@ngrx/store";
import { AppState } from "./app.state";
import * as moment from 'moment';
import { state } from '@angular/animations';

const ReceivedSignal = createAction("[NOTIFICATIONS] RECEIVED SIGNAL");

const LoadNotifications = createAction("[NOTIFICATIONS] LOAD NOTIFICATIONS",
  props<{ notifications: NotificationItemModel[] }>()
);

const OpenNotifications = createAction("[NOTIFICATIONS] OPEN NOTIFICATIONS")

export const NotificationActions = { ReceivedSignal, LoadNotifications, OpenNotifications };

export interface NotificationState {
  notifications: NotificationItemModel[];
  todayCount: number;
}

export const initialState: NotificationState = {
  notifications: [],
  todayCount: 0
};

export const NotificationsFeature = createFeature({
  name: "notification",
  reducer: createReducer(
    initialState,
    on(NotificationActions.LoadNotifications, (state,{notifications}) => {
      let todayCount = notifications.filter(n=>moment(n.created).isSame(new Date(),'day')).length;
      return {
        ...state,
        todayCount,
        notifications
      };
    })
  ),
});

const notificationsSelect = (state: AppState) => state.notification;
const ActiveNotificationsSelect = createSelector(
  notificationsSelect,
  (state: NotificationState) => state.notifications
);
const TodayNotificationsCountSelect = createSelector(
  notificationsSelect,
  (state: NotificationState) => state.todayCount
);
export const NotificationsSelector = {ActiveNotificationsSelect, TodayNotificationsCountSelect}


import { Injectable } from "@angular/core";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { ProfileService } from "./profile.service";

declare let ga: Function;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(
    private gaService: GoogleAnalyticsService,
    private profileService: ProfileService
  ) {}

  sendEvent(eventName: string, eventLab: string) {
    const eventCat = this.profileService.role;
    eventLab = `${eventCat}-${eventLab}`;
    if (eventName === "Main Navigation" || eventName === "Student Details") {
      eventLab = `${eventLab}-View`;
    }
    this.gaService.event(eventName, eventCat, eventLab);

    ga("send", "event", eventName, eventCat, eventLab);

    ga("tracker2.send", "event", eventName, eventCat, eventLab);
  }
}

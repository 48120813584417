import { Component, Input, OnInit } from '@angular/core';
import { AnnouncementAdminListModel } from '../announcement-admin-list-widget/models/AnnouncementAdminListModel';
import { CommunicationListModel } from './models/CommunicationListModel';

@Component({
  selector: 'app-communication-admin-list-widget',
  templateUrl: './communication-admin-list-widget.component.html',
  styleUrls: ['./communication-admin-list-widget.component.scss']
})
export class CommunicationAdminListWidgetComponent implements OnInit {

  @Input() model: CommunicationListModel = null;

    // async component
    loaded: boolean;
  constructor() { }

  ngOnInit(): void {
    this.loaded = this.model != null
  }

}

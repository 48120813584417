import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {

  languages = [
    { slug: 'english', value: 'en', lang: 'English' },
    { slug: 'spanish', value: 'es', lang: 'Español' },
    { slug: 'italian', value: 'it', lang: 'Italiano' },
    { slug: 'persian', value: 'fa', lang: 'فارسی' },
    { slug: 'korean', value: 'ko', lang: '한국어' },
    { slug: 'armenian', value: 'hy', lang: 'Հայերեն' },
    { slug: 'russian', value: 'ru', lang: 'Рyсский' },
    { slug: 'tagalog', value: 'tl', lang: 'Wikang Tagalog' },
    { slug: 'vietnamese', value: 'vi', lang: 'Tiếng Việt' },
    { slug: 'chinese', value: 'zh', lang: '中文' },
  ]

  language = new FormControl('en');

  constructor(
    public dialogRef: MatDialogRef<LanguageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.language.setValue(this.data);
  }

  save() {
    this.dialogRef.close(this.language.value)
  }

  cancel() {
    this.dialogRef.close();
  }

}

import { ProfileService } from "src/app/shared/services/profile.service";
import { StudentsService } from "./../../services/students.service";
import { Component, Input, OnInit } from "@angular/core";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import { Subscription, timer } from "rxjs";
import { StaffSettingsService } from "../../services/staff-settings.service";
import { BadgeStatusService } from "../../badge-status.service";
import { CdnService } from "src/app/services/cdn.service";

@Component({
  selector: "app-avatar-fallback-name-widget",
  templateUrl: "./avatar-fallback-name-widget.component.html",
  styleUrls: ["./avatar-fallback-name-widget.component.scss"],
})
export class AvatarFallbackNameWidgetComponent implements OnInit {
  @Input() role = null;
  @Input() userId = null;
  @Input() customClass = null;
  @Input() isNotification;
  @Input() showFirstName = false;
  @Input() showLastName = false;
  @Input() showRole = true;
  @Input() firstName = null;
  @Input() lastName = null;
  @Input() fixIndicatorPos: boolean = false;

  name: string = "No Name Available";
  avatarSrc: string = "/assets/img/avatar.png";
  errorSrc: string = "/assets/img/avatar.png";

  private studentAvatar = "/assets/img/icons/student.svg";
  private parentAvatar = "/assets/img/icons/parent.svg";
  private teacherAvatar = "/assets/img/icons/teacher.svg";
  private principalAvatar = "/assets/img/icons/principal.svg";
  private counselorAvatar = "/assets/img/icons/counselor.svg";

  badgeSubscription: Subscription = null;
  quietSettings: any = null;
  constructor(
    private profileService: ProfileService,
    private studentServices: StudentsService,
    private rs: RoleServiceFactoryService,
    private staffSettingsService: StaffSettingsService,
    public badgeStatusService: BadgeStatusService,
    private cdnServices: CdnService
  ) {}

  ngOnInit(): void {
    let avatarRole = this.role;

    if (this.userId && !this.role) {
      this.profileService.getProfileById(this.userId).subscribe((profile) => {
        if (profile.avatar != null) {
          this.avatarSrc = `${this.cdnServices.profileAvatars}${profile.avatar}`;
        }
        this.role = profile.role;
      });
    }

    if (avatarRole === this.rs.StudentRole) {
      this.avatarSrc = this.studentAvatar;
      this.errorSrc = this.studentAvatar;
      if (this.userId) {
        this.studentServices.getPicture(this.userId).subscribe((response) => {
          // console.log('here- avatar ----', response);
          this.avatarSrc = response.img;
        });
      }
    }
    if (avatarRole == this.rs.PrincipalRole) {
      this.avatarSrc = this.principalAvatar;
      this.errorSrc = this.principalAvatar;
    }
    if (avatarRole == this.rs.CounselorRole) {
      this.avatarSrc = this.counselorAvatar;
      this.errorSrc = this.counselorAvatar;

      this.badgeSubscription = this.badgeStatusService
        .trackStatus(this.userId)
        .subscribe((settings) => {
          this.quietSettings = settings;
        });
    }
    if (avatarRole == this.rs.TeacherRole) {
      this.avatarSrc = this.teacherAvatar;
      this.errorSrc = this.teacherAvatar;

      this.badgeSubscription = this.badgeStatusService
        .trackStatus(this.userId)
        .subscribe((settings) => {
          this.quietSettings = settings;
        });
    }
    if (avatarRole == this.rs.ParentRole) {
      this.avatarSrc = this.parentAvatar;
      this.errorSrc = this.parentAvatar;
    }
    if (this.userId && this.firstName == null && this.lastName == null) {
      this.profileService.getProfileById(this.userId).subscribe((profile) => {
        if (profile.avatar != null) {
          this.avatarSrc = `${this.cdnServices.profileAvatars}${profile.avatar}`;
        }
        this.role = this.role || profile.role;
        if (this.showFirstName) this.name = profile.firstName;
        if (this.showLastName) this.name = profile.lastName;
        if (this.showFirstName == this.showLastName) {
          this.name = `${profile.lastName || "N/A"}, ${
            profile.firstName || "N/A"
          }`;
        }
      });
    } else {
      if (this.showFirstName) this.name = this.firstName;
      if (this.showLastName) this.name = this.lastName;
      if (this.showFirstName == this.showLastName) {
        this.name = `${this.lastName}, ${this.firstName}`;
      }
    }
  }
  ngOnDestroy() {
    if (this.badgeSubscription) {
      this.badgeSubscription.unsubscribe();
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from './models/NotificationModel';

@Component({
  selector: 'app-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['./notification-widget.component.scss']
})
export class NotificationWidgetComponent implements OnInit {

  @Input() model: NotificationModel = null;
  @Input() classStyle = 'bg-white';
  @Input() iconStyle = 'envelope';
  @Input() colorStyle = 'primary';


  constructor() { }

  ngOnInit(): void {

  }

}

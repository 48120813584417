import {
  HttpRequest,
  HttpClient,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  FilePickerAdapter,
  UploadResponse,
  UploadStatus,
  FilePreviewModel
} from 'ngx-awesome-uploader';
import { ApiService } from '../shared/services/api.service';
import { AppConfigService } from './app-config.service';
import { fromTextArea } from 'codemirror';

export class CustomFilePickerAdapter extends FilePickerAdapter {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; //"https://localhost:44315";
  constructor(
    private http:HttpClient,
    private appConfig: AppConfigService,
    private staffId: string) {
      super();

  }

  public uploadFile(fileItem: any): Observable<UploadResponse> {
    const form = new FormData();
    form.append('file', fileItem.file);

    for(var k in fileItem.body){
      form.append(k,fileItem.body[k])
    }
    const api = `${this.baseUrl}/v1/staff/${this.staffId}/files`;
    const req = new HttpRequest('POST', api, form, { reportProgress: true });
    return this.http.request(req).pipe(
      map((res: HttpEvent<any>) => {
        if (res.type === HttpEventType.Response) {
          const responseFromBackend = res.body;
          return {
            body: responseFromBackend,
            status: UploadStatus.UPLOADED
          };
        } else if (res.type === HttpEventType.UploadProgress) {
          /** Compute and show the % done: */
          const uploadProgress = +Math.round((100 *
            res.loaded) / res.total);
          return {
            status: UploadStatus.IN_PROGRESS,
            progress: uploadProgress
          };
        }
      }),
      catchError(er => {
        console.log(er);
        return of({ status: UploadStatus.ERROR, body: er });
      })
    );
  }
  public removeFile(fileItem: FilePreviewModel): Observable<any> {
    const id = 50;
    const responseFromBackend = fileItem.uploadResponse;
    console.log(fileItem);
    const removeApi =
      'https://run.mocky.io/v3/dedf88ec-7ce8-429a-829b-bd2fc55352bc';
    return this.http.post(removeApi, { id });
  }
}

import { StudentsService } from './students.service';
import { RoleServiceFactoryService } from "src/app/shared/services/role-service-factory.service";
import { Howl } from "howler";
import { StaffSettingsService } from "src/app/shared/services/staff-settings.service";
import { ToastrService } from "ngx-toastr";
import { NotificationServices } from "./notification.service";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { AppConfigService } from "src/app/services/app-config.service";
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import { ProfileService } from "./profile.service";
import *  as m from 'moment';

@Injectable({
  providedIn: "root",
})
export class RemindersService {

  remiders: Subject<ReminderEntry>;
  map: Array<ReminderEntry> = [];

  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; // "https://localhost:44315";
  constructor(
    private notificationServices: NotificationServices,
    private appConfig: AppConfigService,
    private api: ApiService,
    private toastr: ToastrService,
    private staffSettingsService: StaffSettingsService,
    private rs: RoleServiceFactoryService,
    private profileService: ProfileService,
    private studentService: StudentsService
  ) {
    this.api.baseUrl = this.baseUrl;
  }

  public addReminderNotification(reminder: any) { //df
    const notificationServices = this.notificationServices;
    reminder.startTime = new Date();
    reminder.timeout = reminder.repeatInMinutes * 60000;
    reminder.timeoutId = setInterval(() => {
      const reminderName = reminder.name || "Expectation";
      let firstname = (reminder.firstName == null || reminder.firstName==undefined)?'':' '+ reminder.firstName;
      const studentName = `${firstname} ${reminder.lastName}`;
      //const studentName = `${reminder.studentName}` || "Deysy";

      this.studentService.getPicture(reminder.studentId).toPromise().then((picture)=>{
        const imageHtml = `<img src="${picture.img}" class="c-pointer img-border ml-0 rounded-circle student-avatar avatar-reminder">`;
        const text = `<p class="ml-3">It is time to provide reinforcement to ${studentName} for ${reminderName}</p>`;
        const toast = `<div class="d-flex justify-content-between align-items-center">${imageHtml} ${text}</div>`;
        this.addReminderAnimation(reminder.studentId);
        if (this.profileService.profile.role === this.rs.TeacherRole) {
          if (this.staffSettingsService.quietSettings.quietHours.isTeaching || this.staffSettingsService.quietSettings.quietHours.isAvailable) {
            this.toastr.success(toast, "", {
              enableHtml: true,
              closeButton: true,
              tapToDismiss: true,
            });

            var sound = new Howl({
              src: ["assets/notification.mp3"],
            });

            sound.play();
          }
        } else {
          this.toastr.success(toast, "", {
            enableHtml: true,
            closeButton: true,
            tapToDismiss: true,
          });
        }
      });

    }, reminder.timeout);

    let reminderKey = `${reminder.studentId}_${reminder.schoolRoomId}_${reminder.gradeId}`;
    this.map[reminderKey] = reminder;
  }
  public removeReminderNotification(reminder: ReminderEntry) {
    let reminderKey = `${reminder.studentId}_${reminder.schoolRoomId}_${reminder.gradeId}`;
    var reminder = this.map[reminderKey];
    if (reminder) {
      clearInterval(reminder.timeoutId);
    }
  }

  calculateLefTimeReminder(id:string) {
    var reminder = this.map[id];
    if(reminder){
      let x = m(new Date());
      var test = m.duration(x.diff(reminder.startTime)).as('seconds');
      if(test > (reminder.repeatInMinutes*60)) {
        var qty = (test/(reminder.repeatInMinutes*60))
        return   (1 - (qty % 1)) * (reminder.repeatInMinutes*60);
      } else {
        return (reminder.repeatInMinutes*60) - test;
      }
    }

    return 0
  }

  addReminderAnimation(id) {
    let student = document.getElementById(id);
    if (student == null) {
      return;
    } else {
      student.classList.add("animate", "animated", "swing");
      setTimeout(function () {
        student.classList.remove("animate", "animated", "swing");
      }, 2000);
    }
  }

  pause(reminder: any) {
    reminder.enabled = false;
    const path = `${this.baseUrl}/v1/reminders/${reminder.id}`;
    return this.api
      .put(path, reminder)
      .pipe(map((response) => response as any));
  }
  start(reminder: any) {
    reminder.enabled = true;
    const path = `${this.baseUrl}/v1/reminders/${reminder.id}`;
    return this.api
      .put(path, reminder)
      .pipe(map((response) => response as any));
  }
  public createReminder(reminder: any) {
    const path = `${this.baseUrl}/v1/reminders`;
    return this.api
      .post(path, reminder)
      .pipe(map((response) => response as any));
  }
  public removeReminder(id: any) {
    const path = `${this.baseUrl}/v1/reminders/${id}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
  public getRemindersByStudent(reminder: any) {
    const path = `${this.baseUrl}/v1/reminders/${reminder.staffId}/${reminder.schoolRoomId}/${reminder.gradeId}/${reminder.studentId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  public getRemindersByStaff(staffId: any) {
    const path = `${this.baseUrl}/v1/reminders/staff/${staffId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  public loadReminders(staffId) {
    this.getRemindersByStaff(staffId).subscribe((reminders) => {
      reminders.forEach((element) => {
        if (element.enabled) this.addReminderNotification(element);
      });
    });
  }
  stopReminders(staffId: string) {
    const path = `${this.baseUrl}/v1/reminders/staff/${staffId}/pause`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}

interface ReminderEntry {
  reminderId: number;
  timeout: number;
  timeoutId: NodeJS.Timeout;
  schoolRoomId: string;
  gradeId: number;
  studentId: string;
}

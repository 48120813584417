import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective {

  constructor(
    private host: ElementRef
  ) { }

  ngAfterViewInit(): void {
    this.host.nativeElement.focus();
  }
}

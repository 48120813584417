import { Injectable } from "@angular/core";
import { ProfileService } from "../../shared/services/profile.service";

const themeA = require("../../shared/styles/themes/theme-a.scss");
const themeB = require("../../shared/styles/themes/theme-b.scss");
const themeC = require("../../shared/styles/themes/theme-c.scss");
const themeD = require("../../shared/styles/themes/theme-d.scss");
const themeE = require("../../shared/styles/themes/theme-e.scss");
const themeF = require("../../shared/styles/themes/theme-f.scss");
const themeG = require("../../shared/styles/themes/theme-g.scss");
const themeH = require("../../shared/styles/themes/theme-h.scss");
const themeI = require("../../shared/styles/themes/theme-i.scss");
const themeLausd = require("../../shared/styles/themes/theme-lausd.scss");
const themeStudent = require("../../shared/styles/themes/theme-student.scss");
const themeParent = require("../../shared/styles/themes/theme-parent.scss");
const themeTeacher = require("../../shared/styles/themes/theme-teacher.scss");

@Injectable()
export class ThemesService {
  styleTag: any;
  defaultTheme: string =
    localStorage.getItem("theme-color") || this.getUserDefaultTheme();

  constructor(private profileService: ProfileService) {
    this.createStyle();
    this.setTheme(this.defaultTheme);
    this.getUserDefaultTheme();
  }

  private createStyle() {
    const head = document.head || document.getElementsByTagName("head")[0];
    this.styleTag = document.createElement("style");
    this.styleTag.type = "text/css";
    this.styleTag.id = "appthemes";
    head.appendChild(this.styleTag);
  }

  setTheme(name) {
    localStorage.setItem("theme-color", name);
    switch (name) {
      case "A":
        this.injectStylesheet(themeA);
        break;
      case "B":
        this.injectStylesheet(themeB);
        break;
      case "C":
        this.injectStylesheet(themeC);
        break;
      case "D":
        this.injectStylesheet(themeD);
        break;
      case "E":
        this.injectStylesheet(themeE);
        break;
      case "F":
        this.injectStylesheet(themeF);
        break;
      case "G":
        this.injectStylesheet(themeG);
        break;
      case "H":
        this.injectStylesheet(themeH);
        break;
      case "I":
        this.injectStylesheet(themeI);
        break;
      case "student":
        this.injectStylesheet(themeStudent);
        break;
      case "parent":
        this.injectStylesheet(themeParent);
        break;
      case "teacher":
        this.injectStylesheet(themeTeacher);
        break;
      case "Lausd":
        this.injectStylesheet(themeLausd);
        break;
    }
  }

  // since v9, content is available in 'default'
  injectStylesheet(css) {
    this.styleTag.innerHTML = css.default;
  }

  getUserDefaultTheme() {
    let role = this.profileService?.role?.toLocaleLowerCase();
    switch (role) {
      case "parent":
        return (this.defaultTheme = role);
      case "student":
        return (this.defaultTheme = role);
      case "teacher":
        return (this.defaultTheme = role);
      default:
        return (this.defaultTheme = "Lausd");
    }
  }

  resetDefaultTheme() {
    localStorage.removeItem(this.defaultTheme);
    this.setTheme(this.getUserDefaultTheme());
  }

  getDefaultTheme() {
    return this.defaultTheme;
  }
}

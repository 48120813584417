import { Observable, Observer, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() {

  }

  fileChanged$:Subject<FileUpload> = new Subject<FileUpload>();

  fileChangeListener($event) {

    const file: File = $event.target.files[0];
    if (/\.(png|gif|jpeg|jpg)$/i.test(file.name)) {
      const fileReader: FileReader = new FileReader();
      let self = this;
      fileReader.onload = function (loadEvent: any) {
        var dataUrl = fileReader.result as string;
        const fileUpload:FileUpload = {
          name: file.name,
          size: file.size,
          type: self.fileExtension(file.name),
          b64: dataUrl
        }

        self.fileChanged$.next(fileUpload);
      };
      fileReader.readAsDataURL(file)
    }
  }
  fileExtension(fileName){
    return fileName.match(/\.[0-9a-z]+$/i)[0];
  }
  toBase64(dataUrl) {
    return dataUrl.split(';base64,')[1];
  }
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
          // This will call another method that will create image from url
          img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
           observer.error(err);
        };
      } else {
          observer.next(this.getBase64Image(img));
          observer.complete();
      }
    });
 }
  toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
  getExtension(path) {
  var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
                                             // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf(".");       // get last position of `.`

  if (basename === "" || pos < 1)            // if file name is empty or ...
      return "";                             //  `.` not found (-1) or comes first (0)

  return basename.slice(pos + 1);            // extract extension ignoring `.`
}
 getBase64Image(img: HTMLImageElement) {
  // We create a HTML canvas object that will create a 2d image
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  // This will draw image
  ctx.drawImage(img, 0, 0);
  var dataURL = canvas.toDataURL('image/png');
  return dataURL
}
}
export  interface FileUpload {
  name: string,
  size: number,
  b64: string,
  type: string
}

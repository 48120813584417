import { Component, OnInit, Input } from '@angular/core';
// import { ColorsService } from '../../colors/colors.service';
import AsyncComponent from '../common/AsyncComponent';
import { GroupItemModel } from './models/Group-Item-Model';

@Component({
  selector: 'app-group-item-widget',
  templateUrl: './group-item-widget.component.html',
  styleUrls: ['./group-item-widget.component.scss']
})
export class GroupItemWidgetComponent implements OnInit, AsyncComponent {
  @Input() model: GroupItemModel = null;

  // async component
  loaded: boolean;

  /*sparkOptionsWarning = {
    type: 'pie',
    sliceColors: [this.colors.byName('gray-lighter'), this.colors.byName('warning')],
    height: 24
    public colors: ColorsService
  };*/
  constructor() { }

  ngOnInit(): void {
    this.loaded = this.model != null;
  }

}

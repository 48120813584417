import { Injectable } from '@angular/core';
import studentTeacherWidgetStories from 'src/stories/components/studentTeacherWidget.stories';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
      if (items && items.length > 0) {
        items.forEach((item) => {
          this.menuItems.push(item);
      });
      }
    }
    clean() {
      this.menuItems = []
    }
    getMenu() {
        return this.menuItems;
    }

}

import { Component, OnInit, Input } from '@angular/core';
import AsyncComponent from '../common/AsyncComponent';
import { StudentPinProfileContactModel } from './models/StudentPinProfileContactModel';

@Component({
  selector: 'app-student-pin-profile-contact-widget',
  templateUrl: './student-pin-profile-contact-widget.component.html',
  styleUrls: ['./student-pin-profile-contact-widget.component.scss']
})
export class StudentPinProfileContactWidgetComponent implements OnInit, AsyncComponent {
  @Input() model: StudentPinProfileContactModel = null;

  // async component
  loaded: boolean;
  constructor() { }

  ngOnInit(): void {
    this.loaded = true;
  }

}

import { StudentsService } from './../services/students.service';
import { ProfileService } from './../services/profile.service';
import { ActivatedRoute } from '@angular/router';
import { OperationsService } from './../services/operations.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import swal from 'sweetalert';
import * as m from 'moment'
@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor(private router:Router,
    private toast: ToastrService, private operationService: OperationsService, private activeRoute:ActivatedRoute,
    private studentService:StudentsService) {}


  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      tap(
        (res:any) => {
          if(res instanceof HttpResponse){
            if(res.status == 200){
              if(res.headers.get('cache-stamp') != null){
                let date =res.headers.get('cache-stamp');
                this.operationService.lastUpdate$.next('As of: '+ m(date).fromNow());
              }

            }
            if(res.status == 200){
              if(res.body.serviceDown && res.body.query){
                this.operationService.lastUpdate$.next('');

              // swal({
              //   title: `LAUSD Services`,
              //   icon: 'warning',
              //   text: "Some LAUSD services are unavailable. Your experience might be temporarily limited",
              //   buttons: {
              //     cancel: false,
              //     confirm: {
              //       text: 'Ok',
              //       value: true,
              //       visible: true,
              //       closeModal: true
              //     }
              //   }
              // }).then((res) => {
              //   if (res) { // OK clicked---
              //     let student = this.studentService.currentStudent();

              //     this.router.navigate([`parent/students/${student.studentId}/timeline`],{relativeTo:this.activeRoute})
              //   }
              // });
            }
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              return;
            }
            if (err.status == 403) {
              this.router.navigate(["auth","404"]);
            }
            // this.router.navigate(["auth","login"]);
          }
        }
      )
    );
  }
}

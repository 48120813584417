import { Component, Input, OnInit } from '@angular/core';
import { CommunicationInboxMessageModel } from './models/CommunicationInboxMessage';

@Component({
  selector: 'app-communication-inbox-message-widget',
  templateUrl: './communication-inbox-message-widget.component.html',
  styleUrls: ['./communication-inbox-message-widget.component.scss']
})
export class CommunicationInboxMessageWidgetComponent implements OnInit {

  @Input() model: CommunicationInboxMessageModel = null
  constructor() { }

  ngOnInit(): void {
  }

}

import { ModalService } from './../../services/modal.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal-widget',
  templateUrl: './modal-widget.component.html',
  styleUrls: ['./modal-widget.component.scss']
})
export class ModalWidgetComponent implements OnInit {

  @ViewChild('modalWidget')
  classicModal: any;
  @Input() modalSize = 'sm';
  @Input() title = 'Title';
  constructor(public modalServices: ModalService) {

  }

  ngOnInit(): void {
    this.modalServices.modalChanged.subscribe((modalStatus) => {
      if (modalStatus) {
        this.classicModal.show();
      } else {
        this.classicModal.hide();
      }
    });
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { IResourceCenterTopic } from "./models/IResourceCenter";

@Component({
  selector: "app-resource-center-widget",
  templateUrl: "./resource-center-widget.component.html",
  styleUrls: ["./resource-center-widget.component.scss"],
})
export class ResourceCenterWidgetComponent implements OnInit {
  @Input() resources: IResourceCenterTopic[] = [];
  @Output() selectedItem = new EventEmitter<IResourceCenterTopic>();
  @Output() filterChanged = new EventEmitter<string>();
  resourceName = new FormControl("");
  constructor() {}

  ngOnInit(): void {


    this.resourceName.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe((results) => {
        this.filterChanged.emit(results);
      });
  }
}

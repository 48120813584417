import { AppConfigService } from 'src/app/services/app-config.service';
import { environment } from '../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { FaqSectionModel } from "./models/FaqSectionModel";
import AsyncComponent from '../common/AsyncComponent';
import {  IFaqGroup, IFaqItem } from './models/Faq';
import { ProfileService } from '../../services/profile.service';

import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import swal from 'sweetalert'
import { NotificationServices } from '../../services/notification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faq-section-widget',
  templateUrl: './faq-section-widget.component.html',
  styleUrls: ['./faq-section-widget.component.scss']
})
export class FaqSectionWidgetComponent implements OnInit {
  userProfile: any = [];
  language: string;
  environmentUrl = this.appConfig.configuration.environment.configuration.serviceNow.helpDesk;
  @Input() groups: IFaqGroup[] = null;
  @Input() isLoading;
  searchKey=''
  constructor(
    private profileService: ProfileService,
    public readonly tourService: TourService,
    private notificationServices: NotificationServices,
    private appConfig:AppConfigService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userProfile = this.profileService.profile;
    this.language = this.userProfile.language;
    this.getEnvUrl()

    this.startScroll();
    this.startTour();
  }

  handleFAQOpen(item: IFaqItem): void{
    this.groups.map(x =>{
      x.questions.map(y=>{
        if(y.id == item.id){
          y.isOpen = !item.isOpen;
        } else if(item.groupId == y.groupId){
          y.isOpen = false;
        }
      })
    })
  }

  getEnvUrl() {
    if(this.appConfig.configuration.environment.endPoints.authenticationServices.includes('servicesdev')) {
      this.environmentUrl = 'https://lausddev.service-now.com/nav_to.do';
    } else if (this.appConfig.configuration.environment.endPoints.authenticationServices.includes('servicesstg')) {
      this.environmentUrl = 'https://lausdtest.service-now.com/nav_to.do';
    } else {
      this.environmentUrl = 'https://lausd.service-now.com/nav_to.do';
    }
  }

  startScroll() {
    setTimeout(() => {
      const frag = this.route.snapshot.fragment;
      if (!!frag) {
        this.scroll(frag);
      }
    }, 500);
  }
  scroll(id){
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    } else {
      this.startScroll();
    }
}

  startTour() {

    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }

    this.tourService.initialize([
      {
        anchorId: 'start-tour',
        content: 'Here you can find FAQs',
        title: 'Welcome to your FAQ',
      },
      {
        anchorId: 'groups',
        content: 'Click on the questions under each category to display answers',
        title: 'Groups',
        placement: above,
      },
    ]);

    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }
  getTitle(title){
    switch (title) {
      case 'CAASPP':
        return `California Assessment of Student Performance and Progress (CAASPP)`
      case 'ELPAC':
        return `English Language Proficiency Assessments for California (ELPAC)`
      case 'GATE Program':
        return `Gifted & Talented Education (GATE Program)`
      case 'PFT':
        return `Physical Fitness Test (PFT)`
      case 'PSAT':
        return `Preliminary Scholastic Aptitude Test (PSAT)`
      case 'OLSAT':
        return `Otis-Lennon School Ability Test (OLSAT)`
      default:
        return title
    }
  }
  renderAnswer(answer){
    answer = this.unicodeToChar(answer);
    return answer
    // const urlRegex = /(https?:\/\/[^\s]+)/g;

    // if(answer !== null){
    //     return answer
    //   } else {
    //     return answer
    //   }
    // }
  }
  unicodeToChar(text){
    return text.replace(/\\u[\dA-F]{4}/gi,
    function (match) {
         return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
  }
}

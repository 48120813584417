import { NotificationActions } from './../store/notifications.feature';
import { AppState } from './../store/app.state';
import { ProfileService } from "src/app/shared/services/profile.service";
import { AppConfigService } from "./../../services/app-config.service";
import { NotificationServices } from "./notification.service";
import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { Subject } from "rxjs";
import { Store } from '@ngrx/store';

export interface NotificationHubMessage {
  message: any;
  handler: any;
  sender: any;
}

@Injectable({
  providedIn: "root",
})
export class NotificationHubService {
  private connection: signalR.HubConnection;
  connectionEstablished = new Subject<Boolean>();
  public notificationListener = new Subject<NotificationHubMessage>();
  disableNotification: boolean = false;
  private handlers = [];

  connect() {
    var token = localStorage.getItem("access_token");
    if (!this.connection) {
      this.connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl(
          this.appConfig.configuration.environment.endPoints
            .notificationServices + "/notification-hub",
          { accessTokenFactory: () => token }
        )
        .withAutomaticReconnect()
        .build();

      this.connection
        .start()
        .then(() => {
          console.log("Hub connection started");
          this.connectionEstablished.next(true);
         console.log(
            "Notification Hub is connected"
          );
        })
        .catch((err) => {
          console.log(
            "Notifications are temporary down, we're working to bring it up."
          );
          console.log(err);
        });

      this.connection.on("Notify", (handler) => {

        let self = this;
        window.setTimeout(()=>{
          this.notificationServices.notificationListener.next(handler);
          self.store.dispatch(NotificationActions.ReceivedSignal());
        },5000);

      });
    }
  }

  disconnect() {
    if (this.connection) {
      this.connection.stop();
      this.connection = null;
    }
  }
  constructor(
    private appConfig: AppConfigService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private store:Store<AppState>
  ) {
    // this.teacherServices.teacherStatus$.subscribe((change)=>{
    //   if(change) {
    //     if(change.id == '2' || change.id == '4'){//projecting mode
    //       this.disableNotification = true;
    //       this.notificationServices.showInfoToast('Notifications disabled')
    //     }
    //     if(change.id == '1'){//projecting mode
    //       this.disableNotification = false;
    //       this.notificationServices.showInfoToast('Notifications enabled')
    //     }
    //   }
    // })
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { NewsWidgetModel } from './models/NewsWidgetModel';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss']
})
export class NewsWidgetComponent implements OnInit {

  @Input() model: NewsWidgetModel = null;
  userRole: any;
  loaded: boolean;

  constructor(
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    this.loaded = this.model != null
    this.userRole = this.profileService.profile.role;
    console.log(this.userRole)
  }

}

import { CdnService } from "./../../../services/cdn.service";
import { BadgeStatusService } from "./../../badge-status.service";
import { tap } from "rxjs/operators";
import { ProfileService } from "src/app/shared/services/profile.service";
import { StudentsService } from "./../../services/students.service";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import { Subscription, timer } from "rxjs";

@Component({
  selector: "app-avatar-fallback-widget",
  templateUrl: "./avatar-fallback-widget.component.html",
  styleUrls: ["./avatar-fallback-widget.component.scss"],
})
export class AvatarFallbackWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() role = null;
  @Input() userId = null;
  @Input() customClass = null;
  @Input() isNotification;
  @Input() imgSrc = null;
  @Input() chat;
  @Input() contact = false;
  @Input() avatarOnly = false;
  @Input() showBadge = false;
  @Input() inbox = false;
  @Input() inboxFolder = false;
  @Input() containerClass = null;
  @Input() studentClass = null;
  @Input() listView: boolean = false;
  @Output() firstName = new EventEmitter<any>(null);
  settings = null;

  avatarSrc: string = "/assets/img/avatar.png";
  errorSrc: string = "/assets/img/avatar.png";
  sub: Subscription;

  badgeSubscription: Subscription;
  quietSettings: any = null;
  isStudentDefault: boolean = false;

  private studentAvatar = "/assets/img/icons/student.svg";
  private parentAvatar = "/assets/img/icons/parent.svg";
  private teacherAvatar = "/assets/img/icons/teacher.svg";
  private principalAvatar = "/assets/img/icons/principal.svg";
  private adminAvatar = "/assets/img/icons/admin.svg";
  private counselorAvatar = "/assets/img/icons/counselor.svg";


  constructor(
    private profileService: ProfileService,
    private studentServices: StudentsService,
    public rs: RoleServiceFactoryService,
    public badgeStatusService: BadgeStatusService,
    private cdnServices: CdnService,
    private cdRef: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkMaster()) return;
    if (this.avatarOnly) {
      this.applyAvatar(this.role, null);
    } else {
      if (this.userId) {
        this.getBadge();
        this.profileService
          .silentProfileById(this.userId)
          .subscribe((profile) => {
            if (profile) {
              this.role = profile.role;
              this.cdRef.detectChanges();
              this.firstName.emit(`${profile.lastName}, ${profile.firstName}`);
              const picture = `${this.cdnServices.profileAvatars}${profile.avatar}`;
              if (profile.avatar == null) {
                this.applyAvatar(this.role, null);
              } else {
                this.applyAvatar(this.role, picture);
              }
              this.cdRef.detectChanges();
            }
          });
        this.cdRef.detectChanges();
      }
    }
  }



  ngOnInit(): void {
    if (this.checkMaster()) return;

    this.applyAvatar(this.role, null);
    if (this.avatarOnly) {
      this.applyAvatar(this.role, null);
    } else {
      if (this.userId) {
        this.getBadge();
        this.profileService
          .silentProfileById(this.userId)
          .subscribe((profile) => {
            if (profile) {
              if (this.userId == "00514967") {
                console.log(profile);
              }
              this.role = profile.role;
              this.firstName.emit(`${profile.lastName}, ${profile.firstName}`);
              const picture = `${this.cdnServices.profileAvatars}${profile.avatar}`;
              if (profile.avatar == null) {
                this.applyAvatar(this.role, null);
              } else {
                this.applyAvatar(this.role, picture);
              }
            }
          });
      }
    }
  }

  checkMaster(): boolean {
    if (this.userId == "000") {
      this.avatarSrc = "/assets/img/icons/logo.png";

      return true;
    }

    return false;
  }
  applyAvatar(avatarRole: string, picture: string) {
    if (avatarRole === this.rs.StudentRole) {
      this.avatarSrc = this.studentAvatar;
      this.errorSrc = this.studentAvatar;
      if (this.userId) {
        this.studentServices.getPicture(this.userId).subscribe((response) => {
          if (response) {
            this.avatarSrc = response.img;
            this.cdRef.detectChanges();
          } else {
            this.isStudentDefault = true;
          }
        });
        this.cdRef.detectChanges();
      }
    }
    if (avatarRole === this.rs.TeacherRole) {
      this.avatarSrc = this.teacherAvatar;
      this.errorSrc = this.teacherAvatar;

      if (picture) {
        this.avatarSrc = picture;
      }
      this.cdRef.detectChanges();
    }
    if (avatarRole === "principal" || avatarRole === "Principal") {
      this.avatarSrc = this.principalAvatar;
      this.errorSrc = this.principalAvatar;
      if (picture) {
        this.avatarSrc = picture;
      }
      this.cdRef.detectChanges();
    }
    if (avatarRole === "admin" || avatarRole === this.rs.AdminRole) {
      this.avatarSrc = this.adminAvatar;
      this.errorSrc = this.adminAvatar;
      if (picture) {
        this.avatarSrc = picture;
      }
      this.cdRef.detectChanges();
    }
    if (avatarRole === "counselor" || avatarRole === this.rs.CounselorRole) {
      this.avatarSrc = this.counselorAvatar;
      this.errorSrc = this.counselorAvatar;
      if (picture) {
        this.avatarSrc = picture;
      }
      this.cdRef.detectChanges();
    }
    if (avatarRole === this.rs.ParentRole || avatarRole === "parent") {
      this.avatarSrc = this.parentAvatar;
      this.errorSrc = this.parentAvatar;
      if (picture) {
        this.avatarSrc = picture;
      }
      this.cdRef.detectChanges();
    }
  }

  getBadge() {
    if (this.role === this.rs.TeacherRole) {
      this.badgeSubscription = this.badgeStatusService
        .trackStatus(this.userId)
        .subscribe((settings) => {
          this.quietSettings = settings;
        });
    }
  }

  ngOnDestroy() {

    if (this.badgeSubscription) {
      this.badgeSubscription.unsubscribe();
      this.badgeStatusService.untrack(this.userId);
    }
    this.cdRef.detach();
  }
}

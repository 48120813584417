import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-set-quiet-hours',
  templateUrl: './set-quiet-hours.component.html',
  styleUrls: ['./set-quiet-hours.component.scss']
})
export class SetQuietHoursComponent implements OnInit {

  dailyQuietHour = true;
  quietDays = true;
  from = new Date();
  to = new Date();
  constructor(
    public dialogRef: MatDialogRef<SetQuietHoursComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  update() {
    return;
  }
  cancel() {
    return;
  }
}

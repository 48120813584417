import { RoleServiceFactoryService } from 'src/app/shared/services/role-service-factory.service';
import { FilePreviewModalComponent } from "./../../../../shared/components/file-preview-modal/file-preview-modal.component";
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ThumbnailPreviewComponent } from '../../thumbnail-preview/thumbnail-preview.component';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { StaffFilesService } from 'src/app/shared/services/staff-files.service';
import { StudentPbisService } from 'src/app/shared/services/student-pbis.service';
import { StudentsService } from 'src/app/shared/services/students.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import swal from 'sweetalert';
import * as m from 'moment';
import * as _ from "lodash";
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-student-files',
  templateUrl: './student-files.component.html',
  styleUrls: ['./student-files.component.scss']
})
export class StudentFilesComponent implements OnInit {

  single = false;
  mockData = [

  ];

  tableData: any[] = [];
  schoolRooms: any[] = [];
  grades: any[] = [];
  studentProfile: any = null;
  now = new Date();
  studentId: string = null;
  lastUpdated: any;

  previewUrl: string = null;
  selectedItem = null;

  isLoading = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private staffFileServices: StaffFilesService,
    private studentPbis:StudentPbisService,
    public rs:RoleServiceFactoryService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.parent.params.subscribe((p)=>{
      this.studentId = p.id;
      this.studentPbis.getPbisFiles(p.studentId).subscribe((data)=>{
        this.isLoading = false;
        this.tableData = data.files;
        this.getFileType(this.tableData);
      }, error => {
        this.isLoading = false;
        //this.toastr.error(`Can't load Files`);
      });
      this.lastUpdated = m();
    })

    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
      {
        anchorId: 'start-tour',
        content: 'All files sent to you by your instructors will appear here. You can preview, download, and view details about your files',
        title: 'Welcome to Student Files!',
      },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  uploadFile() {
    this.router.navigate(['create'], { relativeTo: this.route });
  }
  // download(item){
  //   this.staffFileServices.getDownloadLink(this.studentId,item.id).subscribe((url)=>{
  //     if(url){
  //       window.open(url.downloadLink)
  //     }
  //   })
  // }
  previewThumbnail(res) {
    this.dialog.open(ThumbnailPreviewComponent, {
      disableClose: false,
      width: '800px',
      panelClass: 'thumbnail-preview',
      data: {
        thumbnail: res,
        tablePreview: true
      }
    }).afterClosed().pipe(take(1)).subscribe(result => {
    });
  }

  preview(item) {
    this.staffFileServices
      .getDownloadLink(item.staffId, item.id)
      .subscribe((url) => {
        if (url) {
          this.previewUrl = url.previewLink;
          this.selectedItem = item;
          this.dialog.open(FilePreviewModalComponent, {
            data: { item: this.selectedItem, previewUrl: this.previewUrl,staffId:item.staffId },
          });
        }
      });
  }

  download() {
    if (this.selectedItem) {
      this.staffFileServices
        .getDownloadLink(this.selectedItem.staffId, this.selectedItem.id)
        .subscribe((url) => {
          if (url) {
            window.open(url.downloadLink, "_blank");
          }
        });
    }
  }

  getFileType(files) {
    var re = /(?:\.([^.]+))?$/;
    _.each(files, (file) => {
      file.fileType = re.exec(file.filename)[1];

      //get file icon
      switch (file.fileType) {
        //images
        case "png":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;
        case "jpg":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;
        case "jpeg":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;
        case "gif":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;
        case "webm":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;
        case "svg":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;
        case "tiff":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;
        case "tif":
          file.fileIcon = '<i class="fas fa-2x fa-file-image"></i>';
          file.color = "#FA7921";
          break;

        //videos
        case "mp4":
          file.fileIcon = '<i class="fas fa-2x fa-file-video"></i>';
          file.color = "#5BC0EB";
          break;
        case "avi":
          file.fileIcon = '<i class="fas fa-2x fa-file-video"></i>';
          file.color = "#5BC0EB";
          break;
        case "mov":
          file.fileIcon = '<i class="fas fa-2x fa-file-video"></i>';
          file.color = "#5BC0EB";
          break;
        case "wmv":
          file.fileIcon = '<i class="fas fa-2x fa-file-video"></i>';
          file.color = "#5BC0EB";
          break;

        //audio
        case "mp3":
          file.fileIcon = '<i class="fas fa-2x fa-file-audio"></i>';
          file.color = "#5BC0EB";
          break;
        case "wav":
          file.fileIcon = '<i class="fas fa-2x fa-file-audio"></i>';
          file.color = "#5BC0EB";
          break;

        //pdf
        case "pdf":
          file.fileIcon = '<i class="fas fa-2x fa-file-pdf"></i>';
          file.color = "#E55934";
          break;

        //word doc
        case "doc":
          file.fileIcon = '<i class="fas fa-2x fa-file-word"></i>';
          file.color = "#5BC0EB";
          break;
        case "docx":
          file.fileIcon = '<i class="fas fa-2x fa-file-word"></i>';
          file.color = "#5BC0EB";
          break;

        //excel
        case "xlsx":
          file.fileIcon = '<i class="fas fa-2x fa-file-excel"></i>';
          file.color = "#9BC53D";
          break;
        case "xlsm":
          file.fileIcon = '<i class="fas fa-2x fa-file-excel"></i>';
          file.color = "#9BC53D";
          break;
        case "xlsb":
          file.fileIcon = '<i class="fas fa-2x fa-file-excel"></i>';
          file.color = "#9BC53D";
          break;
        case "xltx":
          file.fileIcon = '<i class="fas fa-2x fa-file-excel"></i>';
          file.color = "#9BC53D";
          break;

        //powerpoint
        case "ppt":
          file.fileIcon = '<i class="fas fa-2x fa-file-powerpoint"></i>';
          file.color = "#E55934";
          break;
        case "pptm":
          file.fileIcon = '<i class="fas fa-2x fa-file-powerpoint"></i>';
          file.color = "#E55934";
          break;
        case "pptx":
          file.fileIcon = '<i class="fas fa-2x fa-file-powerpoint"></i>';
          file.color = "#E55934";
          break;

        //.txt
        case "txt":
          file.fileIcon = '<i class="fas fa-2x fa-file-alt"></i>';
          file.color = "#585D66";
          break;

        //compressed
        case "zip":
          file.fileIcon = '<i class="fas fa-2x fa-file-archive"></i>';
          file.color = "#FDE74C";
          break;
        case "rar":
          file.fileIcon = '<i class="fas fa-2x fa-file-archive"></i>';
          file.color = "#FDE74C";
          break;

        //other
        default:
          file.fileIcon = '<i class="fas fa-2x fa-file"></i>';
          file.color = "#585D66";
          break;
      }
    });
  }

}

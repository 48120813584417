import { ContactsModel } from './../../routes/parent/contacts-route/contacts/model/ContactsModel';
import { ProfileService } from './profile.service';
import { RoleServiceFactoryService } from './role-service-factory.service';
import { StudentsService } from './students.service';
import { ParentsService } from './parents.service';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { from, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ContactModel } from '../components/contact-widget/models/ContactWidgetModel';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { IEmployeeContact } from './parent.services.models';
import { IRoleServices } from './IRoleServices';
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.customerServices;//"https://localhost:44315";
  selectedContact: IEmployeeContact = null
  serviceFactory: any

  constructor(
    private appConfig: AppConfigService,
    private roleServiceFactory: RoleServiceFactoryService,
    private profileService: ProfileService,
    private api: ApiService,) {
  }
  getContacts() : Observable<ContactModel[]>{
    let id = this.profileService.identity.userIdRole;
    return this.roleFactory().getContacts(id);
  }
  roleFactory():IRoleServices{
    return this.roleServiceFactory.getRoleService()
  }
  setContact(contact: IEmployeeContact) {
    this.selectedContact = contact;
    localStorage.setItem('selectedContact', JSON.stringify(contact));
  }

  counselorSearch(filter: string, role: string): Observable<IEmployeeContact[]> {
    const staffId = this.profileService.profile.id;
    let path = `${this.baseUrl}/v1/counselor/${staffId}/contacts?filter=${filter}&role=${role}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IEmployeeContact[]));
  }

  staffSearch(filter: string, role: string): Observable<IEmployeeContact[]> {
    const staffId = this.profileService.profile.id;
    let path = `${this.baseUrl}/v1/staff/${staffId}/contacts?filter=${filter}&role=${role}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IEmployeeContact[]));
  }
}

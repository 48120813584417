import { ProfileService } from "./profile.service";
import { Observable, of } from "rxjs";
import { ParentsService } from "src/app/shared/services/parents.service";
import { OAuthService } from "angular-oauth2-oidc";
import { Injectable } from "@angular/core";
import { StudentsService } from "./students.service";
import { IRoleServices } from "./IRoleServices";
import { TeacherService } from "./teacher.service";
import { CounselorService } from "./counselor.service";

@Injectable({
  providedIn: "root",
})
export class RoleServiceFactoryService {
  public StudentRole = "Student";
  public TeacherRole = "Teacher";
  public PrincipalRole = "Principal";
  public ParentRole = "Parent";
  public CounselorRole = "Counselor";
  public AdminRole = "Administrator";

  public StudentAvatar = "/assets/img/icons/student.svg";
  public TeacherAvatar = "/assets/img/icons/teacher.svg";
  public PrincipalAvatar = "/assets/img/icons/principal.svg";
  public ParentAvatar = "/assets/img/icons/parent.svg";
  public CounselorAvatar = "/assets/img/icons/counselor.svg";
  public AdminAvatar = "/assets/img/icons/admin.svg";

  constructor(
    private profileService: ProfileService,
    private parentServices: ParentsService,
    private studentServices: StudentsService,
    private teacherServices: TeacherService,
    private counselorServices: CounselorService
  ) {}
  getRoleService(): IRoleServices {
    let userType = this.profileService.identity.userType;
    if (userType == "Student")
      return (this.studentServices as any) as IRoleServices;
    if (userType == "Parent")
      return (this.parentServices as any) as IRoleServices;
    if (userType == this.TeacherRole)
      return (this.teacherServices as any) as IRoleServices;
    if (userType == this.CounselorRole)
      return (this.counselorServices as any) as IRoleServices;
    if (userType == this.AdminRole)
      return (this.teacherServices as any) as IRoleServices;
  }
}

import { UserActions } from './features/users.feature';
import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { NotificationApiService } from "../services/notification-api.service";
import { map, switchMap } from "rxjs/operators";
import { NotificationActions } from "./notifications.feature";
import { ChatService } from '../services/chat.service';


@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationApiService: NotificationApiService,
    private chatService: ChatService
  ) { }
  loadMembers$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActions.ReceivedSignal),
    switchMap(() => this.notificationApiService
      .getNotifications()
      .pipe(
        map((notifications) => {
            this.chatService._currentNotifications.next(notifications);
            return NotificationActions.LoadNotifications({ notifications });
          })
        )
      )
    )
  )
}

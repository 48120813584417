import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from '../notification-widget/models/NotificationModel';
import { ClockModel } from './models/ClockModel';

@Component({
  selector: 'app-clock-widget',
  templateUrl: './clock-widget.component.html',
  styleUrls: ['./clock-widget.component.scss']
})
export class ClockWidgetComponent implements OnInit {

  // async component
  @Input() model: NotificationModel = null;
  @Input() colorStyle: string = 'secondary';
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
    this.loaded = this.model != null
  }

}

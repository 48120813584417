import { Component, OnInit, Input } from '@angular/core';
import AsyncComponent from '../common/AsyncComponent';
import { StudentPinContactInfoModel } from './models/StudentPinContactInfoModel';

@Component({
  selector: 'app-student-pin-profile-contact-info-widget',
  templateUrl: './student-pin-profile-contact-info-widget.component.html',
  styleUrls: ['./student-pin-profile-contact-info-widget.component.scss']
})
export class StudentPinContactInfoWidgetComponent implements OnInit, AsyncComponent {
  @Input() model: StudentPinContactInfoModel = null;
  // async component
  loaded: boolean;
  constructor() { }

  ngOnInit(): void {
    this.loaded = true;
  }

}

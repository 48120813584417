import { id } from '@swimlane/ngx-datatable';
import { GroupsService } from 'src/app/shared/services/groups.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClassAction } from '../class-details-widget/models/ClassDetails';
import { StudentDetails } from '../student-details-widget/models/StudentDetails';
import * as _ from 'lodash';
@Component({
  selector: 'app-class-edit-group-widget',
  templateUrl: './class-edit-group-widget.component.html',
  styleUrls: ['./class-edit-group-widget.component.scss']
})
export class ClassEditGroupWidgetComponent implements OnInit {

  @Input() student: StudentDetails = null;
  @Output() onAction = new EventEmitter<ClassAction>();

  displayedColumns: string[] = ['name', 'students', 'points', 'action'];

   @Input() schoolRoomId = null;
   @Input() gradeId = null;
   availableGroups = []

  get studentGroups(){
    return this.availableGroups.filter(a=>a.assigned)
  }

  get notStudentGroups(){
    return this.availableGroups.filter(a=>!a.assigned)
  }

  constructor(private groupServices: GroupsService) { }

  ngOnInit(): void {
    this.groupServices.getGroupsBySchoolRoomAndGrade(this.schoolRoomId, this.gradeId).subscribe((groups)=>{
      this.groupServices.getGroupsByStudent(this.student.studentId, this.schoolRoomId, this.gradeId).subscribe((studentGroups)=>{

        console.log(studentGroups, 'stsudentgroups---')
        this.availableGroups = _.uniqBy(studentGroups.map(sg=>{
          sg.assigned = true;
          return sg;
        }).concat(groups), (g)=>g.id);

      });
    });
  }

  onClose() {
    const action: ClassAction = {
      target: '',
      command: 'closeEditGroup'
    };
    this.onAction.emit(action);
  }

  addGroup(element) {
    let studentId = this.student.studentId;
    let groupId = element.id;

    this.groupServices.addStudentToGroup(studentId, groupId).subscribe((group)=>{
      element.assigned = true;
    })

  }
  remove(element){
    let studentId = this.student.studentId;
    let groupId = element.id;

    this.groupServices.removeStudentToGroup(studentId,groupId).subscribe((group)=>{
      element.assigned = false;
    })
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.calendarServices; // "https://localhost:44315";

  constructor(
    private api: ApiService,
    private appConfig: AppConfigService) {
    this.api.baseUrl = this.baseUrl;
  }

  getMealsByDate(startDate: string, endDate: string, schoolCode: string): Observable<any> {
    const path = `${this.baseUrl}/v1/calendar/${schoolCode}/meals`;
    return this.api.getWithParams(path, {
      startDate,
      endDate
    }).pipe(map((response) => response));
  }

  getRemindersByDate(startDate: string, endDate: string, usernameNo: string): Observable<any> {
    const path = `${this.baseUrl}/v1/calendar/${usernameNo}/events`;
    return this.api.getWithParams(path, {
      startDate,
      endDate
    }).pipe(map((response) => response));
  }

  createEvent(title: string, startDate: string, endDate: string, usernameNo: string): Observable<any> {
    const path = `${this.baseUrl}/v1/calendar/${usernameNo}/events`;
    return this.api.postForms(path, {
      title,
      username: usernameNo,
      startDate,
      endDate
    }).pipe(map((response) => response));
  }

  getDistrictEventsByDate(startDate: string, endDate: string): Observable<any> {
    const path = `${this.baseUrl}/v1/calendar/district/events`;
    return this.api.getWithParams(path, {
      startDate,
      endDate
    }).pipe(map((response) => response));
  }
  getCareEventsByDate(startDate: string, endDate: string): Observable<any> {
    const path = `${this.baseUrl}/v1/calendar/care/events`;
    return this.api.getWithParams(path, {
      startDate,
      endDate
    }).pipe(map((response) => response));
  }
  getSchoolEventsByDate(startDate: string, endDate: string, schoolCode: string): Observable<any> {
    const path = `${this.baseUrl}/v1/calendar/misis/${schoolCode}/events`;
    return this.api.getWithParams(path, {
      startDate,
      endDate
    }).pipe(map((response) => response));
  }

  processAssignments(assignments) {

    let tasks = new Array();
    assignments.section.forEach((a) => {
      tasks = tasks.concat(a.assignments.assignment);
    });
    return tasks.filter((t) => t.due !== '');
  }
}

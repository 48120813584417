import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { StudentsService } from 'src/app/shared/services/students.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-building-skills',
  templateUrl: './student-building-skills.component.html',
  styleUrls: ['./student-building-skills.component.scss']
})
export class StudentBuildingSkillsComponent implements OnInit {
  env = environment;

  basicskills: any;
  anim = '';
  showRoute = false;
  selectedTerm: any;
  dateList = [];
  byCourseList = [];
  isLoading = false;
  lastUpdated: any;

  
  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
  ) { }

  ngOnInit(): void {
    this.loadData(null);
  }

  
  loadData(event) {
    if (this.env.enableIAP) {
      this.isLoading = true;
      this.activeRoute.parent.params.subscribe((data) => {
  
        this.studentService.getStudentIAPBasicSkills(data.studentId).subscribe(
          (bskills) => {        
            this.basicskills = bskills;
            this.lastUpdated = moment();
          },
        ).add(() => {
          this.isLoading = false;
          if (event) {
            event.target.complete();
          }
        });      
      });
    }
  }
  
  openResourceLink() {
    window.open('https://achieve.lausd.net/Page/18922','_blank');
  }

}

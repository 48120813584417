import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-skeleton-loader",
  templateUrl: "./skeleton-loader.component.html",
  styleUrls: ["./skeleton-loader.component.scss"],
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() name: string;
  @Input() count: number;

  constructor() {}

  ngOnInit(): void {}

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog'
import { SelectEventTimeComponent } from '../calendar-widget/select-event-time/select-event-time.component';
@Component({
  selector: 'app-iep-report-download-confirm-modal',
  templateUrl: './iep-report-download-confirm-modal.component.html',
  styleUrls: ['./iep-report-download-confirm-modal.component.scss']
})
export class IepReportDownloadConfirmModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<IepReportDownloadConfirmModalComponent>) { }

  ngOnInit(): void {
  }

  accept(){
    this.dialogRef.close(true);
  }

}

import { PbisService } from "./pbis.service";
import { ChatService } from "src/app/shared/services/chat.service";
import { CommentsService } from "./comments.service";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AlertNotificationService } from "./alert-notification.service";
import { AbsentTardyService } from "./absent-tardy.service";

@Injectable({
  providedIn: "root",
})
export class NotificationServices {
  private counter: number = 0;
  public localNotificationReceived: Subject<any> = new Subject<any>();
  public notificationListener: Subject<any> = new Subject<any>();
  private chatNotificationList = [];
  private commentsNotificationList = [];
  private toastTypes = ["success", "info", "warning", "danger"];
  public handlers = [];

  public NotificationHandlers = {
    ChatNotification: "ChatNotification",
    CommentNotification: "CommentNotification",
    PbisNotification: "PbisNotification",
    AlertNotification: "AlertNotification",
    AbsentTardyNotification: "AbsentTardyNotification",
    CCRGUpdates: "CCRGUpdates",
  };

  constructor(
    private commentsService: CommentsService,
    private chatService: ChatService,
    private PbisService: PbisService,
    private alertService: AlertNotificationService,
    private toastr: ToastrService,
    private absentTardyService: AbsentTardyService
  ) {
    let h = this.NotificationHandlers;
    this.handlers[h.ChatNotification] = this.chatService.messageReceived;
    this.handlers[h.CommentNotification] = this.commentsService.commentReceived;
    this.handlers[h.PbisNotification] = this.PbisService.notificationReceived;
    this.handlers[h.AlertNotification] = this.alertService.notificationReceived;
    this.handlers[h.CCRGUpdates] = this.commentsService.ccrgReceived;
    this.handlers[h.AbsentTardyNotification] =
      this.absentTardyService.notificationReceived;
    this.localNotificationReceived.subscribe((localNotification) => {
      console.log(localNotification);
      this.createNotification(localNotification);
    });

    this.notificationListener.subscribe((msg) => {
      console.log(msg);
      var subscriptions = this.handlers[msg.handler];
      if (subscriptions) {
        if (!Array.isArray(subscriptions)) {
          msg.message = JSON.parse(msg.message);
          msg.sender = this;
          subscriptions.next(msg);
        } else {
          msg.message = JSON.parse(msg.message);
          msg.sender = this;
          for (let i in subscriptions) {
            let subscription = subscriptions[i];
            subscription.next(msg);
          }
        }
      }
    });
  }
  ngOnInit() {}
  ngOnDestroy() {
    this.localNotificationReceived.unsubscribe();
  }
  get countNotification() {
    return this.counter;
  }
  get chatNotifications() {
    return this.chatNotificationList;
  }
  get commentsNotifications() {
    return this.commentsNotificationList;
  }
  public flushNotifications() {
    this.counter = 0;
  }
  public cleantCommentsNotifications() {
    let total = this.chatNotificationList.length;
    this.counter = this.counter - total;
    this.chatNotificationList = [];
  }
  public cleanChatNotifications() {
    let total = this.chatNotificationList.length;
    this.counter = this.counter - total;
    this.chatNotificationList = [];
  }
  showErrorToast(msg: any) {
    this.toastr.error(msg);
  }
  showInfoToast(msg: any) {
    this.toastr.info(msg);
  }
  showToast(msg: any) {
    this.toastr.show();
  }
  showSuccessToast(msg: any) {
    this.toastr.success(msg);
  }

  public createNotification(msg: any) {
    if (msg.handler == this.NotificationHandlers.ChatNotification) {
      this.chatNotificationList.push({
        type: this.NotificationHandlers.ChatNotification,
        description: msg.message.message,
        action: {
          conversationId: msg.message.conversationId,
        },
      });
      this.counter = this.counter + 1;
    }

    if (msg.handler == this.NotificationHandlers.CCRGUpdates) {
      this.chatNotificationList.push({
        type: this.NotificationHandlers.CCRGUpdates,
        description: msg.message.message,
        action: {
          conversationId: msg.message.conversationId,
        },
      });
      this.counter = this.counter + 1;
    }
    if (msg.handler == this.NotificationHandlers.CommentNotification) {
      this.commentsNotificationList.push({
        type: this.NotificationHandlers.CommentNotification,
        description: msg.message.message,
        action: {},
      });
      this.counter = this.counter + 1;
    }
    if (msg.handler == this.NotificationHandlers.PbisNotification) {
      this.commentsNotificationList.push({
        type: this.NotificationHandlers.CommentNotification,
        description: msg.message.message,
        action: {},
      });
      this.counter = this.counter + 1;
    }

    if (msg.handler == this.NotificationHandlers.AlertNotification) {
      this.commentsNotificationList.push({
        type: this.NotificationHandlers.CommentNotification,
        description: msg.message.message,
        action: {},
      });
      this.counter = this.counter + 1;
    }

    if (msg.handler == this.NotificationHandlers.AbsentTardyNotification) {
      this.commentsNotificationList.push({
        type: this.NotificationHandlers.CommentNotification,
        description: msg.message.message,
        action: {},
      });
      this.counter = this.counter + 1;
    }
  }
}

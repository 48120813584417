import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationServices } from '../../services/notification.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-tour-modal',
  templateUrl: './tour-modal.component.html',
  styleUrls: ['./tour-modal.component.scss']
})
export class TourModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<TourModalComponent>,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
  ) { }

  tour: boolean = true;
  userProfile: any;

  ngOnInit(): void {
    this.userProfile = this.profileService.profile;
    this.tour = this.userProfile.hasTour;
  }

  toggleTour(tour){
    this.userProfile.hasTour = !tour;
    this.profileService.saveProfile(this.userProfile).subscribe(() => {
      this.profileService.getProfile().subscribe(() => {
        this.notificationServices.showSuccessToast(
          "Your profile has been updated"
        );
        window.location.reload();
      });
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}

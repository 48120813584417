import { NotificationApiService } from './../../services/notification-api.service';
import { RoleServiceFactoryService } from 'src/app/shared/services/role-service-factory.service';
import { UserSelectors } from './../../store/features/users.feature';
import { NotificationsSelector } from "./../../store/notifications.feature";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../store/app.state";
import { switchMap, takeUntil } from "rxjs/operators";
import { componentDestroyed } from "../../store/extensions";
import { NotificationItemModel } from "../../store/models/notification-item";
import * as _ from "lodash";
import * as moment from "moment";
import { Router } from '@angular/router';
import { CommentNotificationWidgetComponent } from '../comment-notification-widget/comment-notification-widget.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: "div[app-notifications-menu]",
  templateUrl: "./notifications-menu.component.html",
  styleUrls: ["./notifications-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsMenuComponent implements OnInit, OnDestroy {
  notifications$ = this.store.select(
    NotificationsSelector.ActiveNotificationsSelect
  );
  userProfile$ = this.store.select(UserSelectors.ActiveProfileSelect)
  userProfile: any = null;

  menuItems: _.Dictionary<NotificationItemModel[]> = {};

  self = null;

  categories = [
    {
      title: "Academic Updates",
      icon: "fa fa-graduation-cap fa-2x text-success",
      key: ["AbsentTardyNotification","PbisNotification","AssignmentsNotification","AlertNotification",'CCRGUpdates'],
      items: [],
      showToday: false,
      showWeek: false,
      showMonth: false,
      enabled: false
    },
    {
      title: "CCRG Updates",
      icon: "fa fa-graduation-cap fa-2x text-success",
      key: ["CCRGUpdates"],
      items: [],
      showToday: false,
      showWeek: false,
      showMonth: false,
      enabled: false
    },
    // {
    //   title: "Inbox Messages",
    //   icon: "fa fa-envelope fa-2x text-warning",
    //   key: ["ChatNotification"],
    //   items: [],
    //   showToday: false,
    //   showWeek: false,
    //   showMonth: false,
    //   enabled: true
    // },
    {
      title: "Event Updates",
      icon: "fa fa-calendar fa-2x text-primary",
      key: ["CalendarCommunication","CalendarRsvpNotification","CalendarCheckInpNotification"],
      items: [],
      showToday: false,
      showWeek: false,
      showMonth: false,
      enabled: true
    },
    {
      title: "Comments",
      icon: "fa fa-comments fa-2x text-secondary",
      key: ["CommentNotification"],
      items: [],
      showToday: false,
      showWeek: false,
      showMonth: false,
      enabled: true
    },
  ];

  constructor(
    private store: Store<AppState>,
    private rs:RoleServiceFactoryService,
    private dialog: MatDialog,
    private notificationApiService: NotificationApiService,
    private cdr: ChangeDetectorRef,
    private router:Router) {
    this.notifications$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((notifications) => {
        this.processNotifications(notifications);
      });

    this.userProfile$.pipe(takeUntil(componentDestroyed(this)))
    .subscribe(p=>{
      this.userProfile = p;
      this.enableMenuOptions(p)
    })
  }

  enableMenuOptions(profile){
    let academicMenu = this.categories[0]
    let ccrgMenu = this.categories[1]

    if(this.rs.StudentRole == profile.role || this.rs.ParentRole == profile.role){
      academicMenu.enabled = true;
    }
    if(this.rs.CounselorRole == profile.role) {
       ccrgMenu.enabled = true;
    }
  }

  processNotifications(notifications: Array<NotificationItemModel>) {
    var current = moment().startOf("day");
    let self = this;
    let notificationTypes = _.groupBy(notifications, "type");
    Object.keys(notificationTypes).map((groupKey) => {
      let categories = self.categories.filter((a) => a.key.includes(groupKey)) as any;
      for(let category of categories) {
        let items = notificationTypes[groupKey]
        .sort((a) => Date.parse(a.created) || null)
        .map((item) => {
          var given = moment(item.created);
          var diffDays = moment.duration(current.diff(given)).asDays();
          return {
            ...item,
            group: diffDays > 7 ? "Last" : diffDays > 0 ? "Week" : "Today",
          };
        });
      category.items = category.items.concat(items);
      }
    });
    console.log(this.categories, 'cats')
  }

  todayUpdates(items: Array<NotificationItemModel>) {
    return items.filter((i) => i.group == "Today")?.length || 0;
  }

  filterNotifications(items: Array<NotificationItemModel>, filter: string) {
    return items.filter((i) => i.group == filter);
  }
  filterHasNotifications(items: Array<NotificationItemModel>, filter: string) {
    return items.filter((i) => i.group == filter).length > 0;
  }

  filterCategories(categories){
    return categories.filter(c=>c.item.length > 0);
  }

  actionNotification(notification: NotificationItemModel){
    switch(notification.type){
      case 'ChatNotification':
        this.inboxNotification(notification);
        break;
      case 'CalendarCommunication':
        this.eventsNotification(notification);
        break;
      case 'CommentNotification':
        this.commentsNotification(notification);
        break;
      case 'AbsentTardyNotification':
      case 'PbisNotification':
      case 'AssignmentsNotification':
      case 'AlertNotification':
        this.academicNotification(notification);
        break;
      case 'CCRGUpdates':
        this.ccrgNotification(notification);
      default:
    }
  }

  dismissNotification(id:string,categoryItems: NotificationItemModel[], index:number){
    this.notificationApiService.dismissNotification(id)
    .subscribe(()=>{
      categoryItems.splice(index,1);
      this.cdr.detectChanges();
    })
  }
  inboxNotification(notification: NotificationItemModel){
    let userRole = this.userProfile.role.toLowerCase();
    this.router.navigate([userRole,'inbox','folder',notification.message.conversationId])
  }
  academicNotification(notification: NotificationItemModel){
    let userRole = this.userProfile.role.toLowerCase();
    let studentPath = userRole == this.rs.StudentRole.toLocaleLowerCase() ? 'profile' : this.rs.StudentRole.toLocaleLowerCase();

    if (notification.type === "AbsentTardyNotification") {
      this.router.navigate([userRole, studentPath,notification.message.studentId,'attendance'])
      return;
    }
    if (notification.type === "AssignmentsNotification") {
      this.router.navigate([userRole, studentPath,notification.message.studentId,'assignments'])
      return;
    }
    if (notification.type === "PbisNotification") {
      let entities = this.pbisRoutes(notification.message.entity.toLowerCase());
      this.router.navigate([userRole, studentPath, notification.message.studentId,entities])
      return;
    }
    if (notification.type === "AlertNotification") {
      this.router.navigate([userRole, studentPath,notification.message.data.userId])
      return;
    }
  }

  pbisRoutes(entities){
    let route = ''
    switch(entities){
      case 'survey': route = 'surveys'; break;
      case 'certificate': route = 'certificates'; break;
      case 'checklist': route = 'checklists'; break;
      case 'expectation': route = 'expectations'; break;
    }
    return route;
  }
  ccrgNotification(notification: NotificationItemModel){
    let userRole = this.userProfile.role.toLowerCase();
    let studentPath = userRole == this.rs.StudentRole.toLocaleLowerCase() ? 'profile' : this.rs.StudentRole.toLocaleLowerCase();

    if(notification.message.type && notification.message.category) {
      let category = notification.message.category;
      let entityId = notification.message.entityId;
      this.router.navigate([userRole, studentPath, notification.message.profileId,'ccrg',category,entityId])
    }else{
      let category = notification.message.type.toLowerCase();
      this.router.navigate([userRole, studentPath, notification.message.profileId,'ccrg',category])
    }
  }
  eventsNotification(notification: NotificationItemModel){
    let userRole = this.userProfile.role.toLowerCase();
    this.router.navigate([userRole,'events','id', notification.message.data.communicationId])
  }
  commentsNotification(notification: NotificationItemModel){
    const selectedCategory = notification.message.data.sourceType
    const selectedId = notification.message.data.sourceTypeId;
    const studentId = notification.message.data.userId;
        const notifData = {
      category: selectedCategory,
      id: selectedId,
      sId: studentId,
    };
    console.log(notifData);

    this.dialog.open(CommentNotificationWidgetComponent, {
      panelClass: "offwhite-background",
      maxHeight: "70vh",
      maxWidth: "700px",
      // disableClose: true,
      data: notifData,
    });

  }
  ngOnInit(): void {}

  ngOnDestroy(): void {}
}

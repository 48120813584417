import { ToastrService } from "ngx-toastr";
import { ProfileService } from "./../../shared/services/profile.service";
import { Injectable } from "@angular/core";
import { ThemesService } from "../themes/themes.service";
@Injectable()
export class SettingsService {
  public user: any;
  private app: any;
  private layout: any;

  constructor(
    private profileService: ProfileService,
    private themeService: ThemesService,
    private toastr: ToastrService
  ) {
    // User Settings
    // -----------------------------------
    this.user = {
      name: "",
      job: "",
      picture: "assets/img/user/02.jpg",
      profile: "parent",
    };

    // App Settings
    // -----------------------------------
    this.app = {
      name: "LAUSD Parent Portal",
      description: "© Los Angeles Unified School District",
      year: new Date().getFullYear(),
    };

    // Layout Settings
    // -----------------------------------
    this.layout = {
      isFixed: true,
      isCollapsed: false,
      isBoxed: true,
      isRTL: false,
      horizontal: false,
      isFloat: false,
      asideHover: false,
      theme: null,
      asideScrollbar: false,
      isCollapsedText: false,
      useFullLayout: false,
      hiddenFooter: false,
      offsidebarOpen: false,
      asideToggled: false,
      viewAnimation: "ng-fadeInUp",
    };
  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }
  getUserSetting(name) {
    return name ? this.user[name] : this.user;
  }
  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name, value) {
    if (typeof this.app[name] !== "undefined") {
      this.app[name] = value;
    }
  }
  setUserSetting(name, value) {
    if (typeof this.user[name] !== "undefined") {
      this.user[name] = value;
    }
  }
  initUserThemeSettings() {
    if (localStorage.getItem("theme") != null) {
      this.layout = JSON.parse(localStorage.getItem("theme"));
      this.themeService.setTheme(this.layout.theme);
    }
    this.setLayoutSetting("offsidebarOpen", false);
  }
  setUserThemeSettings(themeSettings) {
    if (themeSettings) {
      localStorage.setItem("theme", JSON.stringify(themeSettings));
    } else {
      localStorage.setItem("theme", JSON.stringify(this.layout));
    }
  }

  setUserPreference() {
    let layout = JSON.parse(localStorage.getItem("theme"));
    let themeColor = localStorage.getItem("theme-color");
    layout.theme = themeColor;
    this.profileService.setTheme(layout).subscribe(() => {
      this.toastr.success("Settings saved successfully");
    });
  }

  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== "undefined") {
      this.layout[name] = value;
      localStorage.setItem("theme", JSON.stringify(this.layout));
      this.layout.theme = localStorage.getItem("theme-color");
      return this.layout[name];
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }
  getClaims() {
    const claims = window.localStorage.getItem("id_token_claims_obj");
    if (claims != null) {
      return JSON.parse(claims);
    }
    return null;
  }
}

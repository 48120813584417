import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { CertificateService } from 'src/app/shared/services/certificate.service';

export interface SurveyPreview {
  content: string;
  grade: number;
  answer: string;
}

@Component({
  selector: 'app-view-results',
  templateUrl: './view-results.component.html',
  styleUrls: ['./view-results.component.scss']
})
export class ViewResultsComponent implements OnInit {

  surveyTitle = '';
  headerUrl: any;
  surveyColor: any;
  completionDate: Date | string;
  selectedQuestions: SurveyPreview[] = [];
  settings: any;

  index = 0;
  total: number;
  responses: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ViewResultsComponent>,
    private surveyService: SurveyService,
    private certificateService: CertificateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log(this.data.survey); // FIXME: remove data survey, we should use ID instead of this data: just for demo
    console.log(this.data.responses)
    const surveyId = this.data.id;
    this.surveyTitle = this.data.survey.name; // FIXME: API res.title : No essential
    this.surveyService.getSurveyById(surveyId).subscribe((survey) => {
      this.headerUrl = survey.headerUrl;
      this.surveyColor = survey.color;
      this.surveyTitle = survey.name;
      this.selectedQuestions = survey.questions;
      this.completionDate = survey.dueDate;
    });
    this.responses = this.data.responses;
    this.index = 0;
    this.total = this.responses.length;
    this.certificateService.getThumbnails().subscribe((settings)=>{
      this.settings = settings;
    })
  }
  getQuestions(survey: any) {
    var qstns = this.surveyService.getQuestions();
    var finalQstns = []
    survey.questions.forEach(element => {
      finalQstns.push({
        id: element,
        question: qstns[element],
        answer: ''
      });
      //this.answered[element] = null;
    });
    return finalQstns;
  }
  nextStudent() {
    if (this.index < this.responses.length - 1)
      ++this.index;
  }
  prevStudent() {
    if (this.index != 0)
      --this.index;
  }
  onClose() {
    this.dialogRef.close();
  }

  openNewTab(evt: boolean) {
    const state = {
      question: evt,
      title: this.surveyTitle,
      completionDate: this.completionDate,
      headerUrl: this.headerUrl,
      surveyColor: this.surveyColor,
      answer: this.responses[this.index],
      index: this.index
    };

    localStorage.setItem('viewResults', JSON.stringify(state));
    this.dialogRef.close();
    // this.router.navigate(['teacher/survey-manager/view-results'], { state });
    // return;
    const link = `http://localhost:4200/teacher/survey-manager/view-results`; // FIXME: get current browser active link
    window.open(link, '_blank');
  }

}

import { IFeed } from './../components/feed-widget/models/IFeed';
import { NewsWidgetModel } from '../components/news-widget/models/NewsWidgetModel';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from './profile.service';


@Injectable({
  providedIn: 'root'
})
export class FeedServiceService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.calendarServices;
  pbisUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices;
  userId: string = this.profileService.identity.userIdRole;

  constructor(
    private appConfig: AppConfigService,
    private api:ApiService,
    private http: HttpClient,
    private profileService: ProfileService) {

  }
  getMyFeeds(codes:string[]) : Observable<IFeed[]>{
    let path = `${this.baseUrl}/v1/calendar/events/my`;
    return this.api.getWithParams(path, {assignedSchoolIds: codes}).pipe(map((response)=> response as IFeed[]));
  }

  getDistrictFeed() : Observable<IFeed[]> {
    return this.http.get("https://lausdmobileapi.lausd.net/parent/districtNews").pipe(map((response) => response as IFeed[]));
  }

  getDashboardNews() : Observable<NewsWidgetModel[]> {
    return this.http.get("https://lausdmobileapi.lausd.net/parent/dashboardNews").pipe(map((response) => response as NewsWidgetModel[]));
  }

  getCalendarEvents(userId) : Observable<any> {
    let path = `${this.pbisUrl}/v1/communications/${userId}/attendance`
    return this.api.get(path).pipe(map((response) => response as any[]));
  }
}

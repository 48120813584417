import { Component, OnInit, Inject, ViewContainerRef, ViewChild, ComponentFactoryResolver, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { FontIconComponent } from './font-icon.component';

const iconLists: string[] = require('src/assets/fonticon.json');

@Component({
  selector: 'app-new-expectation-modal',
  templateUrl: './new-expectation-modal.component.html',
  styleUrls: ['./new-expectation-modal.component.scss']
})
export class NewExpectationModalComponent implements OnInit, AfterViewInit {

  title = '';
  description = '';
  iconLists = iconLists;
  selectedIcon = '';
  points: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  point: any = 'Points:';
  dateCreated: any = '';
  instances = 0;
  editMode = false;
  originalExpectation: any = null;

  @ViewChild('fontIcon', { read: ViewContainerRef }) fontIcon: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<NewExpectationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      console.log(this.data, ' ORIGINAL EXP ---- ');
      this.editMode = true;
      this.originalExpectation = this.data.expectation;
      this.selectedIcon = this.originalExpectation.icon;
      this.title = this.originalExpectation.name;
      this.dateCreated = this.originalExpectation.dateCreated;
      this.description = this.originalExpectation.description;
      this.point = this.originalExpectation.points;
      this.instances = this.originalExpectation.instances;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.editMode) {
      setTimeout(() => {
        const resolver = this.resolver.resolveComponentFactory(FontIconComponent);
        this.fontIcon.clear();
        const compRef = this.fontIcon.createComponent(resolver);
        const myInstance = compRef.instance as FontIconComponent;
        myInstance.iconName = this.selectedIcon;
      }, 0);
    }

  }

  get saveDisable() {
    return this.point === 'Points:' || !this.title || !this.description;
  }

  save() {
    let dateModified: any = new Date();
    if (!this.editMode) {
      this.dateCreated = new Date();
      dateModified = null;
    }
    const newExpectation = {
      icon: this.selectedIcon,
      name: this.title,
      description: this.description,
      enabled: true,
      points: this.point,
      dateCreated: this.dateCreated,
      modified: dateModified,
      id: this.originalExpectation == null ? null : this.originalExpectation.id
    };
    this.dialogRef.close(newExpectation);
  }

  close() {
    this.dialogRef.close();
  }

  selectIcon(icon) {
    this.selectedIcon = icon;
    const resolver = this.resolver.resolveComponentFactory(FontIconComponent);
    this.fontIcon.clear();
    const compRef = this.fontIcon.createComponent(resolver);
    const myInstance = compRef.instance as FontIconComponent;
    myInstance.iconName = icon;
  }

}

import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClassDetails } from '../components/class-details-widget/models/ClassDetails';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices;//"https://localhost:44315"
  parentId: string = null;
  parentUsername: string  = null;

  constructor(
    private appConfig: AppConfigService,
    private api:ApiService,
    private oauth: OAuthService) {}

  createSchoolRoomGroup(groupDetails: any) : Observable<any> {
    let path = `${this.baseUrl}/v1/groups`;
    return this.api.post(path, groupDetails).pipe(map((response)=> response as any));
  }
  editSchoolRoomGroup(groupDetails: any) : Observable<any> {
    let path = `${this.baseUrl}/v1/groups/${groupDetails.id}`;
    return this.api.put(path, groupDetails).pipe(map((response)=> response as any));
  }
  getGroupsBySchoolRoomAndGrade(schoolRoomId: string, gradeId: string) : Observable<any> {
    let path = `${this.baseUrl}/v1/school-room/${schoolRoomId}/grades/${gradeId}/groups`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
  deleteGroup(id: any) {
    let path = `${this.baseUrl}/v1/groups/${id}`;
    return this.api.delete(path).pipe(map((response)=> response as any));
  }
  getStudentsByGroupId(id: any): Observable<any> {
    let path = `${this.baseUrl}/v1/groups/${id}/students`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
  getGroupsByStudent(studentId: string, schoolRoomId: string, gradeId: string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/groups/${schoolRoomId}/${gradeId}`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
  addStudentToGroup(studentId: string, groupId: string) : Observable<any> {
    let path = `${this.baseUrl}/v1/groups/${groupId}/students/${studentId}`;
    return this.api.post(path,{}).pipe(map((response)=> response as any));
  }
  removeStudentToGroup(studentId: string, groupId: string) : Observable<any> {
    let path = `${this.baseUrl}/v1/groups/${groupId}/students/${studentId}`;
    return this.api.delete(path).pipe(map((response)=> response as any));
  }
}

import "./vendor.ts";
import { ApplicationRef, enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { enableDebugTools } from "@angular/platform-browser";

const configUrl = getConfigUrl();

fetch(configUrl)
  .then((response) => response.json())
  .then((config) => {
    localStorage.setItem("app_environment", JSON.stringify(config));
    localStorage.setItem("app_captcha", config.environment.RecaptchaSiteKey);
    localStorage.setItem("app_gkey", config.environment.GoogleApiKey);
    if (environment.production) {
      enableProdMode();
      console.log = function(){

      }
    }

    const p = platformBrowserDynamic().bootstrapModule(AppModule);
    p.then((module) => {
      (window as any).appBootstrap && (window as any).appBootstrap();
    }).catch((err) => console.error(err));
  });

function getConfigUrl() {
  const host = window.location.host;
  let uri = "/configurations/mobility";
  if (host.indexOf("localhost") >= 0) {
    uri = "https://localhost:44350" + uri;
  }
  if (host == "lausdappdev.lausd.net") {
    uri = "https://servicesdev.lausd.net/authentication" + uri;
  }
  if (host == "lausdappqa.lausd.net") {
    uri = "https://servicesqa.lausd.net/authentication" + uri;
  }
  if (host == "lausdappstg.lausd.net") {
    uri = "https://servicesstg.lausd.net/authentication" + uri;
  }
  if (host == "lausdapptst.lausd.net" || host == 'd3ouhnhf3h10mb.cloudfront.net') {
    uri = "https://servicestst.lausd.net/authentication" + uri;
  }
  if (host == "lausdapp.lausd.net") {
    uri = "https://services.lausd.net/authentication" + uri;
  }
  return uri;
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClassDetails } from '../components/class-details-widget/models/ClassDetails';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolRoomService {

  selectedSchoolRoom: ClassDetails

  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; //"https://localhost:44315";

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService) {
    this.api.baseUrl = this.baseUrl;
  }

  public setActiveSchoolRoom(schoolRoom: ClassDetails) {
    this.selectedSchoolRoom = schoolRoom
  }
  public getStudentsBySchoolRoomId(schoolRoomId: string, gradeId: string) {
    let path = `${this.baseUrl}/v1/school-room/${schoolRoomId}/grades/${gradeId}/students`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
  public getSchoolRoomDetailsById(schoolRoomId: string, gradeId: string) {
    let path = `${this.baseUrl}/v1/school-room/${schoolRoomId}/grades/${gradeId}`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }


}

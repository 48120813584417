import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class AppConfigService {
  private appConfig;

  $configurationLoaded: Subject<AppEnvironment> = new Subject<AppEnvironment>();
  get configuration(): AppEnvironment {
    return JSON.parse(localStorage.getItem("app_environment"));
  }
  set configuration(value: AppEnvironment) {
    localStorage.setItem("app_environment", JSON.stringify(value));
  }
  constructor(private http: HttpClient, private oauthConfig: OAuthModuleConfig) {}

  loadAppConfig() {
    return new Promise<void>((resolve, reject) => {
      var config = JSON.parse(localStorage.getItem("app_environment")) as AppEnvironment;
      var appConfiguration = config;
      this.configuration = config;
      this.oauthConfig.resourceServer.allowedUrls = appConfiguration.environment.configuration.oauth.resourceServer.allowedUrls;

      this.$configurationLoaded.next(config);
      resolve();
    });
  }

  getConfig() {
    return this.appConfig;
  }
}

export interface AppEnvironment {
  environment: Environment
  RecaptchaSiteKey: string
  RecaptchaSecretKey: string
  GoogleApiKey: string
  gaTrackingID: string
}

export interface Environment {
  endPoints: EndPoints
  configuration: Configuration
}

export interface EndPoints {
  baseUri: string
  authenticationServices: string
  customerServices: string
  notificationServices: string
  communicationsServices: string
  calendarServices: string
  facilitiesServices: string
  pbisServices: string
  counselorServices: string
  administratorServices: string
  operationServices: string
}

export interface Configuration {
  production: boolean
  environment_name: string
  oauth: Oauth
  serviceNow: ServiceNow
  ppServices: PpServices,
  authenticationTimeout: number
}

export interface Oauth {
  resourceServer: ResourceServer
}

export interface ResourceServer {
  allowedUrls: string[]
  sendAccessToken: boolean
}

export interface ServiceNow {
  helpDesk: string
  chatService: string
}

export interface PpServices {
  requestDevice: string
  uploadDocs: string
  sso: Sso
  externalLogin: ExternalLogin
  hif: hif
}

export interface Sso {
  studentEnrollment: string
  studentRequestDevice: string
}

export interface ExternalLogin {
  enroll: string
  myapps: string
}

export interface hif {
  incomeForm: string
}

import { StaffSettingsService } from 'src/app/shared/services/staff-settings.service';
import { ProfileService } from './../../../shared/services/profile.service';
import { Injectable } from '@angular/core';
import { timer, Subject, interval } from 'rxjs';

@Injectable()
export class UserblockService {
    public userBlockVisible: boolean;
    public quietHoursChanged: Subject<any> = new Subject<any>();
    isRunning: boolean =false;
    constructor(private profileService: ProfileService, private staffSettings: StaffSettingsService) {
        // initially visible
        this.userBlockVisible  = true;
    }

    getVisibility() {
        return this.userBlockVisible;
    }

    setVisibility(stat = true) {
        this.userBlockVisible = stat;
    }

    toggleVisibility() {
        this.userBlockVisible = !this.userBlockVisible;
    }
}

import { TermList } from "./student.service.models";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/services/app-config.service";
import { ApiService } from "./api.service";
import { of } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class CertificateService {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; //"https://localhost:44315"
  parentId: string = null;
  parentUsername: string = null;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private oauth: OAuthService
  ) {}

  createCertificate(request: any): Observable<any> {
    let path = `${this.baseUrl}/v1/certificates/custom/students/custom`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }
  createResource(request: any, file: File): Observable<any> {
    let path = `${this.baseUrl}/v1/certificates/resources`;
    return this.api
      .postFile(path, request, file)
      .pipe(map((response) => response as any));
  }
  getCertificateResourcesByStaff(staffId: any) {
    let path = `${this.baseUrl}/v1/staff/${staffId}/certificates/resources`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  createTEstCertificate(request: any): Observable<any> {
    return of([{ TermList: "" }]);
  }

  archiveResource(res: any) {
    const path = `${this.baseUrl}/v1/certificates/resources/${res.resourceId}/archive`;
    return this.api.put(path, res).pipe(map((response) => response as any));
  }

  unarchiveResource(res: any) {
    const path = `${this.baseUrl}/v1/certificates/resources/${res.resourceId}/unarchive`;
    return this.api.put(path, res).pipe(map((response) => response as any));
  }
  getCertificate(id: string): Observable<any> {
    let path = `${this.baseUrl}/v1/certificates/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getCertificates(): Observable<any> {
    let path = `${this.baseUrl}/v1/certificates`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getThumbnails() {
    let path = `${this.baseUrl}/v1/certificates/thumbnails`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  delete(staffId: string, certificateId: any) {
    let path = `${this.baseUrl}/v1/staff/${staffId}/certificates/${certificateId}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
}

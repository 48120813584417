import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/services/app-config.service";
import { ApiService } from "./api.service";
import { of } from "rxjs";
import { rest } from "lodash";
@Injectable({
  providedIn: "root",
})
export class ChecklistService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.counselorServices; // "https://localhost:44315"
  parentId: string = null;
  parentUsername: string = null;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private oauth: OAuthService
  ) {}

  createChecklist(request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }
  timeline(studentId) {
    let userId = studentId;
    let path = `${this.baseUrl}/v1/checklists/${userId}/timeline`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }

  createChecklistQuestion(request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/questions`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  deleteChecklist(id: any) {
    const path = `${this.baseUrl}/v1/checklists/${id}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
  getChecklists(): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getChecklistsByUserId(userId): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/list/${userId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getQuestions() {
    var questions = [];
    questions.push("Question 1");
    questions.push("Question 2");
    questions.push("Question 3");
    questions.push("Question 4");
    questions.push("Question 5");
    questions.push("Question 6");
    questions.push("Question 7");

    return questions;
  }
  getQuestionCategories():Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/questions/categories`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getChecklistById(id): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getChecklistByUserId(id): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/list/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getChecklistQuestions(): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/questions`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  createResource(request: any, file: File): Observable<any> {
    let path = `${this.baseUrl}/v1/checklists/resources`;
    return this.api
      .postFile(path, request, file)
      .pipe(map((response) => response as any));
  }

  archiveResource(res: any) {
    const path = `${this.baseUrl}/v1/checklists/resources/${res.resourceId}/archive`;
    return this.api.put(path, res).pipe(map((response) => response as any));
  }

  unarchiveResource(res: any) {
    const path = `${this.baseUrl}/v1/checklists/resources/${res.resourceId}/unarchive`;
    return this.api.put(path, res).pipe(map((response) => response as any));
  }



  getChecklistResourcesByStaff(staffId: any) {
    let path = `${this.baseUrl}/v1/checklists/${staffId}/resources`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  saveAnswers(checklistsId, studentId, checklistsModel): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/${checklistsId}/answers/${studentId}`;
    return this.api
      .post(path, checklistsModel)
      .pipe(map((response) => response as any));
  }
  getChecklistAnswers(checklistsId, studentId): Observable<any> {
    const path = `${this.baseUrl}/v1/checklists/${checklistsId}/answers/${studentId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  viewResultById(checklistsId) {
    const path = `${this.baseUrl}/v1/checklists/${checklistsId}/answers`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}

// TODO: We can return all checklistsDetails using getChecklists() API but it will be heavy.

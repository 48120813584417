import { Component, OnInit, Input } from '@angular/core';
import { AnnouncementAdminCommentModel } from './models/AnnouncementAdminCommentModel';
import { AnnouncementAdminCommentStatusType } from './models/AnnouncementAdminCommentStatusType';
import { AnnouncementAdminListStatusType } from '../../announcement-admin-list-widget/models/AnnouncementAdminListStatusType';

@Component({
  selector: 'app-announcement-admin-comments-widget',
  templateUrl: './announcement-admin-comments-widget.component.html',
  styleUrls: ['./announcement-admin-comments-widget.component.scss']
})
export class AnnouncementAdminCommentsWidgetComponent implements OnInit {

  @Input() model: AnnouncementAdminCommentModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  getEnumKeyByEnumValue(enumValue) {
    console.log(enumValue);
    const keys = Object.keys(AnnouncementAdminCommentStatusType).filter(x => AnnouncementAdminCommentStatusType[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
}

import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AppConfigService } from './../../services/app-config.service';
import { Injectable } from '@angular/core';
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class StudentPbisService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; //"https://localhost:44315";

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService) {
    this.api.baseUrl = this.baseUrl;
  }
  getPbis(id: string) : Observable<any>{
    let path = `${this.baseUrl}/v1/students/${id}/pbis`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  getPbisFiles(id: string) : Observable<any>{
    let path = `${this.baseUrl}/v1/students/${id}/pbis/files`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  getPbisCertificates(id: string) : Observable<any>{
    let path = `${this.baseUrl}/v1/students/${id}/pbis/certificates`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  getPbisSurveys(id: string) : Observable<any>{
    let path = `${this.baseUrl}/v1/students/${id}/pbis/surveys`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  getPbisExpectations(id: string) : Observable<any>{
    let path = `${this.baseUrl}/v1/students/${id}/pbis/expectations`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }


}

import { ProfileService } from "./profile.service";
import { IRoleServices } from "./IRoleServices";
import { AppConfigService } from "../../services/app-config.service";
import { Observable, BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ContactModel } from "../components/contact-widget/models/ContactWidgetModel";

@Injectable({
  providedIn: "root",
})
export class CounselorService implements IRoleServices {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices;
  baseCustomerUrl: string = this.appConfig.configuration.environment.endPoints
    .customerServices;

  public teacherStatus$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private profileService: ProfileService
  ) {
    this.api.baseUrl = this.baseUrl;
  }

  getContacts(id: string): Observable<ContactModel[]> {
    const path = `${this.baseCustomerUrl}/v1/counselor/${id}/contacts`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getChecklists(id: string): Observable<any> {
    const path = `${this.baseCustomerUrl}/v1/counselor/checklists/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }


  setAvailability(request: any): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/quiethours/${userId}/availability`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }
}

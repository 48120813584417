import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile-table-widget',
  templateUrl: './profile-table-widget.component.html',
  styleUrls: ['./profile-table-widget.component.scss']
})
export class ProfileTableWidgetComponent implements OnInit {

  @Input() divider: boolean = false;
  @Input() customClass: string;

  constructor() { }

  ngOnInit(): void {
  }

}

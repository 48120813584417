import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/services/app-config.service";
import { ApiService } from "./api.service";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommunicationService {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; // "https://localhost:44315"
  parentId: string = null;
  parentUsername: string = null;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private oauth: OAuthService
  ) {}

  createCommunication(request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/communications`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }
  updateCommunication(request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/communications/${request.id}`;
    return this.api
      .patch(path, request)
      .pipe(map((response) => response as any));
  }

  getCommunications(staffId: string): Observable<any> {
    const path = `${this.baseUrl}/v1/communications/${staffId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  delete(id: any): Observable<any> {
    // FIXME: add delete / const path = `${this.baseUrl}/v1/communications/delete/${id}`; API not working now
    const path = `${this.baseUrl}/v1/communications/${id}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }

  getCommById(staffId,id): Observable<any> {
    const path = `${this.baseUrl}/v1/communications/${staffId}/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getCommunicationById(id): Observable<any> {
    const path = `${this.baseUrl}/v1/communications/communications/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}

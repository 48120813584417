import { EventEmitter } from "@angular/core";
import { Profile, ProfileService } from "./../../services/profile.service";
import { Component, Input, OnInit, Output } from "@angular/core";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import { StudentsService } from "../../services/students.service";

@Component({
  selector: "app-name-widget",
  templateUrl: "./name-widget.component.html",
  styleUrls: ["./name-widget.component.scss"],
})
export class NameWidgetComponent implements OnInit {
  constructor(
    private studentServices: StudentsService,
    private rs: RoleServiceFactoryService,
    private profileService: ProfileService
  ) {}
  displayName = "loading...";
  @Input() userId = null;
  @Input() audienceModal = false;
  @Output() onLoaded = new EventEmitter<Profile>();
  ngOnInit(): void {
    if(this.checkMaster()) return;

    this.profileService.getProfileById(this.userId).subscribe((profile) => {
      if (profile.lastName) {
        this.displayName = `${profile.lastName}`;
      }
      if (profile.firstName) {
        this.displayName = `${profile.firstName}`;
      }
      if (profile.lastName && profile.firstName) {
        if (!this.audienceModal)
          this.displayName = `${profile.lastName}, ${profile.firstName}`;
        else this.displayName = `${profile.firstName} ${profile.lastName}`;
      }
      this.onLoaded.emit(profile);
    });
  }

  checkMaster(): boolean {
    if (this.userId == "000") {
      this.displayName = "Parent & Community Services";
      return true;
    }

    return false;
  }
}

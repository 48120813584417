import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImageFallback]'
})
export class ImageFallbackDirective {

  @Input() appImageFallback: string = ''
  constructor(private eRef: ElementRef) {

  }
  @HostListener('error')
  loadCallbackImageOnError(){
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.appImageFallback;
    if(element.src.includes('assets/img/fallbacks')) {
      element.style.opacity = '0.7'
    }
  }

}

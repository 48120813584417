import { Router } from '@angular/router';
import { Route } from './../shared/services/student.service.models';
import { OAuthService } from 'angular-oauth2-oidc';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-route-discover',
  template: `
    <p>

    </p>
  `
})
export class RouteDiscoverComponent implements OnInit {

  constructor(private oauthService: OAuthService, private router:Router) {

  }

  ngOnInit(): void {
    if(this.oauthService.hasValidAccessToken()){
      var claims = this.oauthService.getIdentityClaims() as any;
      var userType = claims.userType.toLowerCase();
      var route = `${userType}${this.hasRedirectUrl()}`;
      this.router.navigate([route])
    }else{
      this.oauthService.loadDiscoveryDocumentAndLogin().then((response)=>{

      });
    }


  }
  hasRedirectUrl(){
    var redirectUrl = window.localStorage.getItem('returnUrl');
    return redirectUrl ?? '';
  }

}

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ParentsService } from '../../services/parents.service';
import { StudentsService } from '../../services/students.service';
import { IStudentDetails } from '../student-list-widget/models/IStudentDetails';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-student-management',
  templateUrl: './student-management.component.html',
  styleUrls: ['./student-management.component.scss']
})
export class StudentManagementComponent implements OnInit {

  @ViewChild('addStudent') addStudentModal;

  studentId:string = null;
  action: string = null;
  errorMessage: string = null;
  response: any = null;
  selectedStudent: IStudentDetails = null;
  registerModel: string = null;
  successResponse: any = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private parentServices: ParentsService,
    private studentServices: StudentsService,
    private toastr: ToastrService,
    private modalServices: ModalService) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((data) => {
      this.studentId = data['studentId'];
      let  currentStudent = this.studentServices.currentStudent();
      if(currentStudent.studentId == this.studentId)
        this.selectedStudent = currentStudent;
      this.action = data['action'];

    });

  }

  changeEvent($event){
    //let registerModel = $event.registerModel;
    this.registerModel = $event.registerModel;
    if($event.cmd == 'add'){
      console.log(this.registerModel);
      this.parentServices.confirmStudent(this.registerModel).subscribe((confirmResponse)=>{
        console.log(confirmResponse);
        var error = confirmResponse.errorResponse;
        if(error.errorCode){
          this.errorMessage = error.errorMessage;

        }else{
          this.response = confirmResponse;
          this.modalServices.show();
        }
      });
    }
    if($event.cmd == 'cancel'){
      this.router.navigateByUrl('/parent/students')
    }
  }

  cancel(){
    this.modalServices.hide();
  }

  close(){

    this.parentServices.getStudentsByParentNoCache().subscribe(()=>{
      this.modalServices.hide();
      this.router.navigateByUrl('/parent/students');
    });

  }

  add(){
    this.parentServices.addStudent(this.registerModel).subscribe((successResponse)=>{
      console.log(successResponse);
      var error = successResponse.errorResponse;
      if(error.errorCode){
        this.errorMessage = error.errorMessage;

      }else{
        this.response = null;
        this.successResponse = successResponse;
        this.parentServices.getStudentsByParentNoCache().subscribe(() => {});
        this.toastr.success(`Successfully added ${this.selectedStudent.firstName} ${this.selectedStudent.lastName}`)
        this.modalServices.show();

      }
    });
  }

}

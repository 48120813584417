import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './../../services/profile.service';
import { StudentsService } from 'src/app/shared/services/students.service';
import { IStudentResume } from './../student-list-widget/models/IStudentResume';
import {
  CalendarOptions,
  DateSelectArg,
  EventApi,
  EventClickArg,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterContentInit,
} from '@angular/core';
import { CalendarService } from '../../services/calendar.service';
import * as moment from 'moment';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { MatDialog } from '@angular/material/dialog';
import { SelectEventTimeComponent } from './select-event-time/select-event-time.component';
import { LoaderService } from '../../services/loader.service';
import { NotificationServices } from '../../services/notification.service';
import * as _ from 'lodash';

import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { endOf } from 'ngx-bootstrap/chronos';



const swal = require('sweetalert');


@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html',
  styleUrls: ['./calendar-widget.component.scss'],
})
export class CalendarWidgetComponent
  implements OnInit, OnChanges, AfterContentInit {
  constructor(
    private studentServices: StudentsService,
    private calendarServices: CalendarService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private notificationServices: NotificationServices,
    private toastr:ToastrService,
    public readonly tourService: TourService,
  ) { }
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('external') external: ElementRef;
  @Input() students: IStudentResume[] = [];
  //change display based on role
  @Input() role: string = "";
  RemoveAfterDrop = false;
  evNewName: string;
  selectedStudent: IStudentResume = null;
  calendarVisible = true;
  startDateTime = new Date();
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    initialView: 'dayGridMonth',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    droppable: true,
    drop: this.eventDrag.bind(this),
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    datesSet: this.handleEvents.bind(this),
    events: [],

    // plugins: [interactionPlugin],
    /* yviewSkeletonRenderou can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  evColors = [
    'danger',
    'primary',
    'info',
    'success',
    'warning',
    'green',
    'pink',
    'inverse',
    'purple',
    'blue'
  ];
  evColorsHex = [
    '#F44336',
    '#0053A0',
    '#2196F3',
    '#4CAF50',
    '#FF9800',
    '#059e8f',
    '#ee97d8',
    '#263238',
    '#673AB7',
    '#059e8f'
  ];
  externalEvents = [
    // { color: 'green', name: 'Calendar', hex: '#059e8f' },
    { color: 'warning', name: 'Meal', hex: '#002575' },
    { color: 'info', name: 'School Vacation', hex: '#002575' },
    { color: 'danger', name: 'Enrollment Key Dates', hex: '#df1b24' },
    { color: 'purple', name: 'Added Events', hex: '#ee97d8' },
    { color: 'blue', name: 'College and Career', hex: '#059e8f' },
  ];
  externalEventsCopy = [];

  category = [
    // { color: 'green', name: 'Calendar', hex: '#059e8f' },
    { color: 'warning', name: 'Meal', hex: '#005E32' },
    { color: 'info', name: 'School Vacation', hex: '#002575' },
    { color: 'danger', name: 'Enrollment Key Dates', hex: '#df1b24' },
    { color: 'purple', name: 'Reminders', hex: '#673AB7' },
    { color: 'green', name: 'College and Career', hex: '#059e8f' },
  ];
  evSelectedColor = this.evColors[0];

  afterInit: boolean = false;

  disableCreateEvent = true;
  currentStart: any;
  currentEnd: any;
  reminderEventsData: any = [];
  selectedEvent: any;
  currentEvents: EventApi[] = [];

  studentsTypeahead: any = [];
  selectedStudentTypeahead: any;
  cacheStudent: any;

  @ViewChild('classicModal')
  classicModal: any;


  ngAfterContentInit(): void {
    const that = this;
    // setTimeout(() => {
    //   new Draggable(this.external.nativeElement, {
    //     itemSelector: ".fce-event",
    //   });
    // }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.students != null) {
      this.selectedStudent = this.students.find(s=>s.studentId);
    }
  }

  ngOnInit() {
    if (this.students != null) {
      this.students = this.students.filter( s => {
        return s.enrollmentStatus !== 'N'
      })
      this.selectedStudent = this.students[0];
      console.log(this.selectedStudent, "SelectedStudent");
      this.selectedStudentTypeahead = this.selectedStudent.lastName + ', ' + this.selectedStudent.firstName; 
      
      this.filterEvents(this.selectedStudent);
      // this.addTranslationToCal();
      if (this.role == 'counselor') {
        setTimeout(() => {
          this.getTypeaheadList();
        }, 1000);
      }
    }
    this.afterInit = true;
    this.startTour()
    this.checkStudentCategories();
  }

  getTypeaheadList() {
    let name;
    _.each(this.students, student => {
      name = `${student.lastName}, ${student.firstName}`;
      student.fullName = name;
      student.id = student.studentId;
      // this.studentsTypeahead.push(student);
    })
  }

  startTour() {

    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }

    this.tourService.initialize([
      {
        anchorId: 'start-tour',
        content: 'Here you can view events from your student(s)\' school',
        title: 'Welcome to your Calendar',
      },
      {
        anchorId: 'filter',
        content: 'You can view the calendar/events of your student by selecting the student here',
        title: 'Filter by Student',
      },
      {
        anchorId: 'categories',
        content: 'These are the types of events that will be displayed in your calendar',
        title: 'Categories',
        placement: below,
      },
    ]);

    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  checkStudentCategories(){
  
    if(!this.selectedStudent.studentId){
      this.externalEventsCopy = this.externalEvents.filter(e => e.name !== 'College and Career')
    }
    else {
      if(parseInt(this.selectedStudent.gradeLevel) < 9 && this.role == 'parent'){       
        this.externalEventsCopy = this.externalEvents.filter(e => e.name !== 'College and Career')
      } else if (parseInt(this.selectedStudent.gradeId) < 9 && this.role == 'counselor') {
        this.externalEventsCopy = this.externalEvents.filter(e => e.name !== 'College and Career')
      }
      else {
        this.externalEventsCopy = this.externalEvents;
      }
    }
  }

  // addTranslationToCal(){
  //     let events = document.getElementsByClassName('fc-event-title');
  //     console.log(events);
  //     _.each(events, (event)=> {
  //       event.setAttribute('v3-translate', 'translate')
  //     })
  // }

  renderView(args: DateSelectArg) {
    console.log(args);
  }
  filterProfileEvents() {
    let selectedProfileId = this.selectedStudent.studentId || this.selectedStudent.id;
    this.calendarOptions.events = [];

    const startMonth = moment(this.currentStart).format('YYYY-MM-DD');
    const endMonth = moment(this.currentEnd).format('YYYY-MM-DD');
    this.calendarServices
      .getDistrictEventsByDate(startMonth, endMonth)
      .subscribe((districtEvents) => {
        this.processDistrict(districtEvents);
      });
      this.calendarServices
      .getCareEventsByDate(startMonth, endMonth)
      .subscribe((careEvents) => {
        this.processCare(careEvents);
      });
    this.calendarServices
      .getRemindersByDate(
        startMonth,
        endMonth,
        selectedProfileId
      )
      .subscribe((reminderEvents) => {
        this.reminderEventsData = reminderEvents;
        this.processReminders(reminderEvents);
      });
    if (this.selectedStudent.studentId) {
      const schoolCode = this.selectedStudent.locationCode || this.selectedStudent.schoolCode;
      if(schoolCode){
        this.calendarServices
        .getSchoolEventsByDate(
          startMonth,
          endMonth,
          schoolCode
        )
        .subscribe((schoolEvents) => {
          this.processSchoolVaction(schoolEvents);
        });
      }
    }

  }
  filterEvents($event) {
    if (this.role == 'counselor') {
      if($event.studentId || $event.id){
        this.selectedStudent = $event;
        this.cacheStudent = $event;
      } else {
        this.selectedStudent = this.cacheStudent;
      }
    }
    console.log(this.selectedStudent)
    if (this.selectedStudent.id || this.selectedStudent.studentId) {
      // const schoologyId = this.selectedStudent.schoologyID || this.selectedStudeasdant.schoologyId;
      const studentId = this.selectedStudent.studentId || this.selectedStudent.id;
      this.calendarOptions.events = [];
      // if(studentId && this.selectedStudent.studentId){
      //   this.studentServices
      //   .getStudentAssignmentsByStudentId(studentId)
      //   .subscribe((assignments) => {
      //     this.processAssignments(assignments);
      //   });
      // }
      const schoolCode = this.selectedStudent.locationCode || this.selectedStudent.schoolCode;
      const startMonth = moment(this.currentStart).format('YYYY-MM-DD');
      const endMonth = moment(this.currentEnd).format('YYYY-MM-DD');
      if(schoolCode){
        this.calendarServices
          .getMealsByDate(startMonth, endMonth, schoolCode)
          .subscribe((meals) => {
            this.processMeals(meals);
          });
      }
    }

  }
  processSchoolVaction(events) {
    events.forEach((m) => {

        (this.calendarOptions.events as any).push({
          title: 'School Closed',
          allDay:true,
          date: moment(m.calendarDay).toDate(),
          backgroundColor: this.category[1].hex, // Success (green)
          borderColor: this.category[1].hex, // Success (green)
          id: 'vacations',
        });
          });
  }
  processMeals(meals) {
    meals.forEach((m) => {
      (this.calendarOptions.events as any).push({
        title: m.period,
        date: m.date,
        allDay:true,
        display: m.description,
        backgroundColor: this.category[0].hex, // Success (green)
        borderColor: this.category[0].hex, // Success (green)
        id: 'meals',
      });
    });
  }
  processDistrict(events) {
    events.forEach((t) => {
      (this.calendarOptions.events as any).push({
        title: t.eventLongDescEn,
        allDay: true,
        date: moment(t.eventStartDate).toDate(),
        backgroundColor: this.category[2].hex, // Success (green)
        borderColor: this.category[2].hex, // Success (green)
        id: 'district events'
      });
    });
  }
  processCare(events) {

    if(!this.selectedStudent.studentId)return
    else {
      console.log(events, this.selectedStudent)
      if(parseInt(this.selectedStudent.gradeLevel) >= 9 || parseInt(this.selectedStudent.gradeId) >= 9 || parseInt(this.selectedStudent.gradeCode) >= 9){

        events.forEach((t) => {
          (this.calendarOptions.events as any).push({
            title: t.eventShortDescEn,
            allDay: true,
            date: moment(t.eventStartDate).toDate(),
            backgroundColor: this.category[4].hex, // Success (green)
            borderColor: this.category[4].hex, // Success (green)
            id: 'care'
          });
        });
      } else {

        return
      }
    }

  }
  processReminders(events) {
    events.forEach((t) => {
      let allDay = t.startDate == t.endDate;
      (this.calendarOptions.events as any).push({
        title: t.title,
        date: moment(t.startDate.replaceAll('T', ' ')).toDate(),
        start: moment(t.startDate.replaceAll('T', ' ')).toDate(),
        end: moment(t.endDate.replaceAll('T', ' ')).toDate(),
        backgroundColor: this.category[3].hex, // Success (green)
        borderColor: this.category[3].hex, // Success (green)
        id: 'reminders',
        allDay: allDay
      });
    });
    console.log(this.calendarOptions.events, 'cal events')
  }
  processAssignments(assignments) {
    let tasks = new Array();
    assignments.section.forEach((a) => {
      tasks = tasks.concat(a.assignments.assignment);
    });
    tasks.forEach((t) => {
      if(t.due.includes("23:59:00")) {
        (this.calendarOptions.events as any).push({
          title: t.title,
          date: t.due,
          allDay:true,
          backgroundColor: this.category[0].hex, //Success (green)
          borderColor: this.category[0].hex, //Success (green)
          id: 'assignments'
        });
      }
      else {
        (this.calendarOptions.events as any).push({
          title: t.title,
          date: t.due,
          allDay:false,
          backgroundColor: this.category[0].hex, //Success (green)
          borderColor: this.category[0].hex, //Success (green)
          id: 'assignments'
        });
      }
    });
  }
  handleDatesRender($event) {
    console.log($event);
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
    console.log(calendarOptions);
  }

  handleDateSelect(selectInfo: DateSelectArg) { }

  eventDrag(event) {
    const dialogRef = this.dialog.open(SelectEventTimeComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == null || result == '') {

      } else {
        const time = moment(result).format('HH:mm');
        const date = moment(event.dateStr + ' ' + time).format();
        this.loaderService.setHttpProgressStatus(true);
        this.calendarServices.createEvent(event.draggedEl.innerText, event.dateStr + ' ' + time, '', this.profileService.identity.userIdRole).subscribe((res) => {
          this.calendarComponent.getApi().addEventSource([{ date, dateStr: event.dateStr, title: event.draggedEl.innerText, backgroundColor: this.externalEvents[event.draggedEl.id].hex }]);
          this.calendarComponent.getApi().refetchEvents();
          this.loaderService.setHttpProgressStatus(false);
          if (this.RemoveAfterDrop) {
            this.externalEvents.splice(parseInt(event.draggedEl.id), 1);
          }
        });
      }
    });
  }

  getEventDesc(event){
    let chip = document.createElement('div');
    let startEvent;
    let allDay = '';
    console.log(event, 'event')

    //allDay events
    if(event.allDay){
      allDay = 'Yes';
      startEvent = moment(event.start).format("ll");
    }
    else {
      allDay = 'No';
      startEvent = moment(event.start).format("lll");
    }
    let descTemplate = "<div><strong v3-translate>All Day: </strong>" + allDay + "</div><div><strong v3-translate>Date: </strong>"+ startEvent +"</div>"
    switch (event.id) {
      case 'vacations':
        chip.innerHTML = '<span class="p-2 mb-2 badge badge-pill badge-primary" style="background-color: #2196F3!important" v3-translate>Vacations</span>';
        return chip.outerHTML + descTemplate
      case 'meals':
        let content = event.display.replaceAll('| ','\r\n');
        chip.innerHTML = `<div class="mb-2 swal-center-text">${content}</div><span class="p-2 mb-2 badge badge-pill badge-primary" style="background-color: #005E32!important" v3-translate>Meals</span>`;
        return chip.outerHTML + descTemplate
      case 'district events':
        chip.innerHTML = `<span class="p-2 mb-2 badge badge-pill badge-primary" style="background-color: #F44336!important" v3-translate>Enrollment Key Dates</span>`;
        return chip.outerHTML + descTemplate
      case 'care':
        chip.innerHTML = '<span class="p-2 mb-2 badge badge-pill badge-primary" style="background-color: #059e8f!important" v3-translate>CARE</span>';
        return chip.outerHTML + descTemplate
      case 'reminders':
        chip.innerHTML = '<span class="p-2 mb-2 badge badge-pill badge-primary" style="background-color: #673AB7!important" v3-translate>Added Events</span>';
        return chip.outerHTML + descTemplate
      case 'assignments':
        chip.innerHTML = '<span class="p-2 mb-2 badge badge-pill badge-primary" style="background-color: #059e8f!important" v3-translate>Schoology</span>';
        return chip.outerHTML + descTemplate
      default:
        break;
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
    this.selectedEvent = clickInfo.event;
    let swalTemplate = document.createElement('div');
        swalTemplate.innerHTML = this.getEventDesc(this.selectedEvent);
    // this.classicModal.show();
    let title = this.selectedEvent.title ? this.selectedEvent.title : 'N/A';
    title = title.replaceAll('| ', '\r\n')
    swal({
      title: title,
      content: swalTemplate,
      icon: 'info',
      customClass: {
        container: ".calendar-alert"
      },
      buttons: this.role === "student" ?
        {
          confirm: {
            text: 'Close',
            className: 'bg-info',
            closeModal: true
          }
        } :
        { confirm: {
          text: 'Add to Cal',
          value: true,
          visible: this.selectedEvent.id === 'reminders' ? false : true,
          className: 'bg-success',
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---

        if (this.role === "student"){
          console.log("Add to Cal function disabled");
          return;
        };
        console.log("Add to Cal Triggered")
        let title = this.selectedEvent.title;
        let startDate = moment(this.selectedEvent.start).format('YYYY-MM-DD');
        let endDate = moment(this.selectedEvent.end || startDate).format('YYYY-MM-DD');
        this.calendarServices.createEvent(
          title,
          startDate,
          endDate,
          this.profileService.identity.userIdRole).toPromise().then((response)=>{
            this.toastr.success(
              `Successfully created a reminder for  ${this.selectedEvent.title}`
            );
        });
        //add to user's native calendar here
      }
    });
  }

  handleEvents(events: any) {
    if (events.view) {
      const selector = events.view.type;
      this.currentStart = events.view.currentStart;
      this.currentEnd = events.view.currentEnd;
      this.filterEvents(events.view);
      this.filterProfileEvents();
      // if(selector == 'dayGridMonth') {
      //   let start = events.view.currentStart;
      //   let end = events.view.currentEnd;
      //   console.log(start)
      //   console.log(end)
      // }
    }
  }
  getEvColorClasses(evcolor) {
    return (
      'bg-' + evcolor + (this.evSelectedColor === evcolor ? ' selected' : '')
    );
  }

  addNewExternalEvent() {
    const dialogRef = this.dialog.open(SelectEventTimeComponent, {
      disableClose: true,
      panelClass: 'hide-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == null || result == '') {

      } else {
        let startDateTime = '';
        let endDateTime = '';
        if (result.startDateTime != undefined) {
          startDateTime = moment(result.startDateTime).format('YYYY-MM-DDTHH:mm:ss');
          endDateTime = moment(result.startDateTime).add(5, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        }

        console.log('startDateTime', startDateTime);

        this.loaderService.setHttpProgressStatus(true);
        this.calendarServices.createEvent(this.evNewName, startDateTime, endDateTime, this.profileService.identity.userIdRole).subscribe((res) => {
          console.log('here--- ', res);
          this.notificationServices.showSuccessToast(`New Reminder/Event has been added successfully.`);
          if (startDateTime != '') {
            this.calendarComponent.getApi().addEventSource([{ date: startDateTime, dateStr: moment(result.startDateTime).format('YYYY-MM-DD'), title: this.evNewName, color: '#673AB7' }]);
            this.calendarComponent.getApi().refetchEvents();
          }

          this.loaderService.setHttpProgressStatus(false);
          this.reminderEventsData.push({
            title: this.evNewName,
            color: 'purple',
            hex: '#673AB7'
          });
          this.evNewName = '';
        });
      }
    });

  }
}

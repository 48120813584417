import { ViewResultsComponent } from '../../../../routes/shared/survey-manager-route/view-results/view-results.component';
import { RoleServiceFactoryService } from 'src/app/shared/services/role-service-factory.service';
import { StudentsService } from './../../../../shared/services/students.service';
import { StudentPbisService } from './../../../../shared/services/student-pbis.service';
import { take } from 'rxjs/operators';
import { SurveyService } from './../../../../shared/services/survey.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import * as m from 'moment';
import * as _ from 'lodash';
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';

const swal = require('sweetalert');
@Component({
  selector: 'app-student-surveys',
  templateUrl: './student-surveys.component.html',
  styleUrls: ['./student-surveys.component.scss']
})
export class StudentSurveysComponent implements OnInit {

  single = false;

  searchKey = '';

  mockData = [];

  role = null;
  isStudent = false;

  tableData: any[] = [];
  studentProfile: any = null;
  now = new Date();
  lastUpdated: any;
  studentId: any = null;
  isLoading = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private surveyServices: SurveyService,
    private studentServices: StudentsService,
    private studentPbis:StudentPbisService,
    public rs:RoleServiceFactoryService,
    private profileService: ProfileService,
    public readonly tourService: TourService,
    private notificationServices: NotificationServices,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.parent.params.subscribe((p)=>{
      this.studentId = p.studentId;
      this.studentPbis.getPbisSurveys(p.studentId).subscribe((data)=>{
        this.isLoading = false;
        this.tableData = data.surveys;
        this.single = true;
        this.startTour();
      }, error => {
        this.isLoading = false;
        //this.toastr.error(`Can't load Surveys`);
      });
      this.studentServices.getStudentById(p.studentId).subscribe((student)=>{
        this.studentProfile = student;
      })
      this.lastUpdated = m();
    })
    this.role = this.profileService.role;
    if(this.role === "Student"){
      this.isStudent = true;
    } else {
      this.isStudent = false;
    }


  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'Surveys sent by your teachers/counselors will appear here',
      title: 'Surveys',
      placement: above,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  createSurvey() {
    this.router.navigate(['create'], { relativeTo: this.route });
  }

  viewResults(item) {
    console.log('ViewResults---');
    this.dialog.open(ViewResultsComponent, {
      disableClose: false,
      width: '800px',
      panelClass: 'view-results-modal',
      data: {
        survey: item, // FIXME: remove this line
        id: item.id // TODO: should be survey ID that can be used for API call to get Survey Info
      }
    }).afterClosed().pipe(take(1)).subscribe(res => {
      console.log(res, '====');
    });
  }

  delete(item) {
    swal({
      title: `Delete survey ${item.name}`,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        this.surveyServices.deleteSurvey(item.id).subscribe(() => {
          _.remove(this.tableData,{id:item.id});
        });
      }
    });

  }

  edit(survey) {
    console.log(survey, '===edit===');
    this.router.navigate(['edit', survey.id], { relativeTo: this.route });
  }

  answer(survey){
    this.router.navigate([survey.id],{relativeTo:this.route})
  }
  test() {
    console.log('test---');
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatorService } from 'src/app/core/translator/translator.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from '../../services/api.service';
import { LanguageModalComponent } from '../language-modal/language-modal.component';

@Component({
  selector: 'app-side-fab-button',
  templateUrl: './side-fab-button.component.html',
  styleUrls: ['./side-fab-button.component.scss']
})
export class SideFabButtonComponent implements OnInit {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.communicationsServices;

  previousLng: string = 'en';
  currentLng: string = 'en';
  defaultLng: string = 'en';



  constructor(
    private dialog: MatDialog,
    private appConfig: AppConfigService,
    private api: ApiService,
    private translator: TranslatorService
  ) { }

  ngOnInit(): void {
  }

  openModal() {
    this.dialog.open(LanguageModalComponent,  {
      width: "60%",
      data: this.currentLng
    }).afterClosed().subscribe(newLng => {

      if(newLng) {
        this.currentLng = newLng;
        this.translator.fabLng$.next(this.currentLng);
        if(this.currentLng == 'en'){
          location.reload();
          return
        }
        this.processTranslate()
        this.previousLng = newLng;
      }
    })
  }

  processTranslate(){
    var words = []
    var tags = []
    var currentLng = this.currentLng;
    var elements = document.querySelectorAll(`body [v3-translate]`)
    if(currentLng == this.defaultLng)
    return;
    elements.forEach(e=>{
      var lang = e.getAttribute('v3-translate');
      if(lang != currentLng) {
        if(currentLng != this.defaultLng){
          var translateSource = e.getAttribute('v3-translate-source');
          if(translateSource == undefined){
            e.setAttribute('v3-translate-id',e.textContent);
          }
        }
        if(e.childNodes.length == 1){
          if(e.childNodes[0].nodeValue){
            tags.push(e)
            words.push(e.childNodes[0].nodeValue)
          }
        }
      }

    })
    if(tags.length > 0 && this.previousLng != currentLng) {
      let translatorObs = this.translateWords(words, this.previousLng);
      if(translatorObs)
        translatorObs.subscribe((result)=>{
          result.forEach((r,i)=>{
            tags[i].setAttribute('v3-translate',currentLng);
            tags[i].innerHTML = r.target;
          })
        });
    }
  }

  translateWords(words,source) {
    let lng = this.currentLng;
    if(!this.previousLng)
      this.previousLng = this.defaultLng;
    if(lng == null)
      lng = 'en'
    if(source == '')
      source = 'en';
    let path = `${this.baseUrl}/v1/translator?source=${this.previousLng}&target=${lng}`;
    return this.api.post(path, words).pipe(map((response)=>response as any));
  }

}

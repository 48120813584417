import { NotificationActions } from "./../notifications.feature";
import { CertificateService } from "./../../services/certificate.service";
import { CdnService } from "./../../../services/cdn.service";
import { forkJoin, of, scheduled } from "rxjs";
import { ProfileService } from "./../../services/profile.service";
import { UserActions } from "./users.feature";
import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { filter, map, switchMap } from "rxjs/operators";
import { OAuthService } from "angular-oauth2-oidc";
import { AnalyticsService } from "../../services/analytics.service";
import { NotificationHubService } from "../../services/notification-hub.service";

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private cdnService: CdnService,
    private certificateServices: CertificateService,

    private notificationHub: NotificationHubService,
    private oauthService: OAuthService
  ) {}

  loadMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LoggedIn),
      switchMap(() =>
        forkJoin({
          userProfile: this.profileService.getProfile(),
          userClaims: of(this.oauthService.getIdentityClaims()),
          cdn: this.certificateServices.getThumbnails(),
        }).pipe(
          map((join) => {
            this.notificationHub.connect();
            var s3public = `http://${join.cdn.s3PublicRepository}.s3.us-west-2.amazonaws.com/`;
            this.cdnService.basePath = s3public;
            return UserActions.LoadUserProfile({
              userProfile: join.userProfile,
              userClaims: join.userClaims,
            });
          })
        )
      )
    )
  );
  receiveNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LoadUserProfile),
      map(() => NotificationActions.ReceivedSignal())
    )
  );
}

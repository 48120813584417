import { Component, OnInit, Inject, ViewChild, AfterViewInit, Input,Output ,EventEmitter} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AudienceService } from '../../services/audience.service';
import { ProfileService , Profile } from '../../services/profile.service';
import { CommunicationGroupService } from "../../services/communication-group.service";

@Component({
  selector: 'app-audience-list-widget',
  templateUrl: './audience-list-widget.component.html',
  styleUrls: ['./audience-list-widget.component.scss']
})
export class AudienceListWidgetComponent implements OnInit, AfterViewInit {

  isEngagement = false;
  searchKey: any;
  list: any[] = [];
  listArray: any[] = [];
  audience: any[] = [];
  _customData = []
  audienceModal = true;
  allSchools: boolean = false;
  private _categoryId: string;
  @Output() onLoaded = new EventEmitter<Profile>();

  @Input() set customData(value: any) {
     this._customData = value;
     this.dataSource.data = this._customData;
  }

  get customData(): any {
      return this._customData;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tableColumns = ['picture', 'name', 'type'];
  dataSource = new MatTableDataSource<any>();

  constructor(
    private audienceService: AudienceService,
    private router: Router,
    public dialogRef: MatDialogRef<AudienceListWidgetComponent>,
    private profileService: ProfileService,
    private cGroups: CommunicationGroupService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.list) {
      this.list = data.list;
    }
    if (data && data.isEngagement){
      this.isEngagement = data.isEngagement;
    }
  }

  parentNum = 0;
  studentNum = 0;
  ngOnInit(): void {
    const entity = this.data.entity;
    const entityId = this.data.entityId;
    if(this.list?.length > 0) {
      this.dataSource.data = this.list;
      this.parentNum = this.list.filter(c=>c.target == 'Parent').length;
      this.studentNum = this.list.filter(c=>c.target == 'Student').length;
      return;
    };
    if(entity == "communication-group"){
    var x : any;
    this.cGroups
            .getCommunicationGroupsByStaff(this.profileService.identity.userIdRole)
            .subscribe((communicationGroups) => {
              //only display communication groups/NO parents
              x = communicationGroups.filter((cg)=>{
                 return cg.id == entityId;
              });

              x[0].students.forEach(s =>{
                this.profileService.getProfileById(s).subscribe(p =>{
                this.audience.push(  {Name : p.fullName, targetId : p.id, target: p.role });});
                this.list = this.audience;
                this.parentNum = this.list.filter(c=>c.target == 'Parent').length;
                this.studentNum = this.list.filter(c=>c.target == 'Student').length;
                this.dataSource.data = this.list;
              })
              
            });
    
           
  }
  else{
    if(!this.data.list.extraData?.allSchools){
      this.allSchools = false;
    this.audienceService.getAudience(entity, entityId).subscribe((response) => {
      this.list = response;
      this.dataSource.data = this.list;
      this.dataSource.data.forEach(async x => {
       var y = await this.getProfileName(x.targetId);
       x.Name = y;
      })
      this.parentNum = this.list.filter(c=>c.target == 'Parent').length;
    this.studentNum = this.list.filter(c=>c.target == 'Student').length;
    });
  }
  else{
this.allSchools = true
  }
  }
    var t = this.dataSource.data;
  }
  onClose() {
    this.dialogRef.close();
  }

  goPrint() {
    this.dialogRef.close({
      list: this.list,
      print: true
    })

  }

  async getProfileName(userId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.profileService.getProfileById(userId).toPromise().then((profile) => {
        let displayName = '';
        
        if (profile.lastName) {
          displayName = `${profile.lastName}`;
        }
        if (profile.firstName) {
          displayName = `${profile.firstName}`;
        }
        if (profile.lastName && profile.firstName) {
          if (!this.audienceModal) {
            displayName = `${profile.lastName}, ${profile.firstName}`;
          } else {
            displayName = `${profile.firstName} ${profile.lastName}`;
          }
        }
        
        this.onLoaded.emit(profile);
        resolve(displayName);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

    applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.parentNum = this.dataSource.filteredData.filter(c=>c.target == 'Parent').length;
    this.studentNum = this.dataSource.filteredData.filter(c=>c.target == 'Student').length;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {

  comments = [];
  title = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentsModalComponent>,
    ) { }

  ngOnInit(): void {
    this.title = this.data.item.name;
    console.log(this.title)
  }

  onClose() {
    this.dialogRef.close();
  }

}

import { IFaqGroup } from './../../../../shared/components/faq-section-widget/models/Faq';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpCenterService } from 'src/app/shared/services/help-center.service';
import { StudentsService } from 'src/app/shared/services/students.service';

@Component({
  selector: 'app-student-transitioning',
  templateUrl: './student-transitioning.component.html',
  styleUrls: ['./student-transitioning.component.scss']
})
export class StudentTransitioningComponent implements OnInit {

  faq: IFaqGroup = null;
  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private helpService: HelpCenterService
  ) {}

  ngOnInit(): void {
    this.activeRoute.parent.params.subscribe((data) => {
      console.log(data);
      this.studentService
        .getStudentAttendace(data.studentId)
        .subscribe(() => {

        });
    });
    this.helpService.getLinks(["LAUSD School Choice Programs"]).subscribe((faq) => {
      this.faq = faq;
    });
  }


}

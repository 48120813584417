import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProfileService } from "../../services/profile.service";
import { StaffFilesService } from "../../services/staff-files.service";

@Component({
  selector: "app-file-preview-modal",
  templateUrl: "./file-preview-modal.component.html",
  styleUrls: ["./file-preview-modal.component.scss"],
})
export class FilePreviewModalComponent implements OnInit {
  staffId;
  selectedItem;
  previewUrl;
  isSurveyPreview: boolean = false;
  imgOnly: boolean = false;
  imgSrc: string = '';

  constructor(
    private staffFileServices: StaffFilesService,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.staffId =  this.data.staffId || this.profileService.identity.userIdRole;
    this.selectedItem = this.data.item;
    this.previewUrl = this.data.previewUrl;
    this.isSurveyPreview = this.data.isSurveyPreview;
    this.imgOnly = this.data.imgOnly;
    this.imgSrc = this.data.imgSrc;
    console.log(this.imgOnly)
  }

  getType() {
    switch (this.selectedItem.fileType) {
      case "png":
        return true;
      case "jpg":
        return true;
      case "jpeg":
        return true;
      case "gif":
        return true;
      case "webm":
        return true;
      case "svg":
        return true;
      case "tiff":
        return true;
      case "tif":
        return true;
      default:
        return false;
    }
  }

  download() {
    if (this.selectedItem) {
      this.staffFileServices
        .getDownloadLink(this.staffId, this.selectedItem.fileId || this.selectedItem.id)
        .subscribe((url) => {
          if (url) {
            window.open(url.downloadLink, "_blank");
          }
        });
    }
  }


  downloadImage() {
    if (this.imgSrc.length > 0) {
      window.open(this.imgSrc, "_blank")
    }
  }
}

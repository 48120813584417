import { CertificateService } from "src/app/shared/services/certificate.service";
import { ImageCropModalComponent } from "./image-crop-modal/image-crop-modal.component";
import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { TranslatorService } from "src/app/core/translator/translator.service";
import { ProfileService } from "./../../services/profile.service";
import { NotificationServices } from "./../../services/notification.service";
import { StaffSettingsService } from "../../services/staff-settings.service";
import { AppConfigService } from "src/app/services/app-config.service";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { UploadService } from "../../services/upload.service";
import { email } from "ngx-custom-validators/src/app/email/validator";
import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from "ngx-ui-tour-md-menu";
import swal from "sweetalert";
import { AnalyticsService } from "../../services/analytics.service";

@Component({
  selector: "app-settings-widget",
  templateUrl: "./settings-widget.component.html",
  styleUrls: ["./settings-widget.component.scss"],
})
export class SettingsWidgetComponent implements OnInit, OnDestroy {
  settingActive = 1;
  @Input() profile: any = null;
  @Input() contacts: any;
  @Input() userRole: any; // parent - student - teacher

  @ViewChild("firstName") firstName: HTMLElement;

  selectedAvi;
  avi64;
  profileForm = this.fb.group({
    nickname: [""],
    bio: [""],
    url: [""],
    picture: [""],
    firstName: [""],
    lastName: [""],
    middleName: [""],
    email: [{ value: "" }],
    language: [""],
    handbook: [""],
  });
  profilePicture;
  tempPic;
  isEditingEmail = false;
  staffId = this.profileService.identity.userIdRole;

  isEditingFirstName = false;
  isEditingMiddleName = false;
  isEditingLastName = false;

  isUpdatingPhoto = false;
  isDownloadSelected =true;


  handbooks: any[] = [
    {
      lang: 'English',
      url: 'https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/1236/ParentStudentHandbook-2022-2023-English.pdf',
    },
    {
      lang: 'Spanish',
      url: 'https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/1236/ParentStudentHandbook_2022-23-Spanish.pdf',
    },
    {
      lang: 'Armenian',
      url: 'https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/1236//ARMENIAN-2022-23/PSH_ARMENIAN_2022-23_082222.pdf',
    },
    {
      lang: 'Chinese',
      url: 'https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/1236//CHINESE-2022-23/PSH_CHINESE_2022_23_082222.pdf',
    },
    {
      lang: 'Korean',
      url: 'https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/1236//KOREAN-2022-23/PSH_Korean%202022-23_082222.pdf',
    },
 
  ]

  get fallbackImg() {
    const avatars = [
      {
        role: 'Teacher',
        img: '/assets/img/icons/teacher.svg'
      },
      {
        role: 'Student',
        img: '/assets/img/icons/student.svg'
      },
      {
        role: 'Parent',
        img: '/assets/img/icons/parent.svg'
      },
      {
        role: 'Principal',
        img: '/assets/img/icons/principal.svg'
      },
      {
        role: 'Administrator',
        img: '/assets/img/icons/admin.svg'
      },
      {
        role: 'Counselor',
        img: '/assets/img/icons/admin.svg'
      },
    ]
    return avatars.find(r => r.role == this.profileService.profile.role).img;
  }

  aviList = [
    {
      id: 0,
      img: "assets/img/avatars/0.png",
    },
    {
      id: 1,
      img: "assets/img/avatars/1.png",
    },
    {
      id: 2,
      img: "assets/img/avatars/2.png",
    },
    {
      id: 3,
      img: "assets/img/avatars/3.png",
    },
    {
      id: 4,
      img: "assets/img/avatars/4.png",
    },
    {
      id: 5,
      img: "assets/img/avatars/5.png",
    },
    {
      id: 6,
      img: "assets/img/avatars/6.png",
    },
    {
      id: 7,
      img: "assets/img/avatars/7.png",
    },
    {
      id: 8,
      img: "assets/img/avatars/8.png",
    },
    {
      id: 9,
      img: "assets/img/avatars/9.png",
    },
    {
      id: 10,
      img: "assets/img/avatars/10.png",
    },
  ];

  languages = [
    {slug: 'english', value: 'en', lang: 'English'},
    {slug: 'spanish', value: 'es', lang: 'Español'},
    {slug: 'italian', value: 'it', lang: 'Italiano'},
    {slug: 'persian', value: 'fa', lang: 'فارسی'},
    {slug: 'korean', value: 'ko', lang: '한국어'},
    {slug: 'armenian', value: 'hy', lang: 'Հայերեն'},
    {slug: 'russian', value: 'ru', lang: 'Рyсский'},
    {slug: 'tagalog', value: 'tl', lang: 'Wikang Tagalog'},
    {slug: 'vietnamese', value: 'vi', lang: 'Tiếng Việt'},
    {slug: 'chinese', value: 'zh', lang: '中文'},
  ]

  // teacher setting
  dailyQuietHour = true;
  quietDays = true;
  from = new Date();
  to = new Date();
  settings: any = null;

  rounded = true;

  previousLanguage = "";
  previousHandbook = false;
  quietHours;
  s3public = "";
  photoRemoved = false;
  env = null;
  constructor(
    private notificationServices: NotificationServices,
    public profileService: ProfileService,
    private translator: TranslatorService,
    private fb: FormBuilder,
    private staffSettingsService: StaffSettingsService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private appConfig: AppConfigService,
    private uploader: UploadService,
    private certificateServices: CertificateService,
    public tourService: TourService,
    private analyticsService: AnalyticsService
  ) {

    this.env = this.appConfig.configuration.environment.configuration.environment_name;
  }
  

  ngOnInit(): void {
    this.startTour();
    const lastSettingActive = localStorage.getItem("settingActive");
    if (lastSettingActive) {
      this.settingActive = +lastSettingActive;
    }
    this.previousLanguage = this.profileService.profile.language;
    this.previousHandbook = this.profileService.profile.handbook;

    switch (this.userRole) {
      case "teacher":
        this.profileService.getProfile().subscribe((response) => {
          this.profile = response;
          console.log(this.profile)
          //this.profilePicture = response.picture;
          if (!this.profile.language) {
            this.profile.language = "en";
          }

          this.profileForm.patchValue(this.profile);
        });

        this.staffSettingsService
          .getSettings(this.staffId)
          .subscribe((settings) => {
            this.settings = settings;
            const dbQuietHours = settings.quietHours;
            this.quietHours = {
              sunday: {
                from: dbQuietHours.sundayStartTime,
                to: dbQuietHours.sundayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.sundayAllDay,
                selected: dbQuietHours.sunday,
                openFrom: false,
                openTo: false,
              },
              monday: {
                from: dbQuietHours.mondayStartTime,
                to: dbQuietHours.mondayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.mondayAllDay,
                selected: dbQuietHours.monday,
                openFrom: false,
                openTo: false,
              },
              tuesday: {
                from: dbQuietHours.tuesdayStartTime,
                to: dbQuietHours.tuesdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.tuesdayAllDay,
                selected: dbQuietHours.tuesday,
                openFrom: false,
                openTo: false,
              },
              wednesday: {
                from: dbQuietHours.wednesdayStartTime,
                to: dbQuietHours.wednesdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.wednesdayAllDay,
                selected: dbQuietHours.wednesday,
                openFrom: false,
                openTo: false,
              },
              thursday: {
                from: dbQuietHours.thursdayStartTime,
                to: dbQuietHours.thursdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.thursdayAllDay,
                selected: dbQuietHours.thursday,
                openFrom: false,
                openTo: false,
              },
              friday: {
                from: dbQuietHours.fridayStartTime,
                to: dbQuietHours.fridayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.fridayAllDay,
                selected: dbQuietHours.friday,
                openFrom: false,
                openTo: false,
              },
              saturday: {
                from: dbQuietHours.saturdayStartTime,
                to: dbQuietHours.saturdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.saturdayAllDay,
                selected: dbQuietHours.saturday,
                openFrom: false,
                openTo: false,
              },
            };
          });

        break;
      case "counselor":
        this.profileService.getProfile().subscribe((response) => {
          this.profile = response;
          //this.profilePicture = response.picture;
          if (!this.profile.language) {
            this.profile.language = "en";
          }

          this.profileForm.patchValue(this.profile);
        });

        this.staffSettingsService
          .getSettings(this.staffId)
          .subscribe((settings) => {
            this.settings = settings;
            const dbQuietHours = settings.quietHours;
            this.quietHours = {
              sunday: {
                from: dbQuietHours.sundayStartTime,
                to: dbQuietHours.sundayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.sundayAllDay,
                selected: dbQuietHours.sunday,
                openFrom: false,
                openTo: false,
              },
              monday: {
                from: dbQuietHours.mondayStartTime,
                to: dbQuietHours.mondayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.mondayAllDay,
                selected: dbQuietHours.monday,
                openFrom: false,
                openTo: false,
              },
              tuesday: {
                from: dbQuietHours.tuesdayStartTime,
                to: dbQuietHours.tuesdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.tuesdayAllDay,
                selected: dbQuietHours.tuesday,
                openFrom: false,
                openTo: false,
              },
              wednesday: {
                from: dbQuietHours.wednesdayStartTime,
                to: dbQuietHours.wednesdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.wednesdayAllDay,
                selected: dbQuietHours.wednesday,
                openFrom: false,
                openTo: false,
              },
              thursday: {
                from: dbQuietHours.thursdayStartTime,
                to: dbQuietHours.thursdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.thursdayAllDay,
                selected: dbQuietHours.thursday,
                openFrom: false,
                openTo: false,
              },
              friday: {
                from: dbQuietHours.fridayStartTime,
                to: dbQuietHours.fridayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.fridayAllDay,
                selected: dbQuietHours.friday,
                openFrom: false,
                openTo: false,
              },
              saturday: {
                from: dbQuietHours.saturdayStartTime,
                to: dbQuietHours.saturdayEndTime,
                fromDate: null,
                toDate: null,
                allDay: dbQuietHours.saturdayAllDay,
                selected: dbQuietHours.saturday,
                openFrom: false,
                openTo: false,
              },
            };
          });

        break;

      case "student":
        this.profile = JSON.parse(JSON.stringify(this.profileService.profile));
        if (!this.profile.language) {
          this.profile.language = "en";
          this.profileForm.patchValue(this.profile);
          this.previousLanguage = this.profile.language;
        }
        // this.profile = JSON.parse(JSON.stringify(this.profileService.profile));
        // if (!this.profile.language) {
        //   this.profile.language = "en";
        //   this.profileForm.patchValue(this.profile);
        // }
      case "parent":
        this.profile = JSON.parse(JSON.stringify(this.profileService.profile));
        if (!this.profile.language) {
          this.profile.language = "en";
        }

        this.profileForm.patchValue(this.profile);
        this.profileForm.get("email");
        break;

      default:
        break;
    }
    if (this.profileService.profile.avatar !== null) {
      this.certificateServices
        .getThumbnails()
        .toPromise()
        .then((data) => {
          this.s3public =
            "https://" +
            data.s3PublicRepository +
            ".s3.us-west-2.amazonaws.com/profiles/avatars/";
          this.profilePicture = `${this.s3public}${this.profileService.profile.avatar}`;
          this.previousPicture = this.profilePicture;
          if (this.profileService.profile.avatar == null) {
            this.profilePicture = "./assets/img/preloader/preloader.full.png";
            this.previousPicture = this.profilePicture;
          }
        });
      if (!this.profilePicture) {
        this.profilePicture = "./assets/img/preloader/preloader.full.png";
        this.previousPicture = this.profilePicture;
      }
    }
  }

  previousPicture: any;

  isStaff() {
    return (
      this.profileService.identity.userType !== "Parent" &&
      this.profileService.identity.userType !== "Student"
    );
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: "above" };
    let below: MdMenuPlacement = { yPosition: "below" };
    let left: MdMenuPlacement = { xPosition: "before" };
    let right: MdMenuPlacement = { xPosition: "after" };

    let tourAnchors = [
      {
        anchorId: "start-tour",
        content: "Here you can update many different options related to your account",
        title: "Welcome to your Preferences!",
      },
      {
        anchorId: "account",
        content: "Here you can edit settings like your profile picture or language",
        title: "Preferences",
        placement: right,
      },
      {
        anchorId: "quiet-hours",
        content: "Quiet hours will allow you to set times that you are available for being contacted during the day",
        title: "Quiet Settings",
        placement: right,
      },
    ]

    if(!this.isStaff()){
      tourAnchors = tourAnchors.filter(t => {return t.anchorId !== 'quiet-hours'})
    }
    this.tourService.initialize(tourAnchors);



    if (this.profileService.profile.hasTour) {
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: "warning",
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, turn off tour",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }
  update() {
    const newQuietHours = {
      sunday: this.quietHours.sunday.selected,
      sundayStartTime: this.quietHours.sunday.from,
      sundayEndTime: this.quietHours.sunday.to,
      sundayAllDay: this.quietHours.sunday.allDay,
      monday: this.quietHours.monday.selected,
      mondayStartTime: this.quietHours.monday.from,
      mondayEndTime: this.quietHours.monday.to,
      mondayAllDay: this.quietHours.monday.allDay,
      tuesday: this.quietHours.tuesday.selected,
      tuesdayStartTime: this.quietHours.tuesday.from,
      tuesdayEndTime: this.quietHours.tuesday.to,
      tuesdayAllDay: this.quietHours.tuesday.allDay,
      wednesday: this.quietHours.wednesday.selected,
      wednesdayStartTime: this.quietHours.wednesday.from,
      wednesdayEndTime: this.quietHours.wednesday.to,
      wednesdayAllDay: this.quietHours.wednesday.allDay,
      thursday: this.quietHours.thursday.selected,
      thursdayStartTime: this.quietHours.thursday.from,
      thursdayEndTime: this.quietHours.thursday.to,
      thursdayAllDay: this.quietHours.thursday.allDay,
      friday: this.quietHours.friday.selected,
      fridayStartTime: this.quietHours.friday.from,
      fridayEndTime: this.quietHours.friday.to,
      fridayAllDay: this.quietHours.friday.allDay,
      saturday: this.quietHours.saturday.selected,
      saturdayStartTime: this.quietHours.saturday.from,
      saturdayEndTime: this.quietHours.saturday.to,
      saturdayAllDay: this.quietHours.saturday.allDay,
      isTeaching: this.settings.quietHours.isTeaching,
      isProjecting: this.settings.quietHours.isProjecting,
      isNotDisturb: this.settings.quietHours.isNotDisturb,
      isAvailable: this.settings.quietHours.isAvailable,
    };
    const setting = Object.assign({}, this.settings);
    setting.quietHours = newQuietHours;
    this.processSettings(setting);

    // this.staffSettingsService.patch(staffId, setting).subscribe((settings) => {
    //   this.settings = settings;
    //   this.toastr.success("Successfully updated settings");
    //   // this.staffSettingsService.setSettings(settings);
    //   // this.staffSettingsService.updateStaffQuietSettings(settings);
    // });
  }

  updateLang(ev){
    console.log(ev)
  }

  editAccount(type) {
    switch (type) {
      case "firstName":
        this.isEditingFirstName = true;
        break;
      case "middleName":
        this.isEditingMiddleName = true;
        break;
      case "lastName":
        this.isEditingLastName = true;
        break;
      case "email":
        this.isEditingEmail = true;
        break;

      default:
        break;
    }
  }

  saveAccount(type) {
    switch (type) {
      case "firstName":
        this.save();
        this.isEditingFirstName = false;
        break;
      case "middleName":
        this.save();
        this.isEditingMiddleName = false;
        break;
      case "lastName":
        this.save();
        this.isEditingLastName = false;
        break;
      case "email":
        this.save();
        this.isEditingEmail = false;
        break;

      default:
        break;
    }
  }

  processSettings(settings) {
    const dbQuietHours = settings.quietHours;
    const newQuietHours = {
      sunday: {
        from: dbQuietHours.sundayStartTime,
        to: dbQuietHours.sundayEndTime,
      },
      monday: {
        from: dbQuietHours.mondayStartTime,
        to: dbQuietHours.mondayEndTime,
      },
      tuesday: {
        from: dbQuietHours.tuesdayStartTime,
        to: dbQuietHours.tuesdayEndTime,
      },
      wednesday: {
        from: dbQuietHours.wednesdayStartTime,
        to: dbQuietHours.wednesdayEndTime,
      },
      thursday: {
        from: dbQuietHours.thursdayStartTime,
        to: dbQuietHours.thursdayEndTime,
      },
      friday: {
        from: dbQuietHours.fridayStartTime,
        to: dbQuietHours.fridayEndTime,
      },
      saturday: {
        from: dbQuietHours.saturdayStartTime,
        to: dbQuietHours.saturdayEndTime,
      },
    };
    const setting = Object.assign({}, settings);

    // const noQuietHours = Object.values(newQuietHours).every(
    //   (x) => x["from"] === null && x["to"] === null
    // );
    // if (noQuietHours) {
    //   setting.quietHours.isTeaching = true;
    //   setting.quietHours.isNotDisturb = false;
    // }
    const staffId = this.profileService.identity.userIdRole;
    this.staffSettingsService.patch(staffId, setting).subscribe((settings) => {
      this.toastr.success("Successfully updated settings");
      this.settings = settings;
      this.staffSettingsService.setSettings(settings);
      this.staffSettingsService.updateStaffQuietSettings(settings);
    });
  }

  hidePicker(day: string) {
    switch (day) {
      case "sunday":
        this.quietHours.sunday.openFrom = false;
        this.quietHours.sunday.openTo = false;
        break;
      case "monday":
        this.quietHours.monday.openFrom = false;
        this.quietHours.monday.openTo = false;
        break;
      case "tuesday":
        this.quietHours.tuesday.openFrom = false;
        this.quietHours.tuesday.openTo = false;
        break;
      case "wednesday":
        this.quietHours.wednesday.openFrom = false;
        this.quietHours.wednesday.openTo = false;
        break;
      case "thursday":
        this.quietHours.thursday.openFrom = false;
        this.quietHours.thursday.openTo = false;
        break;
      case "friday":
        this.quietHours.friday.openFrom = false;
        this.quietHours.friday.openTo = false;
        break;
      case "saturday":
        this.quietHours.saturday.openFrom = false;
        this.quietHours.saturday.openTo = false;
        break;
      default:
        break;
    }
  }

  cleanHours(day: string) {
    switch (day) {
      case "monday":
        this.quietHours.monday.from = null;
        this.quietHours.monday.to = null;
        this.quietHours.monday.allDay = false;
        break;
      case "tuesday":
        this.quietHours.tuesday.from = null;
        this.quietHours.tuesday.to = null;
        this.quietHours.tuesday.allDay = false;
        break;
      case "wednesday":
        this.quietHours.wednesday.from = null;
        this.quietHours.wednesday.to = null;
        this.quietHours.wednesday.allDay = false;
        break;
      case "thursday":
        this.quietHours.thursday.from = null;
        this.quietHours.thursday.to = null;
        this.quietHours.thursday.allDay = false;
        break;
      case "friday":
        this.quietHours.friday.from = null;
        this.quietHours.friday.to = null;
        this.quietHours.friday.allDay = false;
        break;
      case "saturday":
        this.quietHours.saturday.from = null;
        this.quietHours.saturday.to = null;
        this.quietHours.saturday.allDay = false;
        break;
      case "sunday":
        this.quietHours.sunday.from = null;
        this.quietHours.sunday.to = null;
        this.quietHours.sunday.allDay = false;
        break;

      default:
        break;
    }
  }

  openFrom(day: string) {
    switch (day) {
      case "sunday":
        if (!this.quietHours.sunday.allDay) {
          this.quietHours.sunday.openFrom = true;
        }
        break;
      case "monday":
        if (!this.quietHours.monday.allDay) {
          this.quietHours.monday.openFrom = true;
        }
        break;
      case "tuesday":
        if (!this.quietHours.tuesday.allDay) {
          this.quietHours.tuesday.openFrom = true;
        }
        break;
      case "wednesday":
        if (!this.quietHours.wednesday.allDay) {
          this.quietHours.wednesday.openFrom = true;
        }
        break;
      case "thursday":
        if (!this.quietHours.thursday.allDay) {
          this.quietHours.thursday.openFrom = true;
        }
        break;
      case "friday":
        if (!this.quietHours.friday.allDay) {
          this.quietHours.friday.openFrom = true;
        }
        break;
      case "saturday":
        if (!this.quietHours.saturday.allDay) {
          this.quietHours.saturday.openFrom = true;
        }
        break;
      default:
        break;
    }
  }

  openTo(day: string) {
    switch (day) {
      case "sunday":
        if (!this.quietHours.sunday.allDay) {
          this.quietHours.sunday.openTo = true;
        }
        break;
      case "monday":
        if (!this.quietHours.monday.allDay) {
          this.quietHours.monday.openTo = true;
        }
        break;
      case "tuesday":
        if (!this.quietHours.tuesday.allDay) {
          this.quietHours.tuesday.openTo = true;
        }
        break;
      case "wednesday":
        if (!this.quietHours.wednesday.allDay) {
          this.quietHours.wednesday.openTo = true;
        }
        break;
      case "thursday":
        if (!this.quietHours.thursday.allDay) {
          this.quietHours.thursday.openTo = true;
        }
        break;
      case "friday":
        if (!this.quietHours.friday.allDay) {
          this.quietHours.friday.openTo = true;
        }
        break;
      case "saturday":
        if (!this.quietHours.saturday.allDay) {
          this.quietHours.saturday.openTo = true;
        }
        break;
      default:
        break;
    }
  }

  setAllDay(day: string, event) {
    switch (day) {
      case "sunday":
        if (event) {
          this.quietHours.sunday.from = "00:00:00";
          this.quietHours.sunday.to = "23:59:00";
          this.quietHours.sunday.openTo = false;
          this.quietHours.sunday.openFrom = false;
        } else {
          this.quietHours.sunday.from = null;
          this.quietHours.sunday.to = null;
        }

        this.quietHours.sunday.allDay = !this.quietHours.sunday.allDay;
        break;
      case "monday":
        if (event) {
          this.quietHours.monday.from = "00:00:00";
          this.quietHours.monday.to = "23:59:00";
          this.quietHours.monday.openTo = false;
          this.quietHours.monday.openFrom = false;
        } else {
          this.quietHours.monday.from = null;
          this.quietHours.monday.to = null;
        }
        this.quietHours.monday.allDay = !this.quietHours.monday.allDay;
        break;
      case "tuesday":
        if (event) {
          this.quietHours.tuesday.from = "00:00:00";
          this.quietHours.tuesday.to = "23:59:00";
          this.quietHours.tuesday.openTo = false;
          this.quietHours.tuesday.openFrom = false;
        } else {
          this.quietHours.tuesday.from = null;
          this.quietHours.tuesday.to = null;
        }

        this.quietHours.tuesday.allDay = !this.quietHours.tuesday.allDay;

        break;
      case "wednesday":
        if (event) {
          this.quietHours.wednesday.openTo = false;
          this.quietHours.wednesday.openFrom = false;
          this.quietHours.wednesday.from = "00:00:00";
          this.quietHours.wednesday.to = "23:59:00";
        } else {
          this.quietHours.wednesday.from = null;
          this.quietHours.wednesday.to = null;
        }

        this.quietHours.wednesday.allDay = !this.quietHours.wednesday.allDay;

        break;
      case "thursday":
        if (event) {
          this.quietHours.thursday.openTo = false;
          this.quietHours.thursday.openFrom = false;
          this.quietHours.thursday.from = "00:00:00";
          this.quietHours.thursday.to = "23:59:00";
        } else {
          this.quietHours.thursday.from = null;
          this.quietHours.thursday.to = null;
        }

        this.quietHours.thursday.allDay = !this.quietHours.thursday.allDay;

        break;
      case "friday":
        if (event) {
          this.quietHours.friday.openTo = false;
          this.quietHours.friday.openFrom = false;
          this.quietHours.friday.from = "00:00:00";
          this.quietHours.friday.to = "23:59:00";
        } else {
          this.quietHours.friday.from = null;
          this.quietHours.friday.to = null;
        }

        this.quietHours.friday.allDay = !this.quietHours.friday.allDay;

        break;
      case "saturday":
        if (event) {
          this.quietHours.saturday.openTo = false;
          this.quietHours.saturday.openFrom = false;
          this.quietHours.saturday.from = "00:00:00";
          this.quietHours.saturday.to = "23:59:00";
        } else {
          this.quietHours.saturday.from = null;
          this.quietHours.saturday.to = null;
        }

        this.quietHours.saturday.allDay = !this.quietHours.saturday.allDay;
        break;
      default:
        break;
    }
  }

  setFrom(time: any, day: string) {
    switch (day) {
      case "sunday":
        this.quietHours.sunday.from = moment(time).format("HH:mm:ss");

        break;
      case "monday":
        this.quietHours.monday.from = moment(time).format("HH:mm:ss");

        break;
      case "tuesday":
        this.quietHours.tuesday.from = moment(time).format("HH:mm:ss");

        break;
      case "wednesday":
        this.quietHours.wednesday.from = moment(time).format("HH:mm:ss");

        break;
      case "thursday":
        this.quietHours.thursday.from = moment(time).format("HH:mm:ss");

        break;
      case "friday":
        this.quietHours.friday.from = moment(time).format("HH:mm:ss");

        break;
      case "saturday":
        this.quietHours.saturday.from = moment(time).format("HH:mm:ss");

        break;
      default:
        break;
    }
  }

  setTo(time: any, day: string) {
    switch (day) {
      case "sunday":
        this.quietHours.sunday.to = moment(time).format("HH:mm:ss");

        break;
      case "monday":
        this.quietHours.monday.to = moment(time).format("HH:mm:ss");

        break;
      case "tuesday":
        this.quietHours.tuesday.to = moment(time).format("HH:mm:ss");

        break;
      case "wednesday":
        this.quietHours.wednesday.to = moment(time).format("HH:mm:ss");

        break;
      case "thursday":
        this.quietHours.thursday.to = moment(time).format("HH:mm:ss");

        break;
      case "friday":
        this.quietHours.friday.to = moment(time).format("HH:mm:ss");

        break;
      case "saturday":
        this.quietHours.saturday.to = moment(time).format("HH:mm:ss");

        break;
      default:
        break;
    }
  }

  getDate(time) {
    return moment(time);
  }

  disallowUpdate() {
    if (
      (this.quietHours.monday.selected &&
        (!this.quietHours.monday.from || !this.quietHours.monday.to)) ||
      (this.quietHours.monday.selected &&
        this.quietHours.monday.from > this.quietHours.monday.to) ||
      (this.quietHours.monday.selected &&
        this.quietHours.monday.from === this.quietHours.monday.to) ||
      (this.quietHours.tuesday.selected &&
        (!this.quietHours.tuesday.from || !this.quietHours.tuesday.to)) ||
      (this.quietHours.tuesday.selected &&
        this.quietHours.tuesday.from > this.quietHours.tuesday.to) ||
      (this.quietHours.tuesday.selected &&
        this.quietHours.tuesday.from === this.quietHours.tuesday.to) ||
      (this.quietHours.wednesday.selected &&
        (!this.quietHours.wednesday.from || !this.quietHours.wednesday.to)) ||
      (this.quietHours.wednesday.selected &&
        this.quietHours.wednesday.from > this.quietHours.wednesday.to) ||
      (this.quietHours.wednesday.selected &&
        this.quietHours.wednesday.from === this.quietHours.wednesday.to) ||
      (this.quietHours.thursday.selected &&
        (!this.quietHours.thursday.from || !this.quietHours.thursday.to)) ||
      (this.quietHours.thursday.selected &&
        this.quietHours.thursday.from > this.quietHours.thursday.to) ||
      (this.quietHours.thursday.selected &&
        this.quietHours.thursday.from === this.quietHours.thursday.to) ||
      (this.quietHours.friday.selected &&
        (!this.quietHours.friday.from || !this.quietHours.friday.to)) ||
      (this.quietHours.friday.selected &&
        this.quietHours.friday.from > this.quietHours.friday.to) ||
      (this.quietHours.friday.selected &&
        this.quietHours.friday.from === this.quietHours.friday.to) ||
      (this.quietHours.saturday.selected &&
        (!this.quietHours.saturday.from || !this.quietHours.saturday.to)) ||
      (this.quietHours.saturday.selected &&
        this.quietHours.saturday.from > this.quietHours.saturday.to) ||
      (this.quietHours.saturday.selected &&
        this.quietHours.saturday.from === this.quietHours.saturday.to) ||
      (this.quietHours.sunday.selected &&
        (!this.quietHours.sunday.from || !this.quietHours.sunday.to)) ||
      (this.quietHours.sunday.selected &&
        this.quietHours.sunday.from > this.quietHours.sunday.to) ||
      (this.quietHours.sunday.selected &&
        this.quietHours.sunday.from === this.quietHours.sunday.to)
    ) {
      return true;
    } else {
      return false;
    }
  }

  selectAvi(avi) {
    this.profilePicture = avi.img;
    this.uploader.getBase64ImageFromURL(avi.img).subscribe((base64data) => {
      // this is the image as dataUrl
      this.avi64 = base64data;
      this.profilePicture = this.avi64;
      this.pictureFile = this.dataURLtoFile(base64data);
    });
    // FIXME: if api response ok
  }

  removePhoto() {
    this.profilePicture = null;
    this.pictureFile = null;
    this.photoRemoved = true;
  }
  pictureFile = null;
  fileChangeListener($event) {
    this.dialog
      .open(ImageCropModalComponent, {
        disableClose: true,
        data: {
          file: $event.target.files[0],
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          // TODO: API call, if Success => this.profilePicture = API res
          this.profilePicture = res.url;
          this.pictureFile = this.dataURLtoFile(res.url);
          this.rounded = res.rounded;
          this.photoRemoved = false;
          // FIXME: if api response ok
        } else {
          // cancel
        }
      });
  }
  dataURLtoFile(dataurl) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], "file.jpg", { type: mime });
  }

  cancel(type) {
    if(type === 'language'){
      this.profileForm.controls['language'].setValue(this.previousLanguage)
    }
    if(type === 'handbook'){
      this.profileForm.controls['handbook'].setValue(this.previousHandbook)
    }
    if(type === 'email'){
      this.isEditingEmail = false;
      this.profileForm.controls['email'].setValue(this.profile.email)
    }
    if(type === 'lastName'){
      this.isEditingLastName = false;
      this.profileForm.controls['lastName'].setValue(this.profile.lastName)
    }
    if(type === 'firstName'){
      this.isEditingFirstName = false;
      this.profileForm.controls['firstName'].setValue(this.profile.firstName)
    }
    if(type === 'middleName'){
      this.isEditingMiddleName = false;
      this.profileForm.controls['middleName'].setValue(this.profile.middleName)
    }
    if(type === 'avatar'){
      this.isUpdatingPhoto = false;
      this.profilePicture = this.previousPicture;
    }
  }

  save() {
    // let emailControl = this.profileForm.get("email");
    // emailControl.enable();
    this.tempPic = null;
    this.profile = this.profileForm.value;
    console.log(this.profile);
    this.profile.userIdRole = this.profileService.identity.userIdRole;
    this.profile.role = this.profileService.role;
    this.profile.themeSettings = this.profileService.profile.themeSettings;
    //this.profile.avatar = this.profileService.profile.avatar;
    //this.profile.picture = this.profilePicture;
    this.translator.lastLng = this.profileService.profile.language;

    if (this.pictureFile) {
      this.profileService
        .uploadAvatar(
          this.profileService.identity.userIdRole,
          this.pictureFile,
          this.profileService.profile.avatar
        )
        .toPromise()
        .then(() => {
          this.profileService
            .getProfile()
            .toPromise()
            .then((profile) => {
              this.analyticsService.sendEvent("User Click", "Avatar Update");
              this.profileService.pictureUpdate.next(profile.avatar);
              this.pictureFile = false;
            });
        });
    } else if (this.photoRemoved) {
      this.profileService
        .uploadAvatar(
          this.profileService.identity.userIdRole,
          this.pictureFile,
          this.profileService.profile.avatar
        )
        .toPromise()
        .then(() => {
          this.profileService
            .getProfile()
            .toPromise()
            .then((profile) => {
              this.profileService.pictureUpdate.next(profile.avatar);
            });
        });
    }

    this.profileService.saveProfile(this.profile).subscribe((res) => {
      this.profileService.getProfile().subscribe((response) => {
        this.profileService.profile = response;
        if (res.status >= 400){
          this.profileForm.controls['language'].setValue(this.profileService.profile.language);
          this.profileForm.controls['handbook'].setValue(this.profileService.profile.handbook);
          this.profileForm.controls['email'].setValue(this.profileService.profile.email);
          this.profileForm.controls['lastName'].setValue(this.profileService.profile.lastName);
          this.profileForm.controls['firstName'].setValue(this.profileService.profile.firstName);
          this.profileForm.controls['middleName'].setValue(this.profileService.profile.middleName);
          this.notificationServices.showErrorToast(
            "Error updating settings"
          );
          
        }else{
          this.notificationServices.showSuccessToast(
            "Successfully updated settings"
          );
        }
        if (
          this.previousLanguage !== this.profile.language &&
          this.previousLanguage &&
          this.profile.language
        ) {
          this.previousLanguage = this.profile.language;
          this.translator.processTranslate();
          localStorage.setItem("settingActive", this.settingActive.toString());
          window.location.reload();
        }
      });
    });

    // emailControl.disable();


    this.previousHandbook = this.profileForm.get('handbook').value;
   

  }
  onHandbookChange(event){
    this.handbookToDownload = null;
    if(event.value){
      this.isDownloadSelected = false;

    } else{
      this.isDownloadSelected = true;
    }
  }


  editEmail() {
    let emailControl = this.profileForm.get("email");
    if (emailControl.disabled) {
      emailControl.enable();
      this.isEditingEmail = true;
    } else {
      emailControl.disable();
      this.isEditingEmail = false;
    }
  }

  disableEmail() {
    this.isEditingEmail = false;
    let emailControl = this.profileForm.get("email");
    emailControl.disable();
  }

  ngOnDestroy() {
    localStorage.removeItem("settingActive");
  }

  goToChangePassword(email) {
    var authUrl =
      this.appConfig.configuration.environment.endPoints.authenticationServices;
    var href = window.location.href;
    window.location.href = `${authUrl}/Account/ResetPassword?email=${email}&returnUrl=${href}`;
  }

  handbookToDownload: any = null;
 

  openHandbook() {
    if (this.profile.handbook === true) {
      window.open(
        "https://achieve.lausd.net/site/handlers/filedownload.ashx?moduleinstanceid=40565&dataid=95848&FileName=Parent_Student_Handbook_2020-21_-_English.pdf",
        "_blank"
      );
    }
  }

  hasUpdatedLanguage() {
    return this.profileForm.get('language').value !== this.previousLanguage
  }

  hasUpdatedHandbook() {
    return this.profileForm.get('handbook').value !== this.previousHandbook
  }
}

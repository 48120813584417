import { ContactsService } from "src/app/shared/services/contacts.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { StaffSettingsService } from "src/app/shared/services/staff-settings.service";
import { StudentsService } from "src/app/shared/services/students.service";
import { IStudentResume } from "../../../../shared/components/student-list-widget/models/IStudentResume";
import { IStudentEmergencyContact } from "src/app/shared/services/student.service.models";
import {
  Profile,
  ProfileService,
} from "src/app/shared/services/profile.service";
import { ParentsService } from "src/app/shared/services/parents.service";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ContactDetailsWidgetComponent } from "src/app/shared/components/contact-details-widget/contact-details-widget.component";
import { RoleServiceFactoryService } from "src/app/shared/services/role-service-factory.service";
import { MisisLookupWidgetComponent } from "src/app/shared/components/misis-lookup-widget/misis-lookup-widget.component";
import { AdministratorService } from "src/app/shared/services/administrator.service";
import { AppConfigService } from "src/app/services/app-config.service";
import swal from "sweetalert";
import * as moment from "moment";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { PrintPinModalComponent } from "src/app/shared/components/print-pin-modal/print-pin-modal.component";
import { AnalyticsService } from "../../../../shared/services/analytics.service";
import { SchoolService } from "src/app/shared/services/school.service";
import { environment } from "src/environments/environment";
import { IEmployeeContact } from "./../../../../shared/services/parent.services.models";
import { combineLatest } from 'rxjs';

@Component({
  selector: "app-contact-profile",
  templateUrl: "./contact-profile.component.html",
  styleUrls: ["./contact-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactProfileComponent implements OnInit, OnDestroy {
  settingActive = 2;
  settings;
  studentId;
  parentId;
  emergencyContacts: IStudentEmergencyContact;
  emContacts: IStudentEmergencyContact;
  misisId;
  contactsList = [];
  parents = [];
  studentDob: any;
  userId = this.profileService.profile.id;

  associatedParents: any = null;
  unassociatedParents: any = null;

  get roleUrl() {
    return this.profileService.role.toLocaleLowerCase();
  }

  get isProd() {
    if (environment.environment_name == "Production") return true;
    else return false;
  }

  isReadOnly0: boolean = true;
  isReadOnly1: boolean = true;
  isEditing0: boolean = false;
  isEditing1: boolean = false;
  isDisabled0: boolean = true;
  isDisabled1: boolean = true;
  isEditingEm: boolean = false;

  get contact() {
    let contact = this.contactService.selectedContact;
    if (!contact) {
      contact = JSON.parse(localStorage.getItem("selectedContact"));
    }
    return contact;
  }
  role: string;
  userRole = this.profileService.role;

  students: IStudentResume[] = [];
  allAssociatedStudents: IStudentResume[] = [];
  contacts;

  currentProfile: Profile = null;
  isActive: boolean = null;
  isExceptionAdded: boolean = null;
  misisParents: any = [];
  pinDetails: any = [];
  schoolName: any;
  gradeLevel: any;
  lastLogin: any;
  email: any = null;
  schoolCode: string;
  costCenterCode: string;
  studentsLoading: boolean = false;
  parentsLoading: boolean = false;
  parentCounts: { [pgId: number]: number } = {};

  constructor(
    private contactService: ContactsService,
    private staffSettingsService: StaffSettingsService,
    private router: Router,
    private studentService: StudentsService,
    private profileService: ProfileService,
    private parentService: ParentsService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog,
    public rs: RoleServiceFactoryService,
    private adminService: AdministratorService,
    private appConfig: AppConfigService,
    private analyticsService: AnalyticsService,
    private schoolService: SchoolService
  ) {}

  ngOnInit(): void {
    this.contacts = [];
    this.profileService
      .getProfileById(this.contact.userRoleId || this.contact.id || this.contact.empCode, false)
      .subscribe((profileResponse) => {
        console.log(profileResponse, "profileresponse");
        this.contact.lastName = profileResponse.lastName;
        this.contact.firstName = profileResponse.firstName;
        this.contact.emailAddressTxt = profileResponse.email;

        this.lastLogin = profileResponse.lastLogin;
        this.currentProfile = profileResponse;

        this.cdref.detectChanges();
        this.isActive =
          profileResponse.accountEnabled == null ||
          profileResponse.accountEnabled == true
            ? true
            : false;
        this.isExceptionAdded =
          profileResponse.studentExceptionEnabled == null
            ? false
            : profileResponse.studentExceptionEnabled;

      });
    this.role = this.contact.role.toLocaleLowerCase();

    this.adminOperations();

    if (this.contact.role === "Teacher") {
      this.staffSettingsService
        .getSettings(this.contact.empCode)
        .subscribe((settings) => {
          this.settings = settings;
        });
    }

    if (this.contact.role == "Parent") {
      this.parentsLoading = true;
      this.profileService
        .getProfileById(this.contact.empCode, false)
        .subscribe((profileResponse) => {
          console.log(profileResponse, "profileresponse");
          this.currentProfile = profileResponse;
          this.isActive =
            profileResponse.accountEnabled == null ||
            profileResponse.accountEnabled == true
              ? true
              : false;
          this.isExceptionAdded = profileResponse.studentExceptionEnabled
            ? profileResponse.studentExceptionEnabled
            : false;

          this.cdref.detectChanges();
        });
      this.studentsLoading = true;
      this.parentService
        .getStudentsByParentId(this.contact.empCode)
        .subscribe((students) => {
          this.allAssociatedStudents = students;     
          console.log("All Associated Students", this.allAssociatedStudents);     
          this.studentsLoading = false;      

          if(this.userRole == this.rs.AdminRole){
            this.students = this.allAssociatedStudents;  
          }else{
            this.checkStudentAccess();
          }
          console.log("Accessible Students", this.students);  
          this.cdref.detectChanges();
        }, err => {
          this.studentsLoading = false;
        });
      // this.profileService.getProfileById(this.contact.empCode).subscribe(parent => {
      //   console.log(parent)
      // })
    }

    if (this.contact.role === "Student") {
      console.log("student", this.contact);
      this.studentService
        .getStudentById(this.contact.empCode || this.contact.id)
        .subscribe((res) => {

          this.studentDob = res.dateOfBirth;
          this.gradeLevel = res.gradeCode;
          this.email = res.emailAddressTxt;
          this.schoolCode = res.schoolCode;
          this.costCenterCode = res.costCenterCode
          this.cdref.detectChanges();
          this.adminService
            .getPinDetails(this.contact.empCode || this.contact.id)
            .subscribe((res) => {
              this.parents = res;

              let req = {
                studentId: this.contact.empCode || this.contact.id,
              };
              this.adminService.getParentFromMisis(req).subscribe((res) => {
                this.misisParents = res;

                this.addMisisDataToParents(
                  this.parents,
                  this.misisParents.parents
                );

                console.log(this.parents);

                this.cdref.detectChanges();
              }, err => {
                this.parentsLoading = false;
              });
            });
          // this.studentService
          //   .getParentsByStudent(this.contact.empCode || this.contact.id)
          //   .subscribe((par) => {
          //     if (par.length > 0) {
          //       this.parents = par;
          //       this.parentId = par[0].id;
          //     }
          //     this.getPinDetails();
          //     this.getEmergencyContacts();
          //   });

          this.schoolService
            .getSchoolsById([res.schoolCode])
            .subscribe((school) => {
              this.schoolName = school[0].schoolName;
            });
        });
    }
    this.cdref.detectChanges();
  }
  ngOnDestroy() {
    this.cdref.detach();
  }

  hidePinForInActiveParentWithSameMisisID(parents){
    for (const parent of parents) {
      const { pgId, accountEnabled } = parent;
    
      if (!accountEnabled && this.parentCounts[pgId] > 0) {
        parent.parentPin = null;
      }
      this.parentCounts[pgId] = (this.parentCounts[pgId] || 0) + 1;
    }
  }

  getPinDetails() {
    this.adminService
      .getPinDetails(this.contact.empCode || this.contact.id)
      .subscribe((res) => {
        this.pinDetails = res;
        _.each(this.pinDetails, (parent) => {
          _.each(this.misisParents.parents, (misisParent) => {
            if (misisParent.parentFirstName == parent.pgFirstname) {
              parent.parentVerified = misisParent.parentVerified;
            }
          });
        });
        this.addPinDetailsToParents(this.pinDetails);
        this.cdref.detectChanges();
      });
  }

  //AGGREGATING APIS

  addPinDetailsToParents(pinDetails) {
    _.each(this.parents, (parent) => {
      _.each(pinDetails, (pinParent) => {
        if (parent.misisId == pinParent.pg_id) {
          parent.parent_pin = pinParent.parent_pin;
          parent.date_used = pinParent.date_used;
          parent.parentVerified = pinParent.parentVerified;
          parent.role = "Parent";
        }
      });
    });

    this.addMisisDataToParents(this.parents, this.misisParents.parents);
  }

  addMisisDataToParents(parents, misis) {
    if (parents && misis) {
      _.each(parents, (parent) => {
        _.each(misis, (misisParent) => {
          if (parent.pgId == misisParent.parentId) {
            parent.parentVerified = misisParent.parentVerified;
            parent.verified = misisParent.verified;
          }
        });
      });
    }

    this.addParentData(parents);
  }

  addParentData(parents) {
    this.parentsLoading = true;
    this.studentService
      .getParentsByStudent(this.contact.empCode || this.contact.id)
      .subscribe((par) => {
        this.parentsLoading = false;
        _.each(parents, (parent) => {
          _.each(par, (p) => {
            if (Number(parent.parentId) == p.id) {
              parent.pgFirstname = p.firstName;
              parent.pgLastname = p.lastName;
              parent.id = p.id;
              parent.email = p.email;
              parent.accountEnabled = p.activeFlag == 'Y';
            }
          });
        });

        this.processAssociatedParents(parents);
      }, err => {
        this.parentsLoading = false;
      });
  }

  processAssociatedParents(parents) {
    this.hidePinForInActiveParentWithSameMisisID(parents);
    this.associatedParents = [];
    this.unassociatedParents = [];

    _.each(parents, (parent) => {
      if (parent.dateUsed) {
        this
        this.associatedParents.push(parent);
      } else {
        this
        this.unassociatedParents.push(parent);
      }
    });

    this.getParentDetails(this.associatedParents);
    this.getParentDetails(this.unassociatedParents);

    this.cdref.detectChanges();
  }

  getParentDetails(parents) {
    _.each(parents, (parent) => {
      var id = parent.id || parent.parentId || parent.misisId;
      if (id) {
        this.parentService.getParentIdByMisisId(id).subscribe((res) => {
          parent.accountEnabled = res.accountEnabled;
          parent.studentExceptionEnabled = res.studentExceptionEnabled;
          parent.email = res.email;
        });
      }
    });
    console.log(parents, '~~~parents')
  }

  checkStudentAccess() { 
    combineLatest([this.contactService.getContacts(), this.allAssociatedStudents]).subscribe(([contacts]) => {
      this.contacts = contacts as any as IEmployeeContact[]; 

      this.students = this.allAssociatedStudents.map(obj1 => {
        const matchingContact = this.contacts.find(obj2 => obj2.empCode === obj1.studentId);        
        return matchingContact ? obj1 : null;
      }).filter(Boolean);

    });
  } 

  printPin(parent) {
    console.log(parent);
    const dialogRef = this.dialog.open(PrintPinModalComponent, {
      data: {
        parent: parent,
        student: this.contact,
      },
      width: "680px",
    });
  }

  adminOperations() {
    if (this.userRole == this.rs.AdminRole && this.role == "student") {
      this.settingActive = 0;
    }
  }

  getStudentsByParent() {}

  removeStudent(studentId) {
    this.analyticsService.sendEvent("User Click", "Remove Student");

    swal({
      title: `Remove student?`,
      icon: "warning",
      text: "Student will be removed from this parent account",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, remove",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        this.parentService.removeStudent(studentId).subscribe((res) => {
          this.toastr.success(`Successfully removed student`);
        });
      }
    });
  }
  removeParent(parent) {
    console.log(parent);
    swal({
      title: `Remove parent?`,
      icon: "warning",
      text: "Parent will be removed from this student account",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, remove",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        this.parentService
          .removeStudent2(this.contact.empCode, parent.id)
          .subscribe((res) => {
            this.toastr.success(`Successfully removed parent`);
            this.ngOnInit();
          });
      }
    });
  }

  resetPin(parent, idx) {
    let dob = moment(parent.studentDob).format("L");

    let request = {
      studentId: this.contact.empCode,
      dateOfBirth: dob,
      parentGuardianId: `${parent.pgId}`,
      requestedBy: this.profileService.profile.email,
    };

    this.adminService.resetPin(request).subscribe(
      (res) => {
        this.toastr.success(`Successfully Reset Pin`);
        parent.parentPin = res.pin;
        this.cdref.detectChanges();
      },
      (error) => {
        this.toastr.error(`Could not reset pin. Please try again.`);
      }
    );
  }

  addException(parent) {
    let request = {
      parentEmail: parent.emailAddressTxt,
      adminUser: this.profileService.profile.email,
      parentId: this.contact.empCode,
    };

    this.adminService.addException(request).subscribe(
      (res) => {
        if (res.errorResponse.errorMessage == null) {
          this.toastr.success(`Success. Parent added as exception`);
        } else {
          this.toastr.error(
            `Error adding exception: "${res.errorResponse.errorMessage}"`
          );
        }
      },
      (error) => {
        this.toastr.error(`Could not add exception. Please try again.`);
      }
    );
  }

  misisLookup() {
    let req = {
      studentId: this.contact.empCode || this.contact.id,
    };
    this.adminService.getParentFromMisis(req).subscribe((res) => {
      let misisData = {
        contact: this.contact,
        misisParents: res,
      };

      const dialogRef = this.dialog
        .open(MisisLookupWidgetComponent, {
          data: misisData,
          minWidth: "80vw",
        })
        .afterClosed()
        .subscribe((res) => {
          if (res.updated) {
            this.ngOnInit();
            this.cdref.detectChanges();
          }
        });
    });
  }

  getEmergencyContacts() {
    this.studentId = this.contact.empCode || this.contact.id;
    this.studentService
      .getStudentEmergencyContacts(this.studentId, this.parentId)
      .subscribe((response) => {
        console.log(response);
        this.emergencyContacts = response;
        this.addParentId(this.emergencyContacts.parents);
        this.cdref.detectChanges();
      });
  }

  addParentId(contacts) {
    _.each(contacts, (contact) => {
      this.parentService.getParentIdByMisisId(contact.parentId).subscribe(
        (parent) => {
          let id = parent.id;
          contact.id = id;
        },
        (err) => {
          contact.id = null;
        }
      );
    });
    this.emContacts = this.emergencyContacts;
  }
  checkAdministrator(parentId) {
    if (this.userRole == "Administrator") {
      return false;
    } else {
      if (this.misisId == parentId) {
        return false;
      } else {
        return true;
      }
    }
  }

  getParents() {
    this.studentService
      .getParentsByStudent(this.studentId)
      .subscribe((parents) => {
        this.parents = parents;
      });
  }
  checkEditor(parentId) {
    if (this.userRole == "Administrator") {
      console.log("admin");
      return true;
    } else {
      if (this.misisId == parentId) {
        return true;
      } else {
        return false;
      }
    }
  }

  getMisisId() {
    this.parentService.getStudentsByParent().subscribe((students) => {
      if (students.length > 0) {
        let studentObj;
        _.each(students, (student) => {
          if (student.studentId == this.studentId) {
            studentObj = student;
          }
        });

        this.misisId = studentObj.parentMisisId;
      }
    });
  }

  deactivate(contact) {
    let override;

    if (this.isProd) {
      override = "N";
    } else {
      override = "Y";
    }

    let request = {
      parentEmail: contact.emailAddressTxt,
      action: "deactivate",
      adminUser: this.profileService.profile.email,
      override: override,
      overrideemail: this.profileService.profile.email,
      parentId: this.contact.empCode,
    };

    this.adminService.deactivateParentAccount(request).subscribe(
      () => {
        this.profileService
          .getProfileById(request.parentId, false)
          .subscribe(() => {
            this.toastr.success(`Successfully deactivated account.`);
            this.isActive = false;
            this.cdref.detectChanges();
          });
      },
      (error) => {
        this.toastr.error(`Could not deactivate. Please try again.`);
      }
    );
  }

  activate(contact) {
    let override;

    if (this.isProd) {
      override = "N";
    } else {
      override = "Y";
    }

    let request = {
      parentEmail: contact.emailAddressTxt,
      action: "activate",
      adminUser: this.profileService.profile.email,
      override: override,
      overrideemail: this.profileService.profile.email,
      parentId: this.contact.empCode,
    };

    this.adminService.activateParentAccount(request).subscribe(
      (res) => {
        if (res.errorResponse.errorMessage) {
          this.toastr.error(`${res.errorResponse.errorMessage}`);
          this.isActive = false;
          this.cdref.detectChanges();
        } else {
          this.profileService
            .getProfileById(request.parentId, false)
            .subscribe(() => {
              this.toastr.success(`Successfully activated account.`);
              this.isActive = true;

              this.cdref.detectChanges();
            });
        }
      },
      (error) => {
        this.toastr.error(`Could not activate. Please try again.`);
      }
    );
  }

  refreshStudents() {
    this.parentService
      .getStudentsByParentNoCacheAdmin(this.contact.empCode)
      .subscribe((students) => {
        this.students = students;
        this.checkStudentAccess;
        this.cdref.detectChanges();
      });
  }
  openContactDetails(contact) {
    contact.fromEmContact = false;
    contact.name = `${contact.firstName}, ${contact.lastName}`;
    const dialogRef = this.dialog.open(ContactDetailsWidgetComponent, {
      data: contact,
      minWidth: '70vw',
      maxHeight: '90vh'
    });
  }

  updateEmContactsList(list) {
    let contactList = [];
    for (let index = 0; index < list.length; index++) {
      let contactObj = list[index];
      let contactId = "emContactId" + index;
      let contactNum = (<HTMLInputElement>document.getElementById(contactId))
        .value;
      contactObj.cellNumber = contactNum;
      contactList.push(contactObj);
    }
    return contactList;
  }

  edit(index) {
    if (index == 0) {
      this.isEditing0 = true;
      this.allowEdit(index);
    }
    if (index == 1) {
      this.isEditing1 = false;
      this.allowEdit(index);
    }
  }

  editEm() {
    this.isEditingEm = !this.isEditingEm;
  }
  saveEm() {
    this.isEditingEm = !this.isEditingEm;
    this.emContacts.emergencyContacts = this.updateEmContactsList(
      this.emContacts.emergencyContacts
    );
    this.emergencyContacts = this.emContacts;
    this.studentService
      .updateStudentEmergencyContacts(
        this.studentId,
        this.parentId,
        this.emContacts
      )
      .subscribe(
        () => {
          this.toastr.success(`Successfully updated Emergency Contacts`);
        },
        (error) => {
          this.toastr.error(
            `Could not update Emergency Contacts. Please try again.`
          );
        }
      );
  }

  allowEdit(index) {
    if (index == 0) {
      this.isReadOnly0 = false;
      this.isEditing0 = true;
      this.isDisabled0 = false;
    }
    if (index == 1) {
      this.isReadOnly1 = false;
      this.isEditing1 = true;
      this.isDisabled1 = false;
    }
  }

  updatePhone(index) {
    this.emContacts.parents[index].cellNumber = (<HTMLInputElement>(
      document.getElementById(`cellParent${index}`)
    )).value;
    this.emContacts.parents[index].homeNumber = (<HTMLInputElement>(
      document.getElementById(`phoneParent${index}`)
    )).value;
    this.emContacts.parents[index].workNumber = (<HTMLInputElement>(
      document.getElementById(`workParent${index}`)
    )).value;
    this.emContacts.parents[index].emergency = [];
    this.emContacts.parents[index].attendance = [];
    this.emContacts.parents[index].generalInfo = [];
    if ((<HTMLInputElement>document.getElementById(`cEmer${index}`)).checked) {
      this.emContacts.parents[index].emergency.push("C");
    }
    if ((<HTMLInputElement>document.getElementById(`cAtt${index}`)).checked) {
      this.emContacts.parents[index].attendance.push("C");
    }
    if ((<HTMLInputElement>document.getElementById(`cGen${index}`)).checked) {
      this.emContacts.parents[index].generalInfo.push("C");
    }
    if ((<HTMLInputElement>document.getElementById(`pEmer${index}`)).checked) {
      this.emContacts.parents[index].emergency.push("H");
    }
    if ((<HTMLInputElement>document.getElementById(`pAtt${index}`)).checked) {
      this.emContacts.parents[index].attendance.push("H");
    }
    if ((<HTMLInputElement>document.getElementById(`pGen${index}`)).checked) {
      this.emContacts.parents[index].generalInfo.push("H");
    }
    if ((<HTMLInputElement>document.getElementById(`wEmer${index}`)).checked) {
      this.emContacts.parents[index].emergency.push("W");
    }
    if ((<HTMLInputElement>document.getElementById(`wAtt${index}`)).checked) {
      this.emContacts.parents[index].attendance.push("W");
    }
    if (
      (<HTMLInputElement>document.getElementById(`wGenInfo${index}`)).checked
    ) {
      this.emContacts.parents[index].generalInfo.push("W");
    }
  }

  cancel(index) {
    this.isReadOnly0 = true;
    this.isEditing0 = false;
    this.isDisabled0 = true;
    this.isReadOnly1 = true;
    this.isEditing1 = false;
    this.isDisabled1 = true;
    this.isEditingEm = false;
    this.ngOnInit();
  }

  save(index) {
    if (index == 0) {
      this.isReadOnly0 = true;
      this.isEditing0 = false;
      this.isDisabled0 = true;
      this.updatePhone(index);
      this.emergencyContacts = this.emContacts;
      this.studentService
        .updateStudentEmergencyContacts(
          this.studentId,
          this.parentId,
          this.emContacts
        )
        .subscribe(
          () => {
            this.toastr.success(`Successfully updated Emergency Contacts`);
          },
          (error) => {
            this.toastr.error(
              `Could not update Emergency Contacts. Please try again.`
            );
          }
        );
    }
    if (index == 1) {
      this.isReadOnly1 = true;
      this.isEditing1 = false;
      this.isDisabled1 = true;
      this.updatePhone(index);
      this.emergencyContacts = this.emContacts;
      this.studentService
        .updateStudentEmergencyContacts(
          this.studentId,
          this.parentId,
          this.emContacts
        )
        .subscribe(
          () => {
            this.toastr.success(`Successfully updated Emergency Contacts`);
          },
          (error) => {
            this.toastr.error(
              `Could not update Emergency Contacts. Please try again.`
            );
          }
        );
    }
  }

  onImgStudentError(event, getType) {
    const getVendorType =
      getType.profile == "principal"
        ? "Principal"
        : getType.profile == "employee"
        ? "Teacher"
        : "Student";
  }
  onMessage() {
    let role = this.profileService.profile.role.toLocaleLowerCase();
    this.router.navigate([`${role}/inbox/compose/` + this.contact.userRoleId]);
  }

  goStudentProfile(student) {
    let role = this.profileService.profile.role;
    student.studentId = student.empCode || student.id || student.studentId;
    this.studentService.setStudent(student);
    if (role == "Teacher" || role == "Administrator") {
      this.router.navigate([
        `${role.toLocaleLowerCase()}/classes/${student.studentId}/timeline`,
      ]);
    } else {
      this.router.navigate([
        role.toLocaleLowerCase(),
        "students",
        student.studentId,
      ]);
    }
  }

  goStudentCcrg(student) {
    let role = this.profileService.profile.role.toLocaleLowerCase();
    student.studentId = student.empCode || student.id || student.studentId;
    this.studentService.setStudent(student);
    this.router.navigate([`${role}/students/${student.studentId}/ccrg`]);
  }

  switchTab(index) {
    this.settingActive = index;
  }

  checkNullData(emContacts) {
    // //check data in parents array
    if (emContacts.parents) {
      for (var p in emContacts.parents) {
        if (emContacts.parents[p] == null) {
          emContacts.parents[p] = "No data available";
        }
      }
    }
    // check data in emergency contacts array
    if (emContacts.emergencyContacts) {
      for (var p in emContacts.emergencyContacts) {
        if (emContacts.emergencyContacts[p] == null) {
          emContacts.emergencyContacts[p] = "No data available";
        }
      }
    }
    //check data in family members
    if (emContacts.familyMembers) {
      for (var p in emContacts.familyMembers) {
        if (emContacts.familyMembers[p] == null) {
          emContacts.familyMembers[p] = "No data available";
        }
      }
    }
    //check data in private insurance
    if (emContacts.privateInsurance) {
      for (var p in emContacts.privateInsurance) {
        if (emContacts.privateInsurance[p] == null) {
          emContacts.privateInsurance[p] = "No data available";
        }
      }
    }
    //check data in emContacts
    for (var p in emContacts) {
      if (emContacts[p] == null) {
        emContacts[p] = "No data available";
      }
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupsService } from '../../services/groups.service';
import { StudentDetails } from '../student-details-widget/models/StudentDetails';
import * as _ from "lodash";


@Component({
  selector: 'app-class-edit-group-modal',
  templateUrl: './class-edit-group-modal.component.html',
  styleUrls: ['./class-edit-group-modal.component.scss']
})
export class ClassEditGroupModalComponent implements OnInit {

  student: StudentDetails = null;
  schoolRoomId = null;
  gradeId = null;

  displayedColumns: string[] = ['name', 'students', 'points', 'action'];

  availableGroups = []

  get studentGroups(){
    return this.availableGroups.filter(a=>a.assigned)
  }

  get notStudentGroups(){
    return this.availableGroups.filter(a=>!a.assigned)
  }

  
  constructor(
    public dialogRef: MatDialogRef<ClassEditGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private groupServices: GroupsService
  ) { 
    this.student = data.student,
    this.schoolRoomId = data.schoolRoomId,
    this.gradeId = data.gradeId
  }


  ngOnInit(): void {
    console.log(this.schoolRoomId, this.student, this.gradeId)
    this.groupServices.getGroupsBySchoolRoomAndGrade(this.schoolRoomId, this.gradeId).subscribe((groups)=>{
      this.groupServices.getGroupsByStudent(this.student.studentId, this.schoolRoomId, this.gradeId).subscribe((studentGroups)=>{

        console.log(studentGroups, 'stsudentgroups---')
        this.availableGroups = _.uniqBy(studentGroups.map(sg=>{
          sg.assigned = true;
          return sg;
        }).concat(groups), (g)=>g.id);

      });
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  addGroup(element) {
    let studentId = this.student.studentId;
    let groupId = element.id;

    this.groupServices.addStudentToGroup(studentId, groupId).subscribe((group)=>{
      element.assigned = true;
    })

  }
  remove(element){
    let studentId = this.student.studentId;
    let groupId = element.id;

    this.groupServices.removeStudentToGroup(studentId,groupId).subscribe((group)=>{
      element.assigned = false;
    })
  }

}

import { SurveyPreviewComponent } from '../survey-preview/survey-preview.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { SurveyService } from '../../../../shared/services/survey.service';
import { ViewResultsComponent } from '../view-results/view-results.component';
import swal from 'sweetalert';
import { ToastrService } from 'ngx-toastr';
import { AudienceListWidgetComponent } from 'src/app/shared/components/audience-list-widget/audience-list-widget.component';
import * as m from 'moment';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { NotificationServices } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-survey-manager-page',
  templateUrl: './survey-manager-page.component.html',
  styleUrls: ['./survey-manager-page.component.scss']
})
export class SurveyManagerPageComponent implements OnInit {

  single = false;

  searchKey = '';

  mockData = [];

  tableData: any[] = [];
  lastUpdated: any;

  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private surveyServices: SurveyService,
    private toastr: ToastrService,
    private profileService: ProfileService,
    public readonly tourService: TourService,
    private notificationServices: NotificationServices
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.data && this.route.snapshot.data.type === 'single-student') {
      this.single = true;
      // TODO: tableData from single Student
    }
    this.tableData = this.mockData;
    this.loadSurveys();

    this.startTour();

  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([{
      anchorId: 'start-tour',
      content: 'All the surveys you send to your students will be displayed here',
      title: 'Welcome to your Surveys Page!',
    },
    {
      anchorId: 'generate',
      content: 'You can create and send surveys to your students by clicking here',
      title: 'Welcome to your Surveys Page!',
      placement: below,
    },
    ]);

    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  loadSurveys() {
    this.isLoading = true;
    this.surveyServices.getSurveys().subscribe((surveys) => {
      this.isLoading = false;
      // FIXME: update API : gradeLevel, sentDate, dueDate(completionDate), audience, completionPercent
      this.tableData = surveys;
      this.lastUpdated = m();
    }, error => {
      this.isLoading = false;
      //this.toastr.error(`Can't load Surveys`);
    });
  }
  createSurvey() {
    this.router.navigate(['create'], { relativeTo: this.route });
  }

  viewResults(item) {
    this.surveyServices.viewResultById(item.id).subscribe(res => {
      if (res.length > 0) {
        this.dialog.open(ViewResultsComponent, {
          disableClose: false,
          width: '870px',
          panelClass: 'view-results-modal',
          data: {
            survey: item, // FIXME: remove this line
            id: item.id, // SurveyID to get survey details,
            responses: res
          }
        });
      } else {
        this.toastr.info('Not Available');
      }
    });
  }

  surveyPreview(item) {
    this.dialog.open(SurveyPreviewComponent, {
      disableClose: false,
      width: '870px',
      panelClass: 'view-results-modal',
      data: {
        survey: item, // FIXME: remove this line
        id: item.id // SurveyID to get survey details
      }
    });
  }

  getRadial(percent) {
    let danger = 'radial-bar-danger';
    let warning = 'radial-bar-warning';
    let info = 'radial-bar-info';
    let success = 'radial-bar-success';

    let radial = 'radial-bar-';
    let percentage = Math.ceil(percent/5)*5;
    radial = radial + percentage;

    if(percentage >= 0 && percentage <= 20){
      return radial + ' ' + danger
    }
    if(percentage > 20 && percentage <= 35){
      return radial + ' ' + warning
    }
    if(percentage > 35 && percentage < 100){
      return radial + ' ' + info
    }
    if(percentage == 100){
      return radial + ' ' + success
    }

  }

  delete(item) {
    swal({
      title: `Delete survey ${item.name}`,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        this.surveyServices.deleteSurvey(item.id).subscribe(() => {
          // this.loadSurveys(); FIXME: loadSurveys is not necessary. this API is not working now.
          this.toastr.success('Survey Deleted');
          const index = this.tableData.indexOf(item);
          this.tableData.splice(index, 1);
        });
      }
    });

  }

  edit(survey) {
    this.router.navigate(['edit', survey.id], { relativeTo: this.route });
  }


  csvExport(item) {
    this.surveyServices.viewResultById(item.id).subscribe(res => {
      if (res.length > 0) {
        var questions = res.map(s=>s.response.selectedQuestions);
        var survey = res[0];
        var data = [];
        var completed = survey.created;
        completed = m(completed).format('MMMM Do YYYY, h:mm a')
        for(var q in questions){
          var row = {
            'Survey Name':survey.response.surveyTitle,
            'Due Date': m(res[q].dueDate).format('YYYY-MM-DDTHH:mm:ss'),
            'Username': `${res[q].lastName}, ${res[q].firstName}`,
            'UserID': `${res[q].studentId}`,
            'Completed on': m(res[q].created).format('YYYY-MM-DDTHH:mm:ss'),
          }
          for(var a in questions[q]){
            var question = questions[q][a].question;
            var answer = questions[q][a].answer;
            row[question] = answer;
          }
          data.push(row);
        }
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          headers: Object.keys(data[0]),
          useHeader: true,
          nullToEmptyString: true,
        };
        new AngularCsv(data, `SurveyReport-${item.name}`, options);
      } else {
        this.toastr.info('Not Available');
      }
    });

  }

  viewList(item) {
    // TODO: get students & parents from item
    this.dialog.open(AudienceListWidgetComponent, {
      disableClose: false,
      width: '600px',
      data: { list: item, entity: 'Survey', entityId: item.id } // FIXME: item.students & item.parents
    });
  }
}

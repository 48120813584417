import { Injectable } from '@angular/core';
import {CounselorFilter} from './../models/counselor-filter';
import {ParentsContactFilter} from './../models/parents-contact-filter';

@Injectable({
  providedIn: 'root'
})
export class ContactsSelectionService {
conselorSeletion: CounselorFilter = {} as CounselorFilter
parentsContactFilterSelection: ParentsContactFilter = {} as ParentsContactFilter
  constructor() { }
}

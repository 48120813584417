import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "src/app/services/app-config.service";
import { environment } from "src/environments/environment";
import { IFaqGroup } from "../components/faq-section-widget/models/Faq";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class HelpCenterService {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.customerServices;//"https://localhost:44315";

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService) {
    this.api.baseUrl = this.baseUrl;
  }
  getHelpCenter(): Observable<IFaqGroup[]> {
    let path =`${this.baseUrl}/v1/help-feedback`;
    return this.api.get(path).pipe(map((response) => response as IFaqGroup[]));
  }
  getFaq(component: string): Observable<IFaqGroup[]> {
    let path =`${this.baseUrl}/v1/help-feedback/${component}`;
    return this.api.get(path).pipe(map((response) => response as IFaqGroup[]));
  }
  getLinks(name: string[]) : Observable<IFaqGroup> {
    let params= {
      params:{
        topicName:name
      }
    }
    let path = `${this.baseUrl}/v1/help-feedback/links`;
    return this.api.getWithParams(path, params).pipe(map((response)=> response as IFaqGroup));
  }
}

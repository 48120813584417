import { AppConfigService } from './../../services/app-config.service';
import { ChatService } from "src/app/shared/services/chat.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ApiService } from "./api.service";
import { NotificationHubMessage } from "./notification-hub.service";
import { ProfileService } from "./profile.service";
import { RoleServiceFactoryService } from "./role-service-factory.service";
import { StaffSettingsService } from "./staff-settings.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AccomplishmentService {


  baseUrl: string = this.appConfig.configuration.environment.endPoints.counselorServices;//"https://localhost:44315";
  serviceFactory: any

  constructor(
    private api: ApiService,
    private appConfig: AppConfigService,
    private profileService: ProfileService
  ) {



  }
  create(model: any){
    let userId = this.profileService.identity.userIdRole
    let path = `${this.baseUrl}/v1/accomplishments/${userId}`;
    return this.api.post(path, model).pipe(map((response)=>response as any));
  }
  list(category: any, studentId){
    let userId = studentId;
    let path = `${this.baseUrl}/v1/accomplishments/${userId}/${category}`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  get(accomplishmentId: any){
    let userId = this.profileService.identity.userIdRole
    let path = `${this.baseUrl}/v1/accomplishments/${userId}/${accomplishmentId}`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  update(model: any){
    let userId = this.profileService.identity.userIdRole
    let path = `${this.baseUrl}/v1/accomplishments/${userId}/${model.id}`;
    return this.api.put(path, model).pipe(map((response)=>response as any));
  }
  remove(accomplishmentId: any){
    let userId = this.profileService.identity.userIdRole
    let path = `${this.baseUrl}/v1/accomplishments/${userId}/${accomplishmentId}`;
    return this.api.delete(path).pipe(map((response)=>response as any));
  }
  timeline(studentId) {
    let userId = studentId;
    let path = `${this.baseUrl}/v1/accomplishments/${userId}/timeline`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }

}

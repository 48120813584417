import { ModalService } from './../../services/modal.service';
import { Component, Input, OnInit } from '@angular/core';
import { ISchoolInfo } from '../student-list-widget/models/ISchoolInfo';
import { IStudentDetails } from '../student-list-widget/models/IStudentDetails';
import { IStudentResume } from '../student-list-widget/models/IStudentResume';
import { StudentSmallProfileModel } from './models/StudentSmallProfileModel';
import * as m from 'moment';
import { StudentsService } from '../../services/students.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from '../../services/profile.service';
import { NotificationServices } from '../../services/notification.service';
import swal from 'sweetalert'


@Component({
  selector: 'app-student-small-profile-widget',
  templateUrl: './student-small-profile-widget.component.html',
  styleUrls: ['./student-small-profile-widget.component.scss']
})
export class StudentSmallProfileWidgetComponent implements OnInit {

  @Input() student: IStudentDetails = null;
  @Input() schoolInfo: ISchoolInfo = null;
  @Input() lastUpdated: any;
  principalInfo: any;

  constructor(
    private modalService: ModalService,
    private studentService: StudentsService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    ) { }

  ngOnInit(): void {
    this.studentService.getStudentById(this.student.studentId).subscribe((student)=>{
      this.principalInfo = student;
      this.principalInfo.principalStaffId = `${this.principalInfo.principalStaffId}PCL`;
      setTimeout(() => {
        this.startTour();

      }, 2000)
    });

  }


  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([{
      anchorId: 'start-tour',
      content: 'Here you will find access to all of your student information',
      title: 'Student Profile',
    },
    {
      anchorId: 'school-info',
      content: 'Scroll down to view a timeline of your events. Or click on the buttons above to view more detailed items.',
      title: 'Student Profile',
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  getTimeSince(time) {
    let timeSince = m(time).fromNow();
    return 'Last updated ' +  timeSince
  }
  getEnrolledDate(schoolInfo) {
    if(schoolInfo.enrollmentStatus == 'Y'){
      let date = m().format('MMMM Do, YYYY');
      return date
    }
    else
    return 'Not currently enrolled'
  }

}

import { switchMap } from "rxjs/operators";
import { UserSelectors, UserActions } from "./../store/features/users.feature";
import { AppState } from "./../store/app.state";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  userIsLogged$ = this.store.select(UserSelectors.UserIsLoggedSelect);
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userIsLogged$.pipe(
      map((userStatus) => {
        if (userStatus) {
          return userStatus;
        } else {
          if(this.oauthService.hasValidAccessToken()){
            this.store.dispatch(UserActions.LoggedIn({loggedIn:true}))
          }else{
            this.router.navigate(["/auth/landing"]);
          }
          return userStatus;
        }
      })
    );

    // if (this.oauthService.hasValidAccessToken()) {
    //   return true;
    // } else {
    //   this.router.navigate(["/auth/landing"]);
    //   return false;

    // }
  }
}

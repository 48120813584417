export const Endpoints = {
    baseUri: "https://lausdapptst.lausd.net", //udpates
    authenticationServices : "https://servicestst.lausd.net/authentication",
    notificationServices : "https://servicestst.lausd.net/notifications",
    customerServices : "https://servicestst.lausd.net/customers",
    communicationsServices : "https://servicestst.lausd.net/communications",
    calendarServices : "https://servicestst.lausd.net/calendar",
    facilitiesServices : "https://servicestst.lausd.net/facilities",
  }
  export const environment = {
    production: false,
    chat: 'https://lausd.service-now.com/$sn-va-web-client-app.do?sysparm_nostack=true&sysparm_stack=no&sysparm_portal=lausd&sysparm_page=customer_home&sysparm_language=en&sysparm_liveagent_application=csm&sysparm_live_agent_only=false&sysparm_branding_key=lausd_customer_service',
    portal: 'https://lausd.service-now.com/lausd',
    environment_name: "Test",
    gaTrackingID: "UA-221977737-1",
    enableIAP: false,
    endPoints: Endpoints,
    oauth: {
      resourceServer: {
        allowedUrls: [
          Endpoints.authenticationServices,
          Endpoints.customerServices,
          Endpoints.calendarServices,
          Endpoints.notificationServices,
          Endpoints.facilitiesServices,
          Endpoints.communicationsServices
        ],
        sendAccessToken: true,
      }
    },
  };
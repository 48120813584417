import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-student-modal',
  templateUrl: './remove-student-modal.component.html',
  styleUrls: ['./remove-student-modal.component.scss']
})
export class RemoveStudentModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RemoveStudentModalComponent>,
  ) { }

  student = this.data.student;

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  removeStudent() {
    this.dialogRef.close(true)
  }

}

import { ProfileService } from "./profile.service";
import { IEmployeeContact } from "./parent.services.models";
import { Howl } from "howler";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AppConfigService } from "src/app/services/app-config.service";
import { RoleServiceFactoryService } from "./role-service-factory.service";
import { StaffSettingsService } from "./staff-settings.service";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  baseUrl: string =
    this.appConfig.configuration.environment.endPoints.communicationsServices;
  chatCache: any = [];
  inboxChanged: Subject<any> = new Subject<any>();
  messageReceived: Subject<any> = new Subject<any>();
  contacts: IEmployeeContact[] = [];
  contactsReceived: Subject<any> = new Subject<any>();
  conversationsReceived: Subject<any> = new Subject<any>();
  currentConversationId: any;
  badges = [];
  private _allConversations = new BehaviorSubject<any>(null);
  private _currentConversation = new BehaviorSubject<any>(null);
  public _currentNotifications = new BehaviorSubject<UserNotification[]>(null);
  public menuNotificationReceived$ = new Subject<any>();

  sound: Howl = null;
  constructor(
    private api: ApiService,
    private appConfig: AppConfigService,
    private profileService: ProfileService,
    private staffSettingsService: StaffSettingsService,
    private rs: RoleServiceFactoryService
  ) {
    this.sound = new Howl({
      src: ["assets/inbox.mp3"],
    });

    this.messageReceived.subscribe((msg) => {
      this.loadNotifications();
      msg.sender.createNotification(msg);
      if (this.profileService.identity.userType === this.rs.TeacherRole) {
        if (this.staffSettingsService.isNotificationEnabled) {
          msg.sender.showInfoToast(`You have a new message`);
        }
      } else {
        msg.sender.showInfoToast(`You have a new message`);
      }
      this.addBadge(msg);
      this.createNotif(msg);
      if (this.currentConversationId == msg.message.conversationId) {
        this.newCurrentConversation(msg.message);
      }
      msg.message.unseen =
        this.currentConversationId != msg.message.conversationId;
      this.menuNotificationReceived$.next(msg.message);
      this.sound.play();
    });
  }

  get allConversations() {
    return this._allConversations.asObservable();
  }

  get notifications() {
    return this._currentNotifications.asObservable();
  }

  updateMenuNotification(msg) {}
  loadNotifications() {
    this.getUserNotifications().subscribe((notifications) => {
      this._currentNotifications.next(notifications);
    });
  }

  get currentConversation() {
    return this._currentConversation.asObservable();
  }

  newCurrentConversation(msg) {
    if (typeof msg === "string" || msg instanceof String)
      msg = { conversationId: msg };
    var chatCache = this.chatCache[msg.conversationId];
    if (chatCache && chatCache.isLoaded) {
      if (msg.message)
        chatCache.messages.splice(0, 0, {
          message: JSON.parse(msg.message),
          created: new Date(),
          username: msg.senderId,
        });
      this._currentConversation.next(chatCache);
    } else {
      this.loadConversation(msg.conversationId);
    }
  }

  resetCurrentConversation() {
    this._currentConversation.next(null);
  }

  loadConversation(id) {
    this.getConversation(id).subscribe((conversation) => {
      this._currentConversation.next(conversation);
    });
  }

  removeNotification(id) {
    let finalNotification = null;
    this.notifications.subscribe((notifs) => {
      if (notifs) {
        const index = notifs.findIndex((x) => x.id === id);
        if (index > -1) {
          notifs.splice(index, 1);
        }
        finalNotification = notifs;
      }
    });
    this._currentNotifications.next(finalNotification);
    this.dismissNotification(id).subscribe();
  }

  loadConversations() {
    this.getConversations().subscribe((conversation) => {
      this._allConversations.next(conversation);
    });
  }

  setActiveConversationId(id) {
    this.currentConversationId = id;
    this.badges[id] = null;
  }
  addBadge(msg) {
    let count = this.badges[msg.message.conversationId];
    if (this.currentConversationId != msg.message.conversationId) {
      if (count == null) {
        this.badges[msg.message.conversationId] = 1;
      } else {
        this.badges[msg.message.conversationId] = count + 1;
      }
    } else {
      this.badges[msg.message.conversationId] = null;
    }
  }
  setContact(contacts) {
    this.contacts = contacts;
    this.contactsReceived.next(contacts);
  }

  createNotif(msg) {
    const newNotification = {
      id: msg.id,
      message: msg.message,
      created: Date.now().toString(),
      profileId: this.profileService.identity.userIdRole,
      dismissed: msg.dismissed,
      type: msg.type,
      modified: msg.modified,
    };
    let finalNotification = null;

    this.notifications.subscribe((notifs) => {
      if (notifs) {
        notifs.push(newNotification);
        finalNotification = notifs;
      }
    });
    this._currentNotifications.next(finalNotification);
  }

  getUserNotifications(): Observable<UserNotification[]> {
    let userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/notification/${userId}`;
    return this.api
      .getWithParams(path, { spinner: "no" })
      .pipe(map((response) => response as any));
  }

  dismissNotification(notificationId): Observable<any> {
    let userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/notification/${userId}/dismiss/${notificationId}`;
    return this.api.post(path, {}).pipe(map((response) => response as any));
  }
  getConversations(): Observable<any> {
    let userId = this.profileService.identity.userIdRole;
    let path = `${this.baseUrl}/v1/chat/${userId}/conversations`;
    return this.api.getWithParams(path, { spinner: "no" }).pipe(map((response) => response as any));
  }

  getConversation(id: any): Observable<any> {
    let path = `${this.baseUrl}/v1/chat/${this.api.parentId}/conversations/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getContacts(): Observable<any> {
    let path = `${this.baseUrl}/v1/chat/${this.api.parentId}/contacts`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  addConversation(receiverId: any, message: any): Observable<any> {
    let path = `${this.baseUrl}/v1/chat/${this.api.parentId}/conversations`;
    let body = {
      message: message,
      receiverId: receiverId,
    };
    return this.api.post(path, body).pipe(map((response) => response as any));
  }

  refreshInbox(conversationId) {
    this.getConversations().subscribe((response) => {
      let obj = {
        message: conversationId,
        conversations: response,
      };
      this.inboxChanged.next(obj);
    });
  }

  readMessage(messageId, userId, message): Observable<any> {
    const path = `${this.baseUrl}/v1/chat/${userId}/messageseen/${messageId}`;
    return this.api
      .patch(path, message)
      .pipe(map((response) => response as any));
  }
  postMessage(id, message): Observable<any> {
    let path = `${this.baseUrl}/v1/chat/${this.api.parentId}/conversations/${id}/messages`;
    let body = {
      message: message,
    };
    return this.api.post(path, body).pipe(map((response) => {
      this.loadConversations();
      response as any
    }));
  }
  translate(message, source, target): Observable<any> {
    let path = `${this.baseUrl}/v1/translator/?source=${source}&target=${target}`;
    let body = [message];
    return this.api.post(path, body).pipe(map((response) => response as any));
  }
  getMessagesFromId(conversationId: any, msgId: any): Observable<any> {
    let path = `${this.baseUrl}/v1/chat/${this.api.parentId}/conversations/${conversationId}/messages/${msgId}/previous`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}

export interface UserNotification {
  id: string;
  type: string;
  message: string;
  profileId: string;
  created: string;
  modified: string;
  dismissed: boolean;
  studentId?: string;
}

import { Countdown } from './../countdown-widget/models/Countdown';
import { ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-reminder-countdown',
  templateUrl: './reminder-countdown.component.html',
  styleUrls: ['./reminder-countdown.component.scss']
})
export class ReminderCountdownComponent implements OnInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

@Input() student: any = null;
  constructor() { }

  ngOnInit(): void {
  }

  handleCtd($evt){
    if($evt.action == 'done'){
      this.countdown.config.leftTime = this.student.reminderLoop;
      this.countdown.restart();
    }
  }
}

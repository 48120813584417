import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-service-chat-modal',
  templateUrl: './service-chat-modal.component.html',
  styleUrls: ['./service-chat-modal.component.scss']
})
export class ServiceChatModalComponent implements OnInit {

  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  onClose() {
    this.close.emit(true);
  }

}

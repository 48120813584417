import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationGroupService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; // "https://localhost:44315"
  parentId: string = null;
  parentUsername: string = null;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private oauth: OAuthService) { }


  updateCommunicationGroup(request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/communication-groups/${request.communicationId}`;
    return this.api.patch(path, request).pipe(map((response) => response as any));
  }
  createCommunicationGroup(request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/communication-groups`;
    return this.api.post(path, request).pipe(map((response) => response as any));
  }
  getCommunicationGroups(profileId): Observable<any> {
    const path = `${this.baseUrl}/v1/communication-groups/${profileId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getCommunicationGroup(staffId:string, id: string): Observable<any> {
    const path = `${this.baseUrl}/v1/communication-groups/${staffId}/${id}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getCommunicationGroupsByStaff(staffId): Observable<any> {
    return this.getCommunicationGroups(staffId);
  }

  delete(id: any): Observable<any> {
    // FIXME: delete - const path = `${this.baseUrl}/v1/communication-groups/delete/${id}`; API not working
    const path = `${this.baseUrl}/v1/communication-groups/${id}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
}

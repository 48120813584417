import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import swal from 'sweetalert';
import { AdministratorService } from '../../services/administrator.service';
import { NotificationServices } from '../../services/notification.service';
import { StudentsService } from '../../services/students.service';

@Component({
  selector: 'app-misis-lookup-widget',
  templateUrl: './misis-lookup-widget.component.html',
  styleUrls: ['./misis-lookup-widget.component.scss']
})
export class MisisLookupWidgetComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<MisisLookupWidgetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdministratorService,
    private studentService: StudentsService,
    private notificationService: NotificationServices,
    private cdref: ChangeDetectorRef
  ) { }

  student = this.data.contact;
  misisParents = this.data.misisParents;
  updatedParents = false;

  ngOnInit(): void {
    console.log(this.student, this.data)
  }

  ngOnDestroy() {
    this.cdref.detach();
  }

  onClose() {
    this.dialogRef.close({updated: this.updatedParents});
  }

  addToParentPortal(parent) {

    this.studentService.getStudentById(this.student.empCode).subscribe(student => {

      let req = {
        dateOfBirth: student.dateOfBirth,
        parentFirstName: parent.parentFirstName,
        parentLastName: parent.parentLastName,
        pgId: parent.parentId,
        studentId: this.student.empCode
      }

      this.adminService.importParentFromMisis(req).subscribe(res => {

        swal({
          title: `Successfully imported parent from MiSiS`,
          icon: 'success',
          text: `Here is your PIN to activate account: ${res.pin}`,
          buttons: {
            cancel: false,
            confirm: {
              text: 'OK',
              value: true,
              visible: true,
              closeModal: true
            }
          }
        })

        let payload = {
          studentId: this.student.empCode,
        };

        this.adminService.getParentFromMisis(payload).subscribe((res) => {
          this.misisParents = res;
          this.updatedParents = true;
          this.cdref.detectChanges();
        }, error => {
          this.notificationService.showErrorToast(`Could not refresh MiSiS parents.`)
        });
    

      }, error => {

        this.notificationService.showErrorToast('Could not import parent from MiSiS. Please try again.')

      })


    })

  }


}

import { Component, OnInit, Input } from '@angular/core';
import AsyncComponent from '../common/AsyncComponent';
import { StudentTeachersModel } from './models/StudentTeachersModel';

@Component({
  selector: 'app-student-teachers-widget',
  templateUrl: './student-teachers-widget.component.html',
  styleUrls: ['./student-teachers-widget.component.scss']
})
export class StudentTeachersWidgetComponent implements OnInit, AsyncComponent {
  @Input() model: StudentTeachersModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

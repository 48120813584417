import { RoleServiceFactoryService } from "src/app/shared/services/role-service-factory.service";
import { StudentsService } from "./../../../../shared/services/students.service";
import { StudentPbisService } from "./../../../../shared/services/student-pbis.service";
import { Observable } from "rxjs";
import { ExpectationsService } from "./../../../../shared/services/expectations.service";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ColorsService } from "./../../../../shared/colors/colors.service";
import { Input, AfterViewInit } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { NewExpectationModalComponent } from "../../new-expectation-modal/new-expectation-modal.component";
import { ProfileService } from "src/app/shared/services/profile.service";
import * as moment from "moment";
import * as _ from "lodash";
import { table } from "console";
import { element } from "protractor";
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { TourModalComponent } from "../../tour-modal/tour-modal.component";
import { NotificationServices } from "src/app/shared/services/notification.service";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Label } from "ng2-charts";


const swal = require("sweetalert");

@Component({
  selector: "app-student-expectations",
  templateUrl: "./student-expectations.component.html",
  styleUrls: ["./student-expectations.component.scss"],
})
export class StudentExpectationsComponent implements OnInit, AfterViewInit {
  single = false;

  @Input() tableData: any[] = [];
  tableDataCopy: any[] = null;
  searchKey = "";
  viewType = "table";
  studentProfile: any = null;
  now = new Date();
  lastUpdated: any;
  startLabel = "07:00 AM";
  endLabel = "05:00 PM";

  tableView = true;
  chartView = false;

  timeSlot = "day";
  // BAR
  // -----------------------------------
  barData: any;
  barOptions = {
    scaleStartValue: 0,
    series: {
      bars: {
        align: "center",
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9,
      },
    },
    grid: {
      borderColor: "#eee",
      borderWidth: 1,
      hoverable: true,
      backgroundColor: "#fcfcfc",
    },
    tooltip: true,
    tooltipOpts: {
      content(label, x, y) {
        return x + " : " + y;
      },
    },
    xaxis: {
      tickColor: "#fcfcfc",
      mode: "categories",
    },
    yaxis: {
      // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
      tickColor: "#eee",
      min: 0,
      beginAtZero: true,
    },
    shadowSize: 0,
  };

  isDaySelected = false;
  isWeekSelected = false;
  isMonthSelected = false;
  isYearSelected = false;

  pieDataExp: any[] = [];
  pieDataTeacher: any[] = [];
  pieOptions = {
    series: {
      pie: {
        show: true,
        innerRadius: 0,
        label: {
          show: true,
          radius: 0.8,
          formatter(label, series) {
            return (
              '<div class="flot-pie-label">' +
              label.substring(0, 12) +
              "... " +
              Math.round(series.percent) +
              "%</div>"
            );
          },
          background: {
            opacity: 0.7,
            color: "#222",
          },
        },
      },
    },
  };

  teacherTempName: any;
  teacherNameCache: any;

  isLoading = false;

  public expChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        }
      ]
    }
  };
  public expChartLabels: Label[] = [];
  public expChartType: ChartType = 'horizontalBar';
  public expChartLegend = false;
  public expChartData: ChartDataSets[] = [
    { data: [], label: 'Points', backgroundColor: [] },
  ];
  public teacherChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  public teacherChartLabels: Label[] = [];
  public teacherChartType: ChartType = 'horizontalBar';
  public teacherChartLegend = false;
  public teacherChartData: ChartDataSets[] = [
    { data: [], label: 'Points', barThickness: 24, backgroundColor: [] },
  ];

  teacherIds = [];

  chartData = [];

  constructor(
    public colors: ColorsService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private expectationsService: ExpectationsService,
    private studentPbis: StudentPbisService,
    private studentServices: StudentsService,
    public rs: RoleServiceFactoryService,
    public readonly tourService: TourService,
    private notificationServices: NotificationServices

  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as any;
    if (state === "chart") {
      this.viewType = "chart";
    }

    this.isLoading = true;
    this.route.parent.params.subscribe((p) => {
      this.studentPbis.getPbisExpectations(p.studentId).subscribe((data) => {
        this.isLoading = false;
        this.tableDataCopy = _.cloneDeep(data.points);
        this.tableData = _.cloneDeep(data.points);
        this.chartData = _.cloneDeep(data.points)
        this.single = true;
      }, error => {
        this.isLoading = false;
        //this.toastr.error(`Can't load Expectations`);
      });
      this.studentServices.getStudentById(p.studentId).subscribe((student) => {
        this.studentProfile = student;
      });
      this.lastUpdated = moment();
    });

    // this.profileService
    //   .getProfileById(`lausd.teacher01@lausd.net`)
    //   .subscribe((res) => {
    //     this.teacherTempName = res.firstName;
    //   });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.startTour();
    }, 2000)
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'Click on a row to view more details about your records',
      title: 'Welcome to your Expectations!',
      placement: above,
    },
    {
      anchorId: 'tab1',
      content: 'Table View will display all the expectations you receive in a list',
      title: 'Welcome to your Expectations!',
      placement: below,
    },
    {
      anchorId: 'tab2',
      content: 'Chart View will display all the points you receive from expectations in different charts',
      title: 'Welcome to your Expectations!',
      placement: below,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getBarChartData();
      this.getPieDataExp();
      this.getPieDataTeacher();
      // this.startTour();
      this.initializeCharts();
    }, 2000);

  }

  switchTab(tab: string) {
    this.tableView = false;
    this.chartView = false;
    switch (tab) {
      case "tableView":
        this.tableView = true;
        break;
      case "chartView":
        this.chartView = true;
        break;
    }
  }

  initializeCharts() {
    this.expChartLabels = this.getExpectationChartLabels();
    this.expChartData[0].data = this.getExpectationChartData();
    this.orderExpectations();
    this.expChartData[0].backgroundColor = this.randomizeBarColors(this.expChartData[0].data);
    this.teacherIds = this.getTeacherChartLabels();
    this.teacherChartLabels = this.getTeacherNames(this.teacherIds);
    this.teacherChartData[0].data = this.getTeacherChartData();
    this.orderTeacherExpectations();
    this.teacherChartData[0].backgroundColor = this.randomizeBarColors(this.teacherChartData[0].data);
  }
  randomizeBarColors(dataLength){
    let colors = []
    _.each(dataLength, item => {
      colors.push(this.getRandomHexColor)
    })
    return colors;
  }
  getExpectationChartLabels(): Label[] {
    let labels = [];
    let uniq = _.uniqBy(this.chartData, 'expectationId');

    _.each(uniq, item => {
      labels.push(item.expectationName)
    })
    return labels
  }
  orderExpectations() {
    let labels = this.expChartLabels;
    let data = this.expChartData[0].data;
    let arrayOfObj = labels.map(function(d, i) {
      return {
        label: d,
        data: data[i]
      };
    });


    let sortedArrayOfObj = _.orderBy(arrayOfObj, ['data'], 'desc');

    let sortedLabels = [];
    let sortedData = [];

    _.each(sortedArrayOfObj, item => {
      sortedLabels.push(item.label);
      sortedData.push(item.data);
    })

    this.expChartLabels = sortedLabels;
    this.expChartData[0].data = sortedData;
  }

  orderTeacherExpectations() {
    let labels = this.teacherChartLabels;
    let data = this.teacherChartData[0].data;
    let arrayOfObj = labels.map(function(d, i) {
      return {
        label: d,
        data: data[i]
      };
    });


    let sortedArrayOfObj = _.orderBy(arrayOfObj, ['data'], 'desc');

    let sortedLabels = [];
    let sortedData = [];

    _.each(sortedArrayOfObj, item => {
      sortedLabels.push(item.label);
      sortedData.push(item.data);
    })

    this.teacherChartLabels = sortedLabels;
    this.teacherChartData[0].data = sortedData;
  }

  getExpectationChartData() {
    let labels = [];
    let uniq = _.uniqBy(this.chartData, 'expectationId');
    let chartData = _.cloneDeep(this.chartData);
    let data = [];

    _.each(uniq, item => {
      labels.push(item.expectationName);
      data.push(0);
    })
    _.each(labels, function(label, idx) {
      _.each(chartData, item => {
        if(label == item.expectationName){
          data[idx] += item.points;
        }
      })
    })
    return data
  }

  getTeacherNames(ids): Label[]{
    let teachers = [];
    _.each(ids, id => {
      this.profileService.getProfileById(id).subscribe(profile => {
        teachers.push(profile.fullName);
      })
    })
    return teachers
  }


  getTeacherChartLabels() {
    let teachers = [];
    let uniq = _.uniqBy(this.chartData, 'staffId');

    _.each(uniq, item => {
      teachers.push(item.staffId);
    })
    return teachers
  }

  getTeacherChartData() {
    let data = [];
    let chartData = _.cloneDeep(this.chartData);

    _.each(this.teacherIds, function(id, idx){
      data.push(0);
      _.each(chartData, item => {
        if(id == item.staffId){
          data[idx] += item.points;
        }
      })
    })


    return data
  }

  getPieDataTeacher() {
    const filteredData = _.cloneDeep(this.tableData);
    const tempArray: any[] = [filteredData[0]];
    const newData = [...filteredData];
    newData.shift();
    newData.forEach((element) => {
      let existing = false;
      for (let index = 0; index < tempArray.length; index++) {
        if (tempArray[index].staffId === element.staffId) {
          existing = true;
          break;
        } else {
          existing = false;
        }
      }

      if (existing) {
        const distinction = (item) => item.staffId === element.staffId;
        const index = tempArray.findIndex(distinction);
        const currentPoints = tempArray[index].points;
        const newPoints = currentPoints + element.points;

        tempArray[index].points = newPoints;
      } else {
        tempArray.push(element);
      }
    });

    tempArray.forEach((element) => {
      this.pushElement(element);
      // this.getTeacherName(element.staffId);
      // this.pieDataTeacher.push({
      //   // label: element.staffId || 'Unknown Teacher',
      //   label: this.teacherNameCache,
      //   color: this.getRandomHexColor,
      //   data: element.points
      // });
    });
  }

  async pushElement(element) {
    await this.getTeacherName(element.staffId);
    this.pieDataTeacher.push({
      // label: element.staffId || 'Unknown Teacher',
      label: this.teacherNameCache,
      color: this.getRandomHexColor,
      data: element.points,
    });
  }

  getPieDataExp() {
    const filteredData = _.cloneDeep(this.tableData);
    const tempArray: any[] = [filteredData[0]];
    const newData = [...filteredData];
    if(tempArray){

      newData.shift();
      newData.forEach((element) => {
      let existing = false;
      for (let index = 0; index < tempArray.length; index++) {
        if (tempArray[index].expectationId === element.expectationId) {
          existing = true;
          break;
        } else {
          existing = false;
        }
      }

      if (existing) {
        const distinction = (item) =>
          item.expectationId === element.expectationId;
        const index = tempArray.findIndex(distinction);
        const currentPoints = tempArray[index].points;
        const newPoints = currentPoints + element.points;

        tempArray[index].points = newPoints;
      } else {
        tempArray.push(element);
      }
    });

    tempArray.forEach((element) => {
      this.pieDataExp.push({
        label: element.expectationName,
        color: this.getRandomHexColor,
        data: element.points,
      });
    });

    }

  }

  getWeeksInMonth() {
    const weeks = [];

    const year = new Date().getFullYear();
    const month = new Date().getMonth();

       const firstDate = new Date(year, month, 1);
      const  lastDate = new Date(year, month + 1, 0);
       const numDays = lastDate.getDate();

    let offset = 0;

    if(firstDate.getDay() === 0) offset = 1;
    else if(firstDate.getDay() === 6) offset = 2;

    let start = 1 + offset;
    let end: number;

    if(offset === 0) end = 5 - firstDate.getDay() + 1;
    else end = 5 - start + 1 + (offset * 2);

    while(start <= numDays) {
        weeks.push({start: start, end: end});
        start = end + 3;
        end = end + 7;
        end = start === 1 && end === 8 ? 1 : end;
        if(end > numDays) end = numDays;
    }

    return weeks;
}

  get getRandomHexColor() {
    const n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6);
  }

  checkTimeSelection(time) {
    if (time === "day") {
      this.isDaySelected = true;
      this.isWeekSelected = false;
      this.isMonthSelected = false;
      this.isYearSelected = false;
    } else if (time === "week") {
      this.isDaySelected = false;
      this.isWeekSelected = true;
      this.isMonthSelected = false;
      this.isYearSelected = false;
    } else if (time == "month") {
      this.isDaySelected = false;
      this.isWeekSelected = false;
      this.isMonthSelected = true;
      this.isYearSelected = false;
    } else if (time == "year") {
      this.isDaySelected = false;
      this.isWeekSelected = false;
      this.isMonthSelected = false;
      this.isYearSelected = true;
    }
  }

  getBarChartData() {
    if (this.timeSlot === "day") {
      this.checkTimeSelection(this.timeSlot);
      const barChartData = [
        {
          label: "",
          color: "#2196f3",
          data: [],
        },
      ];
      this.barData = barChartData;
      const data = _.cloneDeep(this.tableData);
      const filteredData = data.filter((item) => {
        const created = moment(item.created);
        const currentStart = moment().clone().startOf("day").toDate();
        const currentEnd = moment().clone().endOf("day").toDate();
        this.startLabel = "7:00 AM";
        this.endLabel = "5:00 PM";
        if (created.isBetween(currentStart, currentEnd)) {
          return item;
        }
      });

      let dayData = {
        h7: 0,
        h8: 0,
        h9: 0,
        h10: 0,
        h11: 0,
        h12: 0,
        h13: 0,
        h14: 0,
        h15: 0,
        h16: 0,
        h17: 0,
      };
      //2021-11-10 09:45
      filteredData.forEach((element) => {
        const index = moment(element.created).format("H");
        var hourCount = dayData[`h${index}`];
        dayData[`h${index}`] = hourCount + element.points;
      });
      const gsHours = [
        ["7AM", dayData.h7],
        ["8AM", dayData.h8],
        ["9AM", dayData.h9],
        ["10AM", dayData.h10],
        ["11AM", dayData.h11],
        ["12PM", dayData.h12],
        ["1PM", dayData.h13],
        ["2PM", dayData.h14],
        ["3PM", dayData.h15],
        ["4PM", dayData.h16],
        ["5PM", dayData.h17],
      ];
      barChartData[0].data = [...gsHours];
      this.barData = barChartData;
      console.log(this.barData, this.barOptions)
    } else if (this.timeSlot === "week") {
      this.checkTimeSelection(this.timeSlot);
      const barChartData = [
        {
          label: "",
          color: "#2196f3",
          data: [],
        },
      ];
      this.barData = barChartData;
      const data = _.cloneDeep(this.tableData);
      const filteredData = data.filter((item) => {
        const created = moment(item.created);
        const currentStart = moment().clone().startOf("week").toDate();
        const currentEnd = moment().clone().endOf("week").toDate();
        this.startLabel = moment(currentStart).add(1, "d").format("L");
        this.endLabel = moment(currentEnd).add(-1, "d").format("L");
        if (created.isBetween(currentStart, currentEnd)) {
          return item;
        }
      });

      let weekData = {
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
      };

      // let weekDates = {
      //   Monday: '',
      //   Tuesday: '',
      //   Wednesday: '',
      //   Thursday: '',
      //   Friday: ''
      // }

      // let today = moment();
      // let year: any = moment().format("YYYY");
      // year = parseInt(year);
      // let weekNumber = moment(today).week();
      // var test = moment().day("Monday").week(weekNumber).year(year);

      filteredData.forEach((element) => {
        const index = moment(element.created).format("dddd");
        var weekDayCount = weekData[index];
        weekData[index] = weekDayCount + element.points;

        // const date = moment(element.created).format("l");
        // weekDates[index] = date
      });

      const myStart = moment().clone().startOf("week").toDate();
      const gsWeekNames = [
        [
          `Monday <br /> ${this.getLabel(
            moment(myStart)
              .add("1", "d")
              .format("LL")
              .replace(/,[^,]+$/, "")
          )}`,
          weekData.Monday,
        ],
        [
          `Tuesday <br /> ${this.getLabel(
            moment(myStart)
              .add(2, "day")
              .format("LL")
              .replace(/,[^,]+$/, "")
          )}`,
          weekData.Tuesday,
        ],
        [
          `Wednesday <br /> ${this.getLabel(
            moment(myStart)
              .add(3, "day")
              .format("LL")
              .replace(/,[^,]+$/, "")
          )}`,
          weekData.Wednesday,
        ],
        [
          `Thursday <br /> ${this.getLabel(
            moment(myStart)
              .add(4, "day")
              .format("LL")
              .replace(/,[^,]+$/, "")
          )}`,
          weekData.Thursday,
        ],
        [
          `Friday <br /> ${this.getLabel(
            moment(myStart)
              .add(5, "day")
              .format("LL")
              .replace(/,[^,]+$/, "")
          )}`,
          weekData.Friday,
        ],
      ];

      barChartData[0].data = [...gsWeekNames];
      this.barData = barChartData;
    } else if (this.timeSlot === "month") {
      this.checkTimeSelection(this.timeSlot);
      const barChartData = [
        {
          label: "",
          color: "#2196f3",
          data: [],
        },
      ];
      this.barData = barChartData;
      const data = _.cloneDeep(this.tableData);
      const filteredData = data.filter((item) => {
        const created = moment(item.created);
        const currentStart = moment().clone().startOf("month").toDate();
        const currentEnd = moment().clone().endOf("month").toDate();
        this.startLabel = moment(currentStart).format("L");
        this.endLabel = moment(currentEnd).format("L");
        if (created.isBetween(currentStart, currentEnd)) {
          return item;
        }
      });

     const monthAnalysis = this.getWeeksInMonth();
      let monthWeeks;
      let gsMonths;

      const currentMonth = new Date().getMonth();
      const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
     if (monthAnalysis.length === 4) {
      monthWeeks = {
        "Week 1": 0,
        "Week 2": 0,
        "Week 3": 0,
        "Week 4": 0,
      };

      filteredData.forEach((element) => {
        const index = this.weekOfMonth(moment(element.created));
        var monthWeekDayCount = monthWeeks[`Week ${index}`];
        monthWeeks[`Week ${index}`] = monthWeekDayCount + element.points;
      });

   gsMonths = [
        [`Week 1 <br/> ${this.getDay(monthAnalysis[0].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[0].start)}`, monthWeeks["Week 1"]],
        [`Week 2 <br/> ${this.getDay(monthAnalysis[1].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[1].start)}`, monthWeeks["Week 2"]],
        [`Week 3 <br/> ${this.getDay(monthAnalysis[2].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[2].start)}`, monthWeeks["Week 3"]],
        [`Week 4 <br/> ${this.getDay(monthAnalysis[3].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[3].start)}`, monthWeeks["Week 4"]],
      ];
     } else {
      monthWeeks = {
        "Week 1": 0,
        "Week 2": 0,
        "Week 3": 0,
        "Week 4": 0,
        "Week 5": 0,
      };


      filteredData.forEach((element) => {
        const index = this.weekOfMonth(moment(element.created));
        var monthWeekDayCount = monthWeeks[`Week ${index}`];
        monthWeeks[`Week ${index}`] = monthWeekDayCount + element.points;
      });
gsMonths = [
        [`Week 1 <br/> ${this.getDay(monthAnalysis[0].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[0].start)}`, monthWeeks["Week 1"]],
        [`Week 2 <br/> ${this.getDay(monthAnalysis[1].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[1].start)}`, monthWeeks["Week 2"]],
        [`Week 3 <br/> ${this.getDay(monthAnalysis[2].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[2].start)}`, monthWeeks["Week 3"]],
        [`Week 4 <br/> ${this.getDay(monthAnalysis[3].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[3].start)}`, monthWeeks["Week 4"]],
        [`Week 5 <br/> ${this.getDay(monthAnalysis[4].start)}  ${this.getLabel(monthNames[currentMonth] + ' ' + monthAnalysis[4].start)}`, monthWeeks["Week 5"]],
      ];
     }





      barChartData[0].data = [...gsMonths];
      this.barData = barChartData;
    } else if (this.timeSlot === "year") {
      this.checkTimeSelection(this.timeSlot);
      const barChartData = [
        {
          label: "",
          color: "#2196f3",
          data: [],
        },
      ];
      this.barData = barChartData;
      // For This Month
      const data = _.cloneDeep(this.tableData);
      const filteredData = data.filter((item) => {
        const created = moment(item.created);
        const date = new Date();
        const month = date.getMonth() + 1;
        if (month <= 6) {
          const year = date.getFullYear();
          const otherYear = year - 1;
          this.startLabel = `07/01/${otherYear}`;
          this.endLabel = `06/30/${year}`;
          const currentStart = moment(this.startLabel, "MM/DD/YYYY");
          const currentEnd = moment(this.endLabel, "MM/DD/YYYY");
          if (created.isBetween(currentStart, currentEnd)) {
            return item;
          }
        } else {
          const year = date.getFullYear();
          const otherYear = year + 1;
          this.startLabel = `07/01/${year}`;
          this.endLabel = `06/30/${otherYear}`;
          const currentStart = moment(this.startLabel, "MM/DD/YYYY");
          const currentEnd = moment(this.endLabel, "MM/DD/YYYY");
          if (created.isBetween(currentStart, currentEnd)) {
            return item;
          }
        }
      });
      let yearMonths = {
        Jan: 0,
        Feb: 0,
        Mar: 0,
        Apr: 0,
        May: 0,
        Jun: 0,
        Jul: 0,
        Aug: 0,
        Sept: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
      };
      filteredData.forEach((element) => {
        const monthIndex = moment(element.created).format("MMM");
        var monthWeekDayCount = yearMonths[monthIndex];
        yearMonths[monthIndex] = monthWeekDayCount + element.points;
      });
      const date = new Date();
      const month = date.getMonth() + 1;

      if (month <= 6) {
        const year = date.getFullYear();
        const otherYear = year - 1;
        const gsYearNames = [
          [`Jul <br/> ${otherYear.toString()}`, yearMonths.Jul],
          [`Aug <br/> ${otherYear.toString()}`, yearMonths.Aug],
          [`Sep <br/> ${otherYear.toString()}`, yearMonths.Sept],
          [`Oct <br/> ${otherYear.toString()}`, yearMonths.Oct],
          [`Nov <br/> ${otherYear.toString()}`, yearMonths.Nov],
          [`Dec <br/> ${otherYear.toString()}`, yearMonths.Dec],
          [`Jan <br/> ${year.toString()}`, yearMonths.Jan],
          [`Feb <br/> ${year.toString()}`, yearMonths.Feb],
          [`Mar <br/> ${year.toString()}`, yearMonths.Mar],
          [`Apr <br/> ${year.toString()}`, yearMonths.Apr],
          [`May <br/> ${year.toString()}`, yearMonths.May],
          [`Jun <br/> ${year.toString()}`, yearMonths.Jun],
        ];
        barChartData[0].data = [...gsYearNames];
      } else {
        const year = date.getFullYear();
        const otherYear = year + 1;
        const gsYearNames = [
          [`Jul <br/> ${year.toString()}`, yearMonths.Jul],
          [`Aug <br/> ${year.toString()}`, yearMonths.Aug],
          [`Sep <br/> ${year.toString()}`, yearMonths.Sept],
          [`Oct <br/> ${year.toString()}`, yearMonths.Oct],
          [`Nov <br/> ${year.toString()}`, yearMonths.Nov],
          [`Dec <br/> ${year.toString()}`, yearMonths.Dec],
          [`Jan <br/> ${otherYear.toString()}`, yearMonths.Jan],
          [`Feb <br/> ${otherYear.toString()}`, yearMonths.Feb],
          [`Mar <br/> ${otherYear.toString()}`, yearMonths.Mar],
          [`Apr <br/> ${otherYear.toString()}`, yearMonths.Apr],
          [`May <br/> ${otherYear.toString()}`, yearMonths.May],
          [`Jun <br/> ${otherYear.toString()}`, yearMonths.Jun],
        ];
        barChartData[0].data = [...gsYearNames];
      }
      this.barData = barChartData;
    }
  }

  get getTotalScore() {
    let score = 0;
    this.tableDataCopy?.forEach((element) => {
      score = score + element.points;
    });
    return score;
  }

  get getChartTitle() {
    let str;
    if (this.timeSlot === "day") {
      let weekday = moment().format("dddd");
      str = `Points for Today (${weekday}) ${this.startLabel} to ${this.endLabel}`;
    } else if (this.timeSlot === "week") {
      str = `Points for This Week ${this.startLabel} to ${this.endLabel}`;
    } else if (this.timeSlot === "month") {
      str = `Points for This Month ${this.startLabel} to ${this.endLabel}`;
    } else if (this.timeSlot === "year") {
      let year = moment().format("YYYY");
      // let lastYear = parseInt(year) - 1;
      // let schoolYear = lastYear + " - " + year;
      str = `Points for SY: ${this.startLabel} to ${this.endLabel}`;
    }
    return str;
  }

  setTimeSlot(type) {
    this.timeSlot = type;
    this.getBarChartData();
  }

  getLabel(label: string) {
    const month = label.substr(0, label.indexOf(" "));
    let day = label.substr(label.indexOf(" ") + 1);
    const result =
      [, "st", "nd", "rd"][(+day / 10) % 10 ^ 1 && +day % 10] || "th";

    day = day + result;

    return `${day} ${month}`;
  }

  async getTeacherName(staffId?) {
    let name;
    if (staffId) {
      const getName: any = await this.profileService
        .getProfileById(staffId)
        .toPromise();
      name = getName.firstName; // + ' ' + getName.lastName;
      this.teacherNameCache = name;
    } else {
      this.teacherNameCache = "Undefined";
    }
    // const id = staffId ? staffId : `lausd.teacher01@lausd.net`;
  }

  // change() {
  //   // Change a value
  //   this.splineData[0].color = '#ff0000';
  //   // Create a new object in order to provide a different instance
  //   // so the directive can detect the change using ngOnChanges
  //   this.splineData = $.extend(true, [], this.splineData);
  // }

  viewOption(type: string) {
    this.viewType = type;
  }

  getDay(day) {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const fullDate =`${month} / ${day} / ${year}`;
    const finalDate = new Date(fullDate);

    return moment(finalDate).format('dddd');
  }

  getChartData(url): Observable<any> {
    return this.http.get(url);
  }
  weekOfMonth(input = moment()) {
    const firstDayOfMonth = input.clone().startOf("month");
    const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

    const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

    return Math.ceil((input.date() + offset) / 7);
  }
  resetToDefault() {
    swal({
      title: "Are you sure?",
      text: "Expectations will be reset.",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, reset it!",
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        this.toastr.info("TODO: API call");
      }
    });
  }

  createExpectation() {
    this.dialog
      .open(NewExpectationModalComponent, {
        disableClose: true,
        width: "70%",
        maxHeight: "80vh",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.tableData.push(res);
        }
      });
  }

  edit(expectation, idx) {
    this.dialog
      .open(NewExpectationModalComponent, {
        disableClose: true,
        width: "70%",
        maxHeight: "80vh",
        data: { expectation },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.expectationsService
            .updateExpectation(res)
            .subscribe((expectation) => {
              this.tableData[idx] = res;
            });
        }
      });
  }

  archive(expectation, idx) {
    swal({
      title: "Are you sure?",
      text: "Expectation will be archived.",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, archive it!",
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        expectation.enabled = false;
        this.expectationsService
          .updateExpectation(expectation)
          .subscribe((response) => {
            this.tableData[idx] = response;
          });
      }
    });
  }
  activate(expectation, idx) {
    swal({
      title: "Are you sure?",
      text: "Expectation will be activate.",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, activate it!",
          value: true,
          visible: true,
          className: "bg-success",
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        expectation.enabled = true;
        this.expectationsService
          .updateExpectation(expectation)
          .subscribe((response) => {
            this.tableData[idx] = response;
          });
      }
    });
  }

  print() {
    window.print();
  }
}

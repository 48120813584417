import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactsService } from '../../services/contacts.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-print-event-attendance',
  templateUrl: './print-event-attendance.component.html',
  styleUrls: ['./print-event-attendance.component.scss']
})
export class PrintEventAttendanceComponent implements OnInit {

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private contactService: ContactsService
  ) {
    // this.list = this.router.getCurrentNavigation().extras.state.list;
   }

  list: [];
  eventData: any;


  ngOnInit(): void {
    
    this.list = history.state.list;
    this.eventData = history.state.eventData;
    console.log(this.eventData)
    console.log(this.list)


  }

  printPage() {
    window.print();
  }

  gotoDetails() {
    let role = this.profileService.profile.role;
    this.router.navigate([role.toLocaleLowerCase(), 'events', 'id', this.eventData.id]);
  }

  command(cmd: any) {
    let role = this.profileService.profile.role;
    this.contactService.setContact(cmd.source);
    if (cmd.name == 'message') {
      const contact = cmd.source;
      this.router.navigate([role.toLocaleLowerCase(), 'inbox', 'compose', contact.empCode]);
    }
    if (cmd.name == 'profile') {
      const contact = cmd.source;
      this.router.navigate([role.toLocaleLowerCase(), 'contacts', 'profile', contact.empCode]);
    }
  }


}

import { Component, OnInit, Input } from '@angular/core';
import { PopularModel } from './models/PopularModel';
import AsyncComponent from '../common/AsyncComponent';

@Component({
  selector: 'app-popular-widget',
  templateUrl: './popular-widget.component.html',
  styleUrls: ['./popular-widget.component.scss']
})
export class PopularWidgetComponent implements OnInit, AsyncComponent {
  @Input() model: PopularModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
    this.loaded = this.model != null
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-search-admin-search-bar-widget',
  templateUrl: './student-search-admin-search-bar-widget.component.html',
  styleUrls: ['./student-search-admin-search-bar-widget.component.scss']
})
export class StudentSearchAdminSearchBarWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { AnnouncementAdminPostModel } from './models/AnnouncementAdminPostModel';

@Component({
  selector: 'app-announcement-admin-post-widget',
  templateUrl: './announcement-admin-post-widget.component.html',
  styleUrls: ['./announcement-admin-post-widget.component.scss']
})
export class AnnouncementAdminPostWidgetComponent implements OnInit {

  @Input() model: AnnouncementAdminPostModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

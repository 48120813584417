import { AppConfigService } from './../../../services/app-config.service';
import { CdnService } from './../../../services/cdn.service';
import { CertificateService } from 'src/app/shared/services/certificate.service';
import { BadgeStatusService } from "./../../../shared/badge-status.service";
import { RoleServiceFactoryService } from "./../../../shared/services/role-service-factory.service";
import { MatDialog } from "@angular/material/dialog";
import { TeacherService } from "./../../../shared/services/teacher.service";
import { ProfileService } from "./../../../shared/services/profile.service";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SettingsService } from "src/app/core/settings/settings.service";

import { UserblockService } from "./userblock.service";
import { StaffSettingsService } from "src/app/shared/services/staff-settings.service";
import { NotificationServices } from "src/app/shared/services/notification.service";
import { ToastrService } from "ngx-toastr";
import { Subscription, timer } from "rxjs";
import { Certificate } from "crypto";
import { CounselorService } from 'src/app/shared/services/counselor.service';
import * as _ from 'lodash';
import { AdministratorService } from 'src/app/shared/services/administrator.service';
import * as moment from 'moment';

@Component({
  selector: "app-userblock",
  templateUrl: "./userblock.component.html",
  styleUrls: ["./userblock.component.scss"],
})
export class UserblockComponent implements OnInit, OnDestroy {
  user: any;

  teacherStatuses: any[] = [
    "Status - Available",
    "Status - Teaching",
    "Status - Projecting",
    "Status - Do Not Disturb",
  ];

  counselorStatuses: any[] = [
    "Status - Available",
    "Status - Counseling",
    "Status - Do Not Disturb",
  ]

  adminStatuses: any[] = [
    "Status - Available",
    "Status - Administrating",
    "Status - Do Not Disturb",
  ]

  statusData: any[] = [
    {
      icon: 'fas fa-smile',
      color: '#4CAF50',
      status: 'Available',
      text: 'Available'
    },
    {
      icon: 'fas fa-chalkboard-teacher',
      color: '#FF4D00',
      status: 'Teaching',
      text: 'Teaching'
    },
    {
      icon: 'far fa-tv',
      color: '#E1393F',
      status: 'Projecting',
      text: 'Projecting'
    },
    {
      icon: 'fas fa-moon-stars',
      color: '#0079C4',
      status: 'DoNotDisturb',
      text: 'Do Not Disturb'
    },
  ]
  counselorStatusData: any[] = [
    {
      icon: 'fas fa-smile',
      color: '#4CAF50',
      status: 'Available',
      text: 'Available'
    },
    {
      icon: 'fas fa-chalkboard-teacher',
      color: '#FF4D00',
      status: 'Counseling',
      text: 'Counseling'
    },
    {
      icon: 'fas fa-moon-stars',
      color: '#0079C4',
      status: 'DoNotDisturb',
      text: 'Do Not Disturb'
    },
  ]
  adminStatusData: any[] = [
    {
      icon: 'fas fa-smile',
      color: '#4CAF50',
      status: 'Available',
      text: 'Available'
    },
    {
      icon: 'fas fa-chalkboard-teacher',
      color: '#FF4D00',
      status: 'Administrating',
      text: 'Administrating'
    },
    {
      icon: 'fas fa-moon-stars',
      color: '#0079C4',
      status: 'DoNotDisturb',
      text: 'Do Not Disturb'
    },
  ]

  get fallbackImg(){
    let userType = this.profileService.identity.userType;
    switch (userType) {

      case 'Administrator':
        return this.rs.AdminAvatar


      case 'Principal':
        return this.rs.PrincipalAvatar


      case 'Teacher':
        return this.rs.TeacherAvatar


      case 'Counselor':
        return this.rs.CounselorAvatar


      case 'Student':
        return this.rs.StudentAvatar

      case 'Parent':
        return this.rs.ParentAvatar


      default:
        return './assets/img/preloader/preloader.full.png'
    }
  }


  chipClass: any;

  // teacherCurrentStatus;
  profileType = null;
  quietSettings: any = null;
  sub: Subscription;
  env: string = null;

  constructor(
    public userblockService: UserblockService,
    public settingsService: SettingsService,
    public profileService: ProfileService,
    private teacherService: TeacherService,
    public staffSettingsService: StaffSettingsService,
    public rs: RoleServiceFactoryService,
    private toastr: ToastrService,
    public badgeStatusService: BadgeStatusService,
    private certificateServices: CertificateService,
    public cndService: CdnService,
    private _cd: ChangeDetectorRef,
    private counselorService: CounselorService,
    private adminService: AdministratorService,
    private appConfigService: AppConfigService
  ) {
    this.user = {
      picture: "assets/img/user/01.jpg",
      name: settingsService.getUserSetting("name"),
    };
    this.env = this.appConfigService.configuration.environment.configuration.environment_name;
    this.staffSettingsService.getSettings(this.profileService.identity.userIdRole).subscribe((res) => 
          this.staffSettingsService.updateStaffQuietSettings(res));
  }
  badgeSubscription: Subscription;
  quietHoursSubscription;
  s3public = null;
  profilePicture = null;
  ngOnInit() {
    this.staffSettingsService.settings.subscribe(quietHours => {
      this.quietHoursSubscription = quietHours;
    })
    this.profileType = this.profileService.role;
    this.badgeSubscription = this.badgeStatusService
      .trackStatus(this.profileService.identity.userIdRole)
      .subscribe((settings) => {
        this.quietSettings = settings;
        this.quietHoursSubscription = settings;
      });

      this.profileService.pictureUpdate.subscribe((avatar)=>{
        this.profilePicture = avatar;
        if(this.profileService.profile.avatar !== null && this.profileService.role == 'Student'){
          this.certificateServices.getThumbnails().toPromise().then((data)=>{
          this.s3public = 'https://'+data.s3PublicRepository+'.s3.us-west-2.amazonaws.com/profiles/avatars/';
          this.profilePicture = `${this.s3public}${this.profileService.profile.avatar}`
          });
        }
        this._cd.detectChanges();
      })
      this.profilePicture = this.profileService.profile.avatar;
      if(this.profileService.profile.avatar !== null && this.profileService.role == 'Student'){
        this.certificateServices.getThumbnails().toPromise().then((data)=>{
        this.s3public = 'https://'+data.s3PublicRepository+'.s3.us-west-2.amazonaws.com/profiles/avatars/';
        this.profilePicture = `${this.s3public}${this.profileService.profile.avatar}`
        });
        this._cd.detectChanges();

      }


      console.log(this.profileService.profile)
      this.getChip(this.profileType)

  }

  getChip(profileType){
    switch (profileType) {
      case 'Student':
        this.chipClass = 'student-chip';
        break;
      case 'Parent':
        this.chipClass = 'parent-chip';
        break;
      case 'Teacher':
        this.chipClass = 'teacher-chip';
        break;
      case 'Counselor':
        this.chipClass = 'counselor-chip';
        break;
      case 'Administrator':
        this.chipClass = 'admin-chip';
        break;
      default:
        break;
    }
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

  checkQuietHours(quietHours){
    const format = 'HH:mm:ss';
    let today = moment().format('dddd').toLocaleLowerCase();
    let date = moment().format(format);
    let now = moment(date, format);
    if(quietHours.quietHours[today]){
      const start = `${today}StartTime`;
      const end = `${today}EndTime`
      let startTime = moment(quietHours.quietHours[start], format);
      let endTime = moment(quietHours.quietHours[end], format);
      if(now.isBetween(startTime, endTime)){
        return true
      } else return false
    }
  }

  changeStatus(event) {
    const staffId = this.profileService.identity.userIdRole;


    if (this.profileService.role === this.rs.TeacherRole) {

      if (event === this.staffSettingsService.TeacherStatus.Available) {
        this.quietHoursSubscription.isNotificationsEnabled = true;
        this.quietHoursSubscription.quietHours.isAvailable = true;
        this.quietHoursSubscription.quietHours.isTeaching = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
      } else if (event === this.staffSettingsService.TeacherStatus.Teaching) {
        this.quietHoursSubscription.isNotificationsEnabled = false;
        this.quietHoursSubscription.quietHours.isTeaching = true;
        this.quietHoursSubscription.quietHours.isAvailable = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
      } else if (event === this.staffSettingsService.TeacherStatus.Projecting) {
        this.quietHoursSubscription.isNotificationsEnabled = false;
        this.quietHoursSubscription.quietHours.isProjecting = true;
        this.quietHoursSubscription.quietHours.isAvailable = false;
        this.quietHoursSubscription.quietHours.isTeaching = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = false;
      } else {
        this.quietHoursSubscription.isNotificationsEnabled = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = true;
        this.quietHoursSubscription.quietHours.isAvailable = false;
        this.quietHoursSubscription.quietHours.isTeaching = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
      }

      if(this.checkQuietHours(this.quietHoursSubscription)){
        this.toastr.warning(`Quiet Hours are enabled. Please disable Quiet Hours before updating status.`)
      } else {
        this.staffSettingsService
        .patch(staffId, this.quietHoursSubscription)
        .subscribe((settings) => {
          this.toastr.success(`Successfully updated status`);
          this.quietHoursSubscription = settings;
          this.teacherService.teacherStatus$.next(event);
          this.badgeStatusService.enqueue(staffId);
        });
      }


    }

    if (this.profileService.role === this.rs.CounselorRole) {

      if (event === this.staffSettingsService.CounselorStatus.Available) {
        this.quietHoursSubscription.quietHours.isAvailable = true;
        this.quietHoursSubscription.quietHours.isTeaching = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = false;
      } else if (event === this.staffSettingsService.CounselorStatus.Counseling) {
        this.quietHoursSubscription.quietHours.isTeaching = true;
        this.quietHoursSubscription.quietHours.isAvailable = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = false;
      } else {
        this.quietHoursSubscription.quietHours.isNotDisturb = true;
        this.quietHoursSubscription.quietHours.isAvailable = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
        this.quietHoursSubscription.quietHours.isTeaching = false;
      }
      if(this.checkQuietHours(this.quietHoursSubscription)){
        this.toastr.warning(`Quiet Hours are enabled. Please disable Quiet Hours before updating status.`)
      } else {
        this.staffSettingsService
        .patch(staffId, this.quietHoursSubscription)
        .subscribe((settings) => {
          this.toastr.success(`Successfully updated status`);
          this.quietHoursSubscription = settings;
          this.counselorService.teacherStatus$.next(event);
          this.badgeStatusService.enqueue(staffId);
        });
      }



    }

    if (this.profileService.role === this.rs.AdminRole) {

      if (event === this.staffSettingsService.AdminStatus.Available) {
        this.quietHoursSubscription.quietHours.isAvailable = true;
        this.quietHoursSubscription.quietHours.isTeaching = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = false;
      } else if (event === this.staffSettingsService.AdminStatus.Administrating) {
        this.quietHoursSubscription.quietHours.isTeaching = true;
        this.quietHoursSubscription.quietHours.isAvailable = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
        this.quietHoursSubscription.quietHours.isNotDisturb = false;
      } else {
        this.quietHoursSubscription.quietHours.isNotDisturb = true;
        this.quietHoursSubscription.quietHours.isAvailable = false;
        this.quietHoursSubscription.quietHours.isProjecting = false;
        this.quietHoursSubscription.quietHours.isTeaching = false;
      }
      if(this.checkQuietHours(this.quietHoursSubscription)){
        this.toastr.warning(`Quiet Hours are enabled. Please disable Quiet Hours before updating status.`)
      } else {
      this.staffSettingsService
        .patch(staffId, this.quietHoursSubscription)
        .subscribe((settings) => {
          this.toastr.success(`Successfully updated status`);
          this.quietHoursSubscription = settings;
          this.adminService.teacherStatus$.next(event);
          this.badgeStatusService.enqueue(staffId);
        });
      }


    }

  }

  ngOnDestroy() {
    if (this.badgeSubscription) {
      this.badgeSubscription.unsubscribe();
    }
  }
}

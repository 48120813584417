import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-select-event-time',
  templateUrl: './select-event-time.component.html',
  styleUrls: ['./select-event-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectEventTimeComponent implements OnInit {
  startDateTime: any;
  endDateTime: any;
  constructor(public dialogRef: MatDialogRef<SelectEventTimeComponent>) { }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close({ startDateTime: this.startDateTime, endDateTime: '' });
  }

}

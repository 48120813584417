import { AudienceService } from '../../../shared/services/audience.service';
import { Route } from '../../../shared/services/student.service.models';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { CommunicationGroupService } from '../../../shared/services/communication-group.service';
import { CustomFilePickerAdapter } from 'src/app/services/file-picker.adapter';
import { AppConfigService } from 'src/app/services/app-config.service';
import { GroupsService } from 'src/app/shared/services/groups.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { SchoolRoomService } from 'src/app/shared/services/school-room.service';
import { StudentsService } from 'src/app/shared/services/students.service';
import { TeacherService } from 'src/app/shared/services/teacher.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-create-communication-groups',
  templateUrl: './create-communication-groups.component.html',
  styleUrls: ['./create-communication-groups.component.scss']
})
export class CreateCommunicationGroupsComponent implements OnInit {

  staffId = null;
  audienceModel = null;
  constructor(
    private router: Router,
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private profileService: ProfileService,
    private teacherServices: TeacherService,
    private cServices: CommunicationGroupService,
    private audienceServices: AudienceService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }
  step1 = true;
  step2 = false;
  step3 = false;

  fileTitle: any;

  students: any[] = [

  ];

  displayedColumns: string[] = ['select', 'photo', 'studentId', 'firstName', 'lastName'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  selectedNum = 0;
  grades: number[] = [1, 2, 3, 4, 5, 6];
  grade: any = 'All grades:';
  classes: string[] = ['class name', 'class name', 'class name'];
  class: any = 'All classes:';
  groups: string[] = ['group name', 'group name', 'group name'];
  group: any = 'All groups:';

  public adapter = new CustomFilePickerAdapter(this.http, this.appConfigService, this.profileService.identity.userIdRole);
  schoolRooms: any = [];
  schoolRoom: any = null;
  studentsCount = 0;
  includeParents = false;
  includeStudents = false;

  pbisList = ['Below 10','Below 20','Above 35', 'Below 35','20 to 30']
  gpaList = ['Below 1','Below 2','Above 35', 'Below 3.5','2 to 3']
  pbisPoints = null;
  editMode = false;
  communicationGroupId = null;
  editAudienceModel = null;
  ngOnInit(): void {
    if (this.route.snapshot?.data?.isEdit) {
      this.editMode = this.route.snapshot?.data?.isEdit;
      // FIXME: real service
      this.communicationGroupId = this.route.snapshot.params.id;
      this.cServices.getCommunicationGroup(this.profileService.identity.userIdRole, this.communicationGroupId).subscribe((res: any) => {

        var item = res[0];
        this.fileTitle = item.name;
        this.editAudienceModel = {
          name: item.name,
          schoolId: item.schoolId,
          schoolRoomId: item.schoolRoomId,
          gradeId: item.gradeId,
          groupId: item.groupId,
          students: item.students,
          includeParents: item.includeParents,
          includeStudents: item.includeStudents,
          minPoints: item.minPoints,
          maxPoints: item.maxPoints,
          staffId: item.staffId
        };
      });
    }

    this.dataSource = new MatTableDataSource<any>(this.students);
    this.staffId = this.profileService.identity.userIdRole;

    this.teacherServices.getSchoolRoomsByStaffId(this.staffId).subscribe((schoolRooms) => {
      this.schoolRooms = schoolRooms;
      this.grades = _.uniq(_.map(schoolRooms, (i) => i.grade));
    });
    this.teacherServices.getStudentsByStaffId(this.staffId).subscribe((students) => {
      this.dataSource = new MatTableDataSource<any>(students);
    });
  }
  enabled = false;
  audienceChange($event) {
    this.audienceModel = $event;
    if(this.audienceModel.schoolCode){
      var schools = JSON.parse(this.audienceModel.schoolCode);
      if(schools) {
        this.enabled = schools.length == 0 ? false : true;
      } else {
        this.enabled = false;
      }

    }

  }

  get isTest() {
    if(environment.environment_name == 'Test') return true
    else return false
  }

  public uploadSuccess(event): void {
    console.log(event, '========Upload======');
  }


  cancel() {
    this.router.navigate(['teacher/communication-groups']);
  }

  submit() {
    const request = {
      ...this.audienceModel,
      schoolId: this.audienceModel.schoolCode,
      name: this.fileTitle,
      staffId: this.staffId
    };
    //delete request.students;
    this.cServices.createCommunicationGroup(request).subscribe(() => {
      this.router.navigate(['../../communication-groups'],{relativeTo: this.route});
      this.toastr.success(`Successfully generated ${this.fileTitle}`);
    });

  }
  update() {
    const request = {
      name: this.fileTitle,
      schoolId: this.audienceModel.schoolCode,
      schoolRoomId: this.audienceModel.schoolRoomId,
      gradeId: this.audienceModel.gradeId,
      groupId: this.audienceModel.groupId,
      students: this.audienceModel.students,
      includeParents: this.audienceModel.includeParents,
      includeStudents: this.audienceModel.includeStudents,
      minPoints: this.audienceModel.minPoints,
      maxPoints: this.audienceModel.maxPoints,
      staffId: this.staffId,
      communicationId: this.communicationGroupId
    };
    console.log(request);
    this.cServices.updateCommunicationGroup(request).subscribe(() => {
      this.router.navigate(['teacher/communication-groups']);
      this.toastr.success(`Successfully generated ${this.fileTitle}`);
    });

  }
}

import { Component, OnInit, Input } from '@angular/core';
import { StudentListModel } from './models/StudentListModel';

@Component({
  selector: 'app-student-list-widget',
  templateUrl: './student-list-widget.component.html',
  styleUrls: ['./student-list-widget.component.scss']
})
export class StudentListWidgetComponent implements OnInit {

  @Input() model: StudentListModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {

  }

}

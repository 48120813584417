import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-attendance-widget',
  templateUrl: './student-attendance-widget.component.html',
  styleUrls: ['./student-attendance-widget.component.scss']
})
export class StudentAttendanceWidgetComponent implements OnInit {

  @Input() attendance: any = null;
  constructor() { }

  ngOnInit(): void {
  }

}

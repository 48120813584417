import { Component, OnInit, Input, ViewChild } from '@angular/core';
import AsyncComponent from '../common/AsyncComponent';
import { GroupAdminListModel } from './models/Group-Admin-List-Model';
import { GroupItemWidgetComponent } from '../group-item-widget/group-item-widget.component';

@Component({
  selector: 'app-group-admin-list-widget',
  templateUrl: './group-admin-list-widget.component.html',
  styleUrls: ['./group-admin-list-widget.component.scss']
})
export class GroupAdminListWidgetComponent implements OnInit, AsyncComponent {
  @Input() model: GroupAdminListModel = null;

  // async component
  loaded: boolean;
  @ViewChild(GroupItemWidgetComponent, { static: false }) calendar: GroupItemWidgetComponent;

  constructor() { }

  ngOnInit(): void {
  }

}

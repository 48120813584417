import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ClassStudentDetails } from "src/app/routes/teacher/classes-route/classes-details-page/model/ClassDetails";
import { GroupsService } from "../../services/groups.service";
import { GroupDetails, GroupAction } from "./models/GroupDetails";
import { ClassCreateGroupModalComponent } from "../class-create-group-modal/class-create-group-modal.component";

@Component({
  selector: "app-group-details-widget",
  templateUrl: "./group-details-widget.component.html",
  styleUrls: ["./group-details-widget.component.scss"],
})
export class GroupDetailsWidgetComponent implements OnInit {
  @Input() group: GroupDetails = null;
  @Input() groups: GroupDetails[] = [];
  @Input() students: ClassStudentDetails[] = [];
  @Input() classTitle: string;
  @Output() onAction = new EventEmitter<GroupAction>();

  constructor(
    private groupsService: GroupsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    // console.log(this.group);
  }

  editGroupModal(group){
    this.groupsService.getStudentsByGroupId(group.id).subscribe((res)=>{
    })
    this.dialog
      .open(ClassCreateGroupModalComponent, {
        disableClose: false,
        data: {
          students: this.students,
          groups: this.groups,
          selectedGroup: group,
          classTitle: this.classTitle
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res.command === "groupEdited") {
          this.groups = res.target;
        }
      });
  }

  onDeleteGroup(selectedGroup: GroupDetails) {
    const action: GroupAction = {
      target: selectedGroup,
      command: "deleteGroup",
    };
    this.onAction.emit(action);
  }

  onGiveReinforcement(selectedGroup: GroupDetails) {
    const action: GroupAction = {
      target: selectedGroup,
      command: "giveReinforcement",
    };
    this.onAction.emit(action);
  }
}

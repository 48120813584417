import { IFaqGroup } from './../../components/faq-section-widget/models/Faq';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-widget',
  templateUrl: './accordion-widget.component.html',
  styleUrls: ['./accordion-widget.component.scss']
})
export class AccordionWidgetComponent implements OnInit {

  @Input() faq:IFaqGroup = null
  constructor() { }

  ngOnInit(): void {
  }

}

import { StudentsService } from "src/app/shared/services/students.service";
import { SchoolService } from "src/app/shared/services/school.service";
import { Observable } from "rxjs/Observable";
import { Profile,ProfileService } from "src/app/shared/services/profile.service";
import { AudienceService } from "./../../services/audience.service";
import { RoleServiceFactoryService } from "src/app/shared/services/role-service-factory.service";
import { map, switchMap } from "rxjs/operators";
import { GroupsService } from "src/app/shared/services/groups.service";
import { TeacherService } from "src/app/shared/services/teacher.service";
import {
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { Component, Input, OnInit, Output, OnChanges } from "@angular/core";
import * as _ from "lodash";
import { SelectionModel } from "@angular/cdk/collections";
import { CommunicationGroupService } from "../../services/communication-group.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { AdministratorService } from "../../services/administrator.service";
import { of, Subject } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { runInThisContext } from "vm";
import { FormControl, FormGroup } from "@angular/forms";
import { ContactsService } from "../../services/contacts.service";
import swal from "sweetalert";
import { options } from "preact";
import { MatTab, MatTabGroup } from "@angular/material/tabs";
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';

@Component({
  selector: "app-pbis-audience-widget",
  templateUrl: "./pbis-audience-widget.component.html",
  styleUrls: ["./pbis-audience-widget.component.scss"],
})
export class PbisAudienceWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("paginatorSelected") paginatorSelected: MatPaginator;
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @Input() staffId: any = [];
  @Input() showCommunicationGroups = false;
  @Input() showPoints = false;
  @Input() showParents = true;
  @Input() showSelection = true;
  @Input() audienceMode = false;
  @Input() stepIsOpen = false;
  @Input() entityId : any;
  @Input() isCommunication = false;
  @ViewChild("searchInput") searchInput: ElementRef<HTMLInputElement>;
  @Output() onLoaded = new EventEmitter<Profile>();

  role = this.profile.profile.role;

  _editAudienceModel: any;
  get editAudienceModel(): any {
    return this._editAudienceModel;
  }
  @Input() set editAudienceModel(value: any) {
    this._editAudienceModel = value;
  }

  @Output() audienceChange: EventEmitter<AudienceModel> =
    new EventEmitter<AudienceModel>();

  tableColumns = [];
  dataSource = new MatTableDataSource<any>();
  selectedDataSource = new MatTableDataSource<any>();
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );
  selectionSelected = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );
  selectedCount = 0;

  selectedSchoolList = [];

  isShowingStudents = false;
  selectedCommunicationGroup = null;
  selectedGrade = null;
  selectedSchoolRoom = null;
  selectedGroup = null;
  selectedSchool = null;
  selectedGroupUsers = null;
  minPoints = 0;
  includeParents = false;
  includeStudents = true;
  maxPoints = 0;

  audienceCopy = [];

  // filter enablers
  isSchoolSelected = false;
  isGradeSelected = false;
  isClassSelected = false;

  _schoolRoomList = [];
  _schoolRoomFiltered = [];
  _gradeList = [];
  _gradeFiltered = [];
  _schoolList = [];
  _schoolListCopy = [];
  selectedSchools: string[] = [];
  _studentList = [];
  _studentFiltered = [];
  _groupList = [];
  _communicationGroups = [];

  allSelected = false;
  userSelection = 'allStudents';
  isLongList = false;
  searchText: string = '';

  searchFormStudent: FormGroup;
  searchFormParent: FormGroup;
  validEmail: any;
  countMode: boolean = false;
  countTotal: number = 0;
  isStudent = true;
  isEdit = false;
  isAllSchoolsSelected: boolean = false;
  isAdmin= false;

  get isEventCreator() {
    if (this.role == "Administrator") return true;
    else if (this.role == "Counselor") return true;
    else return false;
  }

  roleInstance: PbisAudienceWidget = null;
  studentsCount = 0;
  parentsCount = 0;
  constructor(
    private dialog: MatDialog,
    public teacherServices: TeacherService,
    private groupServices: GroupsService,
    private cGroups: CommunicationGroupService,
    private audienceService: AudienceService,
    public rs: RoleServiceFactoryService,
    public profile: ProfileService,
    public adminService: AdministratorService,
    public schoolService: SchoolService,
    private profileService: ProfileService,
    private contactsService: ContactsService,
    private studentService: StudentsService
  ) {
    let roleType = this.profile.identity.userType;
    this.roleInstance =
      this.rs.AdminRole == roleType
        ? new AdministratorRole(this)
        : this.rs.CounselorRole == roleType
        ? new CounselorRole(this)
        : new TeacherRole(this);
  }
  getAudience(entityId){
    this.audienceService.getAudience("communication", entityId).subscribe((response) => {
      response.forEach(element => {
        this.profileService.getProfileById(element.targetId).subscribe((profile) => {
          //var x = profile;
          var element = {firstName : profile.firstName, lastName: profile.lastName, targetRole: profile.role,
          studentId : profile.role === 'Student' ? profile.id : null,
          parentId : profile.role === 'Parent'? profile.id : null}
          if(profile.role === 'Student'){
            this.studentSelectedCount++;
          }
          else{
            this.parentSelectedCount++;
          }
          this.selectedDataSource.data.push(element);
          this.selectedDataSource.data.forEach(x => this.selectionSelected.select(x))
          this.onLoaded.emit(profile);
        });
      });
    });

    
  }
  ngAfterViewInit() {
    if(this.entityId != null){
      this.getAudience(this.entityId);
      this.isEdit = true;
      this.selectedInnerTabIndex = 1;
    }
    this.selectedTabIndex = 0;
   
    this.dataSource.paginator = this.paginator;
    this.selectedDataSource.paginator = this.paginatorSelected;
  }

  get isAdminTest() {
    if(environment.environment_name == 'Test' && this.role == 'Administrator'){
      //TODO only for Test environment
      this.addParentsOnly = true;
      this.addAssociatedParents = true;
      this.userSelection = 'allParents';
      this.parentsOnly = true;
    return true}
    else return false
  }
  filteredData: boolean = false;
  applySearchFilter() {
    this.dataSource.filter = this.searchText.trim().toLowerCase();
    this.filteredData = true;
    if(!this.searchText){
      this.filteredData = false;
    }
    this.calculateAudience();
  }
  ngOnInit(): void {
    this.isAdmin = this.role == 'Administrator'? true: false;
    console.log(this.stepIsOpen, 'step====')
    this.tableColumns = this.showSelection
      ? ["select", "photo", "type", "studentId", "firstName", "lastName"]
      : ["photo", "type", "studentId", "firstName", "lastName"];

    this.roleInstance
      .getSchoolLocations()
      .pipe(
        map((response) => {
          
          this._schoolList = _.orderBy(
            _.uniqBy(response.schools, "locationCode"),
            "locationName",
            "asc"
          );
          var s =_.uniq(response.schools.map(school => school.locationCode.toString())).join(',');
     
          this._schoolList.sort(function(a, b){
            return a.locationName - b.locationName;
          })
          console.log(this._schoolList, "school-lits", response);
          this._schoolListCopy = this._schoolList;
        })
      )
      .subscribe(() => {
        this.dataSource.paginator = this.paginator;
        this.selectedDataSource.paginator = this.paginatorSelected;
      })
      .add(() => {
        if (this.editAudienceModel) {
          this.filterCommunicationGroup(this.editAudienceModel);
          // this.roleInstance.onSchoolChange();
          // this.dataSource.paginator = this.paginator;
          // this.selectedDataSource.paginator = this.paginatorSelected;
        } else {
          this.cGroups
            .getCommunicationGroupsByStaff(this.staffId)
            .subscribe((communicationGroups) => {
              if(this.showCommunicationGroups){
                if(this.isCommunication){
                this._communicationGroups = communicationGroups}
                else{
                  this._communicationGroups = communicationGroups.filter((cg)=>{
                    return cg.includeParents == false;
                 });
                }
              }
              else{
              //only display communication groups/NO parents
              this._communicationGroups = communicationGroups.filter((cg)=>{
                 return cg.includeParents == this.showParents;
              });
            }
            });
            if(this.entityId == null){
          this.resetFilters();
            }
          //this.roleInstance.onSchoolChange();
          //this.isSchoolSelected = true;
        }
      });

    this.initSearchForm();
    console.log(this.userSelection)
  }

  toggleAllSchools() {
    this.isAllSchoolsSelected = !this.isAllSchoolsSelected;
    this.selectedSchools = [];
    this.selectedSchoolsBadges = [];
        this._schoolList = this._schoolList.map((s) => {
          s.selected = false;
          return s;
        });
         this.resetAudienceGroups();  
    this.resetFilters();
    this.emitChanges();
  }

  updateSelectionOptions(ev) {  
    this.isAllSchoolsSelected = false;
    this.resetAudienceGroups();  
    this.resetFilters();
    if (ev.index !=0 ) this.selectedInnerTabIndex = 0;     
    if(ev.index == 0 && this.isEdit) {this.selectedInnerTabIndex = 1;
    this.isEdit = false;}
    else if(ev.index == 0) this.selectedInnerTabIndex = -1;      
    if (ev.index == 2) this.userSelection = 'allStudents';
    if (ev.index == 2) this.showSelection = false;
    else this.showSelection = true;
    if(this.isEdit){this.isEdit = false}
    
    this.tableColumns = this.showSelection
      ? ["select", "photo", "type", "studentId", "firstName", "lastName"]
      : ["photo", "type", "studentId", "firstName", "lastName"];
  }
  
  calculateAudience() {
    var total = this._studentFiltered.length;
     if(this.filteredData){
        this.studentCount = this.dataSource.filteredData.filter(
          (c) => c.targetRole == "Student" || c.parentId == null
        ).length;
        this.parentCount = this.dataSource.filteredData.length - this.studentCount;
      }
    if (total != 0) {
      var studentsLength = this._studentFiltered.filter(
        (c) => c.targetRole == "Student"
      );
      this.studentCount = studentsLength.length;
      this.parentCount = total - studentsLength.length;
    } else {
      total = this.dataSource.data.length;
      var studentsLength = this.dataSource.data.filter(
        (c) => c.role == "Student"
      );
      this.studentCount = studentsLength.length;
      this.parentCount = total - studentsLength.length;
    }
    if(this.entityId == null){
    var totalSelected = this.selectionSelected.selected.length;
    var studentSelectedLength = this.selectionSelected.selected.filter(
      (c) => c.targetRole == "Student" || c.parentId == null
    );
    this.studentSelectedCount = studentSelectedLength.length;
    this.parentSelectedCount = totalSelected - studentSelectedLength.length;
    }
    else{
      var totalSelected = this.selectedDataSource.data.length;
      var studentSelectedLength = this.selectedDataSource.data.filter(
        (c) => c.targetRole == "Student" || c.parentId == null
      );
     
      this.studentSelectedCount = studentSelectedLength.length;
    this.parentSelectedCount = totalSelected - studentSelectedLength.length;
    }
  }
  filterStudents() {
    this._studentFiltered = _.filter(
      this._studentList,
      (s) =>
        (this.selectedSchool == null || this.selectedSchool == s.schoolCode) &&
        (this.selectedGrade == null || this.selectedGrade == s.gradeId) &&
        (this.selectedSchoolRoom == null ||
          this.selectedSchoolRoom == s.schoolRoomId) &&
          (this.selectedGroupUsers == null || _.indexOf(this.selectedGroupUsers, s.studentId) >= 0)
      /*&&
              (this.selectedGroupUsers == null || _.indexOf(this.selectedGroupUsers, s.studentId) >= 0) && this.parentsOnly == false)*/ // ||
      // (this.includeParents && s.parentId !== null && (this.selectedSchool == null || this.selectedSchool == s.schoolCode) &&
      //   (this.selectedGrade == null || this.selectedGrade == s.gradeId) && (this.selectedSchoolRoom == null || this.selectedSchoolRoom == s.schoolRoomId) &&
      //     (this.selectedGroupUsers == null || _.indexOf(this.selectedGroupUsers, s.studentId) >= 0))
    );
    this._studentFiltered = _.uniqBy(this._studentFiltered, "targetId");

    this.dataSource.data = this._studentFiltered;
    if(this.audienceMode){
      this.selectionSelected.clear();
      this.selectedDataSource.data = this.dataSource.data;
      this.selectionSelected.select(...this.dataSource.data)
    }
    this.emitChanges();
    this.calculateAudience();
  }

  initSearchForm() {
    this.searchFormStudent = new FormGroup({
      id: new FormControl(),
      email: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
    });
    this.searchFormParent = new FormGroup({
      email: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
    });
  }

  resetInputs(type) {
    if (type == "Student") {
      this.searchFormStudent.controls["id"].reset();
      this.searchFormStudent.controls["email"].reset();
      this.searchFormStudent.controls["firstName"].reset();
      this.searchFormStudent.controls["lastName"].reset();
    } else {
      this.searchFormParent.controls["id"].reset();
      this.searchFormParent.controls["email"].reset();    
      this.searchFormParent.controls["firstName"].reset();
      this.searchFormParent.controls["lastName"].reset();
    }

    this.initInputs();
    // this.validateRole(this.searchType)
  }

  initInputs() {
    this.searchFormStudent.controls["id"].disable();
    this.searchFormStudent.controls["email"].disable();
    this.searchFormStudent.controls["firstName"].disable();
    this.searchFormStudent.controls["lastName"].disable();
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    this.validEmail = re.test(email);
  }

  resetSearch() {
    this.searchFormStudent.controls["id"].enable();
    this.searchFormStudent.controls["id"].setValue("");
    this.searchFormStudent.controls["email"].enable();
    this.searchFormStudent.controls["email"].setValue("");
    this.searchFormStudent.controls["firstName"].enable();
    this.searchFormStudent.controls["firstName"].setValue("");
    this.searchFormStudent.controls["lastName"].enable();
    this.searchFormStudent.controls["lastName"].setValue("");
    this.searchFormParent.controls["firstName"].enable();
    this.searchFormParent.controls["firstName"].setValue("");
    this.searchFormParent.controls["lastName"].enable();
    this.searchFormParent.controls["lastName"].setValue("");
    this.searchFormParent.controls["email"].enable();
    this.searchFormParent.controls["email"].setValue("");
    this.addAssociatedParents = false;
    this.addParentsOnly = false;
    this.dataSource.data = [];
  }

  toggleInputs($e, role) {
    if ($e == "reset" && role == null) {
      this.searchFormStudent.controls["id"].enable();
      this.searchFormStudent.controls["email"].enable();
      this.searchFormStudent.controls["firstName"].enable();
      this.searchFormStudent.controls["lastName"].enable();
      this.searchFormParent.controls["firstName"].enable();
      this.searchFormParent.controls["lastName"].enable();
      this.searchFormParent.controls["email"].enable();
    } else {
      switch ($e) {
        case "id":
          if (role == "Student") {
            this.searchFormStudent.controls["email"].disable();
            this.searchFormStudent.controls["firstName"].disable();
            this.searchFormStudent.controls["lastName"].disable();
            this.searchFormParent.controls["email"].disable();
            this.searchFormParent.controls["lastName"].disable();
            this.searchFormParent.controls["firstName"].disable();
          }

          break;

        case "email":
          if (role == "Student") {
            this.searchFormStudent.controls["id"].disable();
            this.searchFormStudent.controls["firstName"].disable();
            this.searchFormStudent.controls["lastName"].disable();
            this.searchFormParent.controls["email"].disable();
            this.searchFormParent.controls["lastName"].disable();
            this.searchFormParent.controls["firstName"].disable();

            if (this.searchFormStudent.controls["email"].value?.length > 2) {
              this.validEmail = true;
            } else {
              this.validEmail = false;
            }
          } 
          if (role == "Parent") {
            this.searchFormParent.controls["firstName"].disable();
            this.searchFormParent.controls["lastName"].disable();
            this.searchFormStudent.controls["id"].disable();
            this.searchFormStudent.controls["firstName"].disable();
            this.searchFormStudent.controls["lastName"].disable();
            this.searchFormStudent.controls["email"].disable();
    
            if (this.searchFormParent.controls["email"].value?.length > 2) {
              this.validEmail = true;
            } else {
              this.validEmail = false;
            }
          }

          break;

        case "name":
          if (role == "Student") {
            this.isStudent=true;
            this.searchFormStudent.controls["id"].disable();
            this.searchFormStudent.controls["email"].disable();
            this.searchFormParent.controls["email"].disable();
            this.searchFormParent.controls["lastName"].disable();
            this.searchFormParent.controls["firstName"].disable();

            if (
              this.searchFormStudent.controls["firstName"].value?.length > 1 ||
              this.searchFormStudent.controls["lastName"].value?.length > 1
            ) {
              if(this.searchFormStudent.controls["firstName"].value?.length > 1 &&  this.searchFormStudent.controls["lastName"].value?.length > 1)
             this.searchNameKey = `${this.searchFormStudent.controls["firstName"].value} ${this.searchFormStudent.controls["lastName"].value}`;
              else if(this.searchFormStudent.controls["firstName"].value?.length > 1)
              this.searchNameKey = `${this.searchFormStudent.controls["firstName"].value}`;
             else
             this.searchNameKey = `${this.searchFormStudent.controls["lastName"].value}`;
             
              this.validNameSearch = true;
            } else {
              this.validNameSearch = false;
            }
          }
          if (role == "Parent") {
            this.isStudent=false;
            this.searchFormParent.controls["email"].disable();
            this.searchFormStudent.controls["id"].disable();
            this.searchFormStudent.controls["firstName"].disable();
            this.searchFormStudent.controls["lastName"].disable();
            this.searchFormStudent.controls["email"].disable();

            if (
              this.searchFormParent.controls["firstName"].value?.length > 1 ||
              this.searchFormParent.controls["lastName"].value?.length > 1
            ) {
              if(this.searchFormParent.controls["firstName"].value?.length > 1 &&  this.searchFormParent.controls["lastName"].value?.length > 1)
             this.searchNameKey = `${this.searchFormParent.controls["firstName"].value} ${this.searchFormParent.controls["lastName"].value}`;
              else if(this.searchFormParent.controls["firstName"].value?.length > 1)
              this.searchNameKey = `${this.searchFormParent.controls["firstName"].value}`;
             else
             this.searchNameKey = `${this.searchFormParent.controls["lastName"].value}`;
             
              this.validNameSearch = true;
              
            } else {
              this.validNameSearch = false;
            }
          }

          break;

        default:
          break;
      }
    }
  }

  search() {
    this.roleInstance.onSchoolChangeListener().subscribe(() => {
      this.dataSource.paginator = this.paginator;
      this.selectedDataSource.paginator = this.paginatorSelected;
      if(this.selectedSchools.length == 0){
        this.dataSource.filteredData = [];
        this.dataSource.data = [];
        this.studentCount = 0
      }
      this.selectedInnerTabIndex = 0;
      if (this.audienceMode) {
        this.selectionSelected.clear();
        this.selectionSelected.select(...this.dataSource.data);
        this.calculateAudience();
      }
    });
  }
  addStudents() {
    this.selectionSelected.select(...this._studentFiltered);
    this.selectedDataSource.data = this.selectionSelected.selected;
    this.emitChanges();
    this.calculateAudience();
  }
  removeStudents() {
    this.selectionSelected.clear();
    this.selectedDataSource.data = [];
    this.emitChanges();
    this.calculateAudience();
  }
  hasInput: any;
  searchNameKey: any;
  validNameSearch: any;
  searchType: any;
  searchingStudent = false;
  searchingParent = false;

  searchCopy: any;

  drawerOpen = false;

  appendParents(studentId: string) {
    return this.studentService.getParentsByStudent(studentId);
  }

  parentFilter(res) {
    if (this.addAssociatedParents) {
      var student = res[0];
      this.appendParents(student.empCode).subscribe((parents) => {
        let targetResult = parents.map((p) => {
          return {
            firstName: p.firstName,
            lastName: p.lastName,
            parentId: p.id,
            targetId: p.id,
          };
        });
        var audience = this.addParentsOnly
          ? targetResult
          : res.concat(targetResult);
        this.dataSource.data = audience;
        this.searchCopy = audience;
        this.updateSchoolList(res);
        this.calculateAudience();
      });
    } else {
      this.dataSource.data = res;
      this.searchCopy = res;
      this.updateSchoolList(res);
      this.calculateAudience();
    }
  }

  onSearch(type, query) {
    const role = this.profileService.profile.role;
    this.selectedInnerTabIndex = 0;
    switch (role) {
      case "Administrator":
        this.adminService.adminSearch(query, type).subscribe(
          (res) => {
            _.each(res, (contact) => {
              contact.firstName = contact.empFirstName;
              contact.lastName = contact.empLastName;
              contact.studentId = contact.empCode;
              contact.parentId =
                contact.role == "Parent" ? contact.empCode : null;
              contact.targetId = contact.empCode;
            });

            this.parentFilter(res);
          },
          (error) => {}
        );

        break;

      case "Counselor":
        this.contactsService.counselorSearch(query, type).subscribe(
          (res) => {
            _.each(res, (contact) => {
              contact.firstName = contact.empFirstName;
              contact.lastName = contact.empLastName;
              contact.studentId = contact.empCode;
              contact.parentId =
                contact.role == "Parent" ? contact.empCode : null;
              contact.targetId = contact.empCode;
            });

            this.parentFilter(res);
          },
          (error) => {}
        );

        break;

      case "Teacher":
        this.contactsService.staffSearch(query, type).subscribe(
          (res) => {
            _.each(res, (contact) => {
              contact.firstName = contact.empFirstName;
              contact.lastName = contact.empLastName;
              contact.parentId =
                contact.role == "Parent" ? contact.empCode : null;
              contact.targetId = contact.empCode;
              if (contact.role == "Student") {
                contact.studentId = contact.empCode;
                contact.parentId = null;
              } else {
                contact.parentId = contact.empCode;
                contact.studentId = null;
              }
            });

            this.parentFilter(res);
          },
          (error) => {}
        );

        break;

      default:
        break;
    }

    // this.label = 'Required';
    // this.didSearch = true;
    // this.isLoading = true;
  }

  updateSchoolList(audience) {
    let schoolList = [];

    _.each(audience, (contact) => {
      let schoolModel = {
        locationName: null,
        schoolCode: null,
      };
      schoolModel.locationName = contact.schoolName;
      schoolModel.schoolCode = contact.schoolCode;
      schoolList.push(schoolModel);
    });
    schoolList = _.uniqBy(schoolList, "locationName");

    this._schoolList = schoolList;
  }

  filterSearchSchools(option) {
    console.log(option);
    if (option == null) {
      this.dataSource.data = this.searchCopy;
    } else {
      if (option.schoolCode !== null) {
        this.dataSource.data = _.filter(this.searchCopy, {
          schoolCode: option.schoolCode,
        });
      } else {
        this.dataSource.data = _.filter(this.searchCopy, {
          schoolName: option.locationName,
        });
      }
    }

    this.updateGradeList(this.dataSource.data);
  }

  updateGradeList(audience) {
    let gradeList = [];

    _.each(audience, (contact) => {
      if (contact.grade) {
        gradeList.push(contact.grade);
      } else if (contact.gradeId) {
        gradeList.push(contact.gradeId);
      } else {
        gradeList.push(contact.gradeLevel);
      }
    });

    this._gradeList = gradeList;
  }

  filterSearchGrades(option) {
    if (option == null) {
      this.filterSearchSchools(this.selectedSchool);
    } else {
      this.dataSource.data = _.filter(this.searchCopy, { gradeId: option });
      if(this.audienceMode){
        this.selectedDataSource.data = this.dataSource.data;
        this.selectionSelected.select(...this.dataSource.data);
        this.calculateAudience();
      }
    }
  }

  addAssociatedParents = false;
  addParentsOnly = false;

  checkAssociatedParents() {
    if (this.addAssociatedParents && !this.addParentsOnly) {
      this.userSelection = "allStudentsParents";
      return;
    }
    if (this.addParentsOnly) {
      this.includeStudents = false;
      this.userSelection = "allParents";
      return;
    }
  }

  validateSearch(type, role) {
    this.searchType = "";
    if (role == "Student") {
      if (
        !this.searchFormStudent.controls["id"].value &&
        !this.searchFormStudent.controls["email"].value &&
        !this.searchFormStudent.controls["firstName"].value &&
        !this.searchFormStudent.controls["lastName"].value
      ) {
        this.toggleInputs("reset", null);
        this.hasInput = false;
        this.searchingStudent = false;
      } else {
        this.hasInput = true;
        this.searchingStudent = true;
        this.searchType = `${type} ${role}`;
        this.toggleInputs(type, role);
      }
    }

    if (role == "Parent") {
      if (
        !this.searchFormParent.controls["email"].value &&
        !this.searchFormParent.controls["firstName"].value &&
        !this.searchFormParent.controls["lastName"].value
      ) {
        this.toggleInputs("reset", null);
        this.hasInput = false;
        this.searchingParent = false;
      } else {
        this.hasInput = true;
        this.searchingParent = true;
        this.searchType = `${type} ${role}`;
        this.toggleInputs(type, role);
      }
    }
  }

  selectedTabIndex = 0;
  selectedInnerTabIndex = 0;

  triggerSelectionWarning(ev) {
    this.selection.clear();

    if (this.selectionSelected.hasValue()) {
      swal({
        title: `Are You Sure You Wish to Select Users from Audience Group?`,
        icon: "warning",
        dangerMode: true,
        text: "This will reset any users that have already been selected",
        buttons: {
          cancel: {
            text: "Keep selected recipients",
            visible: true,
          },
          confirm: {
            text: "Use Audience Group",
            value: true,
            visible: true,
            closeModal: true,
          },
        },
      }).then((res) => {
        if (res) {
          this.dataSource.data = [];
          this._studentFiltered = [];
          this.selection.clear();
          this.selectionSelected.clear();
          this.masterToggleRemove();
          this.calculateAudience();
        } else {
          this.selectedTabIndex = 1;
        }
      });
    } else return;
  }

  resetAudienceGroups() {
    this.selectedCommunicationGroup = null;
    this.selection.clear();
    this.selectedDataSource.data = [];
    this.studentSelectedCount = 0;
    this.parentSelectedCount = 0;
    this.selectionSelected.clear();
    this.selectionSelected.clear();
    this.dataSource.data = [];
    this._studentFiltered = [];
    this.changeCommunicationGroup(null);
    this.calculateAudience();
  }

  emitChanges() {
    this.checkAssociatedParents();
    if (
      this.userSelection == "allStudentsParents" ||
      this.userSelection == "allParents"
    )
      this.includeParents = true;

    const audience: AudienceModel = {
      schoolCode: JSON.stringify(this.selectedSchools) ?? null,
      gradeId: this.selectedGrade ?? null,
      schoolRoomId: this.selectedSchoolRoom ?? null,
      groupId: this.selectedGroup ?? null,
      students: this.selectionSelected.selected.map((s) => s.targetId || s.studentId || s.parentId),
      communicationGroupId: this.selectedCommunicationGroup ?? null,
      includeParents: this.includeParents,
      includeStudents: this.includeStudents,
      minPoints: this.minPoints,
      maxPoints: this.maxPoints,
      staffId: this.profile.identity.userIdRole,
      userSelection: this.userSelection,
      studentCount: this.studentSelectedCount?.toString(),
      parentCount: this.parentSelectedCount?.toString(),
      allSchools: this.isAllSchoolsSelected
    };
    this.audienceChange.emit(audience);
  }
  parentsOnly = false;
  parentsIncluded = false;

  changeGrade(grade) {
    // this.selection.clear();
    if (grade !== null) {
      if (this.countMode) {
        this.roleInstance.onSchoolChange();
      } else {
        const getUniqueKey = (room: any) => `${room.grade}-${room.schoolRoomName}-${room.schoolRoomId}`;
        this._schoolRoomFiltered = _.orderBy(
          _.filter(
            _.uniqBy(this._schoolRoomList, getUniqueKey),
            (s) => s.grade == grade
          ),
          ["schoolRoomName", "asc"]
        );
        this._schoolRoomFiltered.map(x => {
          if(x.schoolRoomName.includes(" | Room ID -  " + x.schoolRoomId)) 
           return x.schoolRoomName
          else 
            x.schoolRoomName = x.schoolRoomName + " | Room ID -  " + x.schoolRoomId
          })      
        this.filterStudents();

        this.isGradeSelected = true;

      }
    } else {
      this.selectedGrade = null;
      this.selectedSchoolRoom = null;
      this.selectedGroup = null;
      this._groupList = [];
      this.filterStudents();
    }
    if (this._editAudienceModel != null) this.updateAudienceSchoolRoom();
    this.emitChanges();
  }

  changeSchoolRoom(schoolRoom) {
    // this.selection.clear();
    if (schoolRoom != null) {
      this.filterStudents();
      this.groupServices
        .getGroupsBySchoolRoomAndGrade(schoolRoom, this.selectedGrade)
        .toPromise()
        .then((groups) => {
          this._groupList = groups;
          this.selectedGroup = null;
          this.isClassSelected = true;
          if (this._editAudienceModel != null) this.updateAudienceGroups();
        });
    } else {
      this.filterStudents();
    }
    this.emitChanges();
  }

  async filterCommunicationGroup(editAudienceModel) {
    var schools = JSON.parse(editAudienceModel.schoolId);
    this.includeParents = editAudienceModel.includeParents;
    this.parentsOnly =
      editAudienceModel.includeParents && !editAudienceModel.includeStudents;
    this.selectedSchools = schools;
    this.selectedGrade = editAudienceModel.gradeId;
    this.roleInstance.onSchoolChange();
    this.selectedSchoolRoom = editAudienceModel.schoolRoomId;
    this.selectedGroup = editAudienceModel.groupId;
    _.each(schools, (s) => {
      var school = this._schoolList.find((h) => h.locationCode == s);
      school.selected = true;
      this.selectedSchoolsBadges.push(school);
      //this.onOptionSelected(school)
    });
    if(this.includeStudents) {
      if(this.includeParents) {
        this.userSelection = "allStudentsParents"
      }else{
        this.userSelection = "allStudents"
      }
    }else{
      this.userSelection = "allParents "
    }
    this.isSchoolSelected = true;
    // Wait until _schoolRoomList is not null
    let attempts = 0;
  while (this._schoolRoomList.length === 0 && attempts < 10) {
    await new Promise(resolve => setTimeout(resolve, 100+ (attempts * 50))); // Wait for a short interval until _schoolRoomList is filled
    attempts++;
  }
    this.changeGrade(this.selectedGrade);
    this.isShowingStudents = true;
    
  }

  _audienceList = [];
  changeCommunicationGroup(communicationGroup) {
    this.selection.clear();
    this.selectionSelected.clear();
    if (communicationGroup != null) {
      var audience = this._communicationGroups.find(
        (c) => c.id == communicationGroup
      );
      this.filterCommunicationGroup(audience);
      this.roleInstance.onSchoolChangeListener().subscribe((audience) => {
        this.selection.clear();
        this.selection.select(...audience);
        this.dataSource.data = this.selectionSelected.selected;
        this.selectionSelected.clear();
        this.selectionSelected.select(...audience);
        this.selectedDataSource.data = this.selectionSelected.selected;
        this.emitChanges();
        this.calculateAudience();
      });
    }
  }

  listGroupUsers(group) {
    if (group != null) {
      this.groupServices.getStudentsByGroupId(group).subscribe((students) => {
        this.selectedGroupUsers = students.map((s) => s.studentId);
        this.filterStudents();
      });
    } else {
      this.selectedGroupUsers = null;
      this.filterStudents();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectSchool($e) {
    console.log($e);
  }

  resetFilters() {
    this.selectedCommunicationGroup = null;
    this.selectedGrade = null;
    this.selectedSchoolRoom = null;
    this.selectedGroup = null;
    this.selectedSchool = null;
    this.selectedGroupUsers = null;
    this.studentSelectedCount = 0;
    this.parentSelectedCount = 0;
    this.minPoints = 0;
    this.maxPoints = 0;
    this.studentCount = 0;
    this.parentCount = 0;
    this.selectedSchools = [];
    this.userSelection = "allStudents";
    this.dataSource.data = [];
    this.dataSource.filter = '';
    this.resetSearch();
    // filter enablers
    this.isSchoolSelected = false;
    this.isGradeSelected = false;
    this.isClassSelected = false;
    this.isLongList = false;
    this.selectedSchoolsBadges = [];
    this._schoolList = this._schoolList.map((s) => {
      s.selected = false;
      return s;
    });
    this.allSelected = false
    this.searchText = ""
  }

  parentsChange(included) {
    this.includeParents = included;
    this.roleInstance.onSchoolChange();
  }

  // table specific methods
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggleRemove() {
    this.selectionSelected.clear();
    this.selectedDataSource.data = this.selectionSelected.selected;
    this.selection.clear();
    this.emitChanges();
    this.calculateAudience();
  }

  masterToggle() {
    this.selectedCount = this.isAllSelected() ? 0 : this.dataSource.data.length;
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedCount = 0;
      if (this.entityId == null) {
      this.selectionSelected.clear();
        this.selectedDataSource.data = this.selectionSelected.selected;
      }
      else {
        this.dataSource.data.forEach(x => this.selectionSelected.toggle(x))
        this.selectedDataSource.data = this.selectionSelected.selected;
      }
      this.emitChanges();
      this.calculateAudience();
      return;
    }

    this.selectionSelected.select(...this.dataSource.data);
    this.selectedDataSource.data = this.selectionSelected.selected;
    this.selection.select(...this.dataSource.data);
    this.emitChanges();
    this.calculateAudience();
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  checkboxSelection($event: any, element: any) {
    if (this.audienceMode) return false;
    let isCheckBox = $event.target as HTMLButtonElement
    if(!isCheckBox.classList.contains('mat-checkbox-inner-container')) return false;  
    $event.preventDefault();
    $event.stopPropagation();
    this.selection.toggle(element);
    this.selectedCount = this.selection.selected.length;
    this.updateSelectedDataSource(element);
    this.emitChanges();
    this.calculateAudience();
  }
  removeAllSelected() {
    this.selectionSelected.clear();
    this.selectedDataSource.data = this.selectionSelected.selected;
  }
  removeSelected($event: any, element: any) {
    if (this.audienceMode) return false;
    $event.preventDefault();
    $event.stopPropagation();
    if(this.entityId != null){
      this.selectedDataSource.data.forEach(x => this.selectionSelected.select(x))
    }
    this.selectionSelected.toggle(element);
    this.selection.toggle(element);
    this.selectedDataSource.data = this.selectionSelected.selected;

    this.emitChanges();
    this.calculateAudience();
  }
  updateSelectedDataSource(student) {
      this.selectedDataSource.data.forEach(x => this.selectionSelected.select(x))
      this.selectionSelected.toggle(student);
      this.selectedDataSource.data = this.selectionSelected.selected;
  }
  checkAndDisable(value: boolean): void {
    if (!this.addAssociatedParents && this.addParentsOnly) {
      this.addParentsOnly = false;
    }
  }
  updateAudience() {
    if (this._editAudienceModel) {
      this.includeParents = this._editAudienceModel.includeParents;
      this.includeStudents = this._editAudienceModel.includeStudents;
      if (this._editAudienceModel.schoolId) {
        this.selectedSchool = this._editAudienceModel.schoolId;
      } else {
        this.emitChanges();
      }
    }
  }
  updateAudienceGrades() {
    if (this._editAudienceModel.gradeId) {
      this.selectedGrade = parseInt(this._editAudienceModel.gradeId);
      this.changeGrade(this.selectedGrade);
    }
  }
  updateAudienceSchoolRoom() {
    if (this._editAudienceModel.schoolRoomId) {
      this.selectedSchoolRoom = this._editAudienceModel.schoolRoomId;
      this.changeSchoolRoom(this.selectedSchoolRoom);
    }
  }
  updateAudienceGroups() {
    if (this._editAudienceModel.groupId) {
      this.selectedGroup = this._editAudienceModel.groupId;
      this.listGroupUsers(this.selectedGroup);
    }
  }

  filterOptions(value) {
    // console.log(value)
    return this._schoolList.filter(
      (option) =>
        option.locationName.toLowerCase().includes(value.toLowerCase()) ||
        option.locationCode.toLowerCase().includes(value.toLowerCase())
    );
  }

  isSelected(option) {
    const index = this.selectedSchools.findIndex(
      (auth) => auth === option.toLowerCase()
    );
    return index >= 0;
  }

  audienceCount = 0;
  studentCount = 0;
  parentCount = 0;
  studentSelectedCount = 0;
  parentSelectedCount = 0;
  selectedSchoolCount: any;

  addAudienceCount() {
    this.audienceService
      .getAudienceCount(
        this.selectedSchoolCount,
        this.selectedGrade,
        this.selectedSchoolRoom
      )
      .subscribe((res) => {
        if (this.userSelection == "allStudents") {
          if (res.students) {
            this.audienceCount += parseInt(res.students);
          }
        }
        if (this.userSelection == "allParents") {
          if (res.parents) {
            this.audienceCount += parseInt(res.parents);
          }
        }
        if (this.userSelection == "allStudentsParents") {
          if (res.total) {
            this.audienceCount += parseInt(res.total);
          }
        }
      });
  }

  checkLongList(isLong, ev) {
    this.userSelection = ev.value;
    //this.isLongList = isLong;
    //this.addAudienceCount();
    if (ev.value == "allParents") {
      this.includeParents = true;
      this.includeStudents = false;
      this.parentsOnly = true;
    }
    if (ev.value == "allStudentsParents") {
      this.includeParents = true;
      this.includeStudents = true;
      this.parentsOnly = false;
    }
    if (ev.value == "allStudents") {
      this.includeParents = false;
      this.includeStudents = true;
      this.parentsOnly = false;
    }
    //this.roleInstance.onSchoolChange();
    //allStudentsParents
    if(this.isAllSchoolsSelected){
      this.emitChanges();
      }
  }

  filterData() {
    
    const value = this.searchInput.nativeElement.value;
    if (value) {
      this._schoolList = this.filterOptions(value);
      if (this._schoolList.length == 0) {
        this._schoolList = this.filterOptions(value); //sd
      }
    } else {
      this.resetData();
    }
  }

  resetData() {
    this._schoolList = this._schoolListCopy;
    this.searchInput.nativeElement.value = "";
  }

  selectedSchoolsBadges = [];

  onOptionSelected(option) {
    // this.selection.clear();
    if (option == "all") {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.selectedSchools = [];
        this.selectedSchoolsBadges = [];
        this._schoolList = this._schoolList.map((s) => {
          s.selected = true;
          return s;
        });
        this.selectedSchools = this._schoolList.map((a) => a.locationCode);
        //    this.filterStudents();
      } else {
        this.selectedSchools = [];
        this._schoolList = this._schoolList.map((s) => {
          s.selected = false;
          return s;
        });
      }
    } else {
      const index = this.selectedSchools.findIndex(
        (auth) => auth.toLowerCase() === option.locationCode.toLowerCase()
      );
      this.updateGrades();

      if (index > 0) {
        this.selectedSchools.splice(index, 1);
        this.selectedSchoolsBadges.splice(index, 1);
        option.selected = false;
        this.allSelected = false;

        //   this.filterStudents();
        // this.dataSource.data = this._studentList;
      } else if (index == 0) {
        // this.allSelected = true;
        this.selectedSchools = [];
        this.selectedSchoolsBadges = [];
        option.selected = false;
        this.allSelected = false;
        //this.filterStudents();
      } else {
        this.selectedSchoolCount = option.locationCode;
        this.selectedSchools.push(option.locationCode);
        this.selectedSchoolsBadges.push(option);
        this.allSelected = false;
        option.selected = true;
        // this.teacherServices.getStudentsByLocationStaffId(this.profile.)
        // this.filterStudentsBySchools(this.selectedSchools);
        //this.updateGrades();
      }
    }
    if (this.selectedSchools.length > 0 || this.allSelected) {
      this.isSchoolSelected = true;
    }
    if (this.allSelected) {
      this.schoolMessage = "All Schools";
    } else {
      this.schoolMessage = this.selectedSchoolsBadges
        .filter(
          (a) => this.selectedSchools.find((c) => c == a.locationCode) != null
        )
        .map((b) => b.locationName)
        .join(",");
    }    
    // this.roleInstance.onSchoolChange();
  }
  schoolMessage = "";
  updateGrades() {
    let grades = _.orderBy(
      _.uniqBy(this._schoolRoomList, "grade"),
      "grade",
      "desc"
    );
    grades.sort(function(a, b){
      return a['grade'] - b['grade'];
    })
    this._gradeFiltered = [];
    _.each(grades, (grade) => {
      this._gradeFiltered.push(grade.grade);
    });
  }

  filterStudentsBySchools(schools) {
    this._studentFiltered = [];

    _.each(schools, (school) => {
      let students = _.uniqBy(
        _.filter(
          this._studentList,
          (s) =>
            (school == null || school == s.schoolCode) &&
            (this.selectedGrade == null || this.selectedGrade == s.gradeId) &&
            (this.selectedSchoolRoom == null ||
              this.selectedSchoolRoom == s.schoolRoomId) &&
            (this.selectedGroupUsers == null ||
              _.indexOf(this.selectedGroupUsers, s.studentId) >= 0)
        ),
        (s) => s.studentId
      );
      this._studentFiltered.push(students);
    });
    this._studentFiltered = _.flatten(this._studentFiltered);
    this.dataSource.data = this._studentFiltered;
  }
}
interface PbisAudienceWidget {
  onSchoolChangeListener(): Observable<any>;
  onSchoolChange();
  getSchoolLocations(): Observable<any>;
}

class BasePibsAudienceWidget implements PbisAudienceWidget {
  constructor(public self: PbisAudienceWidgetComponent) {}

  schools = [];
  getSchoolLocations(): Observable<any> {
    return this.self.teacherServices
      .getStudentsByStaffId(this.self.profile.identity.userIdRole)
      .pipe(
        mergeMap((response) => this.getSchools(response)),
        switchMap((schoolsList) =>
          of({
            schools: schoolsList.map((c) => {
              c.locationName = c.schoolName;
              return c;
            }),
          })
        )
      );
  }
  private getSchools(response: Array<any>): Observable<any> {
    let ids = _.uniqBy(response, "schoolCode").map((s) => s.schoolCode);
    return this.self.schoolService.getSchoolsById(ids);
  }

  onSchoolChange(): void {
    let profileId = this.self.profile.identity.userIdRole;
    this.self.teacherServices
      .getStudentsByLocationStaffId(
        profileId,
        this.self.selectedSchools,
        this.self.includeParents,
        this.self.parentsOnly
      )
      .subscribe((studentsResponse) => {
        this.self._studentList = studentsResponse;
        this.self._schoolRoomList = this.processSchoolRoom(studentsResponse);
        this.self.filterStudents();
        this.self.updateGrades();
      });
  }
  onSchoolChangeListener(): Observable<any> {
    let profileId = this.self.profile.identity.userIdRole;
    let subject = new Subject<any>();
    this.self.teacherServices
      .getStudentsByLocationStaffId(
        profileId,
        this.self.selectedSchools,
        this.self.includeParents,
        this.self.parentsOnly
      )
      .subscribe((studentsResponse) => {        
          this.self._studentList = studentsResponse;
          this.self._schoolRoomList = this.processSchoolRoom(studentsResponse);
          this.self.filterStudents();
          this.self.updateGrades();
          subject.next(this.self._studentFiltered);      
      });
    return subject;
  }

  processSchoolRoom(response) {
    let schoolRooms = response.map((r) => {
      let schoolRoom = {
        schoolRoomId: r.schoolRoomId,
        schoolRoomName: r.className,
        grade: r.gradeId,
      };
      return schoolRoom;
    });
    const getUniqueKey = (room: any) => `${room.grade}-${room.schoolRoomName}-${room.schoolRoomId}`;
    var uniqSchoolRooms = _.uniqBy(schoolRooms, getUniqueKey);
    return uniqSchoolRooms;
  }
}
class TeacherRole extends BasePibsAudienceWidget {
  schools = [];
}

class AdministratorRole extends BasePibsAudienceWidget {
  schools: [];

  getSchoolLocations(): Observable<any> {
    return this.self.adminService.getSchoolLocations().pipe(
      map((response) => this.processSchools(response)),
      switchMap(() => of({ schools: this.schools }))
    );
  }
  onSchoolChange(): void {
    let profileId = this.self.profile.identity.userIdRole;
    if (this.self.selectedSchools != null) {
      if (
        this.self.selectedSchools.length > 10 &&
        this.self.audienceMode == false
      ) {
        this.self.countMode = true;
        this.self.teacherServices
          .getStudentsByLocationStaffIdPostCount(
            profileId,
            this.self.selectedSchools,
            this.self.includeParents,
            this.self.parentsOnly,
            this.self.selectedGrade
          )
          .subscribe((studentsResponse) => {
            if (studentsResponse.parentsCount)
              this.self.countTotal = parseInt(studentsResponse.parentsCount);
            if (studentsResponse.studentsCount)
              this.self.countTotal = parseInt(studentsResponse.studentsCount);
            if (studentsResponse.studentsCount && studentsResponse.parentsCount)
              this.self.countTotal =
                parseInt(studentsResponse.studentsCount) +
                parseInt(studentsResponse.parentsCount);
            //this.self._studentList = studentsResponse;
            this.self._schoolRoomList = studentsResponse.grades.map((g) => {
              return {
                grade: g,
              };
            });
            //this.processSchoolRoom(studentsResponse);
            //this.self.filterStudents();
            this.self.updateGrades();
            if (this.self.countTotal > 0) {
              this.self.selectionSelected.clear();
              this.self.selectionSelected.select(...[{}]);
              this.self.emitChanges();
              this.self.studentCount = studentsResponse.studentsCount ?? 0;
              this.self.studentSelectedCount =
                studentsResponse.studentsCount ?? 0;
              this.self.parentCount = studentsResponse.parentsCount ?? 0;
              this.self.parentSelectedCount =
                studentsResponse.parentsCount ?? 0;
            }
          });
      } else {
        this.self.countMode = false;
        this.self.teacherServices
          .getStudentsByLocationStaffIdPost(
            profileId,
            this.self.selectedSchools,
            this.self.includeParents,
            this.self.parentsOnly
          )
          .subscribe((studentsResponse) => {
            this.self._studentList = studentsResponse;
            this.self._schoolRoomList =
              this.processSchoolRoom(studentsResponse);
            this.self.filterStudents();
            this.self.updateGrades();
          });
      }
    }
  }
  processSchools(response) {
    this.schools = response.map((s) => {
      return {
        locationName: s.locationName,
        locationCode: s.locationCode,
      };
    });
  }
}

class CounselorRole extends BasePibsAudienceWidget {
  schools = [];
}
export interface AudienceModel {
  schoolCode?: string;
  gradeId?: string;
  schoolRoomId?: string;
  groupId?: string;
  students?: string[];
  communicationGroupId?: string;
  includeParents?: boolean;
  includeStudents?: boolean;
  minPoints?: number;
  maxPoints?: number;
  staffId: string;
  userSelection: string;
  studentCount: string;
  parentCount: string;
  allSchools?: boolean;
}

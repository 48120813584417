import { forEach } from 'lodash';
import { StaffSettingsService } from "src/app/shared/services/staff-settings.service";
import { Injectable } from "@angular/core";
import { forkJoin, interval, Observable, Subject, Subscription } from "rxjs";
import { map, switchMap, takeWhile } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BadgeStatusService {
  _status = [];
  _lockStatus = [];
  badgeStatusChanged: Subject<any>;
  _badgeSubscription = [];
  _queue = [];

  cron = interval(15000);
  timerSubscription: Subscription;
  constructor(
    private staffSettings: StaffSettingsService  ) {
    this.timerSubscription = this.cron.pipe(
      map(()=>this.checkStatus())
    ).subscribe();
  }

  public status: any = [];

  trackStatus(userId: string): Observable<any> {
    if (!this._badgeSubscription[userId])
      this._badgeSubscription[userId] = new Subject<any>();
    this.enqueue(userId);
    return this._badgeSubscription[userId];
  }
  untrack(userId:string) {
    delete this._badgeSubscription[userId];
    let queueIndex = this._queue.findIndex(q=>q == userId);
    this._queue.splice(queueIndex,1);
  }

  enqueue(userId: string) {

    if(this._queue.find((i)=>i==userId) == undefined)
      this._queue.push(userId);
  }
  checkStatus(){

    let currentQueue = [...this._queue]
    let status = {}

     for (var i = 0; (i < 5 && currentQueue.length != 0); i++) {
        var userId = currentQueue.pop();
        let statusCall = this.staffSettings.silentSettings(userId);
        status[userId] = statusCall;
    }

    forkJoin(status)
    .subscribe((response)=>{
      let keys = Object.keys(status);
      keys.forEach((k)=>{
        let subject = this._badgeSubscription[k] as Subject<any>;
        let settings = response[k]
        if(subject && settings){
          subject.next(settings)
        }
      })

    })

  };
  ngOnDestroy() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
  }
}

import { StudentsService } from './../../services/students.service';
import { GroupsService } from './../../services/groups.service';
import { ClassDetails } from 'src/app/shared/components/class-details-widget/models/ClassDetails';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClassAction } from '../class-details-widget/models/ClassDetails';
import { GroupDetails } from './models/GroupDetails';
import { GroupStudentDetails } from './models/GroupStudentDetails';
import * as _ from 'lodash';

@Component({
  selector: 'app-class-management-widget',
  templateUrl: './class-management-widget.component.html',
  styleUrls: ['./class-management-widget.component.scss']
})
export class ClassManagementWidgetComponent implements OnInit {

  @Output() onAction = new EventEmitter<ClassAction>();
  @Input() schoolRoom: ClassDetails = null;
  @Input() students: any = [];
  @Input() groups: GroupDetails[] = [];

  studentsGroup = [];
  groupName = null;
  displayedColumns: string[] = ['name', 'students', 'points', 'action'];
  tabIndex = 0;
  selectedGroup = null;
  constructor(private groupService: GroupsService, private studentServices: StudentsService) { }

  ngOnInit(): void {
    console.log(this.students, 'Input() students =======');
  }

  onClose() {
    const action: ClassAction = {
      target: '',
      command: 'closeManagement'
    };
    this.onAction.emit(action);
  }
  onClickSave() {
    if (this.selectedGroup) {
      this.onAction.emit({ command: 'editGroup', target: { id: this.selectedGroup.id, groupName: this.groupName, students: this.studentsGroup } });
    } else {
      this.onAction.emit({ command: 'createGroup', target: { groupName: this.groupName, students: this.studentsGroup } });
    }

    this.tabIndex = 0;
    this.studentsGroup = [];
    this.groupName = null;
  }
  onSelectStudent(student: any) {
    let hasStudent = this.studentsGroup.find(c => c == student.studentId);
    if (!hasStudent) {
      this.studentsGroup.push(student.studentId);
      student.selected = true;
    } else {
      _.remove(this.studentsGroup, (n) => n == student.studentId);
      student.selected = false;
    }
  }
  editGroup(group) {
    this.selectedGroup = group;
    this.groupService.getStudentsByGroupId(group.id).subscribe((students) => {
      this.tabIndex = 1;
      this.groupName = group.name;
      students.forEach((s) => {
        const student = this.students.find(x => x.studentId == s.studentId);
        if (student) {
          student.selected = true;
          this.studentsGroup.push(student.studentId);

        }
        this.studentServices.getPicture(student.studentId).subscribe((imgResource)=>{
          student.imgPath = imgResource.img;
        });

      });
    });
  }
  deleteGroup(group: GroupDetails) {
    const action: ClassAction = {
      target: group,
      command: 'deleteGroup'
    };
    this.onAction.emit(action);
  }

  onClickCancel() {
    const action: any = {
      command: 'closeManagement'
    };
    this.onAction.emit(action);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as m from 'moment';
import { StudentsService } from '../../services/students.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import swal from 'sweetalert'
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ProfileService } from 'src/app/shared/services/profile.service';


@Component({
  selector: 'app-student-igp',
  templateUrl: './student-igp.component.html',
  styleUrls: ['./student-igp.component.scss']
})
export class StudentIgpComponent implements OnInit {

  constructor(
    private studentService: StudentsService,
    private activeRoute: ActivatedRoute,
    private readonly tourService: TourService,
    private notificationServices: NotificationServices,
    private profileService: ProfileService
  ) { }

  pdfDisabled: boolean = false;
  studentId: any;
  lastUpdated: any;

  gotReport: boolean = false;
  isDownloading: boolean = false;
  successEng: boolean = false;
  successSp: boolean = false;
  failEng: boolean = false;
  failSp: boolean = false;
  englishReport: any = null;
  spanishReport: any = null;

  message: any = '';

  ngOnInit(): void {

    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.lastUpdated = m();
    });


    this.startTour();
  }

  getReport() {
    this.isDownloading = true;
    this.message = 'Please wait while we download your report';
    this.studentService.getStudentPdfReport(this.studentId, 'igp', 'english').subscribe((report) => {
      this.englishReport = report.buffer;
      this.gotReport = true;
      this.successEng = true;
      this.message = 'Download was successful. Click to view.'
      if(this.englishReport.length < 1000) {
        this.successEng = false;
        this.failEng = true;
        this.message = 'Please check your internet connection and retry'
      }
    }, error => {this.failEng = true})
    // this.studentService.getStudentPdfReport(this.studentId, 'igp', 'spanish').subscribe((report)=> {
    //   this.spanishReport = report.buffer;
    //   this.gotReport = true;
    //   this.successSp = true;
    //   this.message = 'Download was successful. Click to view.'
    //   if(this.spanishReport.length < 1000) {
    //     this.successSp = false;
    //     this.failSp = true;
    //     this.message = 'Please check your internet connection and retry'
    //   }
    // }, error => {this.failEng = true})
  }

  downloadReport(lang) {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    if(lang == 'english'){
      var blob = this.blobPdfFromBase64String(this.englishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `reportcard_english.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }
    if(lang == 'spanish'){
      var blob = this.blobPdfFromBase64String(this.spanishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `reportcard_spanish.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }


  }

  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  }


  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'igp1',
      content: 'Here you can download your latest report.',
      title: 'Individualized Graduation Plan',
      placement: below,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

}

import { Component, OnInit, Input } from "@angular/core";
import { AnnouncementAdminDetailModel } from './models/AnnouncementAdminDetailModel';

@Component({
  selector: "app-announcement-admin-details-widget",
  templateUrl: "./announcement-admin-details-widget.component.html",
  styleUrls: ["./announcement-admin-details-widget.component.scss"],
})
export class AnnouncementAdminDetailsWidgetComponent implements OnInit {
  public itemsCategories: Array<string> = [
    "coding",
    "less",
    "sass",
    "angularjs",
    "node",
    "expressJS",
  ];
  public itemsTags: Array<string> = [
    "JAVASCRIPT",
    "WEB",
    "BOOTSTRAP",
    "SERVER",
    "HTML5",
    "CSS",
  ];
  public itemsReview = [
    "Adam <adam@email.com>",
    "Amalie <amalie@email.com>",
    "Wladimir <wladimir@email.com>",
    "Samantha <samantha@email.com>",
    "Estefanía <estefanía@email.com>",
    "Natasha <natasha@email.com>",
    "Nicole <nicole@email.com>",
    "Adrian <adrian@email.com>",
  ];
  valueCategory;
  valueTag;
  valueReview;
  contents: string;
  @Input() model: AnnouncementAdminDetailModel = null;

  // async component
  loaded: boolean;
  constructor() {}

  ngOnInit(): void {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ProfileService } from './profile.service';


@Injectable({
  providedIn: 'root'
})
export class AudienceService {



  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices
  constructor(
    private appConfig: AppConfigService,
    private api: HttpClient,
    private profileService: ProfileService) {

   }
  public getAudience(entity: any, entityId: any):Observable<any> {
    let path = `${this.baseUrl}/v1/audience/${entity}/${entityId}`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  public getAudiencePreview(entity: any, entityId: any):Observable<any> {
    let path = `${this.baseUrl}/v1/audience/${entity}/${entityId}?preview=true`;
    return this.api.get(path).pipe(map((response)=>response as any));
  }
  getTestAudience(audienceModel: any) {
    let path = `${this.baseUrl}/v1/audience/list`;
    return this.api.post(path,audienceModel).pipe(map((response)=>response as any));
  }

  getAudienceCount(school, grade, classId){
    if(school && grade && classId){
      let path = `${this.baseUrl}/v1/audience/count?location=${school}&grade=${grade}&classNumber${classId}`;
      return this.api.get(path).pipe(map((response)=>response as any));
    }
    if(school && grade && !classId){
      let path = `${this.baseUrl}/v1/audience/count?location=${school}&grade=${grade}`;
      return this.api.get(path).pipe(map((response)=>response as any));
    }
    if(school && !grade && !classId){
      let path = `${this.baseUrl}/v1/audience/count?location=${school}`;
      return this.api.get(path).pipe(map((response)=>response as any));
    }




  }
}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CropperSettings, ImageCropperComponent, Bounds } from 'ngx-img-cropper';

@Component({
  selector: 'app-image-crop-modal',
  templateUrl: './image-crop-modal.component.html',
  styleUrls: ['./image-crop-modal.component.scss']
})

export class ImageCropModalComponent implements OnInit {

  file: File;
  cropperSettings: CropperSettings;
  data1: any;
  @ViewChild('cropper') cropper: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<ImageCropModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.file = data.file;
    this.cropperSettings = new CropperSettings();

    this.cropperSettings.noFileInput = true;

    this.cropperSettings.width = 200;
    this.cropperSettings.height = 200;

    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;

    this.cropperSettings.canvasWidth = 460;
    this.cropperSettings.canvasHeight = 400;

    this.cropperSettings.minWidth = 100;
    this.cropperSettings.minHeight = 100;

    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(0,0,0,.25)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    this.cropperSettings.rounded = true;

    this.data1 = {};
  }

  ngOnInit(): void {
    let image: any = new Image();
    let myReader: FileReader = new FileReader();
    let that = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);
    };

    myReader.readAsDataURL(this.file);
  }

  setRoundedMethod(value: boolean) {
    this.cropperSettings.rounded = value;
  }

  cropped(bounds: Bounds) {
    // this.data1.image;
    console.log(bounds);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({url: this.data1.image, rounded: this.cropperSettings.rounded});
  }

}

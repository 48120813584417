import { Component, OnInit, Input } from '@angular/core';
import { EventModel } from './models/EventModel';

@Component({
  selector: 'app-events-widget',
  templateUrl: './events-widget.component.html',
  styleUrls: ['./events-widget.component.scss']
})
export class EventsWidgetComponent implements OnInit {

  @Input() model: EventModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NAPipe } from './na.pipe';
import { FilterPipe } from './filter.pipe';
import { PhonePipe } from './phone.pipe';
import { StaffIdPipe } from './staff-id.pipe';

@NgModule({
  declarations: [NAPipe, FilterPipe, PhonePipe, StaffIdPipe],
  exports: [NAPipe, FilterPipe, PhonePipe, StaffIdPipe],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }

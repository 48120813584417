import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-table-widget',
  templateUrl: './common-table-widget.component.html',
  styleUrls: ['./common-table-widget.component.scss']
})
export class CommonTableWidgetComponent implements OnInit {

  @Input() columns: any = function(): Column[] {
    return [{title:'column1'} as Column]
  }
  @Input() row: any = function(row){
    return row.value;
  }
  @Input() rows: any = function(): Row[] {
    return [
      {
        cells:[]
      }
    ]
  }
  @Input() data: any = [];
  constructor() { }

  ngOnInit(): void {
  }

}
class Column {
  title: string
  render(){

  }
}
class Row {

}


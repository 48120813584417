import { Component, Input, OnInit } from '@angular/core';
import { StudentAdminSearchModel } from './models/StudentAdminSearchModel';

@Component({
  selector: 'app-student-admin-search-widget',
  templateUrl: './student-admin-search-widget.component.html',
  styleUrls: ['./student-admin-search-widget.component.scss']
})
export class StudentAdminSearchWidgetComponent implements OnInit {

  @Input() model: StudentAdminSearchModel = null;
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
    this.loaded = true;
    this.model = {} as StudentAdminSearchModel;
  }

}

import { filter } from "rxjs/operators";
import { CertificateService } from "./../../services/certificate.service";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import swal from "sweetalert";
import { CommunicationService } from "../../services/communication.service";
import { EventsAttendanceService } from "../../services/events-attendance.service";
import { ProfileService } from "../../services/profile.service";
import { AudienceListWidgetComponent } from "../audience-list-widget/audience-list-widget.component";
import { ContactDetailsWidgetComponent } from "../contact-details-widget/contact-details-widget.component";
import { LoaderService } from "../../services/loader.service";
import { CalendarService } from "../../services/calendar.service";
import { NotificationServices } from "../../services/notification.service";
import { AnalyticsService } from "../../services/analytics.service";

@Component({
  selector: "app-calendar-event-details",
  templateUrl: "./calendar-event-details.component.html",
  styleUrls: ["./calendar-event-details.component.scss"],
})
export class CalendarEventDetailsComponent implements OnInit {
  role = this.profileService.profile.role;
  eventId: any;
  eventData: any = null;
  eventDataRegistrations: any = null;

  staffId: any;

  eventChip = "";
  checkedIn = false;
  rsvped = false;
  basePath = "";
  cdnBase = "";

  isStaff = this.profileService.isStaff;

  allDay: boolean = false;
  isSent: boolean = true;

  get isAfterEvent() {
    let now = moment();
    if (now.isAfter(moment(this.eventData.extraData.endTime))) return true;
    else return false;
  }

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private activeRoute: ActivatedRoute,
    private eventService: EventsAttendanceService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private certificateServices: CertificateService,
    private loaderService: LoaderService,
    private calendarServices: CalendarService,
    private notificationServices: NotificationServices,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((data) => {
      if (data.id) {
        this.eventId = data.id;
        this.communicationService
          .getCommunicationById(this.eventId)
          .subscribe((communication) => {
            this.eventService
              .getAttendanceById(this.eventId)
              .subscribe((res) => {
                this.isSent = !res.notFound;
                if (res.notFound) {
                  this.eventData = communication;
                  this.eventDataRegistrations = [];
                } else {
                  this.eventDataRegistrations = res.registrations;
                  this.eventService
                    .getCheckInStatus(
                      this.eventId,
                      this.profileService.identity.userIdRole
                    )
                    .subscribe((status) => {
                      this.rsvped = status.rsvp;
                      this.checkedIn = status.checkedIn;
                      this.eventData = communication;
                      this.checkAllDay(this.eventData);
                      this.staffId = communication.staffId;
                      this.updateEventChip();
                      this.eventData.registrations =
                        this.eventDataRegistrations.slice(0, 39);
                    });
                }
              });
          });

        this.certificateServices
          .getThumbnails()
          .toPromise()
          .then((response) => {
            let s3public =
              "https://" +
              response.s3PublicRepository +
              ".s3.us-west-2.amazonaws.com/";
            this.basePath = `https://${response.s3PublicRepository}.s3.us-west-2.amazonaws.com/`;
            this.cdnBase = s3public;
          });
      }
    });
  }

  checkAllDay(eventData) {
    if (eventData.extraData.startTime == eventData.extraData.endTime) {
      this.allDay = true;
    } else {
      this.allDay = false;
    }
  }

  contactDetails(id) {
    this.profileService.getProfileById(id).subscribe((contact) => {
      let selectedContact = contact;
      const dialogRef = this.dialog.open(ContactDetailsWidgetComponent, {
        data: selectedContact,
      });
    });
  }

  printPage() {
    window.print();
  }

  goBack() {
    let role = this.role.toLocaleLowerCase();
    if (this.isStaff) {
      this.router.navigate([role, "communications-log"]);
    } else {
      this.router.navigate([role, "feeds"]);
    }
  }

  checkIn() {
    this.analytics.sendEvent("User Click", "Check In");

    swal({
      title: `Check in to event?`,
      icon: "info",
      text: "Your name will be added to the list of attendees and be visible to everyone",
      buttons: {
        cancel: true,
        confirm: {
          text: "Confirm",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        const userId = this.profileService.identity.userIdRole;
        let req = {
          attendeeId: userId,
          channel: "Web",
          role: this.role,
        };
        this.eventService.checkinEvent(this.eventId, req).subscribe(
          (res) => {
            console.log(res);
            if (res.checkIn) {
              this.toastr.success("Successfully checked in");
              this.checkedIn = true;
              this.showCheckIn = false;
            } else {
              if (res.hasError) {
                this.toastr.warning(res.errorMessage);
              } else {
                this.toastr.warning(
                  "Could not RSVP in. Try again next to the Event start time."
                );
              }
            }
          },
          (error) => {
            this.toastr.warning("Could not check in. Try again.");
          }
        );
      }
    });
  }

  getCheckInStatus(registrations) {
    const id = this.profileService.identity.userIdRole;

    let user = _.find(registrations, { attendeeId: id });

    if (user) this.checkedIn = true;
    else this.checkedIn = false;
  }

  getLink(link) {

    return (link.includes('https') ? link : `//${link}`)

  }
  rsvp() {
    this.analytics.sendEvent("User Click", "RSVP");
    swal({
      title: `RSVP to event?`,
      icon: "info",
      text: "Your name will be added to the list of attendees and be visible to everyone",
      buttons: {
        cancel: true,
        confirm: {
          text: "Confirm",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        const userId = this.profileService.identity.userIdRole;
        let req = {
          attendeeId: userId,
          channel: "Web",
          role: this.role,
        };
        this.eventService.checkinEvent(this.eventId, req).subscribe(
          (res) => {
            console.log(res);
            if (res.rsvp) {
              this.toastr.success(`Successfully RSVP'd`);
              this.rsvped = true;
              this.showRsvp = false;
            } else {
              if (res.hasError) {
                this.toastr.warning(res.errorMessage);
              } else {
                this.toastr.warning(
                  "Could not RSVP in. Try again next to the Event start time."
                );
              }
            }
          },
          (error) => {
            this.toastr.warning("Could not RSVP. Try again.");
          }
        );
      }
    });
  }

  openAudienceList(item) {
    // TODO: get students & parents from item
    this.dialog
      .open(AudienceListWidgetComponent, {
        disableClose: false,
        width: "600px",
        data: {
          list: item,
          entity: "communication",
          entityId: item.id,
          isEngagement: true,
        }, // FIXME: item.students & item.parents
      })
      .afterClosed()
      .subscribe((res) => {
        if (res?.print) {
          const role = this.profileService.profile.role.toLocaleLowerCase();
          const list: NavigationExtras = {
            state: {
              list: res.list,
              eventData: this.eventData,
            },
          };
          this.router.navigate([`${role}/events/print-attendance`], list);
        }
      });
  }

  download(item) {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    var file = this.cdnBase + item.extraData.eventFile;
    window.open(file, "_blank");
    // if (ie || oldIE || ieEDGE) {
    //   window.navigator.msSaveBlob(blob, `${item.title}_file.pdf`);
    // } else {
    //   var fileURL = URL.createObjectURL(blob);
    //   window.open(fileURL);
    // }
  }

  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };

  viewQR() {
    this.analytics.sendEvent("User Click", "View QR Code");
    let userType = this.profileService.profile.role;
    console.log(userType);
    if (
      userType == "Administrator" ||
      userType == "Teacher" ||
      userType == "Counselor"
    ) {
      this.router.navigate([
        userType.toLocaleLowerCase(),
        "events",
        "id",
        this.eventId,
        "qr",
      ]);
    } else {
      this.router.navigate([
        userType.toLocaleLowerCase(),
        "events",
        "id",
        this.eventId,
        "qr",
      ]);
    }
  }

  viewAllAttendees() {
    this.eventData.registrations = this.eventDataRegistrations;
  }

  viewEngagement(type) {
    switch (type) {
      case "rsvp":
        this.dialog
          .open(AudienceListWidgetComponent, {
            disableClose: false,
            width: "600px",
            data: {
              list: this.eventDataRegistrations
                .filter((r) => r.rsvp != null)
                .map((c) => {
                  return { target: c.role, targetId: c.attendeeId };
                }),
              entity: "communication",
              audienceType: `RSVP'd`,
              isEngagement: true,
              // entityId: item.id
            }, // FIXME: item.students & item.parents
          })
          .afterClosed()
          .subscribe((res) => {
            if (res?.print) {
              const role = this.profileService.profile.role.toLocaleLowerCase();
              const list: NavigationExtras = {
                state: {
                  list: res.list,
                  eventData: this.eventData,
                },
              };
              this.router.navigate([`${role}/events/print-attendance`], list);
            }
          });
        break;
      case "received":
        this.dialog
          .open(AudienceListWidgetComponent, {
            disableClose: false,
            width: "600px",
            data: {
              // list: item,
              entity: "communication",
              audienceType: `Received`,
              isEngagement: true,
              // entityId: item.id
            }, // FIXME: item.students & item.parents
          })
          .afterClosed()
          .subscribe((res) => {
            if (res?.print) {
              const role = this.profileService.profile.role.toLocaleLowerCase();
              const list: NavigationExtras = {
                state: {
                  list: res.list,
                  eventData: this.eventData,
                },
              };
              this.router.navigate([`${role}/events/print-attendance`], list);
            }
          });
        break;
      case "checked in":
        this.dialog
          .open(AudienceListWidgetComponent, {
            disableClose: false,
            width: "600px",
            data: {
              list: this.eventDataRegistrations
                .filter((r) => r.checkIn != null)
                .map((c) => {
                  return { target: c.role, targetId: c.attendeeId };
                }),
              entity: "communication",
              audienceType: `Checked In`,
              isEngagement: true,
              // entityId: item.id
            }, // FIXME: item.students & item.parents
          })
          .afterClosed()
          .subscribe((res) => {
            if (res?.print) {
              const role = this.profileService.profile.role.toLocaleLowerCase();
              const list: NavigationExtras = {
                state: {
                  list: res.list,
                  eventData: this.eventData,
                },
              };
              this.router.navigate([`${role}/events/print-attendance`], list);
            }
          });
        break;

      default:
        break;
    }
  }

  updateBtnDisable(type) {
    let now = moment();
    const eventTime = moment(this.eventData.startDate);

    if (type == "RSVP") {
      if (now.isBefore(eventTime)) {
        return null;
      } else return true;
    }

    if (type == "CheckIn") {
      if (now.isAfter(eventTime)) {
        return null;
      } else return true;
    }
  }

  showRsvp = false;
  showCheckIn = false;
  updateEventChip() {
    let now = moment();
    const eventStart = moment(this.eventData.extraData.startTime);
    const eventEnd = moment(this.eventData.extraData.endTime);

    if (now.isBefore(eventStart)) {
      this.eventChip = "Upcoming";
      this.showRsvp = this.rsvped ? false : true;
      return;
    }
    if (now.isBetween(eventStart, eventEnd)) {
      this.eventChip = "Started";
      this.showRsvp = false;
      this.showCheckIn = this.checkedIn ? false : (this.eventData.extraData.IsRsvpOnly ? this.rsvped  == true : (this.eventData.extraData.IsPrivate ? this.rsvped == true : true))     // (this.eventData.extraData.IsRsvpOnly ? (this.rsvped == true : false): true);
      return;
    }
    if (now.isAfter(eventEnd)) {
      this.eventChip = "Ended";
      return;
    }
  }

  addToCal(feed: any) {
    let userType = this.profileService.profile.role;
    console.log(feed);
    let startDate = feed.extraData.startTime;
    let endDate = feed.extraData.endTime;
    this.loaderService.setHttpProgressStatus(true);
    this.calendarServices
      .createEvent(
        feed.name,
        startDate,
        endDate,
        this.profileService.identity.userIdRole
      )
      .subscribe(
        (res) => {
          this.loaderService.setHttpProgressStatus(false);
          this.notificationServices.showSuccessToast(
            `New Event has been added successfully.`
          );
          // this.router.navigate([`/${userType.toLocaleLowerCase()}/calendar`]);
        },
        (error) => {
          this.notificationServices.showInfoToast(error);
        }
      );
  }
}

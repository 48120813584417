import { SchoolYearService } from './../../../services/school-year.service';
import { ParentsService } from 'src/app/shared/services/parents.service';
import {
  IStudentGrades,
  TermList,
  ContentStandardsList,
  GradeList,
  CourseList,
} from 'src/app/shared/services/student.service.models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentsService } from 'src/app/shared/services/students.service';
import * as _ from 'lodash';
import * as m from 'moment';
import { RoleServiceFactoryService } from 'src/app/shared/services/role-service-factory.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from 'src/app/shared/services/profile.service';
import swal from 'sweetalert';
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-student-grades',
  templateUrl: './student-grades.component.html',
  styleUrls: ['./student-grades.component.scss'],
})
export class StudentGradesComponent implements OnInit {
  grades: IStudentGrades = null;
  term: TermList = null;
  courses: CourseList[] = [];
  schoologyLink = '';
  schoolName = '';
  lastUpdated: any;
  student = null;
  schoolYears: any = [];

  isLoading = false;
  _ = _;

  isHighSchool = false;
  highSchoolGrades = [];
  elementaryGrades = [];
  hasGrades = false;

  selectedYears: string = '';

  schoologyLoading = false;
  selectedLink = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private parentServices: ParentsService,
    private router: Router,
    public rs: RoleServiceFactoryService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private schoolYear:SchoolYearService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.student = this.studentService.currentStudent();
    this.selectedYears = this.schoolYear.currentYear().slice(0, 4) + '-' +this.schoolYear.currentYear().slice(4);
    
    this.studentService.getStudentYears(this.student.studentId).subscribe(res => {
      this.schoolYears = res.yearList;

    })
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentService.getStudentGradesByYear(data.studentId,this.schoolYear.currentYear()).subscribe((response) => {
        this.isLoading = false;
        this.grades = response;
        if(this.grades.termList == null){
          //this.term = this.grades.termList[0];
          this.hasGrades = false;
        } else {
          this.hasGrades = true;
          this.term = this.grades.termList[0];
          this.processGrades(response)
          console.log(this.term)
          this.filterCourses(0);
        }
      }, error => {
        this.isLoading = false;
        //this.toastr.error(`Can't load Grades`);
      });

      this.lastUpdated = m();
    });

    const student = this.studentService.currentStudent();
    // this.parentServices.getSchoologyRemoteLogin('web', student.schoologyID).subscribe((response) => {
    //   this.schoologyLink = response.remoteLoginURL + '/parent/grades_attendance/grades';
    // });


    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
      {
        anchorId: 'start-tour',
        content: 'All grades will be updated and posted here.  Click on a row to expand further details about the course.',
        title: 'Welcome to Student Grades!',
      },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  processGrades(courses){

    // if(courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList.length == 0){
    //   this.isHighSchool = true;
    // } else if (courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList[0].standardName == null || courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList[0].standardName == 'null') {
    //   this.isHighSchool = true;
    // } else {
    //   this.isHighSchool = false;
    // }



    if (courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList.length == 0){
      this.isHighSchool = true;
    }else if(courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList[0].score == 'null' &&
    courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList[0].standardName == 'null'
  ){
    this.isHighSchool = true;
  }else{
    this.isHighSchool = false;
  }


    // if(courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList[0].score == 'null' &&
    //   courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList[0].standardName == 'null'
    // ){
    //   this.isHighSchool = true;
    // } else if (courses.termList[0].assessmentList[0].courseList[0].gradeList[0].contentStandardsList.length == 0) {
    //   this.isHighSchool = true;
    // } else {
    //   this.isHighSchool = false;
    // }


    if(this.isHighSchool){


      // _.each(courses.termList, term => {
      //   this.highSchoolGrades.push(term)
      // })

      this.highSchoolGrades = courses.termList;

    } else {


      if(courses.termList.length > 0){
      //    courses = courses.termList[0].assessmentList[0].courseList;
      //    let gradePeriods = [];

      //  _.each(courses, course => {
      //     _.each(course.gradeList, gradePeriod => {
      //       gradePeriod.course = course;
      //       gradePeriods.push(gradePeriod);
      //      })
      //    })

      //    gradePeriods = _.orderBy(gradePeriods, 'gradePeriod', 'asc');

        
       
        for (let i = 0; i < courses.termList.length; i++) {          
          for (let j = 0; j < courses.termList[i].assessmentList.length; j++) {            
            for (let k = 0; k < courses.termList[i].assessmentList[j].courseList.length; k++) {
              for (let l = 0; l < courses.termList[i].assessmentList[j].courseList[k].gradeList.length; l++) {
               
                if (courses.termList[i].assessmentList[j].courseList[k].courseName == "Mathematics Content"
                    || courses.termList[i].assessmentList[j].courseList[k].courseName == "English Language Arts"){
                  for (let m = 0; m < courses.termList[i].assessmentList[j].courseList[k].gradeList[l].contentStandardsList.length; m++) {
                      
                      if (courses.termList[i].assessmentList[j].courseList[k].gradeList[l].contentStandardsList[m].standardName == "Composite Mathematics Content" 
                         || courses.termList[i].assessmentList[j].courseList[k].gradeList[l].contentStandardsList[m].standardName == "Composite English Language Arts"){
                          courses.termList[i].assessmentList[j].courseList[k].gradeList[l].grade
                          = courses.termList[i].assessmentList[j].courseList[k].gradeList[l].contentStandardsList[m].score;

                          courses.termList[i].assessmentList[j].courseList[k].gradeList[l].contentStandardsList[m].score;
                          courses.termList[i].assessmentList[j].courseList[k].gradeList[l].contentStandardsList.splice(m, 1);                          
                      }        
                  } 
                } else{
                  courses.termList[i].assessmentList[j].courseList[k].gradeList[l].grade = "N/A"
                }
              }
              courses.termList[i].assessmentList[j].courseList[k].gradeList = _.orderBy(courses.termList[i].assessmentList[j].courseList[k].gradeList, 'gradePeriod', 'asc');
            }
          }
        }      

      this.elementaryGrades = courses.termList;

    }
  }
}

  goSchoology() {
    this.schoologyLoading = true;
    this.parentServices
      .getSchoologyRemoteLogin("web", this.student.districtId)
      .subscribe((response) => {
        this.schoologyLoading = false;
        this.selectedLink = null;
        if(response.errorResponse.errorCode){
          this.toastr.error(`${response.errorResponse.errorMessage} - Please contact the Help Desk at (213)241-5200`,'LAUSD', {
            extendedTimeOut:4000
          });
          return
        }
        if(response.remoteLoginURL){
          window.open(
            response.remoteLoginURL + "/parent/grades_attendance/grades"
          );
        }
      }, error => {
        this.schoologyLoading = false;
        this.selectedLink = null;
      });
  }

  getGradesBySchoolYear(value: string) {
    this.selectedYears = value;
    this.isLoading = true;
    if(value == "null")
      return;
    let year = value.replace('-', '');
    let currentYear = this.schoolYear.currentYear();

    if(year == currentYear){
      this.studentService.getStudentGradesByYear(this.student.studentId,this.schoolYear.currentYear()).subscribe((response) => {
        this.isLoading = false;
        this.grades = response;
        if(this.grades.termList == null){
          this.hasGrades = false;
        }else{
          this.hasGrades = true;
          this.term = this.grades.termList[0];
          this.filterCourses(0);
          this.processGrades(response);
        }
      }, error => {
        this.isLoading = false;
        //this.toastr.error(`Can't load Grades`);
      });
    } else {
      this.studentService.getStudentHistoricalGrades(this.student.studentId, year).subscribe(response => {
        this.isLoading = false;
        this.grades = response;
        if(this.grades.termList == null){
          this.hasGrades = false;
        }else{
          this.hasGrades = true;
          this.term = this.grades.termList[0];
          this.filterCourses(0);
          this.processGrades(response);
        }
      }, error => {
        this.isLoading = false;
      })
    }

  }


  getStandards(gradeList){
    let standards = [];
    _.each(gradeList[0].contentStandardsList, (item) => {
      let standard = {
        standardName: '',
        grades: []
      }
      standard.standardName = item.standardName;
      standards.push(standard);
    })
    _.each(standards, (standard) => {
      _.each(gradeList, (period) => {
        _.each(period.contentStandardsList, (item) => {
          if(item.standardName == standard.standardName){
            let grade = {
              score: item.score,
              p: period.gradePeriod
            }
            standard.grades.push(grade);
          }
        })
      })
    })

    return standards
  }

  getSchoolName(studentId){
    this.studentService.getStudentSchoolInfo(studentId).subscribe((school) => {
      this.schoolName = school.schoolName;
    });
  }
  courseList(gradeList: GradeList[]): ContentStandardsList[] {
    return _.sortBy(gradeList[0].contentStandardsList, 'standardName');
  }
  scoreReport(gradeList: GradeList, contentIndex: number): string {
    return _.sortBy(gradeList.contentStandardsList, 'standardName')[contentIndex].score;
  }
  checkGrade(grade: GradeList) {
    if (grade == undefined) {
      return 'N/A';
    }
    if (grade.grade == null) {
      return 'N/A';
    }
    if (grade.grade == 'null') {
      return 'N/A';
    }
    return grade.grade;
  }
  filterCourses(index: any) {

    this.term = this.grades.termList[index];
    let list = [];
    if(this.term)
      for (const assessment in this.term.assessmentList) {
        const courseList = this.term.assessmentList[assessment].courseList;
        list = list.concat(courseList);
      }

    this.courses = list;
  }

  gotoInbox(course) {
    let role = this.profileService.profile.role;
    this.router.navigate([`${role}/inbox/compose/` + course.gradeList[0].instructorEmployeeId]);
  }
}

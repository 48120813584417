import { Observable } from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  showModal: boolean = false;
  modalChanged = new Subject<boolean>();
  constructor() { }

  show(){
    this.showModal = true;
    this.modalChanged.next(this.showModal);
  }
  hide(){
    this.showModal = false;
    this.modalChanged.next(this.showModal);
  }
}

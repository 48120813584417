import { Component, OnInit, Input } from '@angular/core';
import { interval, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-countdown-widget',
  templateUrl: './countdown-widget.component.html',
  styleUrls: ['./countdown-widget.component.scss']
})
export class CountdownWidgetComponent implements OnInit {
  public enddate: any = '2021-05-28';

  private _diff: number;
   _days: number;
   _hours: number;
   _minutes: number;
   _seconds: number;

  constructor() { }

  ngOnInit() {

    interval(1000).pipe(
      map((x) => {
        this._diff = Date.parse(this.enddate) - Date.parse(new Date().toString());
      })).subscribe((x) => {
        this._days = this.getDays(this._diff);
        this._hours = this.getHours(this._diff);
        this._minutes = this.getMinutes(this._diff);
        this._seconds = this.getSeconds(this._diff);
      });
  }

  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }
}

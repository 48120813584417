import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { StudentsService } from "src/app/shared/services/students.service";
import {
  IStudentGrades,
  TermList,
  CourseList,
  ContentStandardsList,
  GradeList,
} from "src/app/shared/services/student.service.models";
import * as _ from "lodash";
import { ParentsService } from "src/app/shared/services/parents.service";
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import * as m from 'moment';
import { Profile, ProfileService } from "src/app/shared/services/profile.service";
import swal from 'sweetalert';
import { NotificationServices } from "src/app/shared/services/notification.service";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-student-esl",
  templateUrl: "./student-esl.component.html",
  styleUrls: ["./student-esl.component.scss"],
})
export class StudentEslComponent implements OnInit {
  grades: IStudentGrades = null;
  term: TermList = null;
  courses: CourseList[] = [];
  schoologyLink: string = "";
  lastUpdated: any;
  studentGrade: any;
  studentId: any;
  isK5: boolean = false;
  isTk: boolean = false;
  isEtk: boolean = false;
  is6Elementary: boolean = false;
  isSecondary: boolean = false;
  isElp: boolean = false;

  isLoading = false;
  constructor(
    private studentService: StudentsService,
    private activatedRoute: ActivatedRoute,
    private parentServices: ParentsService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.studentService.getStudentElp(data.studentId).subscribe((response) => {
        this.isLoading = false;
        this.grades = response;
        this.isElp = response.englishLearner !== 'N' && response.englishLearner !== null;
        this.term = this.grades.termList[0];
        this.filterCourses(this.term);
        console.log(this.term, 'term')
      }, error => {
        this.isLoading = false;
        //this.toastr.error(`Can't load ELP`);
      });
      this.lastUpdated = m();
    });


    this.studentGrade = this.studentService.currentStudent();
    this.studentGrade = this.studentGrade.gradeCode;
    this.getGradeTable(this.studentGrade);
    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
      {
        anchorId: 'start-tour',
        content: 'All courses related to ELP will be posted and updated here',
        title: 'Welcome to English Learner Program!',
      },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  getGradeTable(grade){
    switch (grade) {
      case 'TK':
        this.isTk = true;
        break;
      case 'ETK':
        this.isEtk = true;
        break;
      case 'TE':
        this.isEtk = true;
        break;
      case 'K':
        this.isK5 = true;
        break;
      case '1':
        this.isK5 = true;
        break;
      case '2':
        this.isK5 = true;
        break;
      case '3':
        this.isK5 = true;
        break;
      case '4':
        this.isK5 = true;
        break;
      case '5':
        this.isK5 = true;
        break;
      case '6':
        this.is6Elementary = true;
        break;
      case '7':
        this.isSecondary = true;
        break;
      case '8':
        this.isSecondary = true;
        break;
      case '9':
        this.isSecondary = true;
        break;
      case '10':
        this.isSecondary = true;
        break;
      case '11':
        this.isSecondary = true;
        break;
      case '12':
        this.isSecondary = true;
        break;

      default:
        break;
    }
  }

  goTest(type) {
    let role = this.profileService.profile.role.toLocaleLowerCase();
    let route = [ 'students', this.studentId, 'testing', type];

    if(this.profileService.profile.role != 'Student'){
      route.unshift(this.profileService.profile.role.toLocaleLowerCase())
    }else{
      route = ['student', 'profile',this.studentId, 'testing', type];
    }

    switch (type) {
      case 'ELPAC':
        this.router.navigate(route);
        break;

      case 'CAASPP':
        this.router.navigate(route);
        break;

      default:
        break;
    }
  }

  courseList(gradeList: GradeList[]): ContentStandardsList[] {
    return _.sortBy(gradeList[0].contentStandardsList, "standardName");
  }
  scoreReport(gradeList: GradeList, contentIndex: number): string {
    return _.sortBy(gradeList.contentStandardsList, "standardName")[
      contentIndex
    ].score;
  }
  checkGrade(grade: GradeList) {
    if (grade == undefined) return "N/A";
    return grade.grade;
  }
  filterCourses(term: TermList) {
    if(this.term) {
      this.term = term;
      let list = [];
      this.courses = term.courseList;
    } else {
      this.term = null;
      let list = [];
      this.courses = [];
    }
    // for (let assessment in term.assessmentList) {
    //   var courseList = term.assessmentList[assessment].courseList;
    //   list = list.concat(courseList);
    // }

    // this.courses = list;
  }

}

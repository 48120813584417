import { CertificateService } from 'src/app/shared/services/certificate.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewResultsComponent } from 'src/app/routes/shared/survey-manager-route/view-results/view-results.component';


@Component({
  selector: 'app-thumbnail-preview',
  templateUrl: './thumbnail-preview.component.html',
  styleUrls: ['./thumbnail-preview.component.scss']
})
export class ThumbnailPreviewComponent implements OnInit {

  pdf = null;
  photo: any = '';
  tablePreview = true;
  settings = null;
 constructor(
    private certificateServices:CertificateService,
    public dialogRef: MatDialogRef<ViewResultsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.photo = this.data.thumbnail;
    this.pdf = this.data.pdf;

    this.tablePreview = this.data.tablePreview;
    console.log(this.tablePreview, '=======');
    this.certificateServices.getThumbnails().subscribe((settings)=>{
      this.settings = settings;
    })
  }

  saveFile() {
    setTimeout(() => {
      //window.open(this.photo);
      // const link = document.createElement('a');
      // link.attributes.setNamedItem(new Attr())
      // link.href = this.photo;
      // link.download = 'certificate.png';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    }, 50);
  }

  print() {
    window.print();
  }

}

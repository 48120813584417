import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(number: string): string {

    const areaCode = number.slice(0, 3);
    const phoneNum = number.slice(3, 6) + '-' + number.slice(6,10);

    return number = `(${areaCode})${phoneNum}`;
  }

}

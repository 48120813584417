// import { NotificationHubMessage } from "./notification-hub.service";
import { Subject } from "rxjs";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProfileService } from "./profile.service";
import { AppConfigService } from "src/app/services/app-config.service";
import { RoleServiceFactoryService } from "./role-service-factory.service";
import { StaffSettingsService } from "./staff-settings.service";
import { ChatService } from "./chat.service";

@Injectable({
  providedIn: "root",
})
export class CommentsService {
  getComments(key: any): Observable<any> {
    let path = `${this.baseUrl}/v1/comments/${key}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  addComment(
    key: any,
    keyOwnerId,
    message,
    sourceOwner,
    type,
    providerId
  ): Observable<any> {
    var formData: any = new FormData();
    formData.append("comment", message);
    formData.append("entityOwnerId", keyOwnerId);
    formData.append("entitySourceOwnerId", sourceOwner);
    formData.append("entitySourceType", type);
    formData.append("entityProviderId", providerId);

    let path = `${this.baseUrl}/v1/comments/${key}`;
    return this.api
      .post(path, formData)
      .pipe(map((response) => response as any));
  }
  addAccomplishmentNotes(key: any, keyOwnerId, message, obj): Observable<any> {
    var formData: any = new FormData();
    formData.append("comment", message);
    formData.append("entityOwnerId", keyOwnerId);
    formData.append("obj", JSON.stringify(obj));

    let path = `${this.baseUrl}/v1/comments/${key}/accomplishment-notes`;
    return this.api
      .post(path, formData)
      .pipe(map((response) => response as any));
  }

  baseUrl: string =
    this.appConfig.configuration.environment.endPoints.communicationsServices;
  commentReceived: Subject<any> = new Subject<any>();

  ccrgReceived: Subject<any> = new Subject<any>();

  constructor(
    private appConfig: AppConfigService,
    private staffSettingsService: StaffSettingsService,
    private rs: RoleServiceFactoryService,
    private profileService: ProfileService,
    private api: ApiService,
    private chatService: ChatService
  ) {
    this.api.baseUrl = this.baseUrl;
    this.commentReceived.subscribe((msg) => {
      msg.sender.createNotification(msg);
      this.chatService.loadNotifications();
      if (this.profileService.identity.userType === this.rs.TeacherRole) {
        if (this.staffSettingsService.isNotificationEnabled) {
          msg.sender.showInfoToast(`You have a new comment`);
        }
      } else {
        msg.sender.showInfoToast(`You have a new comment`);
      }
    });

    this.ccrgReceived.subscribe((msg) => {
      msg.sender.createNotification(msg);
      this.chatService.loadNotifications();
      if (this.profileService.identity.userType === this.rs.TeacherRole) {
        if (this.staffSettingsService.isNotificationEnabled) {
          msg.sender.showInfoToast(`You have a new CCRG Note`);
        }
      } else {
        msg.sender.showInfoToast(`You have a new CCRG Note`);
      }
    });
  }
}

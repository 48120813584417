import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
declare var google;


@Injectable({
  providedIn: 'root',
})
export class GeocodeService {
  constructor() {}

  geocodeAddress(location: string) {
    const geocoder = new window.google.maps.Geocoder();
    return new Observable((observer) => {
      geocoder.geocode({ address: location }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          observer.next({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          observer.complete();
        } else {
          observer.next(null);
          observer.complete();
        }
      });
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-print-pin-modal',
  templateUrl: './print-pin-modal.component.html',
  styleUrls: ['./print-pin-modal.component.scss']
})
export class PrintPinModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PrintPinModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  parent = this.data.parent;
  student = this.data.student;

  ngOnInit(): void {}

  onClose(){
    this.dialogRef.close();
  }

  print(){
    window.print();
  }

}

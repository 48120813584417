import { UsersFeature } from './shared/store/features/users.feature';
import { NotificationEffects } from './shared/store/notification.effects';
import { CountdownModule } from "ngx-countdown";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { APP_INITIALIZER, NgModule } from "@angular/core";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "./app.component";

import {
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from "angular-oauth2-oidc";

import { LoaderInterceptorService } from "./shared/services/loader-interceptor.service";
import { AuthInterceptorInterceptor } from "./shared/auth/auth-interceptor.interceptor";
import { environment } from "./../environments/environment";
import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from "./shared/shared.module";
import { RoutesModule } from "./routes/routes.module";
import { AuthGuard } from "./shared/auth/auth.guard";
import { AgGridModule } from "ag-grid-angular";
import { AppConfigService } from "./services/app-config.service";
import { PipeModule } from "./pipe/pipe.module";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { FilePickerModule } from "ngx-awesome-uploader";
import { TimeagoModule } from "ngx-timeago";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ExtraOptions, RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { NotificationsFeature } from './shared/store/notifications.feature';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './shared/store/features/user.effects';

const appInitializerFn = (appConfig: AppConfigService) => {
  return (): Promise<any> => {
    return appConfig.loadAppConfig();
  };
};
export function oAuthStorageFactory(): OAuthStorage {
  return localStorage;
}
// // https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
export function authConfigFactory(
  appConfig: AppConfigService
): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [],
      sendAccessToken: true,
    },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    CountdownModule,
    TimeagoModule.forRoot(),
    PipeModule,
    OAuthModule.forRoot(),
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    AgGridModule.withComponents([]),
    SharedModule,
    RoutesModule,
    FilePickerModule,
    ScrollingModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(UsersFeature),
    StoreModule.forFeature(NotificationsFeature),
    EffectsModule.forRoot([UserEffects, NotificationEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    // NgxGoogleAnalyticsModule.forRoot("UA-233752499-1"),
    // NgxGoogleAnalyticsRouterModule,
    TourMatMenuModule.forRoot(),
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoaderInterceptorService,
    //   multi: true,
    // },
    {
      provide: OAuthModuleConfig,
      useFactory: authConfigFactory,
    },
    { provide: OAuthStorage, useFactory: oAuthStorageFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

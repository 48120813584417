import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CdnService {
  basePath: string = null;

  get schoolBackgrounds(): string{
    return `${this.basePath}school_background_images/`;
  };
  get resourceImages(): string{
    return `${this.basePath}lausd-resource-links/`;
  };
  get profileAvatars(): string{
    return `${this.basePath}profiles/avatars/`;
  };
  constructor() { }
}

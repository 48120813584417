import { Component, OnInit, Input } from '@angular/core';
import { AnnouncementModel } from './models/AnnouncementModel';

@Component({
  selector: 'app-announcement-widget',
  templateUrl: './announcement-widget.component.html',
  styleUrls: ['./announcement-widget.component.scss']
})
export class AnnouncementWidgetComponent implements OnInit {

  @Input() model: AnnouncementModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
    this.loaded = this.model != null
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from '../../services/profile.service';
import { ChatService } from '../../services/chat.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-remind-grade',
  templateUrl: './remind-grade.component.html',
  styleUrls: ['./remind-grade.component.scss']
})
export class RemindGradeComponent implements OnInit {

  counselor = `${this.profileService.profile.firstName} ${this.profileService.profile.lastName}`
  studentName = `${this.data.student.firstName} ${this.data.student.lastName}`;
  studentFirstName = this.data.student.firstName;
  course = this.data.course.courseName;
  message = `Hi ${this.studentFirstName},

  I'm reaching out to you to remind you that there are available options to re-take ${this.course} to help with your graduation requirements. Please reply back or come see me in person, so we can discuss your options.

  Have an amazing day!`;

  constructor(
    public dialogRef: MatDialogRef<RemindGradeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profileService: ProfileService,
    private chatService: ChatService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  sendReminder() {
this.chatService.addConversation(this.data.student.studentId, this.message).subscribe(() => {
  this.toastr.success('Reminder sent successfully');
  this.onClose();
}, (error) => {
  this.toastr.error('Sending Reminder failed, please try again');
});
  }

}

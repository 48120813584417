import { ParentsService } from './../../../../shared/services/parents.service';
import { IFaqGroup } from "./../../../../shared/components/faq-section-widget/models/Faq";
import { HelpCenterService } from "./../../../../shared/services/help-center.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StudentsService } from "src/app/shared/services/students.service";
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import * as m from 'moment';
import swal from 'sweetalert';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { TourModalComponent } from '../../tour-modal/tour-modal.component';
import { NotificationServices } from 'src/app/shared/services/notification.service';

@Component({
  selector: "app-student-specialed",
  templateUrl: "./student-specialed.component.html",
  styleUrls: ["./student-specialed.component.scss"],
})
export class StudentSpecialedComponent implements OnInit {
  faq: IFaqGroup[] = null;
  studentId: string = null;
  lastUpdated: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private helpService: HelpCenterService,
    private parentService: ParentsService,
    public tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices
  ) {}

  ngOnInit(): void {
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.lastUpdated = m();
    });

    this.startTour();
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'Here you will find resources related to special education.',
      title: 'Special Education',
      placement: right,
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  openPdf(report: string) {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    this.studentService
      .getStudentPdfReport(this.studentId, report, "english")
      .subscribe((response) => {
        var blob = this.blobPdfFromBase64String(response.buffer);

        if (ie || oldIE || ieEDGE) {
          window.navigator.msSaveBlob(blob, `report_${report}.pdf`);
        } else {
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
      });
  }
  openEsy(){
    this.parentService.getEsyLogin().subscribe((response)=>{
      window.open(response.url)
    })
  }
  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };

  downloadConfirmDialog(report){
    swal({
      // title: "Are you sure?",
      text: "You are about to download a document that contains sensitive personal information",
      icon: "warning",
      buttons: [ "Cancel", "Acknowledge"],
    })
    .then((willAcknowledge) => {
      if (willAcknowledge) {
       this.openPdf(report);
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class UserVoiceService {
  baseUrl: string = 'https://goto.lausd.net/api/common/SendNPSRating';

  constructor(private api: ApiService, private http: HttpClient) {
    this.api.baseUrl = this.baseUrl;
  }
  postUserVoice(model: any) {
    return this.http
      .post(this.baseUrl, model)
      .pipe(map((response) => response as any));
  }
}

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactDetailsWidgetComponent } from '../contact-details-widget/contact-details-widget.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-contact-name-widget',
  templateUrl: './contact-name-widget.component.html',
  styleUrls: ['./contact-name-widget.component.scss']
})
export class ContactNameWidgetComponent implements OnInit {

  @Input() contactId: any;
  @Input() customRole: string;
  @Input() showName: boolean;

  fullName: string;

  constructor(
    private profileService: ProfileService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if(this.showName) {
      this.getFullName(this.contactId)
    }
  }

  getFullName(contactId) {
    this.profileService.getProfileById(contactId).subscribe((contact)=> {
      this.fullName = `${contact.firstName} ${contact.lastName}`
      this.cdRef.detectChanges();
    })
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }

  openContact(contactId) {
    this.profileService.getProfileById(contactId).subscribe((contact)=> {
      let selectedContact = contact;
      if(this.customRole)
        selectedContact.role = this.customRole;
        const dialogRef = this.dialog.open(ContactDetailsWidgetComponent, {
        data: selectedContact,
        width: '60vw'
      });
    }, error => {
      this.toastr.error('Cannot load contact. Try again.')
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SurveyService } from '../../services/survey.service';
import { StudentsService } from '../../services/students.service';
import { StudentPbisService } from '../../services/student-pbis.service';
import { RoleServiceFactoryService } from '../../services/role-service-factory.service';
import { ProfileService } from '../../services/profile.service';
import { NotificationServices } from '../../services/notification.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import * as m from 'moment';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { ChecklistComponent } from './checklist/checklist.component';
import { CounselorService } from '../../services/counselor.service';
import { ChecklistService } from '../../services/checklist.service';
import { ToastrService } from 'ngx-toastr';

const swal = require('sweetalert');


@Component({
  selector: 'app-student-checklist',
  templateUrl: './student-checklist.component.html',
  styleUrls: ['./student-checklist.component.scss']
})
export class StudentChecklistComponent implements OnInit {

  single = false;

  searchKey = '';

  mockData = [];

  role = null;
  isStudent = false;

  tableData: any[] = [];
  studentProfile: any = null;
  now = new Date();
  lastUpdated: any;
  studentId: any = null;

  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private surveyServices: SurveyService,
    private studentServices: StudentsService,
    private studentPbis:StudentPbisService,
    public rs:RoleServiceFactoryService,
    private profileService: ProfileService,
    public readonly tourService: TourService,
    private notificationServices: NotificationServices,
    private counselorServices: CounselorService,
    private checklistServices: ChecklistService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.parent.params.subscribe((p)=>{
      this.studentId = p.studentId;
      this.checklistServices.getChecklistsByUserId(this.studentId).subscribe((data)=>{
        this.isLoading = false;
        console.log(data);
        this.tableData = data;
        this.single = true;
        this.startTour();
      }, error => {
        this.isLoading = false;
        //this.toastr.error(`Can't load Checklists`);
      });
      this.studentServices.getStudentById(p.studentId).subscribe((student)=>{
        this.studentProfile = student;
      })
      this.lastUpdated = m();
    })
    this.role = this.profileService.role;
    if(this.role === "Student"){
      this.isStudent = true;
    } else {
      this.isStudent = false;
    }
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'Checklist sent by your teachers will appear here',
      title: 'Welcome to your Checklist!',
      placement: above,
    },  
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }


  viewChecklist(item) {
    console.log(item);
    this.dialog.open(ChecklistComponent, {
      disableClose: false,
      width: '800px',
      panelClass: 'view-results-modal',
      data: {
        checklist: item, // FIXME: remove this line
        id: item.id // TODO: should be survey ID that can be used for API call to get Survey Info
      }
    }).afterClosed().pipe(take(1)).subscribe(res => {
      console.log(res, '====');
    });
  }

  delete(item) {
    swal({
      title: `Delete survey ${item.name}`,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, delete it!',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        this.checklistServices.deleteChecklist(item.id).subscribe(() => {
          _.remove(this.tableData,{id:item.id});
        });
      }
    });

  }

  openChecklist(checklist){
    this.router.navigate([checklist.id],{relativeTo:this.route})
  }


}

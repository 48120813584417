import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CertificateService } from '../../services/certificate.service';
import { CommunicationService } from '../../services/communication.service';
import { EventsAttendanceService } from '../../services/events-attendance.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-calendar-event-qr',
  templateUrl: './calendar-event-qr.component.html',
  styleUrls: ['./calendar-event-qr.component.scss']
})
export class CalendarEventQrComponent implements OnInit {

  role = this.profileService.profile.role;
  eventId: any;
  eventData: any;
  cdnBase: any;

  isStaff = this.profileService.isStaff;
  isSent: boolean = false;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private activeRoute: ActivatedRoute,
    private eventService: EventsAttendanceService,
    private communicationService: CommunicationService,
    private certificateServices: CertificateService
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((data) => {
      if (data.id) {
        this.eventId = data.id;
        this.eventService.getAttendanceById(this.eventId).subscribe(res => {
          this.isSent = !res.notFound;
          this.communicationService.getCommunicationById(this.eventId).subscribe(res => {
            this.eventData = res;
            this.eventService.getQrByEventId(this.eventId).subscribe(qr => {
              this.eventData.qr = qr;
              console.log(this.eventData)
            })
          })


        })
      }
    })
    this.certificateServices.getThumbnails().toPromise().then((response)=>{
      let s3public = 'https://'+response.s3PublicRepository+'.s3.us-west-2.amazonaws.com/';
      this.cdnBase = s3public;
    });
  }

  printPage() {
    window.print();
  }

  goBack() {
    let role = this.role.toLocaleLowerCase();
    if(this.isStaff){
      this.router.navigate([role, 'communications-log']);
    } else {
      this.router.navigate([role, 'feeds']);
    }
  }
  getStartedChip() {
    let now = moment();
    if(now.isBetween(moment(this.eventData.extraData.startTime), moment(this.eventData.extraData.endTime))){
      return true
    } else return false
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { DaysTipModel } from 'src/app/shared/components/tips-day-widget/models/DaysTipModel';
import { CertificateService } from 'src/app/shared/services/certificate.service';

@Component({
  selector: 'app-student-tips-widget',
  templateUrl: './student-tips-widget.component.html',
  styleUrls: ['./student-tips-widget.component.scss']
})
export class StudentTipsWidgetComponent implements OnInit {

  @Input() model: DaysTipModel = null;

  loaded: boolean;
  settings = null;


  constructor(
    private certificateServices: CertificateService,
  ) { }

  ngOnInit(): void {
    this.certificateServices.getThumbnails().subscribe((settings) => {
      this.settings = settings;
    });
    this.loaded = this.model != null;
  }

}

import { ProfileService } from "src/app/shared/services/profile.service";
import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ExpectationsService } from "../../services/expectations.service";
import { ToastrService } from "ngx-toastr";
import { ClassAction } from "../class-details-widget/models/ClassDetails";
import { NewExpectationModalComponent } from "src/app/routes/teacher/expectations-route/expectations-page/new-expectation-modal/new-expectation-modal.component";
import { ReinforcementDetails } from "./models/ReinforcementDetails";
import * as _ from "lodash";

@Component({
  selector: "app-add-reinforcement-modal",
  templateUrl: "./add-reinforcement-modal.component.html",
  styleUrls: ["./add-reinforcement-modal.component.scss"],
})
export class AddReinforcementModalComponent implements OnInit {
  student: any = null;
  selectedClass: any = null;
  selectedGroup: any = null;
  expectations: ReinforcementDetails[] = [];
  type = null;

  constructor(
    public dialogRef: MatDialogRef<AddReinforcementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private expectationsService: ExpectationsService
  ) {
    if (data.student) {
      this.student = this.data.student;
      this.type = "student";
    } else if (data.class) {
      this.type = "class";
      this.selectedClass = data.class;
    } else if (data.group) {
      this.type = "group";
      this.selectedGroup = data.group;
    }
    this.expectations = this.data.expectations;

  }

  ngOnInit(): void {
    this.expectations = _.sortBy(this.expectations, "name");
  }

  addReinforcement(item) {
    const action: ClassAction = {
      target: {
        expectation: item,
      },
      command: "addReinforcement",
    };
    this.dialogRef.close(action);
  }
  pauseReinforcement(item) {
    const action: ClassAction = {
      target: {
        expectation: item,
      },
      command: "pauseReinforcement",
    };
    this.dialogRef.close(action);
  }

  onClose() {
    // command: 'closeReinforcement'
    this.dialogRef.close();
  }

  addExpectation() {
    this.dialog
      .open(NewExpectationModalComponent, {
        disableClose: true,
        width: "70%",
        maxHeight: "80vh",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          res.staffId = this.profileService.identity.userIdRole;
          this.toastr.success("New Expectation Created");
          this.expectations.push(res);
          this.expectationsService
            .createExpectation(res)
            .subscribe((newExp) => {
            });
        }
      });
  }

  gotoExpectations() {
    this.dialogRef.close();
    this.router.navigate(["/teacher/expectations"]);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { AnnouncementAdminListModel } from './models/AnnouncementAdminListModel';

@Component({
  selector: 'app-announcement-admin-list-widget',
  templateUrl: './announcement-admin-list-widget.component.html',
  styleUrls: ['./announcement-admin-list-widget.component.scss']
})
export class AnnouncementAdminListWidgetComponent implements OnInit {

  @Input() model: AnnouncementAdminListModel = null;

  // async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

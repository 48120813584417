import { ProfileService } from "./../../services/profile.service";
import { CommentsService } from "./../../services/comments.service";
import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-comments-widget",
  templateUrl: "./comments-widget.component.html",
  styleUrls: ["./comments-widget.component.scss"],
})
export class CommentsWidgetComponent implements OnInit {
  @Input() key = null;
  @Input() comments = [];
  @Input() keyOwnerId = null;
  @Input() notes = false;
  @Input() obj = {};
  @Input() ccrg = false;
  @Input() type = null;
  @Input() sourceOwner = false;
  @Input() providerId = null;
  @Input() notification = false;
  localComments: any = null;
  commentCount: any;
  message: any;

  constructor(
    public commentsService: CommentsService,
    public studentService: ProfileService
  ) {}

  ngOnInit(): void {
    this.loadComments();
  }
  loadComments() {
    this.commentsService.getComments(this.key).subscribe((comments) => {
      this.localComments = comments;
      this.commentCount = comments.length;
    });
  }
  addComment() {
    this.message = this.message.replace(/<[^>]+>/g, "");
    if (this.notes) {
      this.commentsService
        .addAccomplishmentNotes(
          this.key,
          this.keyOwnerId,
          this.message,
          this.obj
        )
        .subscribe((comment) => {
          this.message = "";
          this.loadComments();
        });
    } else {
      this.commentsService
        .addComment(
          this.key,
          this.keyOwnerId,
          this.message,
          this.sourceOwner,
          this.type,
          this.providerId
        )
        .subscribe((comment) => {
          this.message = "";
          this.loadComments();
        });
    }
  }
  getUserTitle(user) {
    let userTitle = user.ownerId;
    if (user.userDetails.firstName) {
      userTitle = `${user.userDetails.firstName}`;
    }
    if (user.userDetails.lastName) {
      userTitle = `${user.userDetails.lastName}, ${userTitle}`;
    }
    return userTitle;
  }
  getTime(time) {
    let timeSince = moment(time).fromNow();
    return timeSince;
  }
}

import { ProfileService } from "./profile.service";
import { IRoleServices } from "./IRoleServices";
import { AppConfigService } from "./../../services/app-config.service";
import { Observable, BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ContactModel } from "../components/contact-widget/models/ContactWidgetModel";

@Injectable({
  providedIn: "root",
})
export class TeacherService implements IRoleServices {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices;
  baseCustomerUrl: string = this.appConfig.configuration.environment.endPoints
    .customerServices;

  public teacherStatus$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private profileService: ProfileService
  ) {
    this.api.baseUrl = this.baseUrl;
  }
  public getStudentsByStaffId(id: string): Observable<any> {
    const path = `${this.baseUrl}/v1/staff/${id}/students`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  public getStudentsByLocationStaffId(id: string,locations:any, includeParents:boolean, parentsOnly:boolean): Observable<any> {
    let locs = locations.map((l)=>`${l}`)     
    const path = `${this.baseUrl}/v1/staff/${id}/students?${'location='+locs.join(',')}${includeParents ? '&parents=true':'&parents=false'}&${parentsOnly ? 'parentsOnly=true':'parentsOnly=false'}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  public getStudentsByLocationStaffIdPostCount(
    id: string,
    locations:any,
    includeParents:boolean,
    parentsOnly:boolean,
    grade:string): Observable<any> {
    let locs = locations.map((l)=>`location=${l}`)
    const path = `${this.baseUrl}/v1/staff/${id}/students-count`;
    return this.api.post(path,{
      Location: locations,
      Parents: includeParents,
      ParentsOnly: parentsOnly,
      grade:grade

    }).pipe(map((response) => response as any));
  }
  public getStudentsByLocationStaffIdPostCountPreview(
    id: string,
    locations:any,
    includeParents:boolean,
    parentsOnly:boolean,
    grade:string): Observable<any> {
    let locs = locations.map((l)=>`location=${l}`)
    const path = `${this.baseUrl}/v1/staff/${id}/students-count-preview`;
    return this.api.post(path,{
      Location: locations,
      Parents: includeParents,
      ParentsOnly: parentsOnly,
      grade:grade

    }).pipe(map((response) => response as any));
  }
  public getStudentsByLocationStaffIdPost(id: string,locations:any, includeParents:boolean, parentsOnly:boolean): Observable<any> {
    let locs = locations.map((l)=>`location=${l}`)
    const path = `${this.baseUrl}/v1/staff/${id}/students`;
    return this.api.post(path,{
      Location: locations,
      Parents: includeParents,
      ParentsOnly: parentsOnly

    }).pipe(map((response) => response as any));
  }
  public getSchoolRoomsByStaffId(id: string): Observable<any> {
    const path = `${this.baseUrl}/v1/staff/${id}/schoolRooms`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getContacts(id: string): Observable<ContactModel[]> {
    const path = `${this.baseCustomerUrl}/v1/staff/${id}/contacts`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  setAvailability(request: any): Observable<any> {
    const userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/quiethours/${userId}/availability`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }
}

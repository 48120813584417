import { StaffSettingsService } from "./../../services/staff-settings.service";
import { IEmployeeContact } from "./../../services/parent.services.models";
import { ParentsService } from "src/app/shared/services/parents.service";
import { ChatService } from "./../../services/chat.service";
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SettingsService } from "src/app/core/settings/settings.service";
import * as _ from "lodash";
import { ProfileService } from "../../services/profile.service";
import { StudentsService } from "../../services/students.service";
import { Subscription, timer } from "rxjs";

@Component({
  selector: "app-mailbox-folder-widget",
  templateUrl: "./mailbox-folder-widget.component.html",
  styleUrls: ["./mailbox-folder-widget.component.scss"],
})
export class MailboxFolderWidgetComponent implements OnInit, OnDestroy {
  mails: Array<any> = [];
  conversation: any = [];
  sub: Subscription;
  intervals: any = [];
  selectedId: any = null;
  account: any = null;
  lng: any = "en";
  contactId: any = null;
  messageReceived: any;
  selectedContact: IEmployeeContact;
  role: any = null;
  userId: any = null;
  secondContact: any = null;
  secondRole: any = null;
  me: string;
  displayName: string;
  selectedPicture: string = null;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    private chatService: ChatService,
    private parentService: ParentsService,
    private settings: SettingsService,
    private profile: ProfileService,
    private cdref: ChangeDetectorRef,
    private profileService: ProfileService,
    private staffSettingsService: StaffSettingsService,
    private studentServices: StudentsService
  ) {}
  ngAfterViewInit() {}
  ngOnInit(): void {
    this.account = this.profileService.identity.userIdRole;
    this.role = this.profile.role;
    this.userId = this.profileService.identity.userIdRole;
    this.lng = this.profile.profile.language || this.lng;
    this.route.params.subscribe((params) => {
      let id = params["folder"];
      if (id == "inbox") return;
      let contactId = params["contactId"];
      if (contactId) {
        this.selectedId = contactId;

        this.conversation[this.selectedId] = {
          messages: [],
          participants: [
            {
              username: contactId,
            },
            {},
          ],
        };
      } else {
        this.selectedId = params["folder"];
        this.chatService.newCurrentConversation(this.selectedId);
        this.chatService.setActiveConversationId(this.selectedId);
      }
    });

    this.chatService.currentConversation.subscribe((conversation) => {
      if (conversation?.messages) {
        this.getName(conversation);
        this.getNotAvailable(conversation);
      }
      if (conversation) {
        this.chatService.chatCache[conversation.id] = conversation;
        this.conversation[conversation.id] = conversation;
      }
    });

    const source = timer(1000, 15000);

    this.sub = source.subscribe((val) => {
      if (this.selectedId && !this.router.url.includes("compose")) {
        this.chatService.loadConversation(this.selectedId);
      }
    });
  }

  getName(conversation) {
    if (conversation) {
      let userName = null;
      conversation.participants.forEach((participant) => {
        if (participant.username !== this.profileService.identity.userIdRole) {
          userName = participant.username;
        }
      });

      this.displayName = userName;
    }
  }
  getNotAvailable(conversation) {
    var participant = conversation.participants.filter(
      (p) => p.username != this.userId
    );
    if (participant.length > 0) {
      var testUser = participant[0];
      if (testUser.username.length == 8) {
        this.staffSettingsService
          .silentSettings(testUser.username)
          .subscribe((quietSettings) => {
            this.isNotAvailable = !quietSettings.isAvailable;
          });
      } else {
        this.isNotAvailable = false;
      }
    }
  }
  isNotAvailable = false;
  getImage(id0, id1) {
    if (this.userId) {
      let you: string;
      if (id0.toString() === this.userId.toString()) {
        this.me = id0;
        you = id1;
      } else {
        this.me = id1;
        you = id0;
      }
    }
  }

  getUserId(conversation) {
    let userId: string = null;
    if (this.userId == conversation.participants[0].username) {
      userId = conversation.participants[1].username;
    } else {
      userId = conversation.participants[0].username;
    }
    return userId;
  }

  getUserTitle(user) {
    let userTitle = user.username;
    if (user.userDetails.firstName) {
      userTitle = `${user.userDetails.firstName}`;
    }
    if (user.userDetails.lastName) {
      userTitle = `${user.userDetails.lastName}, ${userTitle}`;
    }
    return userTitle;
  }

  noMessages() {
    return this.conversation[this.selectedId].messages.length == 0;
  }

  ngOnDestroy(): void {
    if (this.messageReceived)
      if (this.messageReceived.unsubscribe) {
        this.messageReceived.unsubscribe();
      }
    this.chatService.setActiveConversationId(null);
    this.chatService.resetCurrentConversation();
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.cdref.detach();
  }
}

import { AppState } from "./../app.state";
import {
  createAction,
  createFeature,
  createReducer,
  createSelector,
  on,
  props,
} from "@ngrx/store";

const LoggedIn = createAction(
  "[USER] Logged In",
  props<{ loggedIn: boolean }>()
);
const LoggedInSuccess = createAction("[USER] Logged In Success");
const LoggedInFail = createAction("[USER] Logged In Fail");
const LoadUserProfile = createAction(
  "[USER] Load User Profile",
  props<{ userProfile: any; userClaims: any }>()
);

export const UserActions = {
  LoggedIn,
  LoggedInSuccess,
  LoggedInFail,
  LoadUserProfile,
};

export interface UserState {
  profile: any;
  userClaims: any;
  isLoggedIn: boolean;
}

const initialState: UserState = {
  profile: {},
  userClaims: {},
  isLoggedIn: false,
};

export const UsersFeature = createFeature({
  name: "user",
  reducer: createReducer(
    initialState,
    on(UserActions.LoggedIn, (state, { loggedIn }) => {
      return {
        ...state,
        isLoggedIn: loggedIn,
      };
    }),
    on(UserActions.LoadUserProfile, (state, { userProfile, userClaims }) => {
      return {
        ...state,
        profile: userProfile,
        userClaims,
      };
    })
  ),
});

const userSelect = (state: AppState) => state.user;
const UserIsLoggedSelect = createSelector(
  userSelect,
  (state: UserState) => state.isLoggedIn
);
const ActiveProfileSelect  = createSelector(
  userSelect,
  (state:UserState) => state.profile
)
export const UserSelectors = { UserIsLoggedSelect, ActiveProfileSelect };

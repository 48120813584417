import { OperationsService } from './../../shared/services/operations.service';
import { ProfileService } from './../../shared/services/profile.service';
import { UserVoiceService } from './../../shared/services/user-voice.service';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AlertsService } from '../../shared/services/alerts.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent implements OnInit {
  points: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  done = false;
  submitted = false;
  @Output() close = new EventEmitter();

  isHovering = false;
  hoveredIndex: number | null = null;
  clickedIndices: number | null = null;

  onMouseEnter() {
    this.isHovering = true;
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  constructor(
    private userVoiceService: UserVoiceService,
    private profileService: ProfileService,
    private toast: ToastrService,
    private opServices: OperationsService

  ) {}

  ngOnInit() {}

  giveFeedback(value) {
    this.clickedIndices = value;
    this.submitted = true;

    value = value + 1;  

    const request = {
      reqType: 'nps',
      email: this.profileService.profile.email,
      comment: '',
      rating: value,
      app: `LAUSD APP: ${this.profileService.profile.role }`,
      language: this.profileService.profile.language,
    };
    this.opServices.sendFeedback(request).subscribe(
      (result) => {
        this.toast.success('Successfully submitted feedback');
        this.done = true;
      },
      (error) => {
        this.toast.error(error);
      }
    );
  }

  onClose() {
    this.close.emit(true);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { StudentFriendsModel } from './models/StudentFriendsModel';

@Component({
  selector: 'app-student-friends-widget',
  templateUrl: './student-friends-widget.component.html',
  styleUrls: ['./student-friends-widget.component.scss']
})
export class StudentFriendsWidgetComponent implements OnInit {

  @Input() model: StudentFriendsModel = null;

  //async component
  loaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

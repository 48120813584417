import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { GroupsService } from '../../services/groups.service';
import { StudentsService } from '../../services/students.service';
import { ClassAction, ClassDetails } from '../class-details-widget/models/ClassDetails';


export interface GroupDetails {
  id: string;
  name: string;
  students: number;
  points: number;
}

@Component({
  selector: 'app-class-management-group-modal',
  templateUrl: './class-management-group-modal.component.html',
  styleUrls: ['./class-management-group-modal.component.scss']
})
export class ClassManagementGroupModalComponent implements OnInit {

  @Input() schoolRoom: ClassDetails = null;
  @Input() students: any = [];
  @Input() groups: GroupDetails[] = [];
  selectedGroup = null;

  classTitle: string = '';
  studentsGroup = [];
  groupName = null;
  displayedColumns: string[] = ['name', 'students', 'points', 'action'];

  constructor(
    public dialogRef: MatDialogRef<ClassManagementGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private groupService: GroupsService,
    private studentServices: StudentsService
  ) {
    this.students = this.data.students;
    this.groups = this.data.groups;
    this.classTitle = this.data.classTitle;
  }

  ngOnInit(): void { }

  editGroup(group) {
    const action: ClassAction = {
      target: group,
      command: 'editGroup'
    };
    this.dialogRef.close(action);
  }
  deleteGroup(group) {
    const action: ClassAction = {
      target: group,
      command: 'deleteGroup'
    };
    this.dialogRef.close(action);
  }

  onClose() {
    this.dialogRef.close();
  }


  createGroup() {
    const action: ClassAction = {
      target: null,
      command: 'editGroup'
    };
    this.dialogRef.close(action);
  }
}

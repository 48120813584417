import { AvatarFallbackNameWidgetComponent } from "./../../avatar-fallback-name-widget/avatar-fallback-name-widget.component";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, fromEvent, of, Subject, timer } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  flatMap,
  map,
} from "rxjs/operators";
import { ChatService } from "src/app/shared/services/chat.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { StudentsService } from "src/app/shared/services/students.service";
import { AvatarFallbackWidgetComponent } from "../../avatar-fallback-widget/avatar-fallback-widget.component";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsService } from "../../../services/analytics.service";

@Component({
  selector: "app-chat-area",
  templateUrl: "./chat-area.component.html",
  styleUrls: ["./chat-area.component.scss"],
})
export class ChatAreaComponent implements OnInit {
  @ViewChild("avatarImage") private avatarImage: AvatarFallbackWidgetComponent;
  @ViewChild("avatarName")
  private avatarName: AvatarFallbackNameWidgetComponent;
  @ViewChild("chatScroll") private chatScrollContainer: ElementRef;

  @Input() conversation;
  @Input() displayName;
  @Input() isNotAvailable;
  @Input() account;
  @Input() lng;
  @Input() selectedId;
  @Input() userId;
  currentUserId;
  recipientId;
  message: any = "";
  checkSameData;
  checkExitstUser: string;
  public keyUp = new Subject<any>();
  avatar = {
    id: null,
  };
  groupedConversation;
  obj = Object;

  constructor(
    private chatService: ChatService,
    private profileService: ProfileService,
    private studentServices: StudentsService,
    private cdRef: ChangeDetectorRef,
    public route: ActivatedRoute,
    public router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    const subscription = this.keyUp
      .pipe(
        map((event) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap((search) => of(search))
      )
      .subscribe((search) => {});
    this.markRead();
  }

  markRead() {
    this.conversation.messages.forEach((msg) => {
      if (
        msg.username !== this.profileService.identity.userIdRole &&
        msg.seen === false
      ) {
        msg.seen = true;
        this.chatService
          .readMessage(msg.id, this.profileService.identity.userIdRole, {})
          .subscribe(
            () => {},
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }
  ngAfterViewInit() {
    const scrollSource = fromEvent(
      this.chatScrollContainer.nativeElement,
      "scroll"
    );
    const scrollEventAction = scrollSource.pipe(
      map((event) => {
        let res =
          (event as any).target.scrollHeight -
            20 -
            (event as any).target.clientHeight <=
          (event as any).target.scrollTop * -1;
        return res;
      }),
      debounceTime(500)
    );
    const subscribe = scrollEventAction.subscribe((val) => {
      if (!val) return;

      const msgs = this.conversation.messages;
      var lastMsg = msgs[msgs.length - 1];

      this.chatService
        .getMessagesFromId(this.conversation.id, lastMsg.id)
        .toPromise()
        .then((response) => {
          for (var id in response.messages) {
            var msg = response.messages[id];
            this.conversation.messages.push(msg);
          }
        });
    });
  }

  ngOnChanges() {
    this.avatar = null;
    this.conversation.participants.forEach((participant) => {
      if (participant.username) {
        if (participant.username !== this.userId) {
          this.recipientId = participant.username;
        } else {
          this.currentUserId = participant.username;
        }
      }
    });
    setTimeout(() => {
      this.avatarImage.ngOnInit();
      this.avatarName.ngOnInit();
    }, 100);

    // this.profileService
    //   .silentProfileById(this.recipientId)
    //   .subscribe((profile) => {
    //     if (profile) {
    //       if (profile.role === "Student") {
    //         this.loadStudentImage();
    //       } else {
    //         this.imgEmitter$.next(profile.picture);
    //       }
    //     }
    //   });

    //this.scrollToBottom();

    this.markRead();

    var result = _(this.conversation.messages)
      .groupBy((v) => moment(v.created).format("MM-DD-YYYY"))
      .mapValues((v) => _.map(v))
      .value();
    this.groupedConversation = result;
  }

  checkCreatedDate(getIndex: number, getDate: any, getData: any) {
    if (getIndex === 0) {
      this.checkSameData = "";
    }

    const getNewDate = moment(getDate).format("M-d-Y");
    if (this.checkSameData === getNewDate) {
      return false;
    } else {
      this.checkSameData = getNewDate;
      return true;
    }
  }

  checkExistingUser(getIndex: number, getUsername: string, getDate: any) {
    if (getIndex === 0) {
      this.checkExitstUser = "";
    }

    const getNewDate = moment(getDate).format("M-d-Y");
    const mergeUserAndDate = getUsername + getNewDate;
    if (this.checkExitstUser === mergeUserAndDate) {
      return false;
    } else {
      this.checkExitstUser = mergeUserAndDate;
      return true;
    }
  }

  readMessage(message, lng) {
    let sourceLng = null;
    let clientLng = null;
    for (var key in message) {
      let msg = message[key];
      if (msg.isSource) sourceLng = msg.message;
      if (key == lng) clientLng = msg.message;
    }
    return clientLng || sourceLng;
  }

  send() {
    if (this.message === "") {
      return false;
    }
    this.analytics.sendEvent("User Click", "Send Message");
    let msg = this.message;
    if (this.conversation.messages.length == 0) {
      this.chatService
        .addConversation(this.selectedId, msg)
        .subscribe((response) => {
          this.message = "";
          // this.chatService.refreshInbox(response);
          this.router.navigate([`../../folder/${response[0].conversationId}`], {
            relativeTo: this.route,
          });
          this.chatService.loadConversations();
        });
      this.markRead();
    } else {
      const totalLength = this.conversation.messages.length;

      let clientMsg = {
        isSource: true,
      };
      clientMsg[this.lng] = { message: msg };
      let dates = this.obj.keys(this.groupedConversation);
      this.groupedConversation[dates[0]].splice(0, 0, {
        message: clientMsg,
        created: new Date(),
        username: this.account,
      });
      this.chatService.postMessage(this.selectedId, msg).subscribe(
        (response) => {},
        (error) => {}
      );
      this.message = "";
    }
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        if (this.chatScrollContainer?.nativeElement) {
          this.chatScrollContainer.nativeElement.scrollTop =
            this.chatScrollContainer.nativeElement.scrollHeight;
        }
      });
    } catch (err) {}
  }
}

import { IResourceCenterTopic } from 'src/app/shared/components/resource-center-widget/models/IResourceCenter';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resource-center-details-widget',
  templateUrl: './resource-center-details-widget.component.html',
  styleUrls: ['./resource-center-details-widget.component.scss']
})
export class ResourceCenterDetailsWidgetComponent implements OnInit {

  @Input() resource: IResourceCenterTopic = null
  @Output() selectedItem = new EventEmitter<IResourceCenterTopic>()
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  goBack(){
    this.router.navigate(['/parent/resource-center']);
  }
}

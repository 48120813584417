import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.facilitiesServices; //"https://localhost:44315"
  parentId: string = null;
  parentUsername: string = null;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private oauth: OAuthService
  ) {
    let identity = this.oauth.getIdentityClaims() as any;
  }


  getSchoolsById(schoolIds:string[]) : Observable<any> {
    let idMap = schoolIds.map(a=>`schoolIds=${a}`).join('&');
    let path = `${this.baseUrl}/v1/school/filter?${idMap}`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
}

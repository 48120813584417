import { FontawesomeService } from "./../../../../../shared/services/fontawesome.service";
import {
  Component,
  OnInit,
  Inject,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  AfterViewInit,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { FontIconComponent } from "./font-icon.component";
import * as _ from "lodash";

@Component({
  selector: "app-new-expectation-modal",
  templateUrl: "./new-expectation-modal.component.html",
  styleUrls: ["./new-expectation-modal.component.scss"],
})
export class NewExpectationModalComponent implements OnInit {
  title = "";
  description = "";
  selectedIcon: any;
  points: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  point: any = "0";
  dateCreated: any = "";
  instances = 0;
  editMode = false;
  originalExpectation: any = null;

  bgColor = null;
  bgBadge = null;
  badgeList: any[] = ["circle", "heart", "shield"];
  iconList = [];
  preview = {
    badge: null,
    badgeColor: null,
    icon: null,
    iconColor: null,
  };

  // @ViewChild("fontIcon", { read: ViewContainerRef }) fontIcon: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<NewExpectationModalComponent>,
    private fontawesomeService: FontawesomeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.editMode = true;
      this.originalExpectation = this.data.expectation;
      this.selectedIcon = this.originalExpectation.icon;
      this.title = this.originalExpectation.name;
      this.dateCreated = this.originalExpectation.dateCreated;
      this.description = this.originalExpectation.description;
      this.point = this.originalExpectation.points;
      this.instances = this.originalExpectation.instances;
    }
  }

  ngOnInit(): void {
    this.iconList = [...this.fontawesomeService.iconList].splice(0, 8);
    if (this.data) {
      this.preview.badge = this.data.expectation.badge;
      this.preview.badgeColor = this.data.expectation.badgeColor;
      this.preview.icon = this.data.expectation.icon;
      this.preview.iconColor = this.data.expectation.iconColor;
    } else {
      this.preview = {
        badge: this.badgeList[0],
        badgeColor: "#1976D2",
        icon: this.iconList[0],
        iconColor: "#37474F",
      };
    }
  }

  searchIcon(event) {
    const input = event.currentTarget as HTMLInputElement;
    if (input.value) {
      this.iconList = [];
      this.fontawesomeService.iconList.forEach((element) => {
        if (element[1]?.includes(input.value.toLowerCase())) {
          if (this.iconList.length < 28) {
            this.iconList.push(element);
          }
        }
      });
    } else {
      this.iconList = [...this.fontawesomeService.iconList].splice(0, 28);
    }
  }

  // ngAfterViewInit(): void {
  //   if (this.editMode) {
  //     setTimeout(() => {
  //       const resolver = this.resolver.resolveComponentFactory(
  //         FontIconComponent
  //       );
  //       this.fontIcon.clear();
  //       const compRef = this.fontIcon.createComponent(resolver);
  //       const myInstance = compRef.instance as FontIconComponent;
  //       myInstance.iconName = this.selectedIcon;
  //     }, 0);
  //   }
  // }

  changeBadge(badge: string) {
    this.preview.badge = badge;
  }

  changeBadgeColor(color: string) {
    this.preview.badgeColor = color;
  }

  changeIconColor(color: string) {
    this.preview.iconColor = color;
  }

  changeIcon(icon: string) {
    this.preview.icon = icon;
  }

  get saveDisable() {
    return this.point === "Points:" || !this.title || !this.description;
  }

  save() {
    let dateModified: any = new Date();
    if (!this.editMode) {
      this.dateCreated = new Date();
      dateModified = null;
    }
    const newExpectation = {
      icon:
        typeof this.preview.icon === "string"
          ? this.preview.icon
          : this.preview.icon[1],
      name: this.title,
      description: this.description,
      enabled: true,
      points: parseInt(this.point),
      dateCreated: this.dateCreated,
      modified: dateModified,
      id:
        this.originalExpectation?.id == null
          ? null
          : this.originalExpectation.id,
      badge: this.preview.badge,
      iconColor: this.preview.iconColor,
      badgeColor: this.preview.badgeColor,
    };
    console.log(newExpectation);
    this.dialogRef.close(newExpectation);
  }

  close() {
    this.dialogRef.close();
  }
}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CertificateService } from 'src/app/shared/services/certificate.service';
import { ViewportScroller } from '@angular/common';
import { ChecklistService } from 'src/app/shared/services/checklist.service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private certificateServices: CertificateService,
    private scroller: ViewportScroller,
    private checklistService: ChecklistService
  ) { }

  checklistTitle = '';
  headerUrl: any;
  checklistColor: any;
  completionDate: Date | string;
  selectedQuestions = [];
  questionNum = 0;
  settings: any;
  dueDate: any;
  percentComplete: any;

  index = 0;
  total: number;
  responses: any[] = [];
  percent: number = 0;
  percentArray: any[] = [];
  studentId = null;
  checklistId = null;
  answered = [];

  get isComplete() : boolean{
    return this.selectedQuestions.filter(c=>(c.answer == false || c.answer ==undefined)).length == 0
  }
  ngOnInit(): void {
    this.route.params.subscribe((p) => {
      this.studentId = this.route.parent.snapshot.params.studentId;
      this.checklistId = p.checklistId;
      this.certificateServices.getThumbnails().subscribe((settings)=>{
        this.settings = settings;
      })
      this.checklistService.getChecklistAnswers(this.checklistId, this.studentId).subscribe((answers) => {

        if(answers.response != null){
          this.percentComplete = answers.response.percent;
        }
        if(answers.response == null){
          this.percentComplete = 0;
        }
        if(answers.response == null){
          this.checklistService.getChecklistById(this.checklistId).subscribe((checklist) => {
            this.headerUrl = checklist.headerUrl;
            this.checklistColor = checklist.color;
            this.checklistTitle = checklist.name;
            this.dueDate = checklist.dueDate;
            this.selectedQuestions = checklist.questions;
            this.percent = 0;
            this.initQuestion();
          });
        }else{
          this.headerUrl = answers.response.headerUrl;
            this.checklistColor = answers.response.color;
            this.checklistTitle = answers.response.name;
            this.dueDate = answers.response.dueDate;
            this.selectedQuestions = answers.response.selectedQuestions;
            this.percent = 0;
            this.initQuestion();
        }

        this.scrollToChecklist();
      });

    });
  }

  scrollToChecklist(){
    document.getElementById("student-answer").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  initQuestion() {
    this.questionNum = this.selectedQuestions?.length;
    this.selectedQuestions.forEach(element => {
      if (element.answer && element.answer == true) {
        this.percentArray.push(1);
      } else {
        this.percentArray.push(0);
      }
    });
  }

  getQuestions(checklist: any) {
    var qstns = checklist.questions;
    var finalQstns = [];
    checklist.questions.forEach(element => {
      finalQstns.push({
        id: element,
        question: qstns[element],
        answer: ''
      });
      this.answered[element] = null;
    });
    return finalQstns;
  }

  nextStudent() {
    --this.index;
  }
  prevStudent() {
    ++this.index;
  }

  qChange(item, idx) {
    if (item.answer) {
      this.percentArray[idx] = 1;
    } else {
      this.percentArray[idx] = 0;
    }
    const total = this.questionNum;
    let cal = 0;
    console.log('here-- percentArray', this.percentArray, total);
    this.percentArray.forEach(val => {
      cal = cal + val;
    });
    this.percent = Math.trunc((cal / total) * 100);
    console.log(this.percent)
  }

  cancel() {
    this.router.navigate(['student/profile', this.studentId, 'checklists']);
  }
  saveProgress() {
    // this.surveyService.saveProgress().subscribe(()=>{
    //   this.router.navigate(['/student/profile',this.studentId,'surveys'])
    // })
  }

  save() {
    const checklistModel = {
      headerUrl: this.headerUrl,
      color: this.checklistColor,
      name: this.checklistTitle,
      selectedQuestions: this.selectedQuestions,
      percent: this.percent
    };
    this.checklistService.saveAnswers(this.checklistId, this.studentId, checklistModel).subscribe((res) => {
      this.toastr.success('Checklist Updated');
      this.router.navigate(['student/profile', this.studentId, 'checklists']);
    }, (err) => {
      this.toastr.error('Error');
      console.log('here- Error From Res', err );
    });
  }
  send() {
    const checklistModel = {
      headerUrl: this.headerUrl,
      color: this.checklistColor,
      name: this.checklistTitle,
      selectedQuestions: this.selectedQuestions,
      percent: 100
    };
    this.checklistService.saveAnswers(this.checklistId, this.studentId, checklistModel).subscribe((res) => {

        this.toastr.success('Checklist Submitted');
        this.router.navigate(['student/profile', this.studentId, 'checklists']);


    }, (err) => {
      this.toastr.error('Error');
      console.log('here- Error From Res', err );
    });
  }
}

import { ProfileService } from "src/app/shared/services/profile.service";
import { ToastrService } from "ngx-toastr";
import { ExpectationsService } from "./../../services/expectations.service";
import { Router } from "@angular/router";
import { StudentDetailsModel } from "./../student-details-widget/models/StudentDetailsModel";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ClassAction } from "../class-details-widget/models/ClassDetails";
import { MatDialog } from "@angular/material/dialog";
import { NewExpectationModalComponent } from "src/app/routes/teacher/expectations-route/expectations-page/new-expectation-modal/new-expectation-modal.component";
import { ReinforcementDetails } from "../add-reinforcement-modal/models/ReinforcementDetails";

@Component({
  selector: "app-class-reinforcements-widget",
  templateUrl: "./class-reinforcements-widget.component.html",
  styleUrls: ["./class-reinforcements-widget.component.scss"],
})
export class ClassReinforcementsWidgetComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private expectationService: ExpectationsService,
    private toastr: ToastrService,
    private profileService: ProfileService
  ) {}

  @Input() student: StudentDetailsModel = null;
  @Input() expectations: ReinforcementDetails[] = [];
  @Output() onAction = new EventEmitter<ClassAction>();

  ngOnInit(): void {
    let staffId = this.profileService.identity.userIdRole;
    this.expectationService
      .getExpectations(staffId)
      .subscribe((expectations) => {
        this.expectations = expectations;
        console.log(this.expectations, "=========exp=======");
      });
  }

  addReinforcement(item) {
    const action: ClassAction = {
      target: {
        expectation: item,
      },
      command: "addReinforcement",
    };
    this.onAction.emit(action);
  }

  onClose() {
    const action: ClassAction = {
      target: "",
      command: "closeReinforcement",
    };
    this.onAction.emit(action);
  }

  addExpectation() {
    this.dialog
      .open(NewExpectationModalComponent, {
        disableClose: true,
        width: "70%",
        maxHeight: "80vh",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          // FIXME: add to list with API
          const newExpectation = {
            description: res.description,
            id: "res", // TODO: confirm
            name: res.name,
            points: res.points,
            icon: res.icon,
            staffId:this.profileService.identity.userIdRole
          };
          this.expectations.push(newExpectation);
          this.toastr.success("New Expectation Added");
        }
      });
  }

  gotoExpectations() {
    this.router.navigate(["/teacher/expectations"]);
  }
}

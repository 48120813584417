import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from './message-dialog.interface';
import { of, Subject } from "rxjs";
import { debounceTime, delay, distinctUntilChanged, flatMap, map, } from "rxjs/operators";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleServiceFactoryService } from 'src/app/shared/services/role-service-factory.service';

@Component({
  selector: 'app-mailbox-contacts-dialog',
  templateUrl: './mailbox-contacts-dialog.component.html',
  styleUrls: ['./mailbox-contacts-dialog.component.scss']
})
export class MailboxContactsDialogComponent implements OnInit {
  contact_list: any;
  public keyUp = new Subject<any>();
  constructor(
    public dialogRef: MatDialogRef<MailboxContactsDialogComponent>,
    public rs: RoleServiceFactoryService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.contact_list = JSON.parse(JSON.stringify(this.data.contact_list));
    console.log(this.contact_list, 'this.contact_list CONTACTS====')

    

    
    this.keyUp
      .pipe(
        map((event) =>  event.target.value),
        debounceTime(100),
        distinctUntilChanged(),
        flatMap((search) => of(search))
      ).subscribe((search) => {
        if (search.length == 0) {
          this.contact_list = JSON.parse(JSON.stringify(this.data.contact_list));
        } else {
          this.contact_list = _.filter(this.data.contact_list, (c) => {
            let key = (c.empLastName+' '+c.empFirstName).toLowerCase();
            search = search.toLowerCase();
            return key.indexOf(search) >= 0;
          });
        }
      });
  }

  closeContactList() {
    this.dialogRef.close();
  }

  startChat(contact:any){
    this.dialogRef.close(contact);
    // this.router.navigate(['compose', contact.emailAddressTxt],{
    //   relativeTo: this.route})
  }

}

import { IStudentDetails } from './../student-list-widget/models/IStudentDetails';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-student-management-widget',
  templateUrl: './student-management-widget.component.html',
  styleUrls: ['./student-management-widget.component.scss']
})
export class StudentManagementWidgetComponent implements OnInit {

  @Input() action: string = null;
  @Output() onChange = new EventEmitter<any>();
  @Input() student: IStudentDetails = null;
  modalTitle:string = "Verify/Add a Student"
  registerModel = {
    studentId:'',
    pinCode:'',
    birthDate:'',
    recaptchaReactive: ''
  }
  captcha = null;
  constructor() { }

  ngOnInit(): void {
    this.captcha = localStorage.getItem('app_captcha');
    if(this.student){
      this.modalTitle = 'Verify your student'
      this.registerModel.studentId = this.student.studentId;
    }
  }
  cancel(){
    this.onChange.emit({cmd:'cancel'})
  }
  remove(){
    this.onChange.emit({cmd:'remove'})
  }
  add(){
    this.onChange.emit({cmd:'add',registerModel:this.registerModel})
  }

  resolved(captchaResponse: string) {
   this.registerModel.recaptchaReactive = captchaResponse;
  }
}

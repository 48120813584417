import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from './api.service';
import { map } from "rxjs/operators";
import { Observable } from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class EventsAttendanceService {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.communicationsServices; //"https://localhost:44315"


  constructor(
    private appConfig: AppConfigService,
    private api: ApiService
  ) { }

  getQrByEventId(id: string): Observable<any> {
    const path = `${this.baseUrl}/v1/attendance/${id}/qr-code`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any[]));
  }

  getAttendanceById(id:string): Observable<any> {
    const path = `${this.baseUrl}/v1/attendance/${id}/analytics`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any[]));
  }
  getCheckInStatus(eventId: any, usernameNo: string) {
    const path = `${this.baseUrl}/v1/attendance/${eventId}/attendance/${usernameNo}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any));
  }

  getAttendanceStatusById(id:string,userId:string): Observable<any> {
    const path = `${this.baseUrl}/v1/attendance/${id}/attendance/${userId}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any[]));
  }

  checkinEvent(id:string, request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/attendance/${id}/check-in`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  checkinEventExternal(id:string, request: any): Observable<any> {
    const path = `${this.baseUrl}/v1/attendance/${id}/check-in`;
    return this.api
      .post(path, request)
      .pipe(map((response) => response as any));
  }

  getEventDetailsById(id:string): Observable<any> {
    const path = `${this.baseUrl}/v1/attendance/${id}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any[]));
  }



}

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { catchError, switchMap, zip } from "rxjs/operators";
import { GroupsService } from "../../services/groups.service";
import { StudentsService } from "../../services/students.service";
import {
  ClassAction,
  ClassDetails,
} from "../class-details-widget/models/ClassDetails";

export interface GroupDetails {
  id: string;
  name: string;
  students: number;
  points: number;
}

@Component({
  selector: "app-class-create-group-modal",
  templateUrl: "./class-create-group-modal.component.html",
  styleUrls: ["./class-create-group-modal.component.scss"],
})
export class ClassCreateGroupModalComponent implements OnInit {
  @Input() schoolRoom: ClassDetails = null;
  @Input() students: any = [];
  @Input() groups: GroupDetails[] = [];

  studentsGroup = [];
  groupName = null;
  selectedGroup = null;
  selectedClass = null;
  studentCount = 0;
  isStudentSelected = false;
  classTitle: string = '';

  constructor(
    public dialogRef: MatDialogRef<ClassCreateGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private groupsService: GroupsService,
    private studentServices: StudentsService,
    private toastr: ToastrService
  ) {
    this.students = this.data.students;
    this.groups = this.data.groups;
    this.classTitle = this.data.classTitle;
    this.selectedGroup = this.data.selectedGroup;
    this.selectedClass = this.data.selectedClass;
  }

  ngOnInit(): void {
    if (this.selectedGroup) {
      if (this.selectedGroup.studentsCount > 0) {
        this.isStudentSelected = true;
      }
      this.editGroupInit();
    } else {
      this.students.forEach((element) => {
        element.selected = false;
      });
    }
  }

  onClickSave() {
    if (!this.groupName) {
      this.toastr.error("Please add a valid group title");
      return
    }
    if (!this.groupName.replace(/\s/g, '').length) {
      this.toastr.error("Please add a valid group title");
      return
    }
    if (this.selectedGroup) {
      const groupItem = {
        id: this.selectedGroup.id,
        groupName: this.groupName,
        students: this.studentsGroup,
      };
      this.editGroup(groupItem);
    } else {
      const groupItem = {
        groupName: this.groupName,
        students: this.studentsGroup,
      };
      this.createGroup(groupItem);
    }
  }

  onClose() {
    this.dialogRef.close();
  }


  createGroup(groupItem: any) {
    const groupDetails = {
      name: groupItem.groupName,
      description: "",
      isDynamic: false,
      filter: null,
      staticList: groupItem.students,
      schoolRoomId: this.selectedClass.id,
      gradeId: this.selectedClass.grade,
    };

    this.groupsService
      .createSchoolRoomGroup(groupDetails)
      .pipe(
        catchError((error) => {
          console.log(error);
          this.toastr.error("Creating Group Failed");
          if (error.status === 409){
            this.toastr.error("Duplicate group name. Please try again.");
          }
          throw error;
        }),
        switchMap(() => {
          return this.groupsService.getGroupsBySchoolRoomAndGrade(
            this.selectedClass.id,
            this.selectedClass.grade
          );
        })
      )
      .subscribe((groups) => {
        this.groups = groups;
        this.toastr.success(`Successfully created group ${groupDetails.name}`);
        const action: ClassAction = {
          target: groups,
          command: "groupEdited",
        };
        this.studentsGroup = [];
        this.groupName = null;
        this.dialogRef.close(action);
      });
  }

  editGroup(groupItem: any) {
    const groupDetails = {
      id: groupItem.id,
      name: groupItem.groupName,
      staticList: groupItem.students,
    };
    this.groupsService
      .editSchoolRoomGroup(groupDetails)
      .pipe(
        switchMap((group) => {
          const schoolRoomId = group.schoolRoomId;
          const gradeId = group.gradeId;
          return this.groupsService.getGroupsBySchoolRoomAndGrade(
            schoolRoomId,
            gradeId
          );
        })
      )
      .subscribe((groups) => {
        this.groups = groups;
        this.toastr.success(`Successfully edited group ${groupDetails.name}`);
        const action: ClassAction = {
          target: groups,
          command: "groupEdited",
        };
        this.studentsGroup = [];
        this.groupName = null;
        this.dialogRef.close(action);
      });
  }

  onSelectStudent(student: any) {
    let hasStudent = this.studentsGroup.find((c) => c == student.studentId);
    if (!hasStudent) {
      this.studentsGroup.push(student.studentId);
      student.selected = true;
      this.studentCount++;
    } else {
      _.remove(this.studentsGroup, (n) => n == student.studentId);
      student.selected = false;
      this.studentCount--;
    }
    this.checkSelected();
  }

  checkSelected() {
    if (this.studentsGroup.length > 0) {
      this.isStudentSelected = true;
    } else {
      this.isStudentSelected = false;
    }
  }

  editGroupInit() {
    this.groupsService
      .getStudentsByGroupId(this.selectedGroup.id)
      .subscribe((students) => {
        this.groupName = this.selectedGroup.name;
        students.forEach((s) => {
          const student = this.students.find((x) => x.studentId == s.studentId);
          if (student) {
            student.selected = true;
            this.studentsGroup.push(student.studentId);
          }
          this.studentServices
            .getPicture(student.studentId)
            .subscribe((imgResource) => {
              student.imgPath = imgResource.img;
            });
        });
      });
  }
}

import { HelpCenterService } from './../../../../shared/services/help-center.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentsService } from 'src/app/shared/services/students.service';
import * as m from 'moment';


@Component({
  selector: 'app-student-online-forms',
  templateUrl: './student-online-forms.component.html',
  styleUrls: ['./student-online-forms.component.scss']
})
export class StudentOnlineFormsComponent implements OnInit {

  links:string[] = ["Meal App URL","Athletic Link","Volunteer form URL","LAUSD School Choice Programs"]
  groups: any = null
  lastUpdated: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private helpCenter:HelpCenterService
  ) {}

  ngOnInit(): void {

    this.helpCenter.getLinks(this.links).subscribe((response)=>{
      this.groups = response;
      this.lastUpdated = m();
    });
  }


}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NA'
})
export class NAPipe implements PipeTransform {

  transform(value: any): any {
    let val = (value == undefined || value == '' || value == null) ? 'N/A' : value;
    return val;
  }

}

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { IStudentResume } from 'src/app/shared/components/student-list-widget/models/IStudentResume';
import { ISchoolInfo } from 'src/app/shared/components/student-list-widget/models/ISchoolInfo';
import { ActivatedRoute } from '@angular/router';
import { StudentsService } from 'src/app/shared/services/students.service';
import { ParentsService } from 'src/app/shared/services/parents.service';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { StaffFilesService } from 'src/app/shared/services/staff-files.service';

@Component({
  selector: 'app-student-timeline',
  templateUrl: './student-timeline.component.html',
  styleUrls: ['./student-timeline.component.scss']
})
export class StudentTimelineComponent implements OnInit {

  student: IStudentResume = null;
  schoolInfo: ISchoolInfo = null;
  assignments: any = [];
  lastUpdated: any;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private staffFileServices: StaffFilesService,
    private studentService: StudentsService  ) { }

  ngOnInit(): void {
    this.activeRoute.parent.params.subscribe((data) => {
      this.student = this.studentService.currentStudent();
      this.studentService.getStudentSchoolInfo(data.studentId).subscribe((schoolInfo) => {
        this.schoolInfo = schoolInfo;
        this.lastUpdated = new Date();
      });
      // this.studentService.getStudentAssignments().subscribe((assignments)=>{
      //   this.assignments = assignments
      // })
    });
  }
  command(evt) {
    switch(evt.cmd) {
      case 'download-file': this.download(evt.params);
    }
  }
  download(item){
    this.staffFileServices.getDownloadLink(item.studentId,item.id).subscribe((url)=>{
      if(url){
        window.open(url.downloadLink)
      }
    })
  }


}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StudentsService } from '../../services/students.service';
import { IStudentResume } from '../student-list-widget/models/IStudentResume';

@Component({
  selector: 'app-student-iap-button',
  templateUrl: './student-iap-button.component.html',
  styleUrls: ['./student-iap-button.component.scss']
})
export class StudentIapButtonComponent implements OnInit {

  @Input() letter = '';
  @Input() text = '';
  @Input() imgsrc = '';
  @Input() route = '';
  @Input() student: any = {};
  @Output() selected: EventEmitter<IStudentResume> = new EventEmitter();

  isLoading = false;
  showLastIcon = false;
  
  constructor(
    public studentService: StudentsService,
    private router: Router,
    
  ) { }

  ngOnInit(): void {
    this.loadData();

  }

  loadData() {
    
    if (this.student != null && this.text == "Attendance") {
      this.studentService.getStudentIAPAttendace(this.student.studentId).subscribe(
        (attendance) => {
          this.showLastIcon = attendance.showIcon;
        }        
      );
    }
  }
  
  async StudentIAP(student: any, route: string) {
    
    if (route == '')
      return

    if(this.checkEnrolled(student.enrollmentStatus)){
      this.selected.emit(student);
      this.router.navigateByUrl(`/parent/students/${student.studentId}/${route}`);      
    }  else {
      return;
    }
  }
  
  checkEnrolled(enrolled: string) {
    return enrolled === "Y" ? true : false;
  }

}

import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./../../services/app-config.service";
import { map, switchMap } from "rxjs/operators";
import { ProfileService } from "./profile.service";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppState } from "../store/app.state";
import { UserNotification } from "./chat.service";

@Injectable({
  providedIn: "root",
})
export class NotificationApiService {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.communicationsServices;
  constructor(
    private appConfig: AppConfigService,
    private profileService: ProfileService,
    private api: ApiService
  ) {}

  getNotifications(): Observable<Array<any>> {
    return this.profileService.user$.pipe(
      switchMap((user) =>
        this.getUserNotifications(user.id).pipe(
          map((notifications) => notifications)
        )
      )
    );
  }
  getUserNotifications(userId: string): Observable<UserNotification[]> {
    const path = `${this.baseUrl}/v1/notification/${userId}`;
    return this.api
      .getWithParams(path, { spinner: "no" })
      .pipe(map((response) => response as any));
  }
  dismissNotification(notificationId: string): Observable<any> {
    let userId = this.profileService.identity.userIdRole;
    const path = `${this.baseUrl}/v1/notification/${userId}/dismiss/${notificationId}`;
    return this.api
      .post(path, { spinner: "no" })
      .pipe(map((response) => response as any));
  }
}

import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import OSM from "ol/source/OSM";
import Point from "ol/geom/Point";
import * as olProj from "ol/proj";
import * as ol from "ol/proj";
import TileLayer from "ol/layer/Tile";
@Injectable({
  providedIn: "root",
})
export class BusService {
  tracking: Subject<any> = new Subject<any>();

  constructor(private client: HttpClient) {}

  startTracking(route: string) {
    throw new Error("Method not implemented.");
  }
  // getAddressLocation(address: string):Observable<GeocoderResult[]>{

  // return this.geocoder.geocode({address:address})
  // geocoder.geocode( { 'address': address}, function(results, status) {
  //   if (status == 'OK') {
  //     map.setCenter(results[0].geometry.location);
  //     var marker = new google.maps.Marker({
  //         map: map,
  //         position: results[0].geometry.location
  //     });
  //   } else {
  //     alert('Geocode was not successful for the following reason: ' + status);
  //   }
  // });

  // let encodedAddress = encodeURI(address)
  // let  nomination = `https://nominatim.openstreetmap.org/search.php?street=${encodedAddress}&polygon_geojson=1&format=jsonv2`
  // return this.client.get(nomination).pipe(map((response) => response as any))
  // }
  addBus(lat: number, lng: number, map: any) {
    this.addPoint(lat, lng, "assets/img/bus_icon.png", map);
  }
  addHome(lat: number, lng: number, map: any) {
    this.addPoint(lat, lng, "assets/img/pin2.png", map);
  }
  private addPoint(lat: number, lng: number, ico: string, map: any) {
    var vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [
          new Feature({
            geometry: new Point(
              olProj.transform([lng, lat], "EPSG:4326", "EPSG:3857")
            ),
          }),
        ],
      }),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          src: ico,
        }),
      }),
    });
    map.addLayer(vectorLayer);
  }
}

import { ChatService } from "src/app/shared/services/chat.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppConfigService } from "src/app/services/app-config.service";
import { ApiService } from "./api.service";
import { ProfileService } from "./profile.service";
import { RoleServiceFactoryService } from "./role-service-factory.service";
import { StaffSettingsService } from "./staff-settings.service";

@Injectable({
  providedIn: "root",
})
export class AbsentTardyService {
  notificationReceived: Subject<any> = new Subject<any>();
  constructor(
    private api: ApiService,
    private chatService: ChatService,
    private profileService: ProfileService,
    private staffSettingsService: StaffSettingsService,
    private rs: RoleServiceFactoryService
  ) {
    this.notificationReceived.subscribe((msg) => {
      this.chatService.loadNotifications();
      if (this.profileService.identity.userType === this.rs.TeacherRole) {
        if (this.staffSettingsService.isNotificationEnabled) {
          msg.sender.showInfoToast(msg.message.message);
        }
      } else {
        msg.sender.showInfoToast(msg.message.message);
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StaffFilesService {



  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; //"https://localhost:44315";
  constructor(
    private appConfig: AppConfigService,
    private api: ApiService) {
    this.api.baseUrl = this.baseUrl;
  }

  getFiles(staffId: string): Observable<any> {
    let path = `${this.baseUrl}/v1/staff/${staffId}/files`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
  getFile(staffId: string, fileId: any): Observable<any> {
    let path = `${this.baseUrl}/v1/staff/${staffId}/files/${fileId}`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
  getDownloadLink(studentId:string,fileId: string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/pbis/files/${fileId}`;
    return this.api.get(path).pipe(map((response)=> response as any));
  }
  delete(staffId: string,fileId: any) {
    let path = `${this.baseUrl}/v1/staff/${staffId}/files/${fileId}`;
    return this.api.delete(path).pipe(map((response)=> response as any));
  }
}

import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-expectation-icon-widget",
  templateUrl: "./expectation-icon-widget.component.html",
  styleUrls: ["./expectation-icon-widget.component.scss"],
})
export class ExpectationIconWidgetComponent implements OnInit {
  @Input() expectation;
  @Input() showPoint: boolean;
  @Input() timeline: boolean = false;
  @Input() student: boolean = false;
  constructor() {}

  ngOnInit() {}
}

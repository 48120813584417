import { Injectable } from '@angular/core';

@Injectable({
 providedIn: 'root'
})
export class FontawesomeService {
private icons = [
 ['fas' ,'abacus'],
['fas' ,'acorn'],
['fas' ,'ad'],
['fas' ,'address-book'],
['fas' ,'address-card'],
['fas' ,'adjust'],
['fas' ,'air-conditioner'],
['fas' ,'air-freshener'],
['fas' ,'alarm-clock'],
['fas' ,'alarm-exclamation'],
['fas' ,'alarm-plus'],
['fas' ,'alarm-snooze'],
['fas' ,'album'],
['fas' ,'album-collection'],
['fas' ,'alicorn'],
['fas' ,'alien'],
['fas' ,'alien-monster'],
['fas' ,'align-center'],
['fas' ,'align-justify'],
['fas' ,'align-left'],
['fas' ,'align-right'],
['fas' ,'align-slash'],
['fas' ,'allergies'],
['fas' ,'ambulance'],
['fas' ,'american-sign-language-interpreting'],
['fas' ,'amp-guitar'],
['fas' ,'analytics'],
['fas' ,'anchor'],
['fas' ,'angel'],
['fas' ,'angle-double-down'],
['fas' ,'angle-double-left'],
['fas' ,'angle-double-right'],
['fas' ,'angle-double-up'],
['fas' ,'angle-down'],
['fas' ,'angle-left'],
['fas' ,'angle-right'],
['fas' ,'angle-up'],
['fas' ,'angry'],
['fas' ,'ankh'],
['fas' ,'apple-alt'],
['fas' ,'apple-crate'],
['fas' ,'archive'],
['fas' ,'archway'],
['fas' ,'arrow-alt-circle-down'],
['fas' ,'arrow-alt-circle-left'],
['fas' ,'arrow-alt-circle-right'],
['fas' ,'arrow-alt-circle-up'],
['fas' ,'arrow-alt-down'],
['fas' ,'arrow-alt-from-bottom'],
['fas' ,'arrow-alt-from-left'],
['fas' ,'arrow-alt-from-right'],
['fas' ,'arrow-alt-from-top'],
['fas' ,'arrow-alt-left'],
['fas' ,'arrow-alt-right'],
['fas' ,'arrow-alt-square-down'],
['fas' ,'arrow-alt-square-left'],
['fas' ,'arrow-alt-square-right'],
['fas' ,'arrow-alt-square-up'],
['fas' ,'arrow-alt-to-bottom'],
['fas' ,'arrow-alt-to-left'],
['fas' ,'arrow-alt-to-right'],
['fas' ,'arrow-alt-to-top'],
['fas' ,'arrow-alt-up'],
['fas' ,'arrow-circle-down'],
['fas' ,'arrow-circle-left'],
['fas' ,'arrow-circle-right'],
['fas' ,'arrow-circle-up'],
['fas' ,'arrow-down'],
['fas' ,'arrow-from-bottom'],
['fas' ,'arrow-from-left'],
['fas' ,'arrow-from-right'],
['fas' ,'arrow-from-top'],
['fas' ,'arrow-left'],
['fas' ,'arrow-right'],
['fas' ,'arrow-square-down'],
['fas' ,'arrow-square-left'],
['fas' ,'arrow-square-right'],
['fas' ,'arrow-square-up'],
['fas' ,'arrow-to-bottom'],
['fas' ,'arrow-to-left'],
['fas' ,'arrow-to-right'],
['fas' ,'arrow-to-top'],
['fas' ,'arrow-up'],
['fas' ,'arrows'],
['fas' ,'arrows-alt'],
['fas' ,'arrows-alt-h'],
['fas' ,'arrows-alt-v'],
['fas' ,'arrows-h'],
['fas' ,'arrows-v'],
['fas' ,'assistive-listening-systems'],
['fas' ,'asterisk'],
['fas' ,'at'],
['fas' ,'atlas'],
['fas' ,'atom'],
['fas' ,'atom-alt'],
['fas' ,'audio-description'],
['fas' ,'award'],
['fas' ,'axe'],
['fas' ,'axe-battle'],
['fas' ,'baby'],
['fas' ,'baby-carriage'],
['fas' ,'backpack'],
['fas' ,'backspace'],
['fas' ,'backward'],
['fas' ,'bacon'],
['fas' ,'bacteria'],
['fas' ,'bacterium'],
['fas' ,'badge'],
['fas' ,'badge-check'],
['fas' ,'badge-dollar'],
['fas' ,'badge-percent'],
['fas' ,'badge-sheriff'],
['fas' ,'badger-honey'],
['fas' ,'bags-shopping'],
['fas' ,'bahai'],
['fas' ,'balance-scale'],
['fas' ,'balance-scale-left'],
['fas' ,'balance-scale-right'],
['fas' ,'ball-pile'],
['fas' ,'ballot'],
['fas' ,'ballot-check'],
['fas' ,'ban'],
['fas' ,'band-aid'],
['fas' ,'banjo'],
['fas' ,'barcode'],
['fas' ,'barcode-alt'],
['fas' ,'barcode-read'],
['fas' ,'barcode-scan'],
['fas' ,'bars'],
['fas' ,'baseball'],
['fas' ,'baseball-ball'],
['fas' ,'basketball-ball'],
['fas' ,'basketball-hoop'],
['fas' ,'bat'],
['fas' ,'bath'],
['fas' ,'battery-bolt'],
['fas' ,'battery-empty'],
['fas' ,'battery-full'],
['fas' ,'battery-half'],
['fas' ,'battery-quarter'],
['fas' ,'battery-slash'],
['fas' ,'battery-three-quarters'],
['fas' ,'bed'],
['fas' ,'bed-alt'],
['fas' ,'bed-bunk'],
['fas' ,'bed-empty'],
['fas' ,'beer'],
['fas' ,'bell'],
['fas' ,'bell-exclamation'],
['fas' ,'bell-on'],
['fas' ,'bell-plus'],
['fas' ,'bell-school'],
['fas' ,'bell-school-slash'],
['fas' ,'bell-slash'],
['fas' ,'bells'],
['fas' ,'betamax'],
['fas' ,'bezier-curve'],
['fas' ,'bible'],
['fas' ,'bicycle'],
['fas' ,'biking'],
['fas' ,'biking-mountain'],
['fas' ,'binoculars'],
['fas' ,'biohazard'],
['fas' ,'birthday-cake'],
['fas' ,'blanket'],
['fas' ,'blender'],
['fas' ,'blender-phone'],
['fas' ,'blind'],
['fas' ,'blinds'],
['fas' ,'blinds-open'],
['fas' ,'blinds-raised'],
['fas' ,'blog'],
['fas' ,'bold'],
['fas' ,'bolt'],
['fas' ,'bomb'],
['fas' ,'bone'],
['fas' ,'bone-break'],
['fas' ,'bong'],
['fas' ,'book'],
['fas' ,'book-alt'],
['fas' ,'book-dead'],
['fas' ,'book-heart'],
['fas' ,'book-medical'],
['fas' ,'book-open'],
['fas' ,'book-reader'],
['fas' ,'book-spells'],
['fas' ,'book-user'],
['fas' ,'bookmark'],
['fas' ,'books'],
['fas' ,'books-medical'],
['fas' ,'boombox'],
['fas' ,'boot'],
['fas' ,'booth-curtain'],
['fas' ,'border-all'],
['fas' ,'border-bottom'],
['fas' ,'border-center-h'],
['fas' ,'border-center-v'],
['fas' ,'border-inner'],
['fas' ,'border-left'],
['fas' ,'border-none'],
['fas' ,'border-outer'],
['fas' ,'border-right'],
['fas' ,'border-style'],
['fas' ,'border-style-alt'],
['fas' ,'border-top'],
['fas' ,'bow-arrow'],
['fas' ,'bowling-ball'],
['fas' ,'bowling-pins'],
['fas' ,'box'],
['fas' ,'box-alt'],
['fas' ,'box-ballot'],
['fas' ,'box-check'],
['fas' ,'box-fragile'],
['fas' ,'box-full'],
['fas' ,'box-heart'],
['fas' ,'box-open'],
['fas' ,'box-tissue'],
['fas' ,'box-up'],
['fas' ,'box-usd'],
['fas' ,'boxes'],
['fas' ,'boxes-alt'],
['fas' ,'boxing-glove'],
['fas' ,'brackets'],
['fas' ,'brackets-curly'],
['fas' ,'braille'],
['fas' ,'brain'],
['fas' ,'bread-loaf'],
['fas' ,'bread-slice'],
['fas' ,'briefcase'],
['fas' ,'briefcase-medical'],
['fas' ,'bring-forward'],
['fas' ,'bring-front'],
['fas' ,'broadcast-tower'],
['fas' ,'broom'],
['fas' ,'browser'],
['fas' ,'brush'],
['fas' ,'bug'],
['fas' ,'building'],
['fas' ,'bullhorn'],
['fas' ,'bullseye'],
['fas' ,'bullseye-arrow'],
['fas' ,'bullseye-pointer'],
['fas' ,'burger-soda'],
['fas' ,'burn'],
['fas' ,'burrito'],
['fas' ,'bus'],
['fas' ,'bus-alt'],
['fas' ,'bus-school'],
['fas' ,'business-time'],
['fas' ,'cabinet-filing'],
['fas' ,'cactus'],
['fas' ,'calculator'],
['fas' ,'calculator-alt'],
['fas' ,'calendar'],
['fas' ,'calendar-alt'],
['fas' ,'calendar-check'],
['fas' ,'calendar-day'],
['fas' ,'calendar-edit'],
['fas' ,'calendar-exclamation'],
['fas' ,'calendar-minus'],
['fas' ,'calendar-plus'],
['fas' ,'calendar-star'],
['fas' ,'calendar-times'],
['fas' ,'calendar-week'],
['fas' ,'camcorder'],
['fas' ,'camera'],
['fas' ,'camera-alt'],
['fas' ,'camera-home'],
['fas' ,'camera-movie'],
['fas' ,'camera-polaroid'],
['fas' ,'camera-retro'],
['fas' ,'campfire'],
['fas' ,'campground'],
['fas' ,'candle-holder'],
['fas' ,'candy-cane'],
['fas' ,'candy-corn'],
['fas' ,'cannabis'],
['fas' ,'capsules'],
['fas' ,'car'],
['fas' ,'car-alt'],
['fas' ,'car-battery'],
['fas' ,'car-building'],
['fas' ,'car-bump'],
['fas' ,'car-bus'],
['fas' ,'car-crash'],
['fas' ,'car-garage'],
['fas' ,'car-mechanic'],
['fas' ,'car-side'],
['fas' ,'car-tilt'],
['fas' ,'car-wash'],
['fas' ,'caravan'],
['fas' ,'caravan-alt'],
['fas' ,'caret-circle-down'],
['fas' ,'caret-circle-left'],
['fas' ,'caret-circle-right'],
['fas' ,'caret-circle-up'],
['fas' ,'caret-down'],
['fas' ,'caret-left'],
['fas' ,'caret-right'],
['fas' ,'caret-square-down'],
['fas' ,'caret-square-left'],
['fas' ,'caret-square-right'],
['fas' ,'caret-square-up'],
['fas' ,'caret-up'],
['fas' ,'carrot'],
['fas' ,'cars'],
['fas' ,'cart-arrow-down'],
['fas' ,'cart-plus'],
['fas' ,'cash-register'],
['fas' ,'cassette-tape'],
['fas' ,'cat'],
['fas' ,'cat-space'],
['fas' ,'cauldron'],
['fas' ,'cctv'],
['fas' ,'certificate'],
['fas' ,'chair'],
['fas' ,'chair-office'],
['fas' ,'chalkboard'],
['fas' ,'chalkboard-teacher'],
['fas' ,'charging-station'],
['fas' ,'chart-area'],
['fas' ,'chart-bar'],
['fas' ,'chart-line'],
['fas' ,'chart-line-down'],
['fas' ,'chart-network'],
['fas' ,'chart-pie'],
['fas' ,'chart-pie-alt'],
['fas' ,'chart-scatter'],
['fas' ,'check'],
['fas' ,'check-circle'],
['fas' ,'check-double'],
['fas' ,'check-square'],
['fas' ,'cheese'],
['fas' ,'cheese-swiss'],
['fas' ,'cheeseburger'],
['fas' ,'chess'],
['fas' ,'chess-bishop'],
['fas' ,'chess-bishop-alt'],
['fas' ,'chess-board'],
['fas' ,'chess-clock'],
['fas' ,'chess-clock-alt'],
['fas' ,'chess-king'],
['fas' ,'chess-king-alt'],
['fas' ,'chess-knight'],
['fas' ,'chess-knight-alt'],
['fas' ,'chess-pawn'],
['fas' ,'chess-pawn-alt'],
['fas' ,'chess-queen'],
['fas' ,'chess-queen-alt'],
['fas' ,'chess-rook'],
['fas' ,'chess-rook-alt'],
['fas' ,'chevron-circle-down'],
['fas' ,'chevron-circle-left'],
['fas' ,'chevron-circle-right'],
['fas' ,'chevron-circle-up'],
['fas' ,'chevron-double-down'],
['fas' ,'chevron-double-left'],
['fas' ,'chevron-double-right'],
['fas' ,'chevron-double-up'],
['fas' ,'chevron-down'],
['fas' ,'chevron-left'],
['fas' ,'chevron-right'],
['fas' ,'chevron-square-down'],
['fas' ,'chevron-square-left'],
['fas' ,'chevron-square-right'],
['fas' ,'chevron-square-up'],
['fas' ,'chevron-up'],
['fas' ,'child'],
['fas' ,'chimney'],
['fas' ,'church'],
['fas' ,'circle'],
['fas' ,'circle-notch'],
['fas' ,'city'],
['fas' ,'clarinet'],
['fas' ,'claw-marks'],
['fas' ,'clinic-medical'],
['fas' ,'clipboard'],
['fas' ,'clipboard-check'],
['fas' ,'clipboard-list'],
['fas' ,'clipboard-list-check'],
['fas' ,'clipboard-prescription'],
['fas' ,'clipboard-user'],
['fas' ,'clock'],
['fas' ,'clone'],
['fas' ,'closed-captioning'],
['fas' ,'cloud'],
['fas' ,'cloud-download'],
['fas' ,'cloud-download-alt'],
['fas' ,'cloud-drizzle'],
['fas' ,'cloud-hail'],
['fas' ,'cloud-hail-mixed'],
['fas' ,'cloud-meatball'],
['fas' ,'cloud-moon'],
['fas' ,'cloud-moon-rain'],
['fas' ,'cloud-music'],
['fas' ,'cloud-rain'],
['fas' ,'cloud-rainbow'],
['fas' ,'cloud-showers'],
['fas' ,'cloud-showers-heavy'],
['fas' ,'cloud-sleet'],
['fas' ,'cloud-snow'],
['fas' ,'cloud-sun'],
['fas' ,'cloud-sun-rain'],
['fas' ,'cloud-upload'],
['fas' ,'cloud-upload-alt'],
['fas' ,'clouds'],
['fas' ,'clouds-moon'],
['fas' ,'clouds-sun'],
['fas' ,'club'],
['fas' ,'cocktail'],
['fas' ,'code'],
['fas' ,'code-branch'],
['fas' ,'code-commit'],
['fas' ,'code-merge'],
['fas' ,'coffee'],
['fas' ,'coffee-pot'],
['fas' ,'coffee-togo'],
['fas' ,'coffin'],
['fas' ,'coffin-cross'],
['fas' ,'cog'],
['fas' ,'cogs'],
['fas' ,'coin'],
['fas' ,'coins'],
['fas' ,'columns'],
['fas' ,'comet'],
['fas' ,'comment'],
['fas' ,'comment-alt'],
['fas' ,'comment-alt-check'],
['fas' ,'comment-alt-dollar'],
['fas' ,'comment-alt-dots'],
['fas' ,'comment-alt-edit'],
['fas' ,'comment-alt-exclamation'],
['fas' ,'comment-alt-lines'],
['fas' ,'comment-alt-medical'],
['fas' ,'comment-alt-minus'],
['fas' ,'comment-alt-music'],
['fas' ,'comment-alt-plus'],
['fas' ,'comment-alt-slash'],
['fas' ,'comment-alt-smile'],
['fas' ,'comment-alt-times'],
['fas' ,'comment-check'],
['fas' ,'comment-dollar'],
['fas' ,'comment-dots'],
['fas' ,'comment-edit'],
['fas' ,'comment-exclamation'],
['fas' ,'comment-lines'],
['fas' ,'comment-medical'],
['fas' ,'comment-minus'],
['fas' ,'comment-music'],
['fas' ,'comment-plus'],
['fas' ,'comment-slash'],
['fas' ,'comment-smile'],
['fas' ,'comment-times'],
['fas' ,'comments'],
['fas' ,'comments-alt'],
['fas' ,'comments-alt-dollar'],
['fas' ,'comments-dollar'],
['fas' ,'compact-disc'],
['fas' ,'compass'],
['fas' ,'compass-slash'],
['fas' ,'compress'],
['fas' ,'compress-alt'],
['fas' ,'compress-arrows-alt'],
['fas' ,'compress-wide'],
['fas' ,'computer-classic'],
['fas' ,'computer-speaker'],
['fas' ,'concierge-bell'],
['fas' ,'construction'],
['fas' ,'container-storage'],
['fas' ,'conveyor-belt'],
['fas' ,'conveyor-belt-alt'],
['fas' ,'cookie'],
['fas' ,'cookie-bite'],
['fas' ,'copy'],
['fas' ,'copyright'],
['fas' ,'corn'],
['fas' ,'couch'],
['fas' ,'cow'],
['fas' ,'cowbell'],
['fas' ,'cowbell-more'],
['fas' ,'credit-card'],
['fas' ,'credit-card-blank'],
['fas' ,'credit-card-front'],
['fas' ,'cricket'],
['fas' ,'croissant'],
['fas' ,'crop'],
['fas' ,'crop-alt'],
['fas' ,'cross'],
['fas' ,'crosshairs'],
['fas' ,'crow'],
['fas' ,'crown'],
['fas' ,'crutch'],
['fas' ,'crutches'],
['fas' ,'cube'],
['fas' ,'cubes'],
['fas' ,'curling'],
['fas' ,'cut'],
['fas' ,'dagger'],
['fas' ,'database'],
['fas' ,'deaf'],
['fas' ,'debug'],
['fas' ,'deer'],
['fas' ,'deer-rudolph'],
['fas' ,'democrat'],
['fas' ,'desktop'],
['fas' ,'desktop-alt'],
['fas' ,'dewpoint'],
['fas' ,'dharmachakra'],
['fas' ,'diagnoses'],
['fas' ,'diamond'],
['fas' ,'dice'],
['fas' ,'dice-d10'],
['fas' ,'dice-d12'],
['fas' ,'dice-d20'],
['fas' ,'dice-d4'],
['fas' ,'dice-d6'],
['fas' ,'dice-d8'],
['fas' ,'dice-five'],
['fas' ,'dice-four'],
['fas' ,'dice-one'],
['fas' ,'dice-six'],
['fas' ,'dice-three'],
['fas' ,'dice-two'],
['fas' ,'digging'],
['fas' ,'digital-tachograph'],
['fas' ,'diploma'],
['fas' ,'directions'],
['fas' ,'disc-drive'],
['fas' ,'disease'],
['fas' ,'divide'],
['fas' ,'dizzy'],
['fas' ,'dna'],
['fas' ,'do-not-enter'],
['fas' ,'dog'],
['fas' ,'dog-leashed'],
['fas' ,'dollar-sign'],
['fas' ,'dolly'],
['fas' ,'dolly-empty'],
['fas' ,'dolly-flatbed'],
['fas' ,'dolly-flatbed-alt'],
['fas' ,'dolly-flatbed-empty'],
['fas' ,'donate'],
['fas' ,'door-closed'],
['fas' ,'door-open'],
['fas' ,'dot-circle'],
['fas' ,'dove'],
['fas' ,'download'],
['fas' ,'drafting-compass'],
['fas' ,'dragon'],
['fas' ,'draw-circle'],
['fas' ,'draw-polygon'],
['fas' ,'draw-square'],
['fas' ,'dreidel'],
['fas' ,'drone'],
['fas' ,'drone-alt'],
['fas' ,'drum'],
['fas' ,'drum-steelpan'],
['fas' ,'drumstick'],
['fas' ,'drumstick-bite'],
['fas' ,'dryer'],
['fas' ,'dryer-alt'],
['fas' ,'duck'],
['fas' ,'dumbbell'],
['fas' ,'dumpster'],
['fas' ,'dumpster-fire'],
['fas' ,'dungeon'],
['fas' ,'ear'],
['fas' ,'ear-muffs'],
['fas' ,'eclipse'],
['fas' ,'eclipse-alt'],
['fas' ,'edit'],
['fas' ,'egg'],
['fas' ,'egg-fried'],
['fas' ,'eject'],
['fas' ,'elephant'],
['fas' ,'ellipsis-h'],
['fas' ,'ellipsis-h-alt'],
['fas' ,'ellipsis-v'],
['fas' ,'ellipsis-v-alt'],
['fas' ,'empty-set'],
['fas' ,'engine-warning'],
['fas' ,'envelope'],
['fas' ,'envelope-open'],
['fas' ,'envelope-open-dollar'],
['fas' ,'envelope-open-text'],
['fas' ,'envelope-square'],
['fas' ,'equals'],
['fas' ,'eraser'],
['fas' ,'ethernet'],
['fas' ,'euro-sign'],
['fas' ,'exchange'],
['fas' ,'exchange-alt'],
['fas' ,'exclamation'],
['fas' ,'exclamation-circle'],
['fas' ,'exclamation-square'],
['fas' ,'exclamation-triangle'],
['fas' ,'expand'],
['fas' ,'expand-alt'],
['fas' ,'expand-arrows'],
['fas' ,'expand-arrows-alt'],
['fas' ,'expand-wide'],
['fas' ,'external-link'],
['fas' ,'external-link-alt'],
['fas' ,'external-link-square'],
['fas' ,'external-link-square-alt'],
['fas' ,'eye'],
['fas' ,'eye-dropper'],
['fas' ,'eye-evil'],
['fas' ,'eye-slash'],
['fas' ,'fan'],
['fas' ,'fan-table'],
['fas' ,'farm'],
['fas' ,'fast-backward'],
['fas' ,'fast-forward'],
['fas' ,'faucet'],
['fas' ,'faucet-drip'],
['fas' ,'fax'],
['fas' ,'feather'],
['fas' ,'feather-alt'],
['fas' ,'female'],
['fas' ,'field-hockey'],
['fas' ,'fighter-jet'],
['fas' ,'file'],
['fas' ,'file-alt'],
['fas' ,'file-archive'],
['fas' ,'file-audio'],
['fas' ,'file-certificate'],
['fas' ,'file-chart-line'],
['fas' ,'file-chart-pie'],
['fas' ,'file-check'],
['fas' ,'file-code'],
['fas' ,'file-contract'],
['fas' ,'file-csv'],
['fas' ,'file-download'],
['fas' ,'file-edit'],
['fas' ,'file-excel'],
['fas' ,'file-exclamation'],
['fas' ,'file-export'],
['fas' ,'file-image'],
['fas' ,'file-import'],
['fas' ,'file-invoice'],
['fas' ,'file-invoice-dollar'],
['fas' ,'file-medical'],
['fas' ,'file-medical-alt'],
['fas' ,'file-minus'],
['fas' ,'file-music'],
['fas' ,'file-pdf'],
['fas' ,'file-plus'],
['fas' ,'file-powerpoint'],
['fas' ,'file-prescription'],
['fas' ,'file-search'],
['fas' ,'file-signature'],
['fas' ,'file-spreadsheet'],
['fas' ,'file-times'],
['fas' ,'file-upload'],
['fas' ,'file-user'],
['fas' ,'file-video'],
['fas' ,'file-word'],
['fas' ,'files-medical'],
['fas' ,'fill'],
['fas' ,'fill-drip'],
['fas' ,'film'],
['fas' ,'film-alt'],
['fas' ,'film-canister'],
['fas' ,'filter'],
['fas' ,'fingerprint'],
['fas' ,'fire'],
['fas' ,'fire-alt'],
['fas' ,'fire-extinguisher'],
['fas' ,'fire-smoke'],
['fas' ,'fireplace'],
['fas' ,'first-aid'],
['fas' ,'fish'],
['fas' ,'fish-cooked'],
['fas' ,'fist-raised'],
['fas' ,'flag'],
['fas' ,'flag-alt'],
['fas' ,'flag-checkered'],
['fas' ,'flag-usa'],
['fas' ,'flame'],
['fas' ,'flashlight'],
['fas' ,'flask'],
['fas' ,'flask-poison'],
['fas' ,'flask-potion'],
['fas' ,'flower'],
['fas' ,'flower-daffodil'],
['fas' ,'flower-tulip'],
['fas' ,'flushed'],
['fas' ,'flute'],
['fas' ,'flux-capacitor'],
['fas' ,'fog'],
['fas' ,'folder'],
['fas' ,'folder-download'],
['fas' ,'folder-minus'],
['fas' ,'folder-open'],
['fas' ,'folder-plus'],
['fas' ,'folder-times'],
['fas' ,'folder-tree'],
['fas' ,'folder-upload'],
['fas' ,'folders'],
['fas' ,'font'],
['fas' ,'font-case'],
['fas' ,'football-ball'],
['fas' ,'football-helmet'],
['fas' ,'forklift'],
['fas' ,'forward'],
['fas' ,'fragile'],
['fas' ,'french-fries'],
['fas' ,'frog'],
['fas' ,'frosty-head'],
['fas' ,'frown'],
['fas' ,'frown-open'],
['fas' ,'function'],
['fas' ,'funnel-dollar'],
['fas' ,'futbol'],
['fas' ,'galaxy'],
['fas' ,'game-board'],
['fas' ,'game-board-alt'],
['fas' ,'game-console-handheld'],
['fas' ,'gamepad'],
['fas' ,'gamepad-alt'],
['fas' ,'garage'],
['fas' ,'garage-car'],
['fas' ,'garage-open'],
['fas' ,'gas-pump'],
['fas' ,'gas-pump-slash'],
['fas' ,'gavel'],
['fas' ,'gem'],
['fas' ,'genderless'],
['fas' ,'ghost'],
['fas' ,'gift'],
['fas' ,'gift-card'],
['fas' ,'gifts'],
['fas' ,'gingerbread-man'],
['fas' ,'glass'],
['fas' ,'glass-champagne'],
['fas' ,'glass-cheers'],
['fas' ,'glass-citrus'],
['fas' ,'glass-martini'],
['fas' ,'glass-martini-alt'],
['fas' ,'glass-whiskey'],
['fas' ,'glass-whiskey-rocks'],
['fas' ,'glasses'],
['fas' ,'glasses-alt'],
['fas' ,'globe'],
['fas' ,'globe-africa'],
['fas' ,'globe-americas'],
['fas' ,'globe-asia'],
['fas' ,'globe-europe'],
['fas' ,'globe-snow'],
['fas' ,'globe-stand'],
['fas' ,'golf-ball'],
['fas' ,'golf-club'],
['fas' ,'gopuram'],
['fas' ,'graduation-cap'],
['fas' ,'gramophone'],
['fas' ,'greater-than'],
['fas' ,'greater-than-equal'],
['fas' ,'grimace'],
['fas' ,'grin'],
['fas' ,'grin-alt'],
['fas' ,'grin-beam'],
['fas' ,'grin-beam-sweat'],
['fas' ,'grin-hearts'],
['fas' ,'grin-squint'],
['fas' ,'grin-squint-tears'],
['fas' ,'grin-stars'],
['fas' ,'grin-tears'],
['fas' ,'grin-tongue'],
['fas' ,'grin-tongue-squint'],
['fas' ,'grin-tongue-wink'],
['fas' ,'grin-wink'],
['fas' ,'grip-horizontal'],
['fas' ,'grip-lines'],
['fas' ,'grip-lines-vertical'],
['fas' ,'grip-vertical'],
['fas' ,'guitar'],
['fas' ,'guitar-electric'],
['fas' ,'guitars'],
['fas' ,'h-square'],
['fas' ,'h1'],
['fas' ,'h2'],
['fas' ,'h3'],
['fas' ,'h4'],
['fas' ,'hamburger'],
['fas' ,'hammer'],
['fas' ,'hammer-war'],
['fas' ,'hamsa'],
['fas' ,'hand-heart'],
['fas' ,'hand-holding'],
['fas' ,'hand-holding-box'],
['fas' ,'hand-holding-heart'],
['fas' ,'hand-holding-magic'],
['fas' ,'hand-holding-medical'],
['fas' ,'hand-holding-seedling'],
['fas' ,'hand-holding-usd'],
['fas' ,'hand-holding-water'],
['fas' ,'hand-lizard'],
['fas' ,'hand-paper'],
['fas' ,'hand-peace'],
['fas' ,'hand-point-down'],
['fas' ,'hand-point-left'],
['fas' ,'hand-point-right'],
['fas' ,'hand-point-up'],
['fas' ,'hand-pointer'],
['fas' ,'hand-receiving'],
['fas' ,'hand-rock'],
['fas' ,'hand-scissors'],
['fas' ,'hand-sparkles'],
['fas' ,'hand-spock'],
['fas' ,'hands'],
['fas' ,'hands-heart'],
['fas' ,'hands-helping'],
['fas' ,'hands-usd'],
['fas' ,'hands-wash'],
['fas' ,'handshake'],
['fas' ,'handshake-alt'],
['fas' ,'handshake-alt-slash'],
['fas' ,'handshake-slash'],
['fas' ,'hanukiah'],
['fas' ,'hard-hat'],
['fas' ,'hashtag'],
['fas' ,'hat-chef'],
['fas' ,'hat-cowboy'],
['fas' ,'hat-cowboy-side'],
['fas' ,'hat-santa'],
['fas' ,'hat-winter'],
['fas' ,'hat-witch'],
['fas' ,'hat-wizard'],
['fas' ,'hdd'],
['fas' ,'head-side'],
['fas' ,'head-side-brain'],
['fas' ,'head-side-cough'],
['fas' ,'head-side-cough-slash'],
['fas' ,'head-side-headphones'],
['fas' ,'head-side-mask'],
['fas' ,'head-side-medical'],
['fas' ,'head-side-virus'],
['fas' ,'head-vr'],
['fas' ,'heading'],
['fas' ,'headphones'],
['fas' ,'headphones-alt'],
['fas' ,'headset'],
['fas' ,'heart'],
['fas' ,'heart-broken'],
['fas' ,'heart-circle'],
['fas' ,'heart-rate'],
['fas' ,'heart-square'],
['fas' ,'heartbeat'],
['fas' ,'heat'],
['fas' ,'helicopter'],
['fas' ,'helmet-battle'],
['fas' ,'hexagon'],
['fas' ,'highlighter'],
['fas' ,'hiking'],
['fas' ,'hippo'],
['fas' ,'history'],
['fas' ,'hockey-mask'],
['fas' ,'hockey-puck'],
['fas' ,'hockey-sticks'],
['fas' ,'holly-berry'],
['fas' ,'home'],
['fas' ,'home-alt'],
['fas' ,'home-heart'],
['fas' ,'home-lg'],
['fas' ,'home-lg-alt'],
['fas' ,'hood-cloak'],
['fas' ,'horizontal-rule'],
['fas' ,'horse'],
['fas' ,'horse-head'],
['fas' ,'horse-saddle'],
['fas' ,'hospital'],
['fas' ,'hospital-alt'],
['fas' ,'hospital-symbol'],
['fas' ,'hospital-user'],
['fas' ,'hospitals'],
['fas' ,'hot-tub'],
['fas' ,'hotdog'],
['fas' ,'hotel'],
['fas' ,'hourglass'],
['fas' ,'hourglass-end'],
['fas' ,'hourglass-half'],
['fas' ,'hourglass-start'],
['fas' ,'house'],
['fas' ,'house-damage'],
['fas' ,'house-day'],
['fas' ,'house-flood'],
['fas' ,'house-leave'],
['fas' ,'house-night'],
['fas' ,'house-return'],
['fas' ,'house-signal'],
['fas' ,'house-user'],
['fas' ,'hryvnia'],
['fas' ,'humidity'],
['fas' ,'hurricane'],
['fas' ,'i-cursor'],
['fas' ,'ice-cream'],
['fas' ,'ice-skate'],
['fas' ,'icicles'],
['fas' ,'icons'],
['fas' ,'icons-alt'],
['fas' ,'id-badge'],
['fas' ,'id-card'],
['fas' ,'id-card-alt'],
['fas' ,'igloo'],
['fas' ,'image'],
['fas' ,'image-polaroid'],
['fas' ,'images'],
['fas' ,'inbox'],
['fas' ,'inbox-in'],
['fas' ,'inbox-out'],
['fas' ,'indent'],
['fas' ,'industry'],
['fas' ,'industry-alt'],
['fas' ,'infinity'],
['fas' ,'info'],
['fas' ,'info-circle'],
['fas' ,'info-square'],
['fas' ,'inhaler'],
['fas' ,'integral'],
['fas' ,'intersection'],
['fas' ,'inventory'],
['fas' ,'island-tropical'],
['fas' ,'italic'],
['fas' ,'jack-o-lantern'],
['fas' ,'jedi'],
['fas' ,'joint'],
['fas' ,'journal-whills'],
['fas' ,'joystick'],
['fas' ,'jug'],
['fas' ,'kaaba'],
['fas' ,'kazoo'],
['fas' ,'kerning'],
['fas' ,'key'],
['fas' ,'key-skeleton'],
['fas' ,'keyboard'],
['fas' ,'keynote'],
['fas' ,'khanda'],
['fas' ,'kidneys'],
['fas' ,'kiss'],
['fas' ,'kiss-beam'],
['fas' ,'kiss-wink-heart'],
['fas' ,'kite'],
['fas' ,'kiwi-bird'],
['fas' ,'knife-kitchen'],
['fas' ,'lambda'],
['fas' ,'lamp'],
['fas' ,'lamp-desk'],
['fas' ,'lamp-floor'],
['fas' ,'landmark'],
['fas' ,'landmark-alt'],
['fas' ,'language'],
['fas' ,'laptop'],
['fas' ,'laptop-code'],
['fas' ,'laptop-house'],
['fas' ,'laptop-medical'],
['fas' ,'lasso'],
['fas' ,'laugh'],
['fas' ,'laugh-beam'],
['fas' ,'laugh-squint'],
['fas' ,'laugh-wink'],
['fas' ,'layer-group'],
['fas' ,'layer-minus'],
['fas' ,'layer-plus'],
['fas' ,'leaf'],
['fas' ,'leaf-heart'],
['fas' ,'leaf-maple'],
['fas' ,'leaf-oak'],
['fas' ,'lemon'],
['fas' ,'less-than'],
['fas' ,'less-than-equal'],
['fas' ,'level-down'],
['fas' ,'level-down-alt'],
['fas' ,'level-up'],
['fas' ,'level-up-alt'],
['fas' ,'life-ring'],
['fas' ,'light-ceiling'],
['fas' ,'light-switch'],
['fas' ,'light-switch-off'],
['fas' ,'light-switch-on'],
['fas' ,'lightbulb'],
['fas' ,'lightbulb-dollar'],
['fas' ,'lightbulb-exclamation'],
['fas' ,'lightbulb-on'],
['fas' ,'lightbulb-slash'],
['fas' ,'lights-holiday'],
['fas' ,'line-columns'],
['fas' ,'line-height'],
['fas' ,'link'],
['fas' ,'lips'],
['fas' ,'lira-sign'],
['fas' ,'list'],
['fas' ,'list-alt'],
['fas' ,'list-music'],
['fas' ,'list-ol'],
['fas' ,'list-ul'],
['fas' ,'location'],
['fas' ,'location-arrow'],
['fas' ,'location-circle'],
['fas' ,'location-slash'],
['fas' ,'lock'],
['fas' ,'lock-alt'],
['fas' ,'lock-open'],
['fas' ,'lock-open-alt'],
['fas' ,'long-arrow-alt-down'],
['fas' ,'long-arrow-alt-left'],
['fas' ,'long-arrow-alt-right'],
['fas' ,'long-arrow-alt-up'],
['fas' ,'long-arrow-down'],
['fas' ,'long-arrow-left'],
['fas' ,'long-arrow-right'],
['fas' ,'long-arrow-up'],
['fas' ,'loveseat'],
['fas' ,'low-vision'],
['fas' ,'luchador'],
['fas' ,'luggage-cart'],
['fas' ,'lungs'],
['fas' ,'lungs-virus'],
['fas' ,'mace'],
['fas' ,'magic'],
['fas' ,'magnet'],
['fas' ,'mail-bulk'],
['fas' ,'mailbox'],
['fas' ,'male'],
['fas' ,'mandolin'],
['fas' ,'map'],
['fas' ,'map-marked'],
['fas' ,'map-marked-alt'],
['fas' ,'map-marker'],
['fas' ,'map-marker-alt'],
['fas' ,'map-marker-alt-slash'],
['fas' ,'map-marker-check'],
['fas' ,'map-marker-edit'],
['fas' ,'map-marker-exclamation'],
['fas' ,'map-marker-minus'],
['fas' ,'map-marker-plus'],
['fas' ,'map-marker-question'],
['fas' ,'map-marker-slash'],
['fas' ,'map-marker-smile'],
['fas' ,'map-marker-times'],
['fas' ,'map-pin'],
['fas' ,'map-signs'],
['fas' ,'marker'],
['fas' ,'mars'],
['fas' ,'mars-double'],
['fas' ,'mars-stroke'],
['fas' ,'mars-stroke-h'],
['fas' ,'mars-stroke-v'],
['fas' ,'mask'],
['fas' ,'meat'],
['fas' ,'medal'],
['fas' ,'medkit'],
['fas' ,'megaphone'],
['fas' ,'meh'],
['fas' ,'meh-blank'],
['fas' ,'meh-rolling-eyes'],
['fas' ,'memory'],
['fas' ,'menorah'],
['fas' ,'mercury'],
['fas' ,'meteor'],
['fas' ,'microchip'],
['fas' ,'microphone'],
['fas' ,'microphone-alt'],
['fas' ,'microphone-alt-slash'],
['fas' ,'microphone-slash'],
['fas' ,'microphone-stand'],
['fas' ,'microscope'],
['fas' ,'microwave'],
['fas' ,'mind-share'],
['fas' ,'minus'],
['fas' ,'minus-circle'],
['fas' ,'minus-hexagon'],
['fas' ,'minus-octagon'],
['fas' ,'minus-square'],
['fas' ,'mistletoe'],
['fas' ,'mitten'],
['fas' ,'mobile'],
['fas' ,'mobile-alt'],
['fas' ,'mobile-android'],
['fas' ,'mobile-android-alt'],
['fas' ,'money-bill'],
['fas' ,'money-bill-alt'],
['fas' ,'money-bill-wave'],
['fas' ,'money-bill-wave-alt'],
['fas' ,'money-check'],
['fas' ,'money-check-alt'],
['fas' ,'money-check-edit'],
['fas' ,'money-check-edit-alt'],
['fas' ,'monitor-heart-rate'],
['fas' ,'monkey'],
['fas' ,'monument'],
['fas' ,'moon'],
['fas' ,'moon-cloud'],
['fas' ,'moon-stars'],
['fas' ,'mortar-pestle'],
['fas' ,'mosque'],
['fas' ,'motorcycle'],
['fas' ,'mountain'],
['fas' ,'mountains'],
['fas' ,'mouse'],
['fas' ,'mouse-alt'],
['fas' ,'mouse-pointer'],
['fas' ,'mp3-player'],
['fas' ,'mug'],
['fas' ,'mug-hot'],
['fas' ,'mug-marshmallows'],
['fas' ,'mug-tea'],
['fas' ,'music'],
['fas' ,'music-alt'],
['fas' ,'music-alt-slash'],
['fas' ,'music-slash'],
['fas' ,'narwhal'],
['fas' ,'network-wired'],
['fas' ,'neuter'],
['fas' ,'newspaper'],
['fas' ,'not-equal'],
['fas' ,'notes-medical'],
['fas' ,'object-group'],
['fas' ,'object-ungroup'],
['fas' ,'octagon'],
['fas' ,'oil-can'],
['fas' ,'oil-temp'],
['fas' ,'om'],
['fas' ,'omega'],
['fas' ,'ornament'],
['fas' ,'otter'],
['fas' ,'outdent'],
['fas' ,'outlet'],
['fas' ,'oven'],
['fas' ,'overline'],
['fas' ,'page-break'],
['fas' ,'pager'],
['fas' ,'paint-brush'],
['fas' ,'paint-brush-alt'],
['fas' ,'paint-roller'],
['fas' ,'palette'],
['fas' ,'pallet'],
['fas' ,'pallet-alt'],
['fas' ,'paper-plane'],
['fas' ,'paperclip'],
['fas' ,'parachute-box'],
['fas' ,'paragraph'],
['fas' ,'paragraph-rtl'],
['fas' ,'parking'],
['fas' ,'parking-circle'],
['fas' ,'parking-circle-slash'],
['fas' ,'parking-slash'],
['fas' ,'passport'],
['fas' ,'pastafarianism'],
['fas' ,'paste'],
['fas' ,'pause'],
['fas' ,'pause-circle'],
['fas' ,'paw'],
['fas' ,'paw-alt'],
['fas' ,'paw-claws'],
['fas' ,'peace'],
['fas' ,'pegasus'],
['fas' ,'pen'],
['fas' ,'pen-alt'],
['fas' ,'pen-fancy'],
['fas' ,'pen-nib'],
['fas' ,'pen-square'],
['fas' ,'pencil'],
['fas' ,'pencil-alt'],
['fas' ,'pencil-paintbrush'],
['fas' ,'pencil-ruler'],
['fas' ,'pennant'],
['fas' ,'people-arrows'],
['fas' ,'people-carry'],
['fas' ,'pepper-hot'],
['fas' ,'percent'],
['fas' ,'percentage'],
['fas' ,'person-booth'],
['fas' ,'person-carry'],
['fas' ,'person-dolly'],
['fas' ,'person-dolly-empty'],
['fas' ,'person-sign'],
['fas' ,'phone'],
['fas' ,'phone-alt'],
['fas' ,'phone-laptop'],
['fas' ,'phone-office'],
['fas' ,'phone-plus'],
['fas' ,'phone-rotary'],
['fas' ,'phone-slash'],
['fas' ,'phone-square'],
['fas' ,'phone-square-alt'],
['fas' ,'phone-volume'],
['fas' ,'photo-video'],
['fas' ,'pi'],
['fas' ,'piano'],
['fas' ,'piano-keyboard'],
['fas' ,'pie'],
['fas' ,'pig'],
['fas' ,'piggy-bank'],
['fas' ,'pills'],
['fas' ,'pizza'],
['fas' ,'pizza-slice'],
['fas' ,'place-of-worship'],
['fas' ,'plane'],
['fas' ,'plane-alt'],
['fas' ,'plane-arrival'],
['fas' ,'plane-departure'],
['fas' ,'plane-slash'],
['fas' ,'planet-moon'],
['fas' ,'planet-ringed'],
['fas' ,'play'],
['fas' ,'play-circle'],
['fas' ,'plug'],
['fas' ,'plus'],
['fas' ,'plus-circle'],
['fas' ,'plus-hexagon'],
['fas' ,'plus-octagon'],
['fas' ,'plus-square'],
['fas' ,'podcast'],
['fas' ,'podium'],
['fas' ,'podium-star'],
['fas' ,'police-box'],
['fas' ,'poll'],
['fas' ,'poll-h'],
['fas' ,'poll-people'],
['fas' ,'poo'],
['fas' ,'poo-storm'],
['fas' ,'poop'],
['fas' ,'popcorn'],
['fas' ,'portal-enter'],
['fas' ,'portal-exit'],
['fas' ,'portrait'],
['fas' ,'pound-sign'],
['fas' ,'power-off'],
['fas' ,'pray'],
['fas' ,'praying-hands'],
['fas' ,'prescription'],
['fas' ,'prescription-bottle'],
['fas' ,'prescription-bottle-alt'],
['fas' ,'presentation'],
['fas' ,'print'],
['fas' ,'print-search'],
['fas' ,'print-slash'],
['fas' ,'procedures'],
['fas' ,'project-diagram'],
['fas' ,'projector'],
['fas' ,'pump-medical'],
['fas' ,'pump-soap'],
['fas' ,'pumpkin'],
['fas' ,'puzzle-piece'],
['fas' ,'qrcode'],
['fas' ,'question'],
['fas' ,'question-circle'],
['fas' ,'question-square'],
['fas' ,'quidditch'],
['fas' ,'quote-left'],
['fas' ,'quote-right'],
['fas' ,'quran'],
['fas' ,'rabbit'],
['fas' ,'rabbit-fast'],
['fas' ,'racquet'],
['fas' ,'radar'],
['fas' ,'radiation'],
['fas' ,'radiation-alt'],
['fas' ,'radio'],
['fas' ,'radio-alt'],
['fas' ,'rainbow'],
['fas' ,'raindrops'],
['fas' ,'ram'],
['fas' ,'ramp-loading'],
['fas' ,'random'],
['fas' ,'raygun'],
['fas' ,'receipt'],
['fas' ,'record-vinyl'],
['fas' ,'rectangle-landscape'],
['fas' ,'rectangle-portrait'],
['fas' ,'rectangle-wide'],
['fas' ,'recycle'],
['fas' ,'redo'],
['fas' ,'redo-alt'],
['fas' ,'refrigerator'],
['fas' ,'registered'],
['fas' ,'remove-format'],
['fas' ,'repeat'],
['fas' ,'repeat-1'],
['fas' ,'repeat-1-alt'],
['fas' ,'repeat-alt'],
['fas' ,'reply'],
['fas' ,'reply-all'],
['fas' ,'republican'],
['fas' ,'restroom'],
['fas' ,'retweet'],
['fas' ,'retweet-alt'],
['fas' ,'ribbon'],
['fas' ,'ring'],
['fas' ,'rings-wedding'],
['fas' ,'road'],
['fas' ,'robot'],
['fas' ,'rocket'],
['fas' ,'rocket-launch'],
['fas' ,'route'],
['fas' ,'route-highway'],
['fas' ,'route-interstate'],
['fas' ,'router'],
['fas' ,'rss'],
['fas' ,'rss-square'],
['fas' ,'ruble-sign'],
['fas' ,'ruler'],
['fas' ,'ruler-combined'],
['fas' ,'ruler-horizontal'],
['fas' ,'ruler-triangle'],
['fas' ,'ruler-vertical'],
['fas' ,'running'],
['fas' ,'rupee-sign'],
['fas' ,'rv'],
['fas' ,'sack'],
['fas' ,'sack-dollar'],
['fas' ,'sad-cry'],
['fas' ,'sad-tear'],
['fas' ,'salad'],
['fas' ,'sandwich'],
['fas' ,'satellite'],
['fas' ,'satellite-dish'],
['fas' ,'sausage'],
['fas' ,'save'],
['fas' ,'sax-hot'],
['fas' ,'saxophone'],
['fas' ,'scalpel'],
['fas' ,'scalpel-path'],
['fas' ,'scanner'],
['fas' ,'scanner-image'],
['fas' ,'scanner-keyboard'],
['fas' ,'scanner-touchscreen'],
['fas' ,'scarecrow'],
['fas' ,'scarf'],
['fas' ,'school'],
['fas' ,'screwdriver'],
['fas' ,'scroll'],
['fas' ,'scroll-old'],
['fas' ,'scrubber'],
['fas' ,'scythe'],
['fas' ,'sd-card'],
['fas' ,'search'],
['fas' ,'search-dollar'],
['fas' ,'search-location'],
['fas' ,'search-minus'],
['fas' ,'search-plus'],
['fas' ,'seedling'],
['fas' ,'send-back'],
['fas' ,'send-backward'],
['fas' ,'sensor'],
['fas' ,'sensor-alert'],
['fas' ,'sensor-fire'],
['fas' ,'sensor-on'],
['fas' ,'sensor-smoke'],
['fas' ,'server'],
['fas' ,'shapes'],
['fas' ,'share'],
['fas' ,'share-all'],
['fas' ,'share-alt'],
['fas' ,'share-alt-square'],
['fas' ,'share-square'],
['fas' ,'sheep'],
['fas' ,'shekel-sign'],
['fas' ,'shield'],
['fas' ,'shield-alt'],
['fas' ,'shield-check'],
['fas' ,'shield-cross'],
['fas' ,'shield-virus'],
['fas' ,'ship'],
['fas' ,'shipping-fast'],
['fas' ,'shipping-timed'],
['fas' ,'shish-kebab'],
['fas' ,'shoe-prints'],
['fas' ,'shopping-bag'],
['fas' ,'shopping-basket'],
['fas' ,'shopping-cart'],
['fas' ,'shovel'],
['fas' ,'shovel-snow'],
['fas' ,'shower'],
['fas' ,'shredder'],
['fas' ,'shuttle-van'],
['fas' ,'shuttlecock'],
['fas' ,'sickle'],
['fas' ,'sigma'],
['fas' ,'sign'],
['fas' ,'sign-in'],
['fas' ,'sign-in-alt'],
['fas' ,'sign-language'],
['fas' ,'sign-out'],
['fas' ,'sign-out-alt'],
['fas' ,'signal'],
['fas' ,'signal-1'],
['fas' ,'signal-2'],
['fas' ,'signal-3'],
['fas' ,'signal-4'],
['fas' ,'signal-alt'],
['fas' ,'signal-alt-1'],
['fas' ,'signal-alt-2'],
['fas' ,'signal-alt-3'],
['fas' ,'signal-alt-slash'],
['fas' ,'signal-slash'],
['fas' ,'signal-stream'],
['fas' ,'signature'],
['fas' ,'sim-card'],
['fas' ,'sink'],
['fas' ,'siren'],
['fas' ,'siren-on'],
['fas' ,'sitemap'],
['fas' ,'skating'],
['fas' ,'skeleton'],
['fas' ,'ski-jump'],
['fas' ,'ski-lift'],
['fas' ,'skiing'],
['fas' ,'skiing-nordic'],
['fas' ,'skull'],
['fas' ,'skull-cow'],
['fas' ,'skull-crossbones'],
['fas' ,'slash'],
['fas' ,'sledding'],
['fas' ,'sleigh'],
['fas' ,'sliders-h'],
['fas' ,'sliders-h-square'],
['fas' ,'sliders-v'],
['fas' ,'sliders-v-square'],
['fas' ,'smile'],
['fas' ,'smile-beam'],
['fas' ,'smile-plus'],
['fas' ,'smile-wink'],
['fas' ,'smog'],
['fas' ,'smoke'],
['fas' ,'smoking'],
['fas' ,'smoking-ban'],
['fas' ,'sms'],
['fas' ,'snake'],
['fas' ,'snooze'],
['fas' ,'snow-blowing'],
['fas' ,'snowboarding'],
['fas' ,'snowflake'],
['fas' ,'snowflakes'],
['fas' ,'snowman'],
['fas' ,'snowmobile'],
['fas' ,'snowplow'],
['fas' ,'soap'],
['fas' ,'socks'],
['fas' ,'solar-panel'],
['fas' ,'solar-system'],
['fas' ,'sort'],
['fas' ,'sort-alpha-down'],
['fas' ,'sort-alpha-down-alt'],
['fas' ,'sort-alpha-up'],
['fas' ,'sort-alpha-up-alt'],
['fas' ,'sort-alt'],
['fas' ,'sort-amount-down'],
['fas' ,'sort-amount-down-alt'],
['fas' ,'sort-amount-up'],
['fas' ,'sort-amount-up-alt'],
['fas' ,'sort-circle'],
['fas' ,'sort-circle-down'],
['fas' ,'sort-circle-up'],
['fas' ,'sort-down'],
['fas' ,'sort-numeric-down'],
['fas' ,'sort-numeric-down-alt'],
['fas' ,'sort-numeric-up'],
['fas' ,'sort-numeric-up-alt'],
['fas' ,'sort-shapes-down'],
['fas' ,'sort-shapes-down-alt'],
['fas' ,'sort-shapes-up'],
['fas' ,'sort-shapes-up-alt'],
['fas' ,'sort-size-down'],
['fas' ,'sort-size-down-alt'],
['fas' ,'sort-size-up'],
['fas' ,'sort-size-up-alt'],
['fas' ,'sort-up'],
['fas' ,'soup'],
['fas' ,'spa'],
['fas' ,'space-shuttle'],
['fas' ,'space-station-moon'],
['fas' ,'space-station-moon-alt'],
['fas' ,'spade'],
['fas' ,'sparkles'],
['fas' ,'speaker'],
['fas' ,'speakers'],
['fas' ,'spell-check'],
['fas' ,'spider'],
['fas' ,'spider-black-widow'],
['fas' ,'spider-web'],
['fas' ,'spinner'],
['fas' ,'spinner-third'],
['fas' ,'splotch'],
['fas' ,'spray-can'],
['fas' ,'sprinkler'],
['fas' ,'square'],
['fas' ,'square-full'],
['fas' ,'square-root'],
['fas' ,'square-root-alt'],
['fas' ,'squirrel'],
['fas' ,'staff'],
['fas' ,'stamp'],
['fas' ,'star'],
['fas' ,'star-and-crescent'],
['fas' ,'star-christmas'],
['fas' ,'star-exclamation'],
['fas' ,'star-half'],
['fas' ,'star-half-alt'],
['fas' ,'star-of-david'],
['fas' ,'star-of-life'],
['fas' ,'star-shooting'],
['fas' ,'starfighter'],
['fas' ,'starfighter-alt'],
['fas' ,'stars'],
['fas' ,'starship'],
['fas' ,'starship-freighter'],
['fas' ,'steak'],
['fas' ,'steering-wheel'],
['fas' ,'step-backward'],
['fas' ,'step-forward'],
['fas' ,'stethoscope'],
['fas' ,'sticky-note'],
['fas' ,'stocking'],
['fas' ,'stomach'],
['fas' ,'stop'],
['fas' ,'stop-circle'],
['fas' ,'stopwatch'],
['fas' ,'stopwatch-20'],
['fas' ,'store'],
['fas' ,'store-alt'],
['fas' ,'store-alt-slash'],
['fas' ,'store-slash'],
['fas' ,'stream'],
['fas' ,'street-view'],
['fas' ,'stretcher'],
['fas' ,'strikethrough'],
['fas' ,'stroopwafel'],
['fas' ,'subscript'],
['fas' ,'subway'],
['fas' ,'suitcase'],
['fas' ,'suitcase-rolling'],
['fas' ,'sun'],
['fas' ,'sun-cloud'],
['fas' ,'sun-dust'],
['fas' ,'sun-haze'],
['fas' ,'sunglasses'],
['fas' ,'sunrise'],
['fas' ,'sunset'],
['fas' ,'superscript'],
['fas' ,'surprise'],
['fas' ,'swatchbook'],
['fas' ,'swimmer'],
['fas' ,'swimming-pool'],
['fas' ,'sword'],
['fas' ,'sword-laser'],
['fas' ,'sword-laser-alt'],
['fas' ,'swords'],
['fas' ,'swords-laser'],
['fas' ,'synagogue'],
['fas' ,'sync'],
['fas' ,'sync-alt'],
['fas' ,'syringe'],
['fas' ,'table'],
['fas' ,'table-tennis'],
['fas' ,'tablet'],
['fas' ,'tablet-alt'],
['fas' ,'tablet-android'],
['fas' ,'tablet-android-alt'],
['fas' ,'tablet-rugged'],
['fas' ,'tablets'],
['fas' ,'tachometer'],
['fas' ,'tachometer-alt'],
['fas' ,'tachometer-alt-average'],
['fas' ,'tachometer-alt-fast'],
['fas' ,'tachometer-alt-fastest'],
['fas' ,'tachometer-alt-slow'],
['fas' ,'tachometer-alt-slowest'],
['fas' ,'tachometer-average'],
['fas' ,'tachometer-fast'],
['fas' ,'tachometer-fastest'],
['fas' ,'tachometer-slow'],
['fas' ,'tachometer-slowest'],
['fas' ,'taco'],
['fas' ,'tag'],
['fas' ,'tags'],
['fas' ,'tally'],
['fas' ,'tanakh'],
['fas' ,'tape'],
['fas' ,'tasks'],
['fas' ,'tasks-alt'],
['fas' ,'taxi'],
['fas' ,'teeth'],
['fas' ,'teeth-open'],
['fas' ,'telescope'],
['fas' ,'temperature-down'],
['fas' ,'temperature-frigid'],
['fas' ,'temperature-high'],
['fas' ,'temperature-hot'],
['fas' ,'temperature-low'],
['fas' ,'temperature-up'],
['fas' ,'tenge'],
['fas' ,'tennis-ball'],
['fas' ,'terminal'],
['fas' ,'text'],
['fas' ,'text-height'],
['fas' ,'text-size'],
['fas' ,'text-width'],
['fas' ,'th'],
['fas' ,'th-large'],
['fas' ,'th-list'],
['fas' ,'theater-masks'],
['fas' ,'thermometer'],
['fas' ,'thermometer-empty'],
['fas' ,'thermometer-full'],
['fas' ,'thermometer-half'],
['fas' ,'thermometer-quarter'],
['fas' ,'thermometer-three-quarters'],
['fas' ,'theta'],
['fas' ,'thumbs-down'],
['fas' ,'thumbs-up'],
['fas' ,'thumbtack'],
['fas' ,'thunderstorm'],
['fas' ,'thunderstorm-moon'],
['fas' ,'thunderstorm-sun'],
['fas' ,'ticket'],
['fas' ,'ticket-alt'],
['fas' ,'tilde'],
['fas' ,'times'],
['fas' ,'times-circle'],
['fas' ,'times-hexagon'],
['fas' ,'times-octagon'],
['fas' ,'times-square'],
['fas' ,'tint'],
['fas' ,'tint-slash'],
['fas' ,'tire'],
['fas' ,'tire-flat'],
['fas' ,'tire-pressure-warning'],
['fas' ,'tire-rugged'],
['fas' ,'tired'],
['fas' ,'toggle-off'],
['fas' ,'toggle-on'],
['fas' ,'toilet'],
['fas' ,'toilet-paper'],
['fas' ,'toilet-paper-alt'],
['fas' ,'toilet-paper-slash'],
['fas' ,'tombstone'],
['fas' ,'tombstone-alt'],
['fas' ,'toolbox'],
['fas' ,'tools'],
['fas' ,'tooth'],
['fas' ,'toothbrush'],
['fas' ,'torah'],
['fas' ,'torii-gate'],
['fas' ,'tornado'],
['fas' ,'tractor'],
['fas' ,'trademark'],
['fas' ,'traffic-cone'],
['fas' ,'traffic-light'],
['fas' ,'traffic-light-go'],
['fas' ,'traffic-light-slow'],
['fas' ,'traffic-light-stop'],
['fas' ,'trailer'],
['fas' ,'train'],
['fas' ,'tram'],
['fas' ,'transgender'],
['fas' ,'transgender-alt'],
['fas' ,'transporter'],
['fas' ,'transporter-1'],
['fas' ,'transporter-2'],
['fas' ,'transporter-3'],
['fas' ,'transporter-empty'],
['fas' ,'trash'],
['fas' ,'trash-alt'],
['fas' ,'trash-restore'],
['fas' ,'trash-restore-alt'],
['fas' ,'trash-undo'],
['fas' ,'trash-undo-alt'],
['fas' ,'treasure-chest'],
['fas' ,'tree'],
['fas' ,'tree-alt'],
['fas' ,'tree-christmas'],
['fas' ,'tree-decorated'],
['fas' ,'tree-large'],
['fas' ,'tree-palm'],
['fas' ,'trees'],
['fas' ,'triangle'],
['fas' ,'triangle-music'],
['fas' ,'trophy'],
['fas' ,'trophy-alt'],
['fas' ,'truck'],
['fas' ,'truck-container'],
['fas' ,'truck-couch'],
['fas' ,'truck-loading'],
['fas' ,'truck-monster'],
['fas' ,'truck-moving'],
['fas' ,'truck-pickup'],
['fas' ,'truck-plow'],
['fas' ,'truck-ramp'],
['fas' ,'trumpet'],
['fas' ,'tshirt'],
['fas' ,'tty'],
['fas' ,'turkey'],
['fas' ,'turntable'],
['fas' ,'turtle'],
['fas' ,'tv'],
['fas' ,'tv-alt'],
['fas' ,'tv-music'],
['fas' ,'tv-retro'],
['fas' ,'typewriter'],
['fas' ,'ufo'],
['fas' ,'ufo-beam'],
['fas' ,'umbrella'],
['fas' ,'umbrella-beach'],
['fas' ,'underline'],
['fas' ,'undo'],
['fas' ,'undo-alt'],
['fas' ,'unicorn'],
['fas' ,'union'],
['fas' ,'universal-access'],
['fas' ,'university'],
['fas' ,'unlink'],
['fas' ,'unlock'],
['fas' ,'unlock-alt'],
['fas' ,'upload'],
['fas' ,'usb-drive'],
['fas' ,'usd-circle'],
['fas' ,'usd-square'],
['fas' ,'user'],
['fas' ,'user-alien'],
['fas' ,'user-alt'],
['fas' ,'user-alt-slash'],
['fas' ,'user-astronaut'],
['fas' ,'user-chart'],
['fas' ,'user-check'],
['fas' ,'user-circle'],
['fas' ,'user-clock'],
['fas' ,'user-cog'],
['fas' ,'user-cowboy'],
['fas' ,'user-crown'],
['fas' ,'user-edit'],
['fas' ,'user-friends'],
['fas' ,'user-graduate'],
['fas' ,'user-hard-hat'],
['fas' ,'user-headset'],
['fas' ,'user-injured'],
['fas' ,'user-lock'],
['fas' ,'user-md'],
['fas' ,'user-md-chat'],
['fas' ,'user-minus'],
['fas' ,'user-music'],
['fas' ,'user-ninja'],
['fas' ,'user-nurse'],
['fas' ,'user-plus'],
['fas' ,'user-robot'],
['fas' ,'user-secret'],
['fas' ,'user-shield'],
['fas' ,'user-slash'],
['fas' ,'user-tag'],
['fas' ,'user-tie'],
['fas' ,'user-times'],
['fas' ,'user-unlock'],
['fas' ,'user-visor'],
['fas' ,'users'],
['fas' ,'users-class'],
['fas' ,'users-cog'],
['fas' ,'users-crown'],
['fas' ,'users-medical'],
['fas' ,'users-slash'],
['fas' ,'utensil-fork'],
['fas' ,'utensil-knife'],
['fas' ,'utensil-spoon'],
['fas' ,'utensils'],
['fas' ,'utensils-alt'],
['fas' ,'vacuum'],
['fas' ,'vacuum-robot'],
['fas' ,'value-absolute'],
['fas' ,'vector-square'],
['fas' ,'venus'],
['fas' ,'venus-double'],
['fas' ,'venus-mars'],
['fas' ,'vest'],
['fas' ,'vest-patches'],
['fas' ,'vhs'],
['fas' ,'vial'],
['fas' ,'vials'],
['fas' ,'video'],
['fas' ,'video-plus'],
['fas' ,'video-slash'],
['fas' ,'vihara'],
['fas' ,'violin'],
['fas' ,'virus'],
['fas' ,'virus-slash'],
['fas' ,'viruses'],
['fas' ,'voicemail'],
['fas' ,'volcano'],
['fas' ,'volleyball-ball'],
['fas' ,'volume'],
['fas' ,'volume-down'],
['fas' ,'volume-mute'],
['fas' ,'volume-off'],
['fas' ,'volume-slash'],
['fas' ,'volume-up'],
['fas' ,'vote-nay'],
['fas' ,'vote-yea'],
['fas' ,'vr-cardboard'],
['fas' ,'wagon-covered'],
['fas' ,'walker'],
['fas' ,'walkie-talkie'],
['fas' ,'walking'],
['fas' ,'wallet'],
['fas' ,'wand'],
['fas' ,'wand-magic'],
['fas' ,'warehouse'],
['fas' ,'warehouse-alt'],
['fas' ,'washer'],
['fas' ,'watch'],
['fas' ,'watch-calculator'],
['fas' ,'watch-fitness'],
['fas' ,'water'],
['fas' ,'water-lower'],
['fas' ,'water-rise'],
['fas' ,'wave-sine'],
['fas' ,'wave-square'],
['fas' ,'wave-triangle'],
['fas' ,'waveform'],
['fas' ,'waveform-path'],
['fas' ,'webcam'],
['fas' ,'webcam-slash'],
['fas' ,'weight'],
['fas' ,'weight-hanging'],
['fas' ,'whale'],
['fas' ,'wheat'],
['fas' ,'wheelchair'],
['fas' ,'whistle'],
['fas' ,'wifi'],
['fas' ,'wifi-1'],
['fas' ,'wifi-2'],
['fas' ,'wifi-slash'],
['fas' ,'wind'],
['fas' ,'wind-turbine'],
['fas' ,'wind-warning'],
['fas' ,'window'],
['fas' ,'window-alt'],
['fas' ,'window-close'],
['fas' ,'window-frame'],
['fas' ,'window-frame-open'],
['fas' ,'window-maximize'],
['fas' ,'window-minimize'],
['fas' ,'window-restore'],
['fas' ,'windsock'],
['fas' ,'wine-bottle'],
['fas' ,'wine-glass'],
['fas' ,'wine-glass-alt'],
['fas' ,'won-sign'],
['fas' ,'wreath'],
['fas' ,'wrench'],
['fas' ,'x-ray'],
['fas' ,'yen-sign'],
['fas' ,'yin-yang'],
['fab' ,'500px'],
['fab' ,'accessible-icon'],
['fab' ,'accusoft'],
['fab' ,'acquisitions-incorporated'],
['fab' ,'adn'],
['fab' ,'adversal'],
['fab' ,'affiliatetheme'],
['fab' ,'airbnb'],
['fab' ,'algolia'],
['fab' ,'alipay'],
['fab' ,'amazon'],
['fab' ,'amazon-pay'],
['fab' ,'amilia'],
['fab' ,'android'],
['fab' ,'angellist'],
['fab' ,'angrycreative'],
['fab' ,'angular'],
['fab' ,'app-store'],
['fab' ,'app-store-ios'],
['fab' ,'apper'],
['fab' ,'apple'],
['fab' ,'apple-pay'],
['fab' ,'artstation'],
['fab' ,'asymmetrik'],
['fab' ,'atlassian'],
['fab' ,'audible'],
['fab' ,'autoprefixer'],
['fab' ,'avianex'],
['fab' ,'aviato'],
['fab' ,'aws'],
['fab' ,'bandcamp'],
['fab' ,'battle-net'],
['fab' ,'behance'],
['fab' ,'behance-square'],
['fab' ,'bimobject'],
['fab' ,'bitbucket'],
['fab' ,'bitcoin'],
['fab' ,'bity'],
['fab' ,'black-tie'],
['fab' ,'blackberry'],
['fab' ,'blogger'],
['fab' ,'blogger-b'],
['fab' ,'bluetooth'],
['fab' ,'bluetooth-b'],
['fab' ,'bootstrap'],
['fab' ,'btc'],
['fab' ,'buffer'],
['fab' ,'buromobelexperte'],
['fab' ,'buy-n-large'],
['fab' ,'buysellads'],
['fab' ,'canadian-maple-leaf'],
['fab' ,'cc-amazon-pay'],
['fab' ,'cc-amex'],
['fab' ,'cc-apple-pay'],
['fab' ,'cc-diners-club'],
['fab' ,'cc-discover'],
['fab' ,'cc-jcb'],
['fab' ,'cc-mastercard'],
['fab' ,'cc-paypal'],
['fab' ,'cc-stripe'],
['fab' ,'cc-visa'],
['fab' ,'centercode'],
['fab' ,'centos'],
['fab' ,'chrome'],
['fab' ,'chromecast'],
['fab' ,'cloudflare'],
['fab' ,'cloudscale'],
['fab' ,'cloudsmith'],
['fab' ,'cloudversify'],
['fab' ,'codepen'],
['fab' ,'codiepie'],
['fab' ,'confluence'],
['fab' ,'connectdevelop'],
['fab' ,'contao'],
['fab' ,'cotton-bureau'],
['fab' ,'cpanel'],
['fab' ,'creative-commons'],
['fab' ,'creative-commons-by'],
['fab' ,'creative-commons-nc'],
['fab' ,'creative-commons-nc-eu'],
['fab' ,'creative-commons-nc-jp'],
['fab' ,'creative-commons-nd'],
['fab' ,'creative-commons-pd'],
['fab' ,'creative-commons-pd-alt'],
['fab' ,'creative-commons-remix'],
['fab' ,'creative-commons-sa'],
['fab' ,'creative-commons-sampling'],
['fab' ,'creative-commons-sampling-plus'],
['fab' ,'creative-commons-share'],
['fab' ,'creative-commons-zero'],
['fab' ,'critical-role'],
['fab' ,'css3'],
['fab' ,'css3-alt'],
['fab' ,'cuttlefish'],
['fab' ,'d-and-d'],
['fab' ,'d-and-d-beyond'],
['fab' ,'dailymotion'],
['fab' ,'dashcube'],
['fab' ,'deezer'],
['fab' ,'delicious'],
['fab' ,'deploydog'],
['fab' ,'deskpro'],
['fab' ,'dev'],
['fab' ,'deviantart'],
['fab' ,'dhl'],
['fab' ,'diaspora'],
['fab' ,'digg'],
['fab' ,'digital-ocean'],
['fab' ,'discord'],
['fab' ,'discourse'],
['fab' ,'dochub'],
['fab' ,'docker'],
['fab' ,'draft2digital'],
['fab' ,'dribbble'],
['fab' ,'dribbble-square'],
['fab' ,'dropbox'],
['fab' ,'drupal'],
['fab' ,'dyalog'],
['fab' ,'earlybirds'],
['fab' ,'ebay'],
['fab' ,'edge'],
['fab' ,'edge-legacy'],
['fab' ,'elementor'],
['fab' ,'ello'],
['fab' ,'ember'],
['fab' ,'empire'],
['fab' ,'envira'],
['fab' ,'erlang'],
['fab' ,'ethereum'],
['fab' ,'etsy'],
['fab' ,'evernote'],
['fab' ,'expeditedssl'],
['fab' ,'facebook'],
['fab' ,'facebook-f'],
['fab' ,'facebook-messenger'],
['fab' ,'facebook-square'],
['fab' ,'fantasy-flight-games'],
['fab' ,'fedex'],
['fab' ,'fedora'],
['fab' ,'figma'],
['fab' ,'firefox'],
['fab' ,'firefox-browser'],
['fab' ,'first-order'],
['fab' ,'first-order-alt'],
['fab' ,'firstdraft'],
['fab' ,'flickr'],
['fab' ,'flipboard'],
['fab' ,'fly'],
['fab' ,'font-awesome'],
['fab' ,'font-awesome-alt'],
['fab' ,'font-awesome-flag'],
['fab' ,'fonticons'],
['fab' ,'fonticons-fi'],
['fab' ,'fort-awesome'],
['fab' ,'fort-awesome-alt'],
['fab' ,'forumbee'],
['fab' ,'foursquare'],
['fab' ,'free-code-camp'],
['fab' ,'freebsd'],
['fab' ,'fulcrum'],
['fab' ,'galactic-republic'],
['fab' ,'galactic-senate'],
['fab' ,'get-pocket'],
['fab' ,'gg'],
['fab' ,'gg-circle'],
['fab' ,'git'],
['fab' ,'git-alt'],
['fab' ,'git-square'],
['fab' ,'github'],
['fab' ,'github-alt'],
['fab' ,'github-square'],
['fab' ,'gitkraken'],
['fab' ,'gitlab'],
['fab' ,'gitter'],
['fab' ,'glide'],
['fab' ,'glide-g'],
['fab' ,'gofore'],
['fab' ,'goodreads'],
['fab' ,'goodreads-g'],
['fab' ,'google'],
['fab' ,'google-drive'],
['fab' ,'google-pay'],
['fab' ,'google-play'],
['fab' ,'google-plus'],
['fab' ,'google-plus-g'],
['fab' ,'google-plus-square'],
['fab' ,'google-wallet'],
['fab' ,'gratipay'],
['fab' ,'grav'],
['fab' ,'gripfire'],
['fab' ,'grunt'],
['fab' ,'guilded'],
['fab' ,'gulp'],
['fab' ,'hacker-news'],
['fab' ,'hacker-news-square'],
['fab' ,'hackerrank'],
['fab' ,'hips'],
['fab' ,'hire-a-helper'],
['fab' ,'hive'],
['fab' ,'hooli'],
['fab' ,'hornbill'],
['fab' ,'hotjar'],
['fab' ,'houzz'],
['fab' ,'html5'],
['fab' ,'hubspot'],
['fab' ,'ideal'],
['fab' ,'imdb'],
['fab' ,'innosoft'],
['fab' ,'instagram'],
['fab' ,'instagram-square'],
['fab' ,'instalod'],
['fab' ,'intercom'],
['fab' ,'internet-explorer'],
['fab' ,'invision'],
['fab' ,'ioxhost'],
['fab' ,'itch-io'],
['fab' ,'itunes'],
['fab' ,'itunes-note'],
['fab' ,'java'],
['fab' ,'jedi-order'],
['fab' ,'jenkins'],
['fab' ,'jira'],
['fab' ,'joget'],
['fab' ,'joomla'],
['fab' ,'js'],
['fab' ,'js-square'],
['fab' ,'jsfiddle'],
['fab' ,'kaggle'],
['fab' ,'keybase'],
['fab' ,'keycdn'],
['fab' ,'kickstarter'],
['fab' ,'kickstarter-k'],
['fab' ,'korvue'],
['fab' ,'laravel'],
['fab' ,'lastfm'],
['fab' ,'lastfm-square'],
['fab' ,'leanpub'],
['fab' ,'less'],
['fab' ,'line'],
['fab' ,'linkedin'],
['fab' ,'linkedin-in'],
['fab' ,'linode'],
['fab' ,'linux'],
['fab' ,'lyft'],
['fab' ,'magento'],
['fab' ,'mailchimp'],
['fab' ,'mandalorian'],
['fab' ,'markdown'],
['fab' ,'mastodon'],
['fab' ,'maxcdn'],
['fab' ,'mdb'],
['fab' ,'medapps'],
['fab' ,'medium'],
['fab' ,'medium-m'],
['fab' ,'medrt'],
['fab' ,'meetup'],
['fab' ,'megaport'],
['fab' ,'mendeley'],
['fab' ,'microblog'],
['fab' ,'microsoft'],
['fab' ,'mix'],
['fab' ,'mixcloud'],
['fab' ,'mixer'],
['fab' ,'mizuni'],
['fab' ,'modx'],
['fab' ,'monero'],
['fab' ,'napster'],
['fab' ,'neos'],
['fab' ,'nimblr'],
['fab' ,'node'],
['fab' ,'node-js'],
['fab' ,'npm'],
['fab' ,'ns8'],
['fab' ,'nutritionix'],
['fab' ,'octopus-deploy'],
['fab' ,'odnoklassniki'],
['fab' ,'odnoklassniki-square'],
['fab' ,'old-republic'],
['fab' ,'opencart'],
['fab' ,'openid'],
['fab' ,'opera'],
['fab' ,'optin-monster'],
['fab' ,'orcid'],
['fab' ,'osi'],
['fab' ,'page4'],
['fab' ,'pagelines'],
['fab' ,'palfed'],
['fab' ,'patreon'],
['fab' ,'paypal'],
['fab' ,'penny-arcade'],
['fab' ,'perbyte'],
['fab' ,'periscope'],
['fab' ,'phabricator'],
['fab' ,'phoenix-framework'],
['fab' ,'phoenix-squadron'],
['fab' ,'php'],
['fab' ,'pied-piper'],
['fab' ,'pied-piper-alt'],
['fab' ,'pied-piper-hat'],
['fab' ,'pied-piper-pp'],
['fab' ,'pied-piper-square'],
['fab' ,'pinterest'],
['fab' ,'pinterest-p'],
['fab' ,'pinterest-square'],
['fab' ,'playstation'],
['fab' ,'product-hunt'],
['fab' ,'pushed'],
['fab' ,'python'],
['fab' ,'qq'],
['fab' ,'quinscape'],
['fab' ,'quora'],
['fab' ,'r-project'],
['fab' ,'raspberry-pi'],
['fab' ,'ravelry'],
['fab' ,'react'],
['fab' ,'reacteurope'],
['fab' ,'readme'],
['fab' ,'rebel'],
['fab' ,'red-river'],
['fab' ,'reddit'],
['fab' ,'reddit-alien'],
['fab' ,'reddit-square'],
['fab' ,'redhat'],
['fab' ,'renren'],
['fab' ,'replyd'],
['fab' ,'researchgate'],
['fab' ,'resolving'],
['fab' ,'rev'],
['fab' ,'rocketchat'],
['fab' ,'rockrms'],
['fab' ,'rust'],
['fab' ,'safari'],
['fab' ,'salesforce'],
['fab' ,'sass'],
['fab' ,'schlix'],
['fab' ,'scribd'],
['fab' ,'searchengin'],
['fab' ,'sellcast'],
['fab' ,'sellsy'],
['fab' ,'servicestack'],
['fab' ,'shirtsinbulk'],
['fab' ,'shopify'],
['fab' ,'shopware'],
['fab' ,'simplybuilt'],
['fab' ,'sistrix'],
['fab' ,'sith'],
['fab' ,'sketch'],
['fab' ,'skyatlas'],
['fab' ,'skype'],
['fab' ,'slack'],
['fab' ,'slack-hash'],
['fab' ,'slideshare'],
['fab' ,'snapchat'],
['fab' ,'snapchat-ghost'],
['fab' ,'snapchat-square'],
['fab' ,'soundcloud'],
['fab' ,'sourcetree'],
['fab' ,'speakap'],
['fab' ,'speaker-deck'],
['fab' ,'spotify'],
['fab' ,'squarespace'],
['fab' ,'stack-exchange'],
['fab' ,'stack-overflow'],
['fab' ,'stackpath'],
['fab' ,'staylinked'],
['fab' ,'steam'],
['fab' ,'steam-square'],
['fab' ,'steam-symbol'],
['fab' ,'sticker-mule'],
['fab' ,'strava'],
['fab' ,'stripe'],
['fab' ,'stripe-s'],
['fab' ,'studiovinari'],
['fab' ,'stumbleupon'],
['fab' ,'stumbleupon-circle'],
['fab' ,'superpowers'],
['fab' ,'supple'],
['fab' ,'suse'],
['fab' ,'swift'],
['fab' ,'symfony'],
['fab' ,'teamspeak'],
['fab' ,'telegram'],
['fab' ,'telegram-plane'],
['fab' ,'tencent-weibo'],
['fab' ,'the-red-yeti'],
['fab' ,'themeco'],
['fab' ,'themeisle'],
['fab' ,'think-peaks'],
['fab' ,'tiktok'],
['fab' ,'trade-federation'],
['fab' ,'trello'],
['fab' ,'tumblr'],
['fab' ,'tumblr-square'],
['fab' ,'twitch'],
['fab' ,'twitter'],
['fab' ,'twitter-square'],
['fab' ,'typo3'],
['fab' ,'uber'],
['fab' ,'ubuntu'],
['fab' ,'uikit'],
['fab' ,'umbraco'],
['fab' ,'uncharted'],
['fab' ,'uniregistry'],
['fab' ,'unity'],
['fab' ,'unsplash'],
['fab' ,'untappd'],
['fab' ,'ups'],
['fab' ,'usb'],
['fab' ,'usps'],
['fab' ,'ussunnah'],
['fab' ,'vaadin'],
['fab' ,'viacoin'],
['fab' ,'viadeo'],
['fab' ,'viadeo-square'],
['fab' ,'viber'],
['fab' ,'vimeo'],
['fab' ,'vimeo-square'],
['fab' ,'vimeo-v'],
['fab' ,'vine'],
['fab' ,'vk'],
['fab' ,'vnv'],
['fab' ,'vuejs'],
['fab' ,'watchman-monitoring'],
['fab' ,'waze'],
['fab' ,'weebly'],
['fab' ,'weibo'],
['fab' ,'weixin'],
['fab' ,'whatsapp'],
['fab' ,'whatsapp-square'],
['fab' ,'whmcs'],
['fab' ,'wikipedia-w'],
['fab' ,'windows'],
['fab' ,'wix'],
['fab' ,'wizards-of-the-coast'],
['fab' ,'wodu'],
['fab' ,'wolf-pack-battalion'],
['fab' ,'wordpress'],
['fab' ,'wordpress-simple'],
['fab' ,'wpbeginner'],
['fab' ,'wpexplorer'],
['fab' ,'wpforms'],
['fab' ,'wpressr'],
['fab' ,'xbox'],
['fab' ,'xing'],
['fab' ,'xing-square'],
['fab' ,'y-combinator'],
['fab' ,'yahoo'],
['fab' ,'yammer'],
['fab' ,'yandex'],
['fab' ,'yandex-international'],
['fab' ,'yarn'],
['fab' ,'yelp'],
['fab' ,'yoast'],
['fab' ,'youtube'],
['fab' ,'youtube-square'],
['fab' ,'zhihu'],
['far' ,'abacus'],
['far' ,'acorn'],
['far' ,'ad'],
['far' ,'address-book'],
['far' ,'address-card'],
['far' ,'adjust'],
['far' ,'air-conditioner'],
['far' ,'air-freshener'],
['far' ,'alarm-clock'],
['far' ,'alarm-exclamation'],
['far' ,'alarm-plus'],
['far' ,'alarm-snooze'],
['far' ,'album'],
['far' ,'album-collection'],
['far' ,'alicorn'],
['far' ,'alien'],
['far' ,'alien-monster'],
['far' ,'align-center'],
['far' ,'align-justify'],
['far' ,'align-left'],
['far' ,'align-right'],
['far' ,'align-slash'],
['far' ,'allergies'],
['far' ,'ambulance'],
['far' ,'american-sign-language-interpreting'],
['far' ,'amp-guitar'],
['far' ,'analytics'],
['far' ,'anchor'],
['far' ,'angel'],
['far' ,'angle-double-down'],
['far' ,'angle-double-left'],
['far' ,'angle-double-right'],
['far' ,'angle-double-up'],
['far' ,'angle-down'],
['far' ,'angle-left'],
['far' ,'angle-right'],
['far' ,'angle-up'],
['far' ,'angry'],
['far' ,'ankh'],
['far' ,'apple-alt'],
['far' ,'apple-crate'],
['far' ,'archive'],
['far' ,'archway'],
['far' ,'arrow-alt-circle-down'],
['far' ,'arrow-alt-circle-left'],
['far' ,'arrow-alt-circle-right'],
['far' ,'arrow-alt-circle-up'],
['far' ,'arrow-alt-down'],
['far' ,'arrow-alt-from-bottom'],
['far' ,'arrow-alt-from-left'],
['far' ,'arrow-alt-from-right'],
['far' ,'arrow-alt-from-top'],
['far' ,'arrow-alt-left'],
['far' ,'arrow-alt-right'],
['far' ,'arrow-alt-square-down'],
['far' ,'arrow-alt-square-left'],
['far' ,'arrow-alt-square-right'],
['far' ,'arrow-alt-square-up'],
['far' ,'arrow-alt-to-bottom'],
['far' ,'arrow-alt-to-left'],
['far' ,'arrow-alt-to-right'],
['far' ,'arrow-alt-to-top'],
['far' ,'arrow-alt-up'],
['far' ,'arrow-circle-down'],
['far' ,'arrow-circle-left'],
['far' ,'arrow-circle-right'],
['far' ,'arrow-circle-up'],
['far' ,'arrow-down'],
['far' ,'arrow-from-bottom'],
['far' ,'arrow-from-left'],
['far' ,'arrow-from-right'],
['far' ,'arrow-from-top'],
['far' ,'arrow-left'],
['far' ,'arrow-right'],
['far' ,'arrow-square-down'],
['far' ,'arrow-square-left'],
['far' ,'arrow-square-right'],
['far' ,'arrow-square-up'],
['far' ,'arrow-to-bottom'],
['far' ,'arrow-to-left'],
['far' ,'arrow-to-right'],
['far' ,'arrow-to-top'],
['far' ,'arrow-up'],
['far' ,'arrows'],
['far' ,'arrows-alt'],
['far' ,'arrows-alt-h'],
['far' ,'arrows-alt-v'],
['far' ,'arrows-h'],
['far' ,'arrows-v'],
['far' ,'assistive-listening-systems'],
['far' ,'asterisk'],
['far' ,'at'],
['far' ,'atlas'],
['far' ,'atom'],
['far' ,'atom-alt'],
['far' ,'audio-description'],
['far' ,'award'],
['far' ,'axe'],
['far' ,'axe-battle'],
['far' ,'baby'],
['far' ,'baby-carriage'],
['far' ,'backpack'],
['far' ,'backspace'],
['far' ,'backward'],
['far' ,'bacon'],
['far' ,'bacteria'],
['far' ,'bacterium'],
['far' ,'badge'],
['far' ,'badge-check'],
['far' ,'badge-dollar'],
['far' ,'badge-percent'],
['far' ,'badge-sheriff'],
['far' ,'badger-honey'],
['far' ,'bags-shopping'],
['far' ,'bahai'],
['far' ,'balance-scale'],
['far' ,'balance-scale-left'],
['far' ,'balance-scale-right'],
['far' ,'ball-pile'],
['far' ,'ballot'],
['far' ,'ballot-check'],
['far' ,'ban'],
['far' ,'band-aid'],
['far' ,'banjo'],
['far' ,'barcode'],
['far' ,'barcode-alt'],
['far' ,'barcode-read'],
['far' ,'barcode-scan'],
['far' ,'bars'],
['far' ,'baseball'],
['far' ,'baseball-ball'],
['far' ,'basketball-ball'],
['far' ,'basketball-hoop'],
['far' ,'bat'],
['far' ,'bath'],
['far' ,'battery-bolt'],
['far' ,'battery-empty'],
['far' ,'battery-full'],
['far' ,'battery-half'],
['far' ,'battery-quarter'],
['far' ,'battery-slash'],
['far' ,'battery-three-quarters'],
['far' ,'bed'],
['far' ,'bed-alt'],
['far' ,'bed-bunk'],
['far' ,'bed-empty'],
['far' ,'beer'],
['far' ,'bell'],
['far' ,'bell-exclamation'],
['far' ,'bell-on'],
['far' ,'bell-plus'],
['far' ,'bell-school'],
['far' ,'bell-school-slash'],
['far' ,'bell-slash'],
['far' ,'bells'],
['far' ,'betamax'],
['far' ,'bezier-curve'],
['far' ,'bible'],
['far' ,'bicycle'],
['far' ,'biking'],
['far' ,'biking-mountain'],
['far' ,'binoculars'],
['far' ,'biohazard'],
['far' ,'birthday-cake'],
['far' ,'blanket'],
['far' ,'blender'],
['far' ,'blender-phone'],
['far' ,'blind'],
['far' ,'blinds'],
['far' ,'blinds-open'],
['far' ,'blinds-raised'],
['far' ,'blog'],
['far' ,'bold'],
['far' ,'bolt'],
['far' ,'bomb'],
['far' ,'bone'],
['far' ,'bone-break'],
['far' ,'bong'],
['far' ,'book'],
['far' ,'book-alt'],
['far' ,'book-dead'],
['far' ,'book-heart'],
['far' ,'book-medical'],
['far' ,'book-open'],
['far' ,'book-reader'],
['far' ,'book-spells'],
['far' ,'book-user'],
['far' ,'bookmark'],
['far' ,'books'],
['far' ,'books-medical'],
['far' ,'boombox'],
['far' ,'boot'],
['far' ,'booth-curtain'],
['far' ,'border-all'],
['far' ,'border-bottom'],
['far' ,'border-center-h'],
['far' ,'border-center-v'],
['far' ,'border-inner'],
['far' ,'border-left'],
['far' ,'border-none'],
['far' ,'border-outer'],
['far' ,'border-right'],
['far' ,'border-style'],
['far' ,'border-style-alt'],
['far' ,'border-top'],
['far' ,'bow-arrow'],
['far' ,'bowling-ball'],
['far' ,'bowling-pins'],
['far' ,'box'],
['far' ,'box-alt'],
['far' ,'box-ballot'],
['far' ,'box-check'],
['far' ,'box-fragile'],
['far' ,'box-full'],
['far' ,'box-heart'],
['far' ,'box-open'],
['far' ,'box-tissue'],
['far' ,'box-up'],
['far' ,'box-usd'],
['far' ,'boxes'],
['far' ,'boxes-alt'],
['far' ,'boxing-glove'],
['far' ,'brackets'],
['far' ,'brackets-curly'],
['far' ,'braille'],
['far' ,'brain'],
['far' ,'bread-loaf'],
['far' ,'bread-slice'],
['far' ,'briefcase'],
['far' ,'briefcase-medical'],
['far' ,'bring-forward'],
['far' ,'bring-front'],
['far' ,'broadcast-tower'],
['far' ,'broom'],
['far' ,'browser'],
['far' ,'brush'],
['far' ,'bug'],
['far' ,'building'],
['far' ,'bullhorn'],
['far' ,'bullseye'],
['far' ,'bullseye-arrow'],
['far' ,'bullseye-pointer'],
['far' ,'burger-soda'],
['far' ,'burn'],
['far' ,'burrito'],
['far' ,'bus'],
['far' ,'bus-alt'],
['far' ,'bus-school'],
['far' ,'business-time'],
['far' ,'cabinet-filing'],
['far' ,'cactus'],
['far' ,'calculator'],
['far' ,'calculator-alt'],
['far' ,'calendar'],
['far' ,'calendar-alt'],
['far' ,'calendar-check'],
['far' ,'calendar-day'],
['far' ,'calendar-edit'],
['far' ,'calendar-exclamation'],
['far' ,'calendar-minus'],
['far' ,'calendar-plus'],
['far' ,'calendar-star'],
['far' ,'calendar-times'],
['far' ,'calendar-week'],
['far' ,'camcorder'],
['far' ,'camera'],
['far' ,'camera-alt'],
['far' ,'camera-home'],
['far' ,'camera-movie'],
['far' ,'camera-polaroid'],
['far' ,'camera-retro'],
['far' ,'campfire'],
['far' ,'campground'],
['far' ,'candle-holder'],
['far' ,'candy-cane'],
['far' ,'candy-corn'],
['far' ,'cannabis'],
['far' ,'capsules'],
['far' ,'car'],
['far' ,'car-alt'],
['far' ,'car-battery'],
['far' ,'car-building'],
['far' ,'car-bump'],
['far' ,'car-bus'],
['far' ,'car-crash'],
['far' ,'car-garage'],
['far' ,'car-mechanic'],
['far' ,'car-side'],
['far' ,'car-tilt'],
['far' ,'car-wash'],
['far' ,'caravan'],
['far' ,'caravan-alt'],
['far' ,'caret-circle-down'],
['far' ,'caret-circle-left'],
['far' ,'caret-circle-right'],
['far' ,'caret-circle-up'],
['far' ,'caret-down'],
['far' ,'caret-left'],
['far' ,'caret-right'],
['far' ,'caret-square-down'],
['far' ,'caret-square-left'],
['far' ,'caret-square-right'],
['far' ,'caret-square-up'],
['far' ,'caret-up'],
['far' ,'carrot'],
['far' ,'cars'],
['far' ,'cart-arrow-down'],
['far' ,'cart-plus'],
['far' ,'cash-register'],
['far' ,'cassette-tape'],
['far' ,'cat'],
['far' ,'cat-space'],
['far' ,'cauldron'],
['far' ,'cctv'],
['far' ,'certificate'],
['far' ,'chair'],
['far' ,'chair-office'],
['far' ,'chalkboard'],
['far' ,'chalkboard-teacher'],
['far' ,'charging-station'],
['far' ,'chart-area'],
['far' ,'chart-bar'],
['far' ,'chart-line'],
['far' ,'chart-line-down'],
['far' ,'chart-network'],
['far' ,'chart-pie'],
['far' ,'chart-pie-alt'],
['far' ,'chart-scatter'],
['far' ,'check'],
['far' ,'check-circle'],
['far' ,'check-double'],
['far' ,'check-square'],
['far' ,'cheese'],
['far' ,'cheese-swiss'],
['far' ,'cheeseburger'],
['far' ,'chess'],
['far' ,'chess-bishop'],
['far' ,'chess-bishop-alt'],
['far' ,'chess-board'],
['far' ,'chess-clock'],
['far' ,'chess-clock-alt'],
['far' ,'chess-king'],
['far' ,'chess-king-alt'],
['far' ,'chess-knight'],
['far' ,'chess-knight-alt'],
['far' ,'chess-pawn'],
['far' ,'chess-pawn-alt'],
['far' ,'chess-queen'],
['far' ,'chess-queen-alt'],
['far' ,'chess-rook'],
['far' ,'chess-rook-alt'],
['far' ,'chevron-circle-down'],
['far' ,'chevron-circle-left'],
['far' ,'chevron-circle-right'],
['far' ,'chevron-circle-up'],
['far' ,'chevron-double-down'],
['far' ,'chevron-double-left'],
['far' ,'chevron-double-right'],
['far' ,'chevron-double-up'],
['far' ,'chevron-down'],
['far' ,'chevron-left'],
['far' ,'chevron-right'],
['far' ,'chevron-square-down'],
['far' ,'chevron-square-left'],
['far' ,'chevron-square-right'],
['far' ,'chevron-square-up'],
['far' ,'chevron-up'],
['far' ,'child'],
['far' ,'chimney'],
['far' ,'church'],
['far' ,'circle'],
['far' ,'circle-notch'],
['far' ,'city'],
['far' ,'clarinet'],
['far' ,'claw-marks'],
['far' ,'clinic-medical'],
['far' ,'clipboard'],
['far' ,'clipboard-check'],
['far' ,'clipboard-list'],
['far' ,'clipboard-list-check'],
['far' ,'clipboard-prescription'],
['far' ,'clipboard-user'],
['far' ,'clock'],
['far' ,'clone'],
['far' ,'closed-captioning'],
['far' ,'cloud'],
['far' ,'cloud-download'],
['far' ,'cloud-download-alt'],
['far' ,'cloud-drizzle'],
['far' ,'cloud-hail'],
['far' ,'cloud-hail-mixed'],
['far' ,'cloud-meatball'],
['far' ,'cloud-moon'],
['far' ,'cloud-moon-rain'],
['far' ,'cloud-music'],
['far' ,'cloud-rain'],
['far' ,'cloud-rainbow'],
['far' ,'cloud-showers'],
['far' ,'cloud-showers-heavy'],
['far' ,'cloud-sleet'],
['far' ,'cloud-snow'],
['far' ,'cloud-sun'],
['far' ,'cloud-sun-rain'],
['far' ,'cloud-upload'],
['far' ,'cloud-upload-alt'],
['far' ,'clouds'],
['far' ,'clouds-moon'],
['far' ,'clouds-sun'],
['far' ,'club'],
['far' ,'cocktail'],
['far' ,'code'],
['far' ,'code-branch'],
['far' ,'code-commit'],
['far' ,'code-merge'],
['far' ,'coffee'],
['far' ,'coffee-pot'],
['far' ,'coffee-togo'],
['far' ,'coffin'],
['far' ,'coffin-cross'],
['far' ,'cog'],
['far' ,'cogs'],
['far' ,'coin'],
['far' ,'coins'],
['far' ,'columns'],
['far' ,'comet'],
['far' ,'comment'],
['far' ,'comment-alt'],
['far' ,'comment-alt-check'],
['far' ,'comment-alt-dollar'],
['far' ,'comment-alt-dots'],
['far' ,'comment-alt-edit'],
['far' ,'comment-alt-exclamation'],
['far' ,'comment-alt-lines'],
['far' ,'comment-alt-medical'],
['far' ,'comment-alt-minus'],
['far' ,'comment-alt-music'],
['far' ,'comment-alt-plus'],
['far' ,'comment-alt-slash'],
['far' ,'comment-alt-smile'],
['far' ,'comment-alt-times'],
['far' ,'comment-check'],
['far' ,'comment-dollar'],
['far' ,'comment-dots'],
['far' ,'comment-edit'],
['far' ,'comment-exclamation'],
['far' ,'comment-lines'],
['far' ,'comment-medical'],
['far' ,'comment-minus'],
['far' ,'comment-music'],
['far' ,'comment-plus'],
['far' ,'comment-slash'],
['far' ,'comment-smile'],
['far' ,'comment-times'],
['far' ,'comments'],
['far' ,'comments-alt'],
['far' ,'comments-alt-dollar'],
['far' ,'comments-dollar'],
['far' ,'compact-disc'],
['far' ,'compass'],
['far' ,'compass-slash'],
['far' ,'compress'],
['far' ,'compress-alt'],
['far' ,'compress-arrows-alt'],
['far' ,'compress-wide'],
['far' ,'computer-classic'],
['far' ,'computer-speaker'],
['far' ,'concierge-bell'],
['far' ,'construction'],
['far' ,'container-storage'],
['far' ,'conveyor-belt'],
['far' ,'conveyor-belt-alt'],
['far' ,'cookie'],
['far' ,'cookie-bite'],
['far' ,'copy'],
['far' ,'copyright'],
['far' ,'corn'],
['far' ,'couch'],
['far' ,'cow'],
['far' ,'cowbell'],
['far' ,'cowbell-more'],
['far' ,'credit-card'],
['far' ,'credit-card-blank'],
['far' ,'credit-card-front'],
['far' ,'cricket'],
['far' ,'croissant'],
['far' ,'crop'],
['far' ,'crop-alt'],
['far' ,'cross'],
['far' ,'crosshairs'],
['far' ,'crow'],
['far' ,'crown'],
['far' ,'crutch'],
['far' ,'crutches'],
['far' ,'cube'],
['far' ,'cubes'],
['far' ,'curling'],
['far' ,'cut'],
['far' ,'dagger'],
['far' ,'database'],
['far' ,'deaf'],
['far' ,'debug'],
['far' ,'deer'],
['far' ,'deer-rudolph'],
['far' ,'democrat'],
['far' ,'desktop'],
['far' ,'desktop-alt'],
['far' ,'dewpoint'],
['far' ,'dharmachakra'],
['far' ,'diagnoses'],
['far' ,'diamond'],
['far' ,'dice'],
['far' ,'dice-d10'],
['far' ,'dice-d12'],
['far' ,'dice-d20'],
['far' ,'dice-d4'],
['far' ,'dice-d6'],
['far' ,'dice-d8'],
['far' ,'dice-five'],
['far' ,'dice-four'],
['far' ,'dice-one'],
['far' ,'dice-six'],
['far' ,'dice-three'],
['far' ,'dice-two'],
['far' ,'digging'],
['far' ,'digital-tachograph'],
['far' ,'diploma'],
['far' ,'directions'],
['far' ,'disc-drive'],
['far' ,'disease'],
['far' ,'divide'],
['far' ,'dizzy'],
['far' ,'dna'],
['far' ,'do-not-enter'],
['far' ,'dog'],
['far' ,'dog-leashed'],
['far' ,'dollar-sign'],
['far' ,'dolly'],
['far' ,'dolly-empty'],
['far' ,'dolly-flatbed'],
['far' ,'dolly-flatbed-alt'],
['far' ,'dolly-flatbed-empty'],
['far' ,'donate'],
['far' ,'door-closed'],
['far' ,'door-open'],
['far' ,'dot-circle'],
['far' ,'dove'],
['far' ,'download'],
['far' ,'drafting-compass'],
['far' ,'dragon'],
['far' ,'draw-circle'],
['far' ,'draw-polygon'],
['far' ,'draw-square'],
['far' ,'dreidel'],
['far' ,'drone'],
['far' ,'drone-alt'],
['far' ,'drum'],
['far' ,'drum-steelpan'],
['far' ,'drumstick'],
['far' ,'drumstick-bite'],
['far' ,'dryer'],
['far' ,'dryer-alt'],
['far' ,'duck'],
['far' ,'dumbbell'],
['far' ,'dumpster'],
['far' ,'dumpster-fire'],
['far' ,'dungeon'],
['far' ,'ear'],
['far' ,'ear-muffs'],
['far' ,'eclipse'],
['far' ,'eclipse-alt'],
['far' ,'edit'],
['far' ,'egg'],
['far' ,'egg-fried'],
['far' ,'eject'],
['far' ,'elephant'],
['far' ,'ellipsis-h'],
['far' ,'ellipsis-h-alt'],
['far' ,'ellipsis-v'],
['far' ,'ellipsis-v-alt'],
['far' ,'empty-set'],
['far' ,'engine-warning'],
['far' ,'envelope'],
['far' ,'envelope-open'],
['far' ,'envelope-open-dollar'],
['far' ,'envelope-open-text'],
['far' ,'envelope-square'],
['far' ,'equals'],
['far' ,'eraser'],
['far' ,'ethernet'],
['far' ,'euro-sign'],
['far' ,'exchange'],
['far' ,'exchange-alt'],
['far' ,'exclamation'],
['far' ,'exclamation-circle'],
['far' ,'exclamation-square'],
['far' ,'exclamation-triangle'],
['far' ,'expand'],
['far' ,'expand-alt'],
['far' ,'expand-arrows'],
['far' ,'expand-arrows-alt'],
['far' ,'expand-wide'],
['far' ,'external-link'],
['far' ,'external-link-alt'],
['far' ,'external-link-square'],
['far' ,'external-link-square-alt'],
['far' ,'eye'],
['far' ,'eye-dropper'],
['far' ,'eye-evil'],
['far' ,'eye-slash'],
['far' ,'fan'],
['far' ,'fan-table'],
['far' ,'farm'],
['far' ,'fast-backward'],
['far' ,'fast-forward'],
['far' ,'faucet'],
['far' ,'faucet-drip'],
['far' ,'fax'],
['far' ,'feather'],
['far' ,'feather-alt'],
['far' ,'female'],
['far' ,'field-hockey'],
['far' ,'fighter-jet'],
['far' ,'file'],
['far' ,'file-alt'],
['far' ,'file-archive'],
['far' ,'file-audio'],
['far' ,'file-certificate'],
['far' ,'file-chart-line'],
['far' ,'file-chart-pie'],
['far' ,'file-check'],
['far' ,'file-code'],
['far' ,'file-contract'],
['far' ,'file-csv'],
['far' ,'file-download'],
['far' ,'file-edit'],
['far' ,'file-excel'],
['far' ,'file-exclamation'],
['far' ,'file-export'],
['far' ,'file-image'],
['far' ,'file-import'],
['far' ,'file-invoice'],
['far' ,'file-invoice-dollar'],
['far' ,'file-medical'],
['far' ,'file-medical-alt'],
['far' ,'file-minus'],
['far' ,'file-music'],
['far' ,'file-pdf'],
['far' ,'file-plus'],
['far' ,'file-powerpoint'],
['far' ,'file-prescription'],
['far' ,'file-search'],
['far' ,'file-signature'],
['far' ,'file-spreadsheet'],
['far' ,'file-times'],
['far' ,'file-upload'],
['far' ,'file-user'],
['far' ,'file-video'],
['far' ,'file-word'],
['far' ,'files-medical'],
['far' ,'fill'],
['far' ,'fill-drip'],
['far' ,'film'],
['far' ,'film-alt'],
['far' ,'film-canister'],
['far' ,'filter'],
['far' ,'fingerprint'],
['far' ,'fire'],
['far' ,'fire-alt'],
['far' ,'fire-extinguisher'],
['far' ,'fire-smoke'],
['far' ,'fireplace'],
['far' ,'first-aid'],
['far' ,'fish'],
['far' ,'fish-cooked'],
['far' ,'fist-raised'],
['far' ,'flag'],
['far' ,'flag-alt'],
['far' ,'flag-checkered'],
['far' ,'flag-usa'],
['far' ,'flame'],
['far' ,'flashlight'],
['far' ,'flask'],
['far' ,'flask-poison'],
['far' ,'flask-potion'],
['far' ,'flower'],
['far' ,'flower-daffodil'],
['far' ,'flower-tulip'],
['far' ,'flushed'],
['far' ,'flute'],
['far' ,'flux-capacitor'],
['far' ,'fog'],
['far' ,'folder'],
['far' ,'folder-download'],
['far' ,'folder-minus'],
['far' ,'folder-open'],
['far' ,'folder-plus'],
['far' ,'folder-times'],
['far' ,'folder-tree'],
['far' ,'folder-upload'],
['far' ,'folders'],
['far' ,'font'],
['far' ,'font-case'],
['far' ,'football-ball'],
['far' ,'football-helmet'],
['far' ,'forklift'],
['far' ,'forward'],
['far' ,'fragile'],
['far' ,'french-fries'],
['far' ,'frog'],
['far' ,'frosty-head'],
['far' ,'frown'],
['far' ,'frown-open'],
['far' ,'function'],
['far' ,'funnel-dollar'],
['far' ,'futbol'],
['far' ,'galaxy'],
['far' ,'game-board'],
['far' ,'game-board-alt'],
['far' ,'game-console-handheld'],
['far' ,'gamepad'],
['far' ,'gamepad-alt'],
['far' ,'garage'],
['far' ,'garage-car'],
['far' ,'garage-open'],
['far' ,'gas-pump'],
['far' ,'gas-pump-slash'],
['far' ,'gavel'],
['far' ,'gem'],
['far' ,'genderless'],
['far' ,'ghost'],
['far' ,'gift'],
['far' ,'gift-card'],
['far' ,'gifts'],
['far' ,'gingerbread-man'],
['far' ,'glass'],
['far' ,'glass-champagne'],
['far' ,'glass-cheers'],
['far' ,'glass-citrus'],
['far' ,'glass-martini'],
['far' ,'glass-martini-alt'],
['far' ,'glass-whiskey'],
['far' ,'glass-whiskey-rocks'],
['far' ,'glasses'],
['far' ,'glasses-alt'],
['far' ,'globe'],
['far' ,'globe-africa'],
['far' ,'globe-americas'],
['far' ,'globe-asia'],
['far' ,'globe-europe'],
['far' ,'globe-snow'],
['far' ,'globe-stand'],
['far' ,'golf-ball'],
['far' ,'golf-club'],
['far' ,'gopuram'],
['far' ,'graduation-cap'],
['far' ,'gramophone'],
['far' ,'greater-than'],
['far' ,'greater-than-equal'],
['far' ,'grimace'],
['far' ,'grin'],
['far' ,'grin-alt'],
['far' ,'grin-beam'],
['far' ,'grin-beam-sweat'],
['far' ,'grin-hearts'],
['far' ,'grin-squint'],
['far' ,'grin-squint-tears'],
['far' ,'grin-stars'],
['far' ,'grin-tears'],
['far' ,'grin-tongue'],
['far' ,'grin-tongue-squint'],
['far' ,'grin-tongue-wink'],
['far' ,'grin-wink'],
['far' ,'grip-horizontal'],
['far' ,'grip-lines'],
['far' ,'grip-lines-vertical'],
['far' ,'grip-vertical'],
['far' ,'guitar'],
['far' ,'guitar-electric'],
['far' ,'guitars'],
['far' ,'h-square'],
['far' ,'h1'],
['far' ,'h2'],
['far' ,'h3'],
['far' ,'h4'],
['far' ,'hamburger'],
['far' ,'hammer'],
['far' ,'hammer-war'],
['far' ,'hamsa'],
['far' ,'hand-heart'],
['far' ,'hand-holding'],
['far' ,'hand-holding-box'],
['far' ,'hand-holding-heart'],
['far' ,'hand-holding-magic'],
['far' ,'hand-holding-medical'],
['far' ,'hand-holding-seedling'],
['far' ,'hand-holding-usd'],
['far' ,'hand-holding-water'],
['far' ,'hand-lizard'],
['far' ,'hand-paper'],
['far' ,'hand-peace'],
['far' ,'hand-point-down'],
['far' ,'hand-point-left'],
['far' ,'hand-point-right'],
['far' ,'hand-point-up'],
['far' ,'hand-pointer'],
['far' ,'hand-receiving'],
['far' ,'hand-rock'],
['far' ,'hand-scissors'],
['far' ,'hand-sparkles'],
['far' ,'hand-spock'],
['far' ,'hands'],
['far' ,'hands-heart'],
['far' ,'hands-helping'],
['far' ,'hands-usd'],
['far' ,'hands-wash'],
['far' ,'handshake'],
['far' ,'handshake-alt'],
['far' ,'handshake-alt-slash'],
['far' ,'handshake-slash'],
['far' ,'hanukiah'],
['far' ,'hard-hat'],
['far' ,'hashtag'],
['far' ,'hat-chef'],
['far' ,'hat-cowboy'],
['far' ,'hat-cowboy-side'],
['far' ,'hat-santa'],
['far' ,'hat-winter'],
['far' ,'hat-witch'],
['far' ,'hat-wizard'],
['far' ,'hdd'],
['far' ,'head-side'],
['far' ,'head-side-brain'],
['far' ,'head-side-cough'],
['far' ,'head-side-cough-slash'],
['far' ,'head-side-headphones'],
['far' ,'head-side-mask'],
['far' ,'head-side-medical'],
['far' ,'head-side-virus'],
['far' ,'head-vr'],
['far' ,'heading'],
['far' ,'headphones'],
['far' ,'headphones-alt'],
['far' ,'headset'],
['far' ,'heart'],
['far' ,'heart-broken'],
['far' ,'heart-circle'],
['far' ,'heart-rate'],
['far' ,'heart-square'],
['far' ,'heartbeat'],
['far' ,'heat'],
['far' ,'helicopter'],
['far' ,'helmet-battle'],
['far' ,'hexagon'],
['far' ,'highlighter'],
['far' ,'hiking'],
['far' ,'hippo'],
['far' ,'history'],
['far' ,'hockey-mask'],
['far' ,'hockey-puck'],
['far' ,'hockey-sticks'],
['far' ,'holly-berry'],
['far' ,'home'],
['far' ,'home-alt'],
['far' ,'home-heart'],
['far' ,'home-lg'],
['far' ,'home-lg-alt'],
['far' ,'hood-cloak'],
['far' ,'horizontal-rule'],
['far' ,'horse'],
['far' ,'horse-head'],
['far' ,'horse-saddle'],
['far' ,'hospital'],
['far' ,'hospital-alt'],
['far' ,'hospital-symbol'],
['far' ,'hospital-user'],
['far' ,'hospitals'],
['far' ,'hot-tub'],
['far' ,'hotdog'],
['far' ,'hotel'],
['far' ,'hourglass'],
['far' ,'hourglass-end'],
['far' ,'hourglass-half'],
['far' ,'hourglass-start'],
['far' ,'house'],
['far' ,'house-damage'],
['far' ,'house-day'],
['far' ,'house-flood'],
['far' ,'house-leave'],
['far' ,'house-night'],
['far' ,'house-return'],
['far' ,'house-signal'],
['far' ,'house-user'],
['far' ,'hryvnia'],
['far' ,'humidity'],
['far' ,'hurricane'],
['far' ,'i-cursor'],
['far' ,'ice-cream'],
['far' ,'ice-skate'],
['far' ,'icicles'],
['far' ,'icons'],
['far' ,'icons-alt'],
['far' ,'id-badge'],
['far' ,'id-card'],
['far' ,'id-card-alt'],
['far' ,'igloo'],
['far' ,'image'],
['far' ,'image-polaroid'],
['far' ,'images'],
['far' ,'inbox'],
['far' ,'inbox-in'],
['far' ,'inbox-out'],
['far' ,'indent'],
['far' ,'industry'],
['far' ,'industry-alt'],
['far' ,'infinity'],
['far' ,'info'],
['far' ,'info-circle'],
['far' ,'info-square'],
['far' ,'inhaler'],
['far' ,'integral'],
['far' ,'intersection'],
['far' ,'inventory'],
['far' ,'island-tropical'],
['far' ,'italic'],
['far' ,'jack-o-lantern'],
['far' ,'jedi'],
['far' ,'joint'],
['far' ,'journal-whills'],
['far' ,'joystick'],
['far' ,'jug'],
['far' ,'kaaba'],
['far' ,'kazoo'],
['far' ,'kerning'],
['far' ,'key'],
['far' ,'key-skeleton'],
['far' ,'keyboard'],
['far' ,'keynote'],
['far' ,'khanda'],
['far' ,'kidneys'],
['far' ,'kiss'],
['far' ,'kiss-beam'],
['far' ,'kiss-wink-heart'],
['far' ,'kite'],
['far' ,'kiwi-bird'],
['far' ,'knife-kitchen'],
['far' ,'lambda'],
['far' ,'lamp'],
['far' ,'lamp-desk'],
['far' ,'lamp-floor'],
['far' ,'landmark'],
['far' ,'landmark-alt'],
['far' ,'language'],
['far' ,'laptop'],
['far' ,'laptop-code'],
['far' ,'laptop-house'],
['far' ,'laptop-medical'],
['far' ,'lasso'],
['far' ,'laugh'],
['far' ,'laugh-beam'],
['far' ,'laugh-squint'],
['far' ,'laugh-wink'],
['far' ,'layer-group'],
['far' ,'layer-minus'],
['far' ,'layer-plus'],
['far' ,'leaf'],
['far' ,'leaf-heart'],
['far' ,'leaf-maple'],
['far' ,'leaf-oak'],
['far' ,'lemon'],
['far' ,'less-than'],
['far' ,'less-than-equal'],
['far' ,'level-down'],
['far' ,'level-down-alt'],
['far' ,'level-up'],
['far' ,'level-up-alt'],
['far' ,'life-ring'],
['far' ,'light-ceiling'],
['far' ,'light-switch'],
['far' ,'light-switch-off'],
['far' ,'light-switch-on'],
['far' ,'lightbulb'],
['far' ,'lightbulb-dollar'],
['far' ,'lightbulb-exclamation'],
['far' ,'lightbulb-on'],
['far' ,'lightbulb-slash'],
['far' ,'lights-holiday'],
['far' ,'line-columns'],
['far' ,'line-height'],
['far' ,'link'],
['far' ,'lips'],
['far' ,'lira-sign'],
['far' ,'list'],
['far' ,'list-alt'],
['far' ,'list-music'],
['far' ,'list-ol'],
['far' ,'list-ul'],
['far' ,'location'],
['far' ,'location-arrow'],
['far' ,'location-circle'],
['far' ,'location-slash'],
['far' ,'lock'],
['far' ,'lock-alt'],
['far' ,'lock-open'],
['far' ,'lock-open-alt'],
['far' ,'long-arrow-alt-down'],
['far' ,'long-arrow-alt-left'],
['far' ,'long-arrow-alt-right'],
['far' ,'long-arrow-alt-up'],
['far' ,'long-arrow-down'],
['far' ,'long-arrow-left'],
['far' ,'long-arrow-right'],
['far' ,'long-arrow-up'],
['far' ,'loveseat'],
['far' ,'low-vision'],
['far' ,'luchador'],
['far' ,'luggage-cart'],
['far' ,'lungs'],
['far' ,'lungs-virus'],
['far' ,'mace'],
['far' ,'magic'],
['far' ,'magnet'],
['far' ,'mail-bulk'],
['far' ,'mailbox'],
['far' ,'male'],
['far' ,'mandolin'],
['far' ,'map'],
['far' ,'map-marked'],
['far' ,'map-marked-alt'],
['far' ,'map-marker'],
['far' ,'map-marker-alt'],
['far' ,'map-marker-alt-slash'],
['far' ,'map-marker-check'],
['far' ,'map-marker-edit'],
['far' ,'map-marker-exclamation'],
['far' ,'map-marker-minus'],
['far' ,'map-marker-plus'],
['far' ,'map-marker-question'],
['far' ,'map-marker-slash'],
['far' ,'map-marker-smile'],
['far' ,'map-marker-times'],
['far' ,'map-pin'],
['far' ,'map-signs'],
['far' ,'marker'],
['far' ,'mars'],
['far' ,'mars-double'],
['far' ,'mars-stroke'],
['far' ,'mars-stroke-h'],
['far' ,'mars-stroke-v'],
['far' ,'mask'],
['far' ,'meat'],
['far' ,'medal'],
['far' ,'medkit'],
['far' ,'megaphone'],
['far' ,'meh'],
['far' ,'meh-blank'],
['far' ,'meh-rolling-eyes'],
['far' ,'memory'],
['far' ,'menorah'],
['far' ,'mercury'],
['far' ,'meteor'],
['far' ,'microchip'],
['far' ,'microphone'],
['far' ,'microphone-alt'],
['far' ,'microphone-alt-slash'],
['far' ,'microphone-slash'],
['far' ,'microphone-stand'],
['far' ,'microscope'],
['far' ,'microwave'],
['far' ,'mind-share'],
['far' ,'minus'],
['far' ,'minus-circle'],
['far' ,'minus-hexagon'],
['far' ,'minus-octagon'],
['far' ,'minus-square'],
['far' ,'mistletoe'],
['far' ,'mitten'],
['far' ,'mobile'],
['far' ,'mobile-alt'],
['far' ,'mobile-android'],
['far' ,'mobile-android-alt'],
['far' ,'money-bill'],
['far' ,'money-bill-alt'],
['far' ,'money-bill-wave'],
['far' ,'money-bill-wave-alt'],
['far' ,'money-check'],
['far' ,'money-check-alt'],
['far' ,'money-check-edit'],
['far' ,'money-check-edit-alt'],
['far' ,'monitor-heart-rate'],
['far' ,'monkey'],
['far' ,'monument'],
['far' ,'moon'],
['far' ,'moon-cloud'],
['far' ,'moon-stars'],
['far' ,'mortar-pestle'],
['far' ,'mosque'],
['far' ,'motorcycle'],
['far' ,'mountain'],
['far' ,'mountains'],
['far' ,'mouse'],
['far' ,'mouse-alt'],
['far' ,'mouse-pointer'],
['far' ,'mp3-player'],
['far' ,'mug'],
['far' ,'mug-hot'],
['far' ,'mug-marshmallows'],
['far' ,'mug-tea'],
['far' ,'music'],
['far' ,'music-alt'],
['far' ,'music-alt-slash'],
['far' ,'music-slash'],
['far' ,'narwhal'],
['far' ,'network-wired'],
['far' ,'neuter'],
['far' ,'newspaper'],
['far' ,'not-equal'],
['far' ,'notes-medical'],
['far' ,'object-group'],
['far' ,'object-ungroup'],
['far' ,'octagon'],
['far' ,'oil-can'],
['far' ,'oil-temp'],
['far' ,'om'],
['far' ,'omega'],
['far' ,'ornament'],
['far' ,'otter'],
['far' ,'outdent'],
['far' ,'outlet'],
['far' ,'oven'],
['far' ,'overline'],
['far' ,'page-break'],
['far' ,'pager'],
['far' ,'paint-brush'],
['far' ,'paint-brush-alt'],
['far' ,'paint-roller'],
['far' ,'palette'],
['far' ,'pallet'],
['far' ,'pallet-alt'],
['far' ,'paper-plane'],
['far' ,'paperclip'],
['far' ,'parachute-box'],
['far' ,'paragraph'],
['far' ,'paragraph-rtl'],
['far' ,'parking'],
['far' ,'parking-circle'],
['far' ,'parking-circle-slash'],
['far' ,'parking-slash'],
['far' ,'passport'],
['far' ,'pastafarianism'],
['far' ,'paste'],
['far' ,'pause'],
['far' ,'pause-circle'],
['far' ,'paw'],
['far' ,'paw-alt'],
['far' ,'paw-claws'],
['far' ,'peace'],
['far' ,'pegasus'],
['far' ,'pen'],
['far' ,'pen-alt'],
['far' ,'pen-fancy'],
['far' ,'pen-nib'],
['far' ,'pen-square'],
['far' ,'pencil'],
['far' ,'pencil-alt'],
['far' ,'pencil-paintbrush'],
['far' ,'pencil-ruler'],
['far' ,'pennant'],
['far' ,'people-arrows'],
['far' ,'people-carry'],
['far' ,'pepper-hot'],
['far' ,'percent'],
['far' ,'percentage'],
['far' ,'person-booth'],
['far' ,'person-carry'],
['far' ,'person-dolly'],
['far' ,'person-dolly-empty'],
['far' ,'person-sign'],
['far' ,'phone'],
['far' ,'phone-alt'],
['far' ,'phone-laptop'],
['far' ,'phone-office'],
['far' ,'phone-plus'],
['far' ,'phone-rotary'],
['far' ,'phone-slash'],
['far' ,'phone-square'],
['far' ,'phone-square-alt'],
['far' ,'phone-volume'],
['far' ,'photo-video'],
['far' ,'pi'],
['far' ,'piano'],
['far' ,'piano-keyboard'],
['far' ,'pie'],
['far' ,'pig'],
['far' ,'piggy-bank'],
['far' ,'pills'],
['far' ,'pizza'],
['far' ,'pizza-slice'],
['far' ,'place-of-worship'],
['far' ,'plane'],
['far' ,'plane-alt'],
['far' ,'plane-arrival'],
['far' ,'plane-departure'],
['far' ,'plane-slash'],
['far' ,'planet-moon'],
['far' ,'planet-ringed'],
['far' ,'play'],
['far' ,'play-circle'],
['far' ,'plug'],
['far' ,'plus'],
['far' ,'plus-circle'],
['far' ,'plus-hexagon'],
['far' ,'plus-octagon'],
['far' ,'plus-square'],
['far' ,'podcast'],
['far' ,'podium'],
['far' ,'podium-star'],
['far' ,'police-box'],
['far' ,'poll'],
['far' ,'poll-h'],
['far' ,'poll-people'],
['far' ,'poo'],
['far' ,'poo-storm'],
['far' ,'poop'],
['far' ,'popcorn'],
['far' ,'portal-enter'],
['far' ,'portal-exit'],
['far' ,'portrait'],
['far' ,'pound-sign'],
['far' ,'power-off'],
['far' ,'pray'],
['far' ,'praying-hands'],
['far' ,'prescription'],
['far' ,'prescription-bottle'],
['far' ,'prescription-bottle-alt'],
['far' ,'presentation'],
['far' ,'print'],
['far' ,'print-search'],
['far' ,'print-slash'],
['far' ,'procedures'],
['far' ,'project-diagram'],
['far' ,'projector'],
['far' ,'pump-medical'],
['far' ,'pump-soap'],
['far' ,'pumpkin'],
['far' ,'puzzle-piece'],
['far' ,'qrcode'],
['far' ,'question'],
['far' ,'question-circle'],
['far' ,'question-square'],
['far' ,'quidditch'],
['far' ,'quote-left'],
['far' ,'quote-right'],
['far' ,'quran'],
['far' ,'rabbit'],
['far' ,'rabbit-fast'],
['far' ,'racquet'],
['far' ,'radar'],
['far' ,'radiation'],
['far' ,'radiation-alt'],
['far' ,'radio'],
['far' ,'radio-alt'],
['far' ,'rainbow'],
['far' ,'raindrops'],
['far' ,'ram'],
['far' ,'ramp-loading'],
['far' ,'random'],
['far' ,'raygun'],
['far' ,'receipt'],
['far' ,'record-vinyl'],
['far' ,'rectangle-landscape'],
['far' ,'rectangle-portrait'],
['far' ,'rectangle-wide'],
['far' ,'recycle'],
['far' ,'redo'],
['far' ,'redo-alt'],
['far' ,'refrigerator'],
['far' ,'registered'],
['far' ,'remove-format'],
['far' ,'repeat'],
['far' ,'repeat-1'],
['far' ,'repeat-1-alt'],
['far' ,'repeat-alt'],
['far' ,'reply'],
['far' ,'reply-all'],
['far' ,'republican'],
['far' ,'restroom'],
['far' ,'retweet'],
['far' ,'retweet-alt'],
['far' ,'ribbon'],
['far' ,'ring'],
['far' ,'rings-wedding'],
['far' ,'road'],
['far' ,'robot'],
['far' ,'rocket'],
['far' ,'rocket-launch'],
['far' ,'route'],
['far' ,'route-highway'],
['far' ,'route-interstate'],
['far' ,'router'],
['far' ,'rss'],
['far' ,'rss-square'],
['far' ,'ruble-sign'],
['far' ,'ruler'],
['far' ,'ruler-combined'],
['far' ,'ruler-horizontal'],
['far' ,'ruler-triangle'],
['far' ,'ruler-vertical'],
['far' ,'running'],
['far' ,'rupee-sign'],
['far' ,'rv'],
['far' ,'sack'],
['far' ,'sack-dollar'],
['far' ,'sad-cry'],
['far' ,'sad-tear'],
['far' ,'salad'],
['far' ,'sandwich'],
['far' ,'satellite'],
['far' ,'satellite-dish'],
['far' ,'sausage'],
['far' ,'save'],
['far' ,'sax-hot'],
['far' ,'saxophone'],
['far' ,'scalpel'],
['far' ,'scalpel-path'],
['far' ,'scanner'],
['far' ,'scanner-image'],
['far' ,'scanner-keyboard'],
['far' ,'scanner-touchscreen'],
['far' ,'scarecrow'],
['far' ,'scarf'],
['far' ,'school'],
['far' ,'screwdriver'],
['far' ,'scroll'],
['far' ,'scroll-old'],
['far' ,'scrubber'],
['far' ,'scythe'],
['far' ,'sd-card'],
['far' ,'search'],
['far' ,'search-dollar'],
['far' ,'search-location'],
['far' ,'search-minus'],
['far' ,'search-plus'],
['far' ,'seedling'],
['far' ,'send-back'],
['far' ,'send-backward'],
['far' ,'sensor'],
['far' ,'sensor-alert'],
['far' ,'sensor-fire'],
['far' ,'sensor-on'],
['far' ,'sensor-smoke'],
['far' ,'server'],
['far' ,'shapes'],
['far' ,'share'],
['far' ,'share-all'],
['far' ,'share-alt'],
['far' ,'share-alt-square'],
['far' ,'share-square'],
['far' ,'sheep'],
['far' ,'shekel-sign'],
['far' ,'shield'],
['far' ,'shield-alt'],
['far' ,'shield-check'],
['far' ,'shield-cross'],
['far' ,'shield-virus'],
['far' ,'ship'],
['far' ,'shipping-fast'],
['far' ,'shipping-timed'],
['far' ,'shish-kebab'],
['far' ,'shoe-prints'],
['far' ,'shopping-bag'],
['far' ,'shopping-basket'],
['far' ,'shopping-cart'],
['far' ,'shovel'],
['far' ,'shovel-snow'],
['far' ,'shower'],
['far' ,'shredder'],
['far' ,'shuttle-van'],
['far' ,'shuttlecock'],
['far' ,'sickle'],
['far' ,'sigma'],
['far' ,'sign'],
['far' ,'sign-in'],
['far' ,'sign-in-alt'],
['far' ,'sign-language'],
['far' ,'sign-out'],
['far' ,'sign-out-alt'],
['far' ,'signal'],
['far' ,'signal-1'],
['far' ,'signal-2'],
['far' ,'signal-3'],
['far' ,'signal-4'],
['far' ,'signal-alt'],
['far' ,'signal-alt-1'],
['far' ,'signal-alt-2'],
['far' ,'signal-alt-3'],
['far' ,'signal-alt-slash'],
['far' ,'signal-slash'],
['far' ,'signal-stream'],
['far' ,'signature'],
['far' ,'sim-card'],
['far' ,'sink'],
['far' ,'siren'],
['far' ,'siren-on'],
['far' ,'sitemap'],
['far' ,'skating'],
['far' ,'skeleton'],
['far' ,'ski-jump'],
['far' ,'ski-lift'],
['far' ,'skiing'],
['far' ,'skiing-nordic'],
['far' ,'skull'],
['far' ,'skull-cow'],
['far' ,'skull-crossbones'],
['far' ,'slash'],
['far' ,'sledding'],
['far' ,'sleigh'],
['far' ,'sliders-h'],
['far' ,'sliders-h-square'],
['far' ,'sliders-v'],
['far' ,'sliders-v-square'],
['far' ,'smile'],
['far' ,'smile-beam'],
['far' ,'smile-plus'],
['far' ,'smile-wink'],
['far' ,'smog'],
['far' ,'smoke'],
['far' ,'smoking'],
['far' ,'smoking-ban'],
['far' ,'sms'],
['far' ,'snake'],
['far' ,'snooze'],
['far' ,'snow-blowing'],
['far' ,'snowboarding'],
['far' ,'snowflake'],
['far' ,'snowflakes'],
['far' ,'snowman'],
['far' ,'snowmobile'],
['far' ,'snowplow'],
['far' ,'soap'],
['far' ,'socks'],
['far' ,'solar-panel'],
['far' ,'solar-system'],
['far' ,'sort'],
['far' ,'sort-alpha-down'],
['far' ,'sort-alpha-down-alt'],
['far' ,'sort-alpha-up'],
['far' ,'sort-alpha-up-alt'],
['far' ,'sort-alt'],
['far' ,'sort-amount-down'],
['far' ,'sort-amount-down-alt'],
['far' ,'sort-amount-up'],
['far' ,'sort-amount-up-alt'],
['far' ,'sort-circle'],
['far' ,'sort-circle-down'],
['far' ,'sort-circle-up'],
['far' ,'sort-down'],
['far' ,'sort-numeric-down'],
['far' ,'sort-numeric-down-alt'],
['far' ,'sort-numeric-up'],
['far' ,'sort-numeric-up-alt'],
['far' ,'sort-shapes-down'],
['far' ,'sort-shapes-down-alt'],
['far' ,'sort-shapes-up'],
['far' ,'sort-shapes-up-alt'],
['far' ,'sort-size-down'],
['far' ,'sort-size-down-alt'],
['far' ,'sort-size-up'],
['far' ,'sort-size-up-alt'],
['far' ,'sort-up'],
['far' ,'soup'],
['far' ,'spa'],
['far' ,'space-shuttle'],
['far' ,'space-station-moon'],
['far' ,'space-station-moon-alt'],
['far' ,'spade'],
['far' ,'sparkles'],
['far' ,'speaker'],
['far' ,'speakers'],
['far' ,'spell-check'],
['far' ,'spider'],
['far' ,'spider-black-widow'],
['far' ,'spider-web'],
['far' ,'spinner'],
['far' ,'spinner-third'],
['far' ,'splotch'],
['far' ,'spray-can'],
['far' ,'sprinkler'],
['far' ,'square'],
['far' ,'square-full'],
['far' ,'square-root'],
['far' ,'square-root-alt'],
['far' ,'squirrel'],
['far' ,'staff'],
['far' ,'stamp'],
['far' ,'star'],
['far' ,'star-and-crescent'],
['far' ,'star-christmas'],
['far' ,'star-exclamation'],
['far' ,'star-half'],
['far' ,'star-half-alt'],
['far' ,'star-of-david'],
['far' ,'star-of-life'],
['far' ,'star-shooting'],
['far' ,'starfighter'],
['far' ,'starfighter-alt'],
['far' ,'stars'],
['far' ,'starship'],
['far' ,'starship-freighter'],
['far' ,'steak'],
['far' ,'steering-wheel'],
['far' ,'step-backward'],
['far' ,'step-forward'],
['far' ,'stethoscope'],
['far' ,'sticky-note'],
['far' ,'stocking'],
['far' ,'stomach'],
['far' ,'stop'],
['far' ,'stop-circle'],
['far' ,'stopwatch'],
['far' ,'stopwatch-20'],
['far' ,'store'],
['far' ,'store-alt'],
['far' ,'store-alt-slash'],
['far' ,'store-slash'],
['far' ,'stream'],
['far' ,'street-view'],
['far' ,'stretcher'],
['far' ,'strikethrough'],
['far' ,'stroopwafel'],
['far' ,'subscript'],
['far' ,'subway'],
['far' ,'suitcase'],
['far' ,'suitcase-rolling'],
['far' ,'sun'],
['far' ,'sun-cloud'],
['far' ,'sun-dust'],
['far' ,'sun-haze'],
['far' ,'sunglasses'],
['far' ,'sunrise'],
['far' ,'sunset'],
['far' ,'superscript'],
['far' ,'surprise'],
['far' ,'swatchbook'],
['far' ,'swimmer'],
['far' ,'swimming-pool'],
['far' ,'sword'],
['far' ,'sword-laser'],
['far' ,'sword-laser-alt'],
['far' ,'swords'],
['far' ,'swords-laser'],
['far' ,'synagogue'],
['far' ,'sync'],
['far' ,'sync-alt'],
['far' ,'syringe'],
['far' ,'table'],
['far' ,'table-tennis'],
['far' ,'tablet'],
['far' ,'tablet-alt'],
['far' ,'tablet-android'],
['far' ,'tablet-android-alt'],
['far' ,'tablet-rugged'],
['far' ,'tablets'],
['far' ,'tachometer'],
['far' ,'tachometer-alt'],
['far' ,'tachometer-alt-average'],
['far' ,'tachometer-alt-fast'],
['far' ,'tachometer-alt-fastest'],
['far' ,'tachometer-alt-slow'],
['far' ,'tachometer-alt-slowest'],
['far' ,'tachometer-average'],
['far' ,'tachometer-fast'],
['far' ,'tachometer-fastest'],
['far' ,'tachometer-slow'],
['far' ,'tachometer-slowest'],
['far' ,'taco'],
['far' ,'tag'],
['far' ,'tags'],
['far' ,'tally'],
['far' ,'tanakh'],
['far' ,'tape'],
['far' ,'tasks'],
['far' ,'tasks-alt'],
['far' ,'taxi'],
['far' ,'teeth'],
['far' ,'teeth-open'],
['far' ,'telescope'],
['far' ,'temperature-down'],
['far' ,'temperature-frigid'],
['far' ,'temperature-high'],
['far' ,'temperature-hot'],
['far' ,'temperature-low'],
['far' ,'temperature-up'],
['far' ,'tenge'],
['far' ,'tennis-ball'],
['far' ,'terminal'],
['far' ,'text'],
['far' ,'text-height'],
['far' ,'text-size'],
['far' ,'text-width'],
['far' ,'th'],
['far' ,'th-large'],
['far' ,'th-list'],
['far' ,'theater-masks'],
['far' ,'thermometer'],
['far' ,'thermometer-empty'],
['far' ,'thermometer-full'],
['far' ,'thermometer-half'],
['far' ,'thermometer-quarter'],
['far' ,'thermometer-three-quarters'],
['far' ,'theta'],
['far' ,'thumbs-down'],
['far' ,'thumbs-up'],
['far' ,'thumbtack'],
['far' ,'thunderstorm'],
['far' ,'thunderstorm-moon'],
['far' ,'thunderstorm-sun'],
['far' ,'ticket'],
['far' ,'ticket-alt'],
['far' ,'tilde'],
['far' ,'times'],
['far' ,'times-circle'],
['far' ,'times-hexagon'],
['far' ,'times-octagon'],
['far' ,'times-square'],
['far' ,'tint'],
['far' ,'tint-slash'],
['far' ,'tire'],
['far' ,'tire-flat'],
['far' ,'tire-pressure-warning'],
['far' ,'tire-rugged'],
['far' ,'tired'],
['far' ,'toggle-off'],
['far' ,'toggle-on'],
['far' ,'toilet'],
['far' ,'toilet-paper'],
['far' ,'toilet-paper-alt'],
['far' ,'toilet-paper-slash'],
['far' ,'tombstone'],
['far' ,'tombstone-alt'],
['far' ,'toolbox'],
['far' ,'tools'],
['far' ,'tooth'],
['far' ,'toothbrush'],
['far' ,'torah'],
['far' ,'torii-gate'],
['far' ,'tornado'],
['far' ,'tractor'],
['far' ,'trademark'],
['far' ,'traffic-cone'],
['far' ,'traffic-light'],
['far' ,'traffic-light-go'],
['far' ,'traffic-light-slow'],
['far' ,'traffic-light-stop'],
['far' ,'trailer'],
['far' ,'train'],
['far' ,'tram'],
['far' ,'transgender'],
['far' ,'transgender-alt'],
['far' ,'transporter'],
['far' ,'transporter-1'],
['far' ,'transporter-2'],
['far' ,'transporter-3'],
['far' ,'transporter-empty'],
['far' ,'trash'],
['far' ,'trash-alt'],
['far' ,'trash-restore'],
['far' ,'trash-restore-alt'],
['far' ,'trash-undo'],
['far' ,'trash-undo-alt'],
['far' ,'treasure-chest'],
['far' ,'tree'],
['far' ,'tree-alt'],
['far' ,'tree-christmas'],
['far' ,'tree-decorated'],
['far' ,'tree-large'],
['far' ,'tree-palm'],
['far' ,'trees'],
['far' ,'triangle'],
['far' ,'triangle-music'],
['far' ,'trophy'],
['far' ,'trophy-alt'],
['far' ,'truck'],
['far' ,'truck-container'],
['far' ,'truck-couch'],
['far' ,'truck-loading'],
['far' ,'truck-monster'],
['far' ,'truck-moving'],
['far' ,'truck-pickup'],
['far' ,'truck-plow'],
['far' ,'truck-ramp'],
['far' ,'trumpet'],
['far' ,'tshirt'],
['far' ,'tty'],
['far' ,'turkey'],
['far' ,'turntable'],
['far' ,'turtle'],
['far' ,'tv'],
['far' ,'tv-alt'],
['far' ,'tv-music'],
['far' ,'tv-retro'],
['far' ,'typewriter'],
['far' ,'ufo'],
['far' ,'ufo-beam'],
['far' ,'umbrella'],
['far' ,'umbrella-beach'],
['far' ,'underline'],
['far' ,'undo'],
['far' ,'undo-alt'],
['far' ,'unicorn'],
['far' ,'union'],
['far' ,'universal-access'],
['far' ,'university'],
['far' ,'unlink'],
['far' ,'unlock'],
['far' ,'unlock-alt'],
['far' ,'upload'],
['far' ,'usb-drive'],
['far' ,'usd-circle'],
['far' ,'usd-square'],
['far' ,'user'],
['far' ,'user-alien'],
['far' ,'user-alt'],
['far' ,'user-alt-slash'],
['far' ,'user-astronaut'],
['far' ,'user-chart'],
['far' ,'user-check'],
['far' ,'user-circle'],
['far' ,'user-clock'],
['far' ,'user-cog'],
['far' ,'user-cowboy'],
['far' ,'user-crown'],
['far' ,'user-edit'],
['far' ,'user-friends'],
['far' ,'user-graduate'],
['far' ,'user-hard-hat'],
['far' ,'user-headset'],
['far' ,'user-injured'],
['far' ,'user-lock'],
['far' ,'user-md'],
['far' ,'user-md-chat'],
['far' ,'user-minus'],
['far' ,'user-music'],
['far' ,'user-ninja'],
['far' ,'user-nurse'],
['far' ,'user-plus'],
['far' ,'user-robot'],
['far' ,'user-secret'],
['far' ,'user-shield'],
['far' ,'user-slash'],
['far' ,'user-tag'],
['far' ,'user-tie'],
['far' ,'user-times'],
['far' ,'user-unlock'],
['far' ,'user-visor'],
['far' ,'users'],
['far' ,'users-class'],
['far' ,'users-cog'],
['far' ,'users-crown'],
['far' ,'users-medical'],
['far' ,'users-slash'],
['far' ,'utensil-fork'],
['far' ,'utensil-knife'],
['far' ,'utensil-spoon'],
['far' ,'utensils'],
['far' ,'utensils-alt'],
['far' ,'vacuum'],
['far' ,'vacuum-robot'],
['far' ,'value-absolute'],
['far' ,'vector-square'],
['far' ,'venus'],
['far' ,'venus-double'],
['far' ,'venus-mars'],
['far' ,'vest'],
['far' ,'vest-patches'],
['far' ,'vhs'],
['far' ,'vial'],
['far' ,'vials'],
['far' ,'video'],
['far' ,'video-plus'],
['far' ,'video-slash'],
['far' ,'vihara'],
['far' ,'violin'],
['far' ,'virus'],
['far' ,'virus-slash'],
['far' ,'viruses'],
['far' ,'voicemail'],
['far' ,'volcano'],
['far' ,'volleyball-ball'],
['far' ,'volume'],
['far' ,'volume-down'],
['far' ,'volume-mute'],
['far' ,'volume-off'],
['far' ,'volume-slash'],
['far' ,'volume-up'],
['far' ,'vote-nay'],
['far' ,'vote-yea'],
['far' ,'vr-cardboard'],
['far' ,'wagon-covered'],
['far' ,'walker'],
['far' ,'walkie-talkie'],
['far' ,'walking'],
['far' ,'wallet'],
['far' ,'wand'],
['far' ,'wand-magic'],
['far' ,'warehouse'],
['far' ,'warehouse-alt'],
['far' ,'washer'],
['far' ,'watch'],
['far' ,'watch-calculator'],
['far' ,'watch-fitness'],
['far' ,'water'],
['far' ,'water-lower'],
['far' ,'water-rise'],
['far' ,'wave-sine'],
['far' ,'wave-square'],
['far' ,'wave-triangle'],
['far' ,'waveform'],
['far' ,'waveform-path'],
['far' ,'webcam'],
['far' ,'webcam-slash'],
['far' ,'weight'],
['far' ,'weight-hanging'],
['far' ,'whale'],
['far' ,'wheat'],
['far' ,'wheelchair'],
['far' ,'whistle'],
['far' ,'wifi'],
['far' ,'wifi-1'],
['far' ,'wifi-2'],
['far' ,'wifi-slash'],
['far' ,'wind'],
['far' ,'wind-turbine'],
['far' ,'wind-warning'],
['far' ,'window'],
['far' ,'window-alt'],
['far' ,'window-close'],
['far' ,'window-frame'],
['far' ,'window-frame-open'],
['far' ,'window-maximize'],
['far' ,'window-minimize'],
['far' ,'window-restore'],
['far' ,'windsock'],
['far' ,'wine-bottle'],
['far' ,'wine-glass'],
['far' ,'wine-glass-alt'],
['far' ,'won-sign'],
['far' ,'wreath'],
['far' ,'wrench'],
['far' ,'x-ray'],
['far' ,'yen-sign'],
['far' ,'yin-yang'],
['fal' ,'abacus'],
['fal' ,'acorn'],
['fal' ,'ad'],
['fal' ,'address-book'],
['fal' ,'address-card'],
['fal' ,'adjust'],
['fal' ,'air-conditioner'],
['fal' ,'air-freshener'],
['fal' ,'alarm-clock'],
['fal' ,'alarm-exclamation'],
['fal' ,'alarm-plus'],
['fal' ,'alarm-snooze'],
['fal' ,'album'],
['fal' ,'album-collection'],
['fal' ,'alicorn'],
['fal' ,'alien'],
['fal' ,'alien-monster'],
['fal' ,'align-center'],
['fal' ,'align-justify'],
['fal' ,'align-left'],
['fal' ,'align-right'],
['fal' ,'align-slash'],
['fal' ,'allergies'],
['fal' ,'ambulance'],
['fal' ,'american-sign-language-interpreting'],
['fal' ,'amp-guitar'],
['fal' ,'analytics'],
['fal' ,'anchor'],
['fal' ,'angel'],
['fal' ,'angle-double-down'],
['fal' ,'angle-double-left'],
['fal' ,'angle-double-right'],
['fal' ,'angle-double-up'],
['fal' ,'angle-down'],
['fal' ,'angle-left'],
['fal' ,'angle-right'],
['fal' ,'angle-up'],
['fal' ,'angry'],
['fal' ,'ankh'],
['fal' ,'apple-alt'],
['fal' ,'apple-crate'],
['fal' ,'archive'],
['fal' ,'archway'],
['fal' ,'arrow-alt-circle-down'],
['fal' ,'arrow-alt-circle-left'],
['fal' ,'arrow-alt-circle-right'],
['fal' ,'arrow-alt-circle-up'],
['fal' ,'arrow-alt-down'],
['fal' ,'arrow-alt-from-bottom'],
['fal' ,'arrow-alt-from-left'],
['fal' ,'arrow-alt-from-right'],
['fal' ,'arrow-alt-from-top'],
['fal' ,'arrow-alt-left'],
['fal' ,'arrow-alt-right'],
['fal' ,'arrow-alt-square-down'],
['fal' ,'arrow-alt-square-left'],
['fal' ,'arrow-alt-square-right'],
['fal' ,'arrow-alt-square-up'],
['fal' ,'arrow-alt-to-bottom'],
['fal' ,'arrow-alt-to-left'],
['fal' ,'arrow-alt-to-right'],
['fal' ,'arrow-alt-to-top'],
['fal' ,'arrow-alt-up'],
['fal' ,'arrow-circle-down'],
['fal' ,'arrow-circle-left'],
['fal' ,'arrow-circle-right'],
['fal' ,'arrow-circle-up'],
['fal' ,'arrow-down'],
['fal' ,'arrow-from-bottom'],
['fal' ,'arrow-from-left'],
['fal' ,'arrow-from-right'],
['fal' ,'arrow-from-top'],
['fal' ,'arrow-left'],
['fal' ,'arrow-right'],
['fal' ,'arrow-square-down'],
['fal' ,'arrow-square-left'],
['fal' ,'arrow-square-right'],
['fal' ,'arrow-square-up'],
['fal' ,'arrow-to-bottom'],
['fal' ,'arrow-to-left'],
['fal' ,'arrow-to-right'],
['fal' ,'arrow-to-top'],
['fal' ,'arrow-up'],
['fal' ,'arrows'],
['fal' ,'arrows-alt'],
['fal' ,'arrows-alt-h'],
['fal' ,'arrows-alt-v'],
['fal' ,'arrows-h'],
['fal' ,'arrows-v'],
['fal' ,'assistive-listening-systems'],
['fal' ,'asterisk'],
['fal' ,'at'],
['fal' ,'atlas'],
['fal' ,'atom'],
['fal' ,'atom-alt'],
['fal' ,'audio-description'],
['fal' ,'award'],
['fal' ,'axe'],
['fal' ,'axe-battle'],
['fal' ,'baby'],
['fal' ,'baby-carriage'],
['fal' ,'backpack'],
['fal' ,'backspace'],
['fal' ,'backward'],
['fal' ,'bacon'],
['fal' ,'bacteria'],
['fal' ,'bacterium'],
['fal' ,'badge'],
['fal' ,'badge-check'],
['fal' ,'badge-dollar'],
['fal' ,'badge-percent'],
['fal' ,'badge-sheriff'],
['fal' ,'badger-honey'],
['fal' ,'bags-shopping'],
['fal' ,'bahai'],
['fal' ,'balance-scale'],
['fal' ,'balance-scale-left'],
['fal' ,'balance-scale-right'],
['fal' ,'ball-pile'],
['fal' ,'ballot'],
['fal' ,'ballot-check'],
['fal' ,'ban'],
['fal' ,'band-aid'],
['fal' ,'banjo'],
['fal' ,'barcode'],
['fal' ,'barcode-alt'],
['fal' ,'barcode-read'],
['fal' ,'barcode-scan'],
['fal' ,'bars'],
['fal' ,'baseball'],
['fal' ,'baseball-ball'],
['fal' ,'basketball-ball'],
['fal' ,'basketball-hoop'],
['fal' ,'bat'],
['fal' ,'bath'],
['fal' ,'battery-bolt'],
['fal' ,'battery-empty'],
['fal' ,'battery-full'],
['fal' ,'battery-half'],
['fal' ,'battery-quarter'],
['fal' ,'battery-slash'],
['fal' ,'battery-three-quarters'],
['fal' ,'bed'],
['fal' ,'bed-alt'],
['fal' ,'bed-bunk'],
['fal' ,'bed-empty'],
['fal' ,'beer'],
['fal' ,'bell'],
['fal' ,'bell-exclamation'],
['fal' ,'bell-on'],
['fal' ,'bell-plus'],
['fal' ,'bell-school'],
['fal' ,'bell-school-slash'],
['fal' ,'bell-slash'],
['fal' ,'bells'],
['fal' ,'betamax'],
['fal' ,'bezier-curve'],
['fal' ,'bible'],
['fal' ,'bicycle'],
['fal' ,'biking'],
['fal' ,'biking-mountain'],
['fal' ,'binoculars'],
['fal' ,'biohazard'],
['fal' ,'birthday-cake'],
['fal' ,'blanket'],
['fal' ,'blender'],
['fal' ,'blender-phone'],
['fal' ,'blind'],
['fal' ,'blinds'],
['fal' ,'blinds-open'],
['fal' ,'blinds-raised'],
['fal' ,'blog'],
['fal' ,'bold'],
['fal' ,'bolt'],
['fal' ,'bomb'],
['fal' ,'bone'],
['fal' ,'bone-break'],
['fal' ,'bong'],
['fal' ,'book'],
['fal' ,'book-alt'],
['fal' ,'book-dead'],
['fal' ,'book-heart'],
['fal' ,'book-medical'],
['fal' ,'book-open'],
['fal' ,'book-reader'],
['fal' ,'book-spells'],
['fal' ,'book-user'],
['fal' ,'bookmark'],
['fal' ,'books'],
['fal' ,'books-medical'],
['fal' ,'boombox'],
['fal' ,'boot'],
['fal' ,'booth-curtain'],
['fal' ,'border-all'],
['fal' ,'border-bottom'],
['fal' ,'border-center-h'],
['fal' ,'border-center-v'],
['fal' ,'border-inner'],
['fal' ,'border-left'],
['fal' ,'border-none'],
['fal' ,'border-outer'],
['fal' ,'border-right'],
['fal' ,'border-style'],
['fal' ,'border-style-alt'],
['fal' ,'border-top'],
['fal' ,'bow-arrow'],
['fal' ,'bowling-ball'],
['fal' ,'bowling-pins'],
['fal' ,'box'],
['fal' ,'box-alt'],
['fal' ,'box-ballot'],
['fal' ,'box-check'],
['fal' ,'box-fragile'],
['fal' ,'box-full'],
['fal' ,'box-heart'],
['fal' ,'box-open'],
['fal' ,'box-tissue'],
['fal' ,'box-up'],
['fal' ,'box-usd'],
['fal' ,'boxes'],
['fal' ,'boxes-alt'],
['fal' ,'boxing-glove'],
['fal' ,'brackets'],
['fal' ,'brackets-curly'],
['fal' ,'braille'],
['fal' ,'brain'],
['fal' ,'bread-loaf'],
['fal' ,'bread-slice'],
['fal' ,'briefcase'],
['fal' ,'briefcase-medical'],
['fal' ,'bring-forward'],
['fal' ,'bring-front'],
['fal' ,'broadcast-tower'],
['fal' ,'broom'],
['fal' ,'browser'],
['fal' ,'brush'],
['fal' ,'bug'],
['fal' ,'building'],
['fal' ,'bullhorn'],
['fal' ,'bullseye'],
['fal' ,'bullseye-arrow'],
['fal' ,'bullseye-pointer'],
['fal' ,'burger-soda'],
['fal' ,'burn'],
['fal' ,'burrito'],
['fal' ,'bus'],
['fal' ,'bus-alt'],
['fal' ,'bus-school'],
['fal' ,'business-time'],
['fal' ,'cabinet-filing'],
['fal' ,'cactus'],
['fal' ,'calculator'],
['fal' ,'calculator-alt'],
['fal' ,'calendar'],
['fal' ,'calendar-alt'],
['fal' ,'calendar-check'],
['fal' ,'calendar-day'],
['fal' ,'calendar-edit'],
['fal' ,'calendar-exclamation'],
['fal' ,'calendar-minus'],
['fal' ,'calendar-plus'],
['fal' ,'calendar-star'],
['fal' ,'calendar-times'],
['fal' ,'calendar-week'],
['fal' ,'camcorder'],
['fal' ,'camera'],
['fal' ,'camera-alt'],
['fal' ,'camera-home'],
['fal' ,'camera-movie'],
['fal' ,'camera-polaroid'],
['fal' ,'camera-retro'],
['fal' ,'campfire'],
['fal' ,'campground'],
['fal' ,'candle-holder'],
['fal' ,'candy-cane'],
['fal' ,'candy-corn'],
['fal' ,'cannabis'],
['fal' ,'capsules'],
['fal' ,'car'],
['fal' ,'car-alt'],
['fal' ,'car-battery'],
['fal' ,'car-building'],
['fal' ,'car-bump'],
['fal' ,'car-bus'],
['fal' ,'car-crash'],
['fal' ,'car-garage'],
['fal' ,'car-mechanic'],
['fal' ,'car-side'],
['fal' ,'car-tilt'],
['fal' ,'car-wash'],
['fal' ,'caravan'],
['fal' ,'caravan-alt'],
['fal' ,'caret-circle-down'],
['fal' ,'caret-circle-left'],
['fal' ,'caret-circle-right'],
['fal' ,'caret-circle-up'],
['fal' ,'caret-down'],
['fal' ,'caret-left'],
['fal' ,'caret-right'],
['fal' ,'caret-square-down'],
['fal' ,'caret-square-left'],
['fal' ,'caret-square-right'],
['fal' ,'caret-square-up'],
['fal' ,'caret-up'],
['fal' ,'carrot'],
['fal' ,'cars'],
['fal' ,'cart-arrow-down'],
['fal' ,'cart-plus'],
['fal' ,'cash-register'],
['fal' ,'cassette-tape'],
['fal' ,'cat'],
['fal' ,'cat-space'],
['fal' ,'cauldron'],
['fal' ,'cctv'],
['fal' ,'certificate'],
['fal' ,'chair'],
['fal' ,'chair-office'],
['fal' ,'chalkboard'],
['fal' ,'chalkboard-teacher'],
['fal' ,'charging-station'],
['fal' ,'chart-area'],
['fal' ,'chart-bar'],
['fal' ,'chart-line'],
['fal' ,'chart-line-down'],
['fal' ,'chart-network'],
['fal' ,'chart-pie'],
['fal' ,'chart-pie-alt'],
['fal' ,'chart-scatter'],
['fal' ,'check'],
['fal' ,'check-circle'],
['fal' ,'check-double'],
['fal' ,'check-square'],
['fal' ,'cheese'],
['fal' ,'cheese-swiss'],
['fal' ,'cheeseburger'],
['fal' ,'chess'],
['fal' ,'chess-bishop'],
['fal' ,'chess-bishop-alt'],
['fal' ,'chess-board'],
['fal' ,'chess-clock'],
['fal' ,'chess-clock-alt'],
['fal' ,'chess-king'],
['fal' ,'chess-king-alt'],
['fal' ,'chess-knight'],
['fal' ,'chess-knight-alt'],
['fal' ,'chess-pawn'],
['fal' ,'chess-pawn-alt'],
['fal' ,'chess-queen'],
['fal' ,'chess-queen-alt'],
['fal' ,'chess-rook'],
['fal' ,'chess-rook-alt'],
['fal' ,'chevron-circle-down'],
['fal' ,'chevron-circle-left'],
['fal' ,'chevron-circle-right'],
['fal' ,'chevron-circle-up'],
['fal' ,'chevron-double-down'],
['fal' ,'chevron-double-left'],
['fal' ,'chevron-double-right'],
['fal' ,'chevron-double-up'],
['fal' ,'chevron-down'],
['fal' ,'chevron-left'],
['fal' ,'chevron-right'],
['fal' ,'chevron-square-down'],
['fal' ,'chevron-square-left'],
['fal' ,'chevron-square-right'],
['fal' ,'chevron-square-up'],
['fal' ,'chevron-up'],
['fal' ,'child'],
['fal' ,'chimney'],
['fal' ,'church'],
['fal' ,'circle'],
['fal' ,'circle-notch'],
['fal' ,'city'],
['fal' ,'clarinet'],
['fal' ,'claw-marks'],
['fal' ,'clinic-medical'],
['fal' ,'clipboard'],
['fal' ,'clipboard-check'],
['fal' ,'clipboard-list'],
['fal' ,'clipboard-list-check'],
['fal' ,'clipboard-prescription'],
['fal' ,'clipboard-user'],
['fal' ,'clock'],
['fal' ,'clone'],
['fal' ,'closed-captioning'],
['fal' ,'cloud'],
['fal' ,'cloud-download'],
['fal' ,'cloud-download-alt'],
['fal' ,'cloud-drizzle'],
['fal' ,'cloud-hail'],
['fal' ,'cloud-hail-mixed'],
['fal' ,'cloud-meatball'],
['fal' ,'cloud-moon'],
['fal' ,'cloud-moon-rain'],
['fal' ,'cloud-music'],
['fal' ,'cloud-rain'],
['fal' ,'cloud-rainbow'],
['fal' ,'cloud-showers'],
['fal' ,'cloud-showers-heavy'],
['fal' ,'cloud-sleet'],
['fal' ,'cloud-snow'],
['fal' ,'cloud-sun'],
['fal' ,'cloud-sun-rain'],
['fal' ,'cloud-upload'],
['fal' ,'cloud-upload-alt'],
['fal' ,'clouds'],
['fal' ,'clouds-moon'],
['fal' ,'clouds-sun'],
['fal' ,'club'],
['fal' ,'cocktail'],
['fal' ,'code'],
['fal' ,'code-branch'],
['fal' ,'code-commit'],
['fal' ,'code-merge'],
['fal' ,'coffee'],
['fal' ,'coffee-pot'],
['fal' ,'coffee-togo'],
['fal' ,'coffin'],
['fal' ,'coffin-cross'],
['fal' ,'cog'],
['fal' ,'cogs'],
['fal' ,'coin'],
['fal' ,'coins'],
['fal' ,'columns'],
['fal' ,'comet'],
['fal' ,'comment'],
['fal' ,'comment-alt'],
['fal' ,'comment-alt-check'],
['fal' ,'comment-alt-dollar'],
['fal' ,'comment-alt-dots'],
['fal' ,'comment-alt-edit'],
['fal' ,'comment-alt-exclamation'],
['fal' ,'comment-alt-lines'],
['fal' ,'comment-alt-medical'],
['fal' ,'comment-alt-minus'],
['fal' ,'comment-alt-music'],
['fal' ,'comment-alt-plus'],
['fal' ,'comment-alt-slash'],
['fal' ,'comment-alt-smile'],
['fal' ,'comment-alt-times'],
['fal' ,'comment-check'],
['fal' ,'comment-dollar'],
['fal' ,'comment-dots'],
['fal' ,'comment-edit'],
['fal' ,'comment-exclamation'],
['fal' ,'comment-lines'],
['fal' ,'comment-medical'],
['fal' ,'comment-minus'],
['fal' ,'comment-music'],
['fal' ,'comment-plus'],
['fal' ,'comment-slash'],
['fal' ,'comment-smile'],
['fal' ,'comment-times'],
['fal' ,'comments'],
['fal' ,'comments-alt'],
['fal' ,'comments-alt-dollar'],
['fal' ,'comments-dollar'],
['fal' ,'compact-disc'],
['fal' ,'compass'],
['fal' ,'compass-slash'],
['fal' ,'compress'],
['fal' ,'compress-alt'],
['fal' ,'compress-arrows-alt'],
['fal' ,'compress-wide'],
['fal' ,'computer-classic'],
['fal' ,'computer-speaker'],
['fal' ,'concierge-bell'],
['fal' ,'construction'],
['fal' ,'container-storage'],
['fal' ,'conveyor-belt'],
['fal' ,'conveyor-belt-alt'],
['fal' ,'cookie'],
['fal' ,'cookie-bite'],
['fal' ,'copy'],
['fal' ,'copyright'],
['fal' ,'corn'],
['fal' ,'couch'],
['fal' ,'cow'],
['fal' ,'cowbell'],
['fal' ,'cowbell-more'],
['fal' ,'credit-card'],
['fal' ,'credit-card-blank'],
['fal' ,'credit-card-front'],
['fal' ,'cricket'],
['fal' ,'croissant'],
['fal' ,'crop'],
['fal' ,'crop-alt'],
['fal' ,'cross'],
['fal' ,'crosshairs'],
['fal' ,'crow'],
['fal' ,'crown'],
['fal' ,'crutch'],
['fal' ,'crutches'],
['fal' ,'cube'],
['fal' ,'cubes'],
['fal' ,'curling'],
['fal' ,'cut'],
['fal' ,'dagger'],
['fal' ,'database'],
['fal' ,'deaf'],
['fal' ,'debug'],
['fal' ,'deer'],
['fal' ,'deer-rudolph'],
['fal' ,'democrat'],
['fal' ,'desktop'],
['fal' ,'desktop-alt'],
['fal' ,'dewpoint'],
['fal' ,'dharmachakra'],
['fal' ,'diagnoses'],
['fal' ,'diamond'],
['fal' ,'dice'],
['fal' ,'dice-d10'],
['fal' ,'dice-d12'],
['fal' ,'dice-d20'],
['fal' ,'dice-d4'],
['fal' ,'dice-d6'],
['fal' ,'dice-d8'],
['fal' ,'dice-five'],
['fal' ,'dice-four'],
['fal' ,'dice-one'],
['fal' ,'dice-six'],
['fal' ,'dice-three'],
['fal' ,'dice-two'],
['fal' ,'digging'],
['fal' ,'digital-tachograph'],
['fal' ,'diploma'],
['fal' ,'directions'],
['fal' ,'disc-drive'],
['fal' ,'disease'],
['fal' ,'divide'],
['fal' ,'dizzy'],
['fal' ,'dna'],
['fal' ,'do-not-enter'],
['fal' ,'dog'],
['fal' ,'dog-leashed'],
['fal' ,'dollar-sign'],
['fal' ,'dolly'],
['fal' ,'dolly-empty'],
['fal' ,'dolly-flatbed'],
['fal' ,'dolly-flatbed-alt'],
['fal' ,'dolly-flatbed-empty'],
['fal' ,'donate'],
['fal' ,'door-closed'],
['fal' ,'door-open'],
['fal' ,'dot-circle'],
['fal' ,'dove'],
['fal' ,'download'],
['fal' ,'drafting-compass'],
['fal' ,'dragon'],
['fal' ,'draw-circle'],
['fal' ,'draw-polygon'],
['fal' ,'draw-square'],
['fal' ,'dreidel'],
['fal' ,'drone'],
['fal' ,'drone-alt'],
['fal' ,'drum'],
['fal' ,'drum-steelpan'],
['fal' ,'drumstick'],
['fal' ,'drumstick-bite'],
['fal' ,'dryer'],
['fal' ,'dryer-alt'],
['fal' ,'duck'],
['fal' ,'dumbbell'],
['fal' ,'dumpster'],
['fal' ,'dumpster-fire'],
['fal' ,'dungeon'],
['fal' ,'ear'],
['fal' ,'ear-muffs'],
['fal' ,'eclipse'],
['fal' ,'eclipse-alt'],
['fal' ,'edit'],
['fal' ,'egg'],
['fal' ,'egg-fried'],
['fal' ,'eject'],
['fal' ,'elephant'],
['fal' ,'ellipsis-h'],
['fal' ,'ellipsis-h-alt'],
['fal' ,'ellipsis-v'],
['fal' ,'ellipsis-v-alt'],
['fal' ,'empty-set'],
['fal' ,'engine-warning'],
['fal' ,'envelope'],
['fal' ,'envelope-open'],
['fal' ,'envelope-open-dollar'],
['fal' ,'envelope-open-text'],
['fal' ,'envelope-square'],
['fal' ,'equals'],
['fal' ,'eraser'],
['fal' ,'ethernet'],
['fal' ,'euro-sign'],
['fal' ,'exchange'],
['fal' ,'exchange-alt'],
['fal' ,'exclamation'],
['fal' ,'exclamation-circle'],
['fal' ,'exclamation-square'],
['fal' ,'exclamation-triangle'],
['fal' ,'expand'],
['fal' ,'expand-alt'],
['fal' ,'expand-arrows'],
['fal' ,'expand-arrows-alt'],
['fal' ,'expand-wide'],
['fal' ,'external-link'],
['fal' ,'external-link-alt'],
['fal' ,'external-link-square'],
['fal' ,'external-link-square-alt'],
['fal' ,'eye'],
['fal' ,'eye-dropper'],
['fal' ,'eye-evil'],
['fal' ,'eye-slash'],
['fal' ,'fan'],
['fal' ,'fan-table'],
['fal' ,'farm'],
['fal' ,'fast-backward'],
['fal' ,'fast-forward'],
['fal' ,'faucet'],
['fal' ,'faucet-drip'],
['fal' ,'fax'],
['fal' ,'feather'],
['fal' ,'feather-alt'],
['fal' ,'female'],
['fal' ,'field-hockey'],
['fal' ,'fighter-jet'],
['fal' ,'file'],
['fal' ,'file-alt'],
['fal' ,'file-archive'],
['fal' ,'file-audio'],
['fal' ,'file-certificate'],
['fal' ,'file-chart-line'],
['fal' ,'file-chart-pie'],
['fal' ,'file-check'],
['fal' ,'file-code'],
['fal' ,'file-contract'],
['fal' ,'file-csv'],
['fal' ,'file-download'],
['fal' ,'file-edit'],
['fal' ,'file-excel'],
['fal' ,'file-exclamation'],
['fal' ,'file-export'],
['fal' ,'file-image'],
['fal' ,'file-import'],
['fal' ,'file-invoice'],
['fal' ,'file-invoice-dollar'],
['fal' ,'file-medical'],
['fal' ,'file-medical-alt'],
['fal' ,'file-minus'],
['fal' ,'file-music'],
['fal' ,'file-pdf'],
['fal' ,'file-plus'],
['fal' ,'file-powerpoint'],
['fal' ,'file-prescription'],
['fal' ,'file-search'],
['fal' ,'file-signature'],
['fal' ,'file-spreadsheet'],
['fal' ,'file-times'],
['fal' ,'file-upload'],
['fal' ,'file-user'],
['fal' ,'file-video'],
['fal' ,'file-word'],
['fal' ,'files-medical'],
['fal' ,'fill'],
['fal' ,'fill-drip'],
['fal' ,'film'],
['fal' ,'film-alt'],
['fal' ,'film-canister'],
['fal' ,'filter'],
['fal' ,'fingerprint'],
['fal' ,'fire'],
['fal' ,'fire-alt'],
['fal' ,'fire-extinguisher'],
['fal' ,'fire-smoke'],
['fal' ,'fireplace'],
['fal' ,'first-aid'],
['fal' ,'fish'],
['fal' ,'fish-cooked'],
['fal' ,'fist-raised'],
['fal' ,'flag'],
['fal' ,'flag-alt'],
['fal' ,'flag-checkered'],
['fal' ,'flag-usa'],
['fal' ,'flame'],
['fal' ,'flashlight'],
['fal' ,'flask'],
['fal' ,'flask-poison'],
['fal' ,'flask-potion'],
['fal' ,'flower'],
['fal' ,'flower-daffodil'],
['fal' ,'flower-tulip'],
['fal' ,'flushed'],
['fal' ,'flute'],
['fal' ,'flux-capacitor'],
['fal' ,'fog'],
['fal' ,'folder'],
['fal' ,'folder-download'],
['fal' ,'folder-minus'],
['fal' ,'folder-open'],
['fal' ,'folder-plus'],
['fal' ,'folder-times'],
['fal' ,'folder-tree'],
['fal' ,'folder-upload'],
['fal' ,'folders'],
['fal' ,'font'],
['fal' ,'font-case'],
['fal' ,'football-ball'],
['fal' ,'football-helmet'],
['fal' ,'forklift'],
['fal' ,'forward'],
['fal' ,'fragile'],
['fal' ,'french-fries'],
['fal' ,'frog'],
['fal' ,'frosty-head'],
['fal' ,'frown'],
['fal' ,'frown-open'],
['fal' ,'function'],
['fal' ,'funnel-dollar'],
['fal' ,'futbol'],
['fal' ,'galaxy'],
['fal' ,'game-board'],
['fal' ,'game-board-alt'],
['fal' ,'game-console-handheld'],
['fal' ,'gamepad'],
['fal' ,'gamepad-alt'],
['fal' ,'garage'],
['fal' ,'garage-car'],
['fal' ,'garage-open'],
['fal' ,'gas-pump'],
['fal' ,'gas-pump-slash'],
['fal' ,'gavel'],
['fal' ,'gem'],
['fal' ,'genderless'],
['fal' ,'ghost'],
['fal' ,'gift'],
['fal' ,'gift-card'],
['fal' ,'gifts'],
['fal' ,'gingerbread-man'],
['fal' ,'glass'],
['fal' ,'glass-champagne'],
['fal' ,'glass-cheers'],
['fal' ,'glass-citrus'],
['fal' ,'glass-martini'],
['fal' ,'glass-martini-alt'],
['fal' ,'glass-whiskey'],
['fal' ,'glass-whiskey-rocks'],
['fal' ,'glasses'],
['fal' ,'glasses-alt'],
['fal' ,'globe'],
['fal' ,'globe-africa'],
['fal' ,'globe-americas'],
['fal' ,'globe-asia'],
['fal' ,'globe-europe'],
['fal' ,'globe-snow'],
['fal' ,'globe-stand'],
['fal' ,'golf-ball'],
['fal' ,'golf-club'],
['fal' ,'gopuram'],
['fal' ,'graduation-cap'],
['fal' ,'gramophone'],
['fal' ,'greater-than'],
['fal' ,'greater-than-equal'],
['fal' ,'grimace'],
['fal' ,'grin'],
['fal' ,'grin-alt'],
['fal' ,'grin-beam'],
['fal' ,'grin-beam-sweat'],
['fal' ,'grin-hearts'],
['fal' ,'grin-squint'],
['fal' ,'grin-squint-tears'],
['fal' ,'grin-stars'],
['fal' ,'grin-tears'],
['fal' ,'grin-tongue'],
['fal' ,'grin-tongue-squint'],
['fal' ,'grin-tongue-wink'],
['fal' ,'grin-wink'],
['fal' ,'grip-horizontal'],
['fal' ,'grip-lines'],
['fal' ,'grip-lines-vertical'],
['fal' ,'grip-vertical'],
['fal' ,'guitar'],
['fal' ,'guitar-electric'],
['fal' ,'guitars'],
['fal' ,'h-square'],
['fal' ,'h1'],
['fal' ,'h2'],
['fal' ,'h3'],
['fal' ,'h4'],
['fal' ,'hamburger'],
['fal' ,'hammer'],
['fal' ,'hammer-war'],
['fal' ,'hamsa'],
['fal' ,'hand-heart'],
['fal' ,'hand-holding'],
['fal' ,'hand-holding-box'],
['fal' ,'hand-holding-heart'],
['fal' ,'hand-holding-magic'],
['fal' ,'hand-holding-medical'],
['fal' ,'hand-holding-seedling'],
['fal' ,'hand-holding-usd'],
['fal' ,'hand-holding-water'],
['fal' ,'hand-lizard'],
['fal' ,'hand-paper'],
['fal' ,'hand-peace'],
['fal' ,'hand-point-down'],
['fal' ,'hand-point-left'],
['fal' ,'hand-point-right'],
['fal' ,'hand-point-up'],
['fal' ,'hand-pointer'],
['fal' ,'hand-receiving'],
['fal' ,'hand-rock'],
['fal' ,'hand-scissors'],
['fal' ,'hand-sparkles'],
['fal' ,'hand-spock'],
['fal' ,'hands'],
['fal' ,'hands-heart'],
['fal' ,'hands-helping'],
['fal' ,'hands-usd'],
['fal' ,'hands-wash'],
['fal' ,'handshake'],
['fal' ,'handshake-alt'],
['fal' ,'handshake-alt-slash'],
['fal' ,'handshake-slash'],
['fal' ,'hanukiah'],
['fal' ,'hard-hat'],
['fal' ,'hashtag'],
['fal' ,'hat-chef'],
['fal' ,'hat-cowboy'],
['fal' ,'hat-cowboy-side'],
['fal' ,'hat-santa'],
['fal' ,'hat-winter'],
['fal' ,'hat-witch'],
['fal' ,'hat-wizard'],
['fal' ,'hdd'],
['fal' ,'head-side'],
['fal' ,'head-side-brain'],
['fal' ,'head-side-cough'],
['fal' ,'head-side-cough-slash'],
['fal' ,'head-side-headphones'],
['fal' ,'head-side-mask'],
['fal' ,'head-side-medical'],
['fal' ,'head-side-virus'],
['fal' ,'head-vr'],
['fal' ,'heading'],
['fal' ,'headphones'],
['fal' ,'headphones-alt'],
['fal' ,'headset'],
['fal' ,'heart'],
['fal' ,'heart-broken'],
['fal' ,'heart-circle'],
['fal' ,'heart-rate'],
['fal' ,'heart-square'],
['fal' ,'heartbeat'],
['fal' ,'heat'],
['fal' ,'helicopter'],
['fal' ,'helmet-battle'],
['fal' ,'hexagon'],
['fal' ,'highlighter'],
['fal' ,'hiking'],
['fal' ,'hippo'],
['fal' ,'history'],
['fal' ,'hockey-mask'],
['fal' ,'hockey-puck'],
['fal' ,'hockey-sticks'],
['fal' ,'holly-berry'],
['fal' ,'home'],
['fal' ,'home-alt'],
['fal' ,'home-heart'],
['fal' ,'home-lg'],
['fal' ,'home-lg-alt'],
['fal' ,'hood-cloak'],
['fal' ,'horizontal-rule'],
['fal' ,'horse'],
['fal' ,'horse-head'],
['fal' ,'horse-saddle'],
['fal' ,'hospital'],
['fal' ,'hospital-alt'],
['fal' ,'hospital-symbol'],
['fal' ,'hospital-user'],
['fal' ,'hospitals'],
['fal' ,'hot-tub'],
['fal' ,'hotdog'],
['fal' ,'hotel'],
['fal' ,'hourglass'],
['fal' ,'hourglass-end'],
['fal' ,'hourglass-half'],
['fal' ,'hourglass-start'],
['fal' ,'house'],
['fal' ,'house-damage'],
['fal' ,'house-day'],
['fal' ,'house-flood'],
['fal' ,'house-leave'],
['fal' ,'house-night'],
['fal' ,'house-return'],
['fal' ,'house-signal'],
['fal' ,'house-user'],
['fal' ,'hryvnia'],
['fal' ,'humidity'],
['fal' ,'hurricane'],
['fal' ,'i-cursor'],
['fal' ,'ice-cream'],
['fal' ,'ice-skate'],
['fal' ,'icicles'],
['fal' ,'icons'],
['fal' ,'icons-alt'],
['fal' ,'id-badge'],
['fal' ,'id-card'],
['fal' ,'id-card-alt'],
['fal' ,'igloo'],
['fal' ,'image'],
['fal' ,'image-polaroid'],
['fal' ,'images'],
['fal' ,'inbox'],
['fal' ,'inbox-in'],
['fal' ,'inbox-out'],
['fal' ,'indent'],
['fal' ,'industry'],
['fal' ,'industry-alt'],
['fal' ,'infinity'],
['fal' ,'info'],
['fal' ,'info-circle'],
['fal' ,'info-square'],
['fal' ,'inhaler'],
['fal' ,'integral'],
['fal' ,'intersection'],
['fal' ,'inventory'],
['fal' ,'island-tropical'],
['fal' ,'italic'],
['fal' ,'jack-o-lantern'],
['fal' ,'jedi'],
['fal' ,'joint'],
['fal' ,'journal-whills'],
['fal' ,'joystick'],
['fal' ,'jug'],
['fal' ,'kaaba'],
['fal' ,'kazoo'],
['fal' ,'kerning'],
['fal' ,'key'],
['fal' ,'key-skeleton'],
['fal' ,'keyboard'],
['fal' ,'keynote'],
['fal' ,'khanda'],
['fal' ,'kidneys'],
['fal' ,'kiss'],
['fal' ,'kiss-beam'],
['fal' ,'kiss-wink-heart'],
['fal' ,'kite'],
['fal' ,'kiwi-bird'],
['fal' ,'knife-kitchen'],
['fal' ,'lambda'],
['fal' ,'lamp'],
['fal' ,'lamp-desk'],
['fal' ,'lamp-floor'],
['fal' ,'landmark'],
['fal' ,'landmark-alt'],
['fal' ,'language'],
['fal' ,'laptop'],
['fal' ,'laptop-code'],
['fal' ,'laptop-house'],
['fal' ,'laptop-medical'],
['fal' ,'lasso'],
['fal' ,'laugh'],
['fal' ,'laugh-beam'],
['fal' ,'laugh-squint'],
['fal' ,'laugh-wink'],
['fal' ,'layer-group'],
['fal' ,'layer-minus'],
['fal' ,'layer-plus'],
['fal' ,'leaf'],
['fal' ,'leaf-heart'],
['fal' ,'leaf-maple'],
['fal' ,'leaf-oak'],
['fal' ,'lemon'],
['fal' ,'less-than'],
['fal' ,'less-than-equal'],
['fal' ,'level-down'],
['fal' ,'level-down-alt'],
['fal' ,'level-up'],
['fal' ,'level-up-alt'],
['fal' ,'life-ring'],
['fal' ,'light-ceiling'],
['fal' ,'light-switch'],
['fal' ,'light-switch-off'],
['fal' ,'light-switch-on'],
['fal' ,'lightbulb'],
['fal' ,'lightbulb-dollar'],
['fal' ,'lightbulb-exclamation'],
['fal' ,'lightbulb-on'],
['fal' ,'lightbulb-slash'],
['fal' ,'lights-holiday'],
['fal' ,'line-columns'],
['fal' ,'line-height'],
['fal' ,'link'],
['fal' ,'lips'],
['fal' ,'lira-sign'],
['fal' ,'list'],
['fal' ,'list-alt'],
['fal' ,'list-music'],
['fal' ,'list-ol'],
['fal' ,'list-ul'],
['fal' ,'location'],
['fal' ,'location-arrow'],
['fal' ,'location-circle'],
['fal' ,'location-slash'],
['fal' ,'lock'],
['fal' ,'lock-alt'],
['fal' ,'lock-open'],
['fal' ,'lock-open-alt'],
['fal' ,'long-arrow-alt-down'],
['fal' ,'long-arrow-alt-left'],
['fal' ,'long-arrow-alt-right'],
['fal' ,'long-arrow-alt-up'],
['fal' ,'long-arrow-down'],
['fal' ,'long-arrow-left'],
['fal' ,'long-arrow-right'],
['fal' ,'long-arrow-up'],
['fal' ,'loveseat'],
['fal' ,'low-vision'],
['fal' ,'luchador'],
['fal' ,'luggage-cart'],
['fal' ,'lungs'],
['fal' ,'lungs-virus'],
['fal' ,'mace'],
['fal' ,'magic'],
['fal' ,'magnet'],
['fal' ,'mail-bulk'],
['fal' ,'mailbox'],
['fal' ,'male'],
['fal' ,'mandolin'],
['fal' ,'map'],
['fal' ,'map-marked'],
['fal' ,'map-marked-alt'],
['fal' ,'map-marker'],
['fal' ,'map-marker-alt'],
['fal' ,'map-marker-alt-slash'],
['fal' ,'map-marker-check'],
['fal' ,'map-marker-edit'],
['fal' ,'map-marker-exclamation'],
['fal' ,'map-marker-minus'],
['fal' ,'map-marker-plus'],
['fal' ,'map-marker-question'],
['fal' ,'map-marker-slash'],
['fal' ,'map-marker-smile'],
['fal' ,'map-marker-times'],
['fal' ,'map-pin'],
['fal' ,'map-signs'],
['fal' ,'marker'],
['fal' ,'mars'],
['fal' ,'mars-double'],
['fal' ,'mars-stroke'],
['fal' ,'mars-stroke-h'],
['fal' ,'mars-stroke-v'],
['fal' ,'mask'],
['fal' ,'meat'],
['fal' ,'medal'],
['fal' ,'medkit'],
['fal' ,'megaphone'],
['fal' ,'meh'],
['fal' ,'meh-blank'],
['fal' ,'meh-rolling-eyes'],
['fal' ,'memory'],
['fal' ,'menorah'],
['fal' ,'mercury'],
['fal' ,'meteor'],
['fal' ,'microchip'],
['fal' ,'microphone'],
['fal' ,'microphone-alt'],
['fal' ,'microphone-alt-slash'],
['fal' ,'microphone-slash'],
['fal' ,'microphone-stand'],
['fal' ,'microscope'],
['fal' ,'microwave'],
['fal' ,'mind-share'],
['fal' ,'minus'],
['fal' ,'minus-circle'],
['fal' ,'minus-hexagon'],
['fal' ,'minus-octagon'],
['fal' ,'minus-square'],
['fal' ,'mistletoe'],
['fal' ,'mitten'],
['fal' ,'mobile'],
['fal' ,'mobile-alt'],
['fal' ,'mobile-android'],
['fal' ,'mobile-android-alt'],
['fal' ,'money-bill'],
['fal' ,'money-bill-alt'],
['fal' ,'money-bill-wave'],
['fal' ,'money-bill-wave-alt'],
['fal' ,'money-check'],
['fal' ,'money-check-alt'],
['fal' ,'money-check-edit'],
['fal' ,'money-check-edit-alt'],
['fal' ,'monitor-heart-rate'],
['fal' ,'monkey'],
['fal' ,'monument'],
['fal' ,'moon'],
['fal' ,'moon-cloud'],
['fal' ,'moon-stars'],
['fal' ,'mortar-pestle'],
['fal' ,'mosque'],
['fal' ,'motorcycle'],
['fal' ,'mountain'],
['fal' ,'mountains'],
['fal' ,'mouse'],
['fal' ,'mouse-alt'],
['fal' ,'mouse-pointer'],
['fal' ,'mp3-player'],
['fal' ,'mug'],
['fal' ,'mug-hot'],
['fal' ,'mug-marshmallows'],
['fal' ,'mug-tea'],
['fal' ,'music'],
['fal' ,'music-alt'],
['fal' ,'music-alt-slash'],
['fal' ,'music-slash'],
['fal' ,'narwhal'],
['fal' ,'network-wired'],
['fal' ,'neuter'],
['fal' ,'newspaper'],
['fal' ,'not-equal'],
['fal' ,'notes-medical'],
['fal' ,'object-group'],
['fal' ,'object-ungroup'],
['fal' ,'octagon'],
['fal' ,'oil-can'],
['fal' ,'oil-temp'],
['fal' ,'om'],
['fal' ,'omega'],
['fal' ,'ornament'],
['fal' ,'otter'],
['fal' ,'outdent'],
['fal' ,'outlet'],
['fal' ,'oven'],
['fal' ,'overline'],
['fal' ,'page-break'],
['fal' ,'pager'],
['fal' ,'paint-brush'],
['fal' ,'paint-brush-alt'],
['fal' ,'paint-roller'],
['fal' ,'palette'],
['fal' ,'pallet'],
['fal' ,'pallet-alt'],
['fal' ,'paper-plane'],
['fal' ,'paperclip'],
['fal' ,'parachute-box'],
['fal' ,'paragraph'],
['fal' ,'paragraph-rtl'],
['fal' ,'parking'],
['fal' ,'parking-circle'],
['fal' ,'parking-circle-slash'],
['fal' ,'parking-slash'],
['fal' ,'passport'],
['fal' ,'pastafarianism'],
['fal' ,'paste'],
['fal' ,'pause'],
['fal' ,'pause-circle'],
['fal' ,'paw'],
['fal' ,'paw-alt'],
['fal' ,'paw-claws'],
['fal' ,'peace'],
['fal' ,'pegasus'],
['fal' ,'pen'],
['fal' ,'pen-alt'],
['fal' ,'pen-fancy'],
['fal' ,'pen-nib'],
['fal' ,'pen-square'],
['fal' ,'pencil'],
['fal' ,'pencil-alt'],
['fal' ,'pencil-paintbrush'],
['fal' ,'pencil-ruler'],
['fal' ,'pennant'],
['fal' ,'people-arrows'],
['fal' ,'people-carry'],
['fal' ,'pepper-hot'],
['fal' ,'percent'],
['fal' ,'percentage'],
['fal' ,'person-booth'],
['fal' ,'person-carry'],
['fal' ,'person-dolly'],
['fal' ,'person-dolly-empty'],
['fal' ,'person-sign'],
['fal' ,'phone'],
['fal' ,'phone-alt'],
['fal' ,'phone-laptop'],
['fal' ,'phone-office'],
['fal' ,'phone-plus'],
['fal' ,'phone-rotary'],
['fal' ,'phone-slash'],
['fal' ,'phone-square'],
['fal' ,'phone-square-alt'],
['fal' ,'phone-volume'],
['fal' ,'photo-video'],
['fal' ,'pi'],
['fal' ,'piano'],
['fal' ,'piano-keyboard'],
['fal' ,'pie'],
['fal' ,'pig'],
['fal' ,'piggy-bank'],
['fal' ,'pills'],
['fal' ,'pizza'],
['fal' ,'pizza-slice'],
['fal' ,'place-of-worship'],
['fal' ,'plane'],
['fal' ,'plane-alt'],
['fal' ,'plane-arrival'],
['fal' ,'plane-departure'],
['fal' ,'plane-slash'],
['fal' ,'planet-moon'],
['fal' ,'planet-ringed'],
['fal' ,'play'],
['fal' ,'play-circle'],
['fal' ,'plug'],
['fal' ,'plus'],
['fal' ,'plus-circle'],
['fal' ,'plus-hexagon'],
['fal' ,'plus-octagon'],
['fal' ,'plus-square'],
['fal' ,'podcast'],
['fal' ,'podium'],
['fal' ,'podium-star'],
['fal' ,'police-box'],
['fal' ,'poll'],
['fal' ,'poll-h'],
['fal' ,'poll-people'],
['fal' ,'poo'],
['fal' ,'poo-storm'],
['fal' ,'poop'],
['fal' ,'popcorn'],
['fal' ,'portal-enter'],
['fal' ,'portal-exit'],
['fal' ,'portrait'],
['fal' ,'pound-sign'],
['fal' ,'power-off'],
['fal' ,'pray'],
['fal' ,'praying-hands'],
['fal' ,'prescription'],
['fal' ,'prescription-bottle'],
['fal' ,'prescription-bottle-alt'],
['fal' ,'presentation'],
['fal' ,'print'],
['fal' ,'print-search'],
['fal' ,'print-slash'],
['fal' ,'procedures'],
['fal' ,'project-diagram'],
['fal' ,'projector'],
['fal' ,'pump-medical'],
['fal' ,'pump-soap'],
['fal' ,'pumpkin'],
['fal' ,'puzzle-piece'],
['fal' ,'qrcode'],
['fal' ,'question'],
['fal' ,'question-circle'],
['fal' ,'question-square'],
['fal' ,'quidditch'],
['fal' ,'quote-left'],
['fal' ,'quote-right'],
['fal' ,'quran'],
['fal' ,'rabbit'],
['fal' ,'rabbit-fast'],
['fal' ,'racquet'],
['fal' ,'radar'],
['fal' ,'radiation'],
['fal' ,'radiation-alt'],
['fal' ,'radio'],
['fal' ,'radio-alt'],
['fal' ,'rainbow'],
['fal' ,'raindrops'],
['fal' ,'ram'],
['fal' ,'ramp-loading'],
['fal' ,'random'],
['fal' ,'raygun'],
['fal' ,'receipt'],
['fal' ,'record-vinyl'],
['fal' ,'rectangle-landscape'],
['fal' ,'rectangle-portrait'],
['fal' ,'rectangle-wide'],
['fal' ,'recycle'],
['fal' ,'redo'],
['fal' ,'redo-alt'],
['fal' ,'refrigerator'],
['fal' ,'registered'],
['fal' ,'remove-format'],
['fal' ,'repeat'],
['fal' ,'repeat-1'],
['fal' ,'repeat-1-alt'],
['fal' ,'repeat-alt'],
['fal' ,'reply'],
['fal' ,'reply-all'],
['fal' ,'republican'],
['fal' ,'restroom'],
['fal' ,'retweet'],
['fal' ,'retweet-alt'],
['fal' ,'ribbon'],
['fal' ,'ring'],
['fal' ,'rings-wedding'],
['fal' ,'road'],
['fal' ,'robot'],
['fal' ,'rocket'],
['fal' ,'rocket-launch'],
['fal' ,'route'],
['fal' ,'route-highway'],
['fal' ,'route-interstate'],
['fal' ,'router'],
['fal' ,'rss'],
['fal' ,'rss-square'],
['fal' ,'ruble-sign'],
['fal' ,'ruler'],
['fal' ,'ruler-combined'],
['fal' ,'ruler-horizontal'],
['fal' ,'ruler-triangle'],
['fal' ,'ruler-vertical'],
['fal' ,'running'],
['fal' ,'rupee-sign'],
['fal' ,'rv'],
['fal' ,'sack'],
['fal' ,'sack-dollar'],
['fal' ,'sad-cry'],
['fal' ,'sad-tear'],
['fal' ,'salad'],
['fal' ,'sandwich'],
['fal' ,'satellite'],
['fal' ,'satellite-dish'],
['fal' ,'sausage'],
['fal' ,'save'],
['fal' ,'sax-hot'],
['fal' ,'saxophone'],
['fal' ,'scalpel'],
['fal' ,'scalpel-path'],
['fal' ,'scanner'],
['fal' ,'scanner-image'],
['fal' ,'scanner-keyboard'],
['fal' ,'scanner-touchscreen'],
['fal' ,'scarecrow'],
['fal' ,'scarf'],
['fal' ,'school'],
['fal' ,'screwdriver'],
['fal' ,'scroll'],
['fal' ,'scroll-old'],
['fal' ,'scrubber'],
['fal' ,'scythe'],
['fal' ,'sd-card'],
['fal' ,'search'],
['fal' ,'search-dollar'],
['fal' ,'search-location'],
['fal' ,'search-minus'],
['fal' ,'search-plus'],
['fal' ,'seedling'],
['fal' ,'send-back'],
['fal' ,'send-backward'],
['fal' ,'sensor'],
['fal' ,'sensor-alert'],
['fal' ,'sensor-fire'],
['fal' ,'sensor-on'],
['fal' ,'sensor-smoke'],
['fal' ,'server'],
['fal' ,'shapes'],
['fal' ,'share'],
['fal' ,'share-all'],
['fal' ,'share-alt'],
['fal' ,'share-alt-square'],
['fal' ,'share-square'],
['fal' ,'sheep'],
['fal' ,'shekel-sign'],
['fal' ,'shield'],
['fal' ,'shield-alt'],
['fal' ,'shield-check'],
['fal' ,'shield-cross'],
['fal' ,'shield-virus'],
['fal' ,'ship'],
['fal' ,'shipping-fast'],
['fal' ,'shipping-timed'],
['fal' ,'shish-kebab'],
['fal' ,'shoe-prints'],
['fal' ,'shopping-bag'],
['fal' ,'shopping-basket'],
['fal' ,'shopping-cart'],
['fal' ,'shovel'],
['fal' ,'shovel-snow'],
['fal' ,'shower'],
['fal' ,'shredder'],
['fal' ,'shuttle-van'],
['fal' ,'shuttlecock'],
['fal' ,'sickle'],
['fal' ,'sigma'],
['fal' ,'sign'],
['fal' ,'sign-in'],
['fal' ,'sign-in-alt'],
['fal' ,'sign-language'],
['fal' ,'sign-out'],
['fal' ,'sign-out-alt'],
['fal' ,'signal'],
['fal' ,'signal-1'],
['fal' ,'signal-2'],
['fal' ,'signal-3'],
['fal' ,'signal-4'],
['fal' ,'signal-alt'],
['fal' ,'signal-alt-1'],
['fal' ,'signal-alt-2'],
['fal' ,'signal-alt-3'],
['fal' ,'signal-alt-slash'],
['fal' ,'signal-slash'],
['fal' ,'signal-stream'],
['fal' ,'signature'],
['fal' ,'sim-card'],
['fal' ,'sink'],
['fal' ,'siren'],
['fal' ,'siren-on'],
['fal' ,'sitemap'],
['fal' ,'skating'],
['fal' ,'skeleton'],
['fal' ,'ski-jump'],
['fal' ,'ski-lift'],
['fal' ,'skiing'],
['fal' ,'skiing-nordic'],
['fal' ,'skull'],
['fal' ,'skull-cow'],
['fal' ,'skull-crossbones'],
['fal' ,'slash'],
['fal' ,'sledding'],
['fal' ,'sleigh'],
['fal' ,'sliders-h'],
['fal' ,'sliders-h-square'],
['fal' ,'sliders-v'],
['fal' ,'sliders-v-square'],
['fal' ,'smile'],
['fal' ,'smile-beam'],
['fal' ,'smile-plus'],
['fal' ,'smile-wink'],
['fal' ,'smog'],
['fal' ,'smoke'],
['fal' ,'smoking'],
['fal' ,'smoking-ban'],
['fal' ,'sms'],
['fal' ,'snake'],
['fal' ,'snooze'],
['fal' ,'snow-blowing'],
['fal' ,'snowboarding'],
['fal' ,'snowflake'],
['fal' ,'snowflakes'],
['fal' ,'snowman'],
['fal' ,'snowmobile'],
['fal' ,'snowplow'],
['fal' ,'soap'],
['fal' ,'socks'],
['fal' ,'solar-panel'],
['fal' ,'solar-system'],
['fal' ,'sort'],
['fal' ,'sort-alpha-down'],
['fal' ,'sort-alpha-down-alt'],
['fal' ,'sort-alpha-up'],
['fal' ,'sort-alpha-up-alt'],
['fal' ,'sort-alt'],
['fal' ,'sort-amount-down'],
['fal' ,'sort-amount-down-alt'],
['fal' ,'sort-amount-up'],
['fal' ,'sort-amount-up-alt'],
['fal' ,'sort-circle'],
['fal' ,'sort-circle-down'],
['fal' ,'sort-circle-up'],
['fal' ,'sort-down'],
['fal' ,'sort-numeric-down'],
['fal' ,'sort-numeric-down-alt'],
['fal' ,'sort-numeric-up'],
['fal' ,'sort-numeric-up-alt'],
['fal' ,'sort-shapes-down'],
['fal' ,'sort-shapes-down-alt'],
['fal' ,'sort-shapes-up'],
['fal' ,'sort-shapes-up-alt'],
['fal' ,'sort-size-down'],
['fal' ,'sort-size-down-alt'],
['fal' ,'sort-size-up'],
['fal' ,'sort-size-up-alt'],
['fal' ,'sort-up'],
['fal' ,'soup'],
['fal' ,'spa'],
['fal' ,'space-shuttle'],
['fal' ,'space-station-moon'],
['fal' ,'space-station-moon-alt'],
['fal' ,'spade'],
['fal' ,'sparkles'],
['fal' ,'speaker'],
['fal' ,'speakers'],
['fal' ,'spell-check'],
['fal' ,'spider'],
['fal' ,'spider-black-widow'],
['fal' ,'spider-web'],
['fal' ,'spinner'],
['fal' ,'spinner-third'],
['fal' ,'splotch'],
['fal' ,'spray-can'],
['fal' ,'sprinkler'],
['fal' ,'square'],
['fal' ,'square-full'],
['fal' ,'square-root'],
['fal' ,'square-root-alt'],
['fal' ,'squirrel'],
['fal' ,'staff'],
['fal' ,'stamp'],
['fal' ,'star'],
['fal' ,'star-and-crescent'],
['fal' ,'star-christmas'],
['fal' ,'star-exclamation'],
['fal' ,'star-half'],
['fal' ,'star-half-alt'],
['fal' ,'star-of-david'],
['fal' ,'star-of-life'],
['fal' ,'star-shooting'],
['fal' ,'starfighter'],
['fal' ,'starfighter-alt'],
['fal' ,'stars'],
['fal' ,'starship'],
['fal' ,'starship-freighter'],
['fal' ,'steak'],
['fal' ,'steering-wheel'],
['fal' ,'step-backward'],
['fal' ,'step-forward'],
['fal' ,'stethoscope'],
['fal' ,'sticky-note'],
['fal' ,'stocking'],
['fal' ,'stomach'],
['fal' ,'stop'],
['fal' ,'stop-circle'],
['fal' ,'stopwatch'],
['fal' ,'stopwatch-20'],
['fal' ,'store'],
['fal' ,'store-alt'],
['fal' ,'store-alt-slash'],
['fal' ,'store-slash'],
['fal' ,'stream'],
['fal' ,'street-view'],
['fal' ,'stretcher'],
['fal' ,'strikethrough'],
['fal' ,'stroopwafel'],
['fal' ,'subscript'],
['fal' ,'subway'],
['fal' ,'suitcase'],
['fal' ,'suitcase-rolling'],
['fal' ,'sun'],
['fal' ,'sun-cloud'],
['fal' ,'sun-dust'],
['fal' ,'sun-haze'],
['fal' ,'sunglasses'],
['fal' ,'sunrise'],
['fal' ,'sunset'],
['fal' ,'superscript'],
['fal' ,'surprise'],
['fal' ,'swatchbook'],
['fal' ,'swimmer'],
['fal' ,'swimming-pool'],
['fal' ,'sword'],
['fal' ,'sword-laser'],
['fal' ,'sword-laser-alt'],
['fal' ,'swords'],
['fal' ,'swords-laser'],
['fal' ,'synagogue'],
['fal' ,'sync'],
['fal' ,'sync-alt'],
['fal' ,'syringe'],
['fal' ,'table'],
['fal' ,'table-tennis'],
['fal' ,'tablet'],
['fal' ,'tablet-alt'],
['fal' ,'tablet-android'],
['fal' ,'tablet-android-alt'],
['fal' ,'tablet-rugged'],
['fal' ,'tablets'],
['fal' ,'tachometer'],
['fal' ,'tachometer-alt'],
['fal' ,'tachometer-alt-average'],
['fal' ,'tachometer-alt-fast'],
['fal' ,'tachometer-alt-fastest'],
['fal' ,'tachometer-alt-slow'],
['fal' ,'tachometer-alt-slowest'],
['fal' ,'tachometer-average'],
['fal' ,'tachometer-fast'],
['fal' ,'tachometer-fastest'],
['fal' ,'tachometer-slow'],
['fal' ,'tachometer-slowest'],
['fal' ,'taco'],
['fal' ,'tag'],
['fal' ,'tags'],
['fal' ,'tally'],
['fal' ,'tanakh'],
['fal' ,'tape'],
['fal' ,'tasks'],
['fal' ,'tasks-alt'],
['fal' ,'taxi'],
['fal' ,'teeth'],
['fal' ,'teeth-open'],
['fal' ,'telescope'],
['fal' ,'temperature-down'],
['fal' ,'temperature-frigid'],
['fal' ,'temperature-high'],
['fal' ,'temperature-hot'],
['fal' ,'temperature-low'],
['fal' ,'temperature-up'],
['fal' ,'tenge'],
['fal' ,'tennis-ball'],
['fal' ,'terminal'],
['fal' ,'text'],
['fal' ,'text-height'],
['fal' ,'text-size'],
['fal' ,'text-width'],
['fal' ,'th'],
['fal' ,'th-large'],
['fal' ,'th-list'],
['fal' ,'theater-masks'],
['fal' ,'thermometer'],
['fal' ,'thermometer-empty'],
['fal' ,'thermometer-full'],
['fal' ,'thermometer-half'],
['fal' ,'thermometer-quarter'],
['fal' ,'thermometer-three-quarters'],
['fal' ,'theta'],
['fal' ,'thumbs-down'],
['fal' ,'thumbs-up'],
['fal' ,'thumbtack'],
['fal' ,'thunderstorm'],
['fal' ,'thunderstorm-moon'],
['fal' ,'thunderstorm-sun'],
['fal' ,'ticket'],
['fal' ,'ticket-alt'],
['fal' ,'tilde'],
['fal' ,'times'],
['fal' ,'times-circle'],
['fal' ,'times-hexagon'],
['fal' ,'times-octagon'],
['fal' ,'times-square'],
['fal' ,'tint'],
['fal' ,'tint-slash'],
['fal' ,'tire'],
['fal' ,'tire-flat'],
['fal' ,'tire-pressure-warning'],
['fal' ,'tire-rugged'],
['fal' ,'tired'],
['fal' ,'toggle-off'],
['fal' ,'toggle-on'],
['fal' ,'toilet'],
['fal' ,'toilet-paper'],
['fal' ,'toilet-paper-alt'],
['fal' ,'toilet-paper-slash'],
['fal' ,'tombstone'],
['fal' ,'tombstone-alt'],
['fal' ,'toolbox'],
['fal' ,'tools'],
['fal' ,'tooth'],
['fal' ,'toothbrush'],
['fal' ,'torah'],
['fal' ,'torii-gate'],
['fal' ,'tornado'],
['fal' ,'tractor'],
['fal' ,'trademark'],
['fal' ,'traffic-cone'],
['fal' ,'traffic-light'],
['fal' ,'traffic-light-go'],
['fal' ,'traffic-light-slow'],
['fal' ,'traffic-light-stop'],
['fal' ,'trailer'],
['fal' ,'train'],
['fal' ,'tram'],
['fal' ,'transgender'],
['fal' ,'transgender-alt'],
['fal' ,'transporter'],
['fal' ,'transporter-1'],
['fal' ,'transporter-2'],
['fal' ,'transporter-3'],
['fal' ,'transporter-empty'],
['fal' ,'trash'],
['fal' ,'trash-alt'],
['fal' ,'trash-restore'],
['fal' ,'trash-restore-alt'],
['fal' ,'trash-undo'],
['fal' ,'trash-undo-alt'],
['fal' ,'treasure-chest'],
['fal' ,'tree'],
['fal' ,'tree-alt'],
['fal' ,'tree-christmas'],
['fal' ,'tree-decorated'],
['fal' ,'tree-large'],
['fal' ,'tree-palm'],
['fal' ,'trees'],
['fal' ,'triangle'],
['fal' ,'triangle-music'],
['fal' ,'trophy'],
['fal' ,'trophy-alt'],
['fal' ,'truck'],
['fal' ,'truck-container'],
['fal' ,'truck-couch'],
['fal' ,'truck-loading'],
['fal' ,'truck-monster'],
['fal' ,'truck-moving'],
['fal' ,'truck-pickup'],
['fal' ,'truck-plow'],
['fal' ,'truck-ramp'],
['fal' ,'trumpet'],
['fal' ,'tshirt'],
['fal' ,'tty'],
['fal' ,'turkey'],
['fal' ,'turntable'],
['fal' ,'turtle'],
['fal' ,'tv'],
['fal' ,'tv-alt'],
['fal' ,'tv-music'],
['fal' ,'tv-retro'],
['fal' ,'typewriter'],
['fal' ,'ufo'],
['fal' ,'ufo-beam'],
['fal' ,'umbrella'],
['fal' ,'umbrella-beach'],
['fal' ,'underline'],
['fal' ,'undo'],
['fal' ,'undo-alt'],
['fal' ,'unicorn'],
['fal' ,'union'],
['fal' ,'universal-access'],
['fal' ,'university'],
['fal' ,'unlink'],
['fal' ,'unlock'],
['fal' ,'unlock-alt'],
['fal' ,'upload'],
['fal' ,'usb-drive'],
['fal' ,'usd-circle'],
['fal' ,'usd-square'],
['fal' ,'user'],
['fal' ,'user-alien'],
['fal' ,'user-alt'],
['fal' ,'user-alt-slash'],
['fal' ,'user-astronaut'],
['fal' ,'user-chart'],
['fal' ,'user-check'],
['fal' ,'user-circle'],
['fal' ,'user-clock'],
['fal' ,'user-cog'],
['fal' ,'user-cowboy'],
['fal' ,'user-crown'],
['fal' ,'user-edit'],
['fal' ,'user-friends'],
['fal' ,'user-graduate'],
['fal' ,'user-hard-hat'],
['fal' ,'user-headset'],
['fal' ,'user-injured'],
['fal' ,'user-lock'],
['fal' ,'user-md'],
['fal' ,'user-md-chat'],
['fal' ,'user-minus'],
['fal' ,'user-music'],
['fal' ,'user-ninja'],
['fal' ,'user-nurse'],
['fal' ,'user-plus'],
['fal' ,'user-robot'],
['fal' ,'user-secret'],
['fal' ,'user-shield'],
['fal' ,'user-slash'],
['fal' ,'user-tag'],
['fal' ,'user-tie'],
['fal' ,'user-times'],
['fal' ,'user-unlock'],
['fal' ,'user-visor'],
['fal' ,'users'],
['fal' ,'users-class'],
['fal' ,'users-cog'],
['fal' ,'users-crown'],
['fal' ,'users-medical'],
['fal' ,'users-slash'],
['fal' ,'utensil-fork'],
['fal' ,'utensil-knife'],
['fal' ,'utensil-spoon'],
['fal' ,'utensils'],
['fal' ,'utensils-alt'],
['fal' ,'vacuum'],
['fal' ,'vacuum-robot'],
['fal' ,'value-absolute'],
['fal' ,'vector-square'],
['fal' ,'venus'],
['fal' ,'venus-double'],
['fal' ,'venus-mars'],
['fal' ,'vest'],
['fal' ,'vest-patches'],
['fal' ,'vhs'],
['fal' ,'vial'],
['fal' ,'vials'],
['fal' ,'video'],
['fal' ,'video-plus'],
['fal' ,'video-slash'],
['fal' ,'vihara'],
['fal' ,'violin'],
['fal' ,'virus'],
['fal' ,'virus-slash'],
['fal' ,'viruses'],
['fal' ,'voicemail'],
['fal' ,'volcano'],
['fal' ,'volleyball-ball'],
['fal' ,'volume'],
['fal' ,'volume-down'],
['fal' ,'volume-mute'],
['fal' ,'volume-off'],
['fal' ,'volume-slash'],
['fal' ,'volume-up'],
['fal' ,'vote-nay'],
['fal' ,'vote-yea'],
['fal' ,'vr-cardboard'],
['fal' ,'wagon-covered'],
['fal' ,'walker'],
['fal' ,'walkie-talkie'],
['fal' ,'walking'],
['fal' ,'wallet'],
['fal' ,'wand'],
['fal' ,'wand-magic'],
['fal' ,'warehouse'],
['fal' ,'warehouse-alt'],
['fal' ,'washer'],
['fal' ,'watch'],
['fal' ,'watch-calculator'],
['fal' ,'watch-fitness'],
['fal' ,'water'],
['fal' ,'water-lower'],
['fal' ,'water-rise'],
['fal' ,'wave-sine'],
['fal' ,'wave-square'],
['fal' ,'wave-triangle'],
['fal' ,'waveform'],
['fal' ,'waveform-path'],
['fal' ,'webcam'],
['fal' ,'webcam-slash'],
['fal' ,'weight'],
['fal' ,'weight-hanging'],
['fal' ,'whale'],
['fal' ,'wheat'],
['fal' ,'wheelchair'],
['fal' ,'whistle'],
['fal' ,'wifi'],
['fal' ,'wifi-1'],
['fal' ,'wifi-2'],
['fal' ,'wifi-slash'],
['fal' ,'wind'],
['fal' ,'wind-turbine'],
['fal' ,'wind-warning'],
['fal' ,'window'],
['fal' ,'window-alt'],
['fal' ,'window-close'],
['fal' ,'window-frame'],
['fal' ,'window-frame-open'],
['fal' ,'window-maximize'],
['fal' ,'window-minimize'],
['fal' ,'window-restore'],
['fal' ,'windsock'],
['fal' ,'wine-bottle'],
['fal' ,'wine-glass'],
['fal' ,'wine-glass-alt'],
['fal' ,'won-sign'],
['fal' ,'wreath'],
['fal' ,'wrench'],
['fal' ,'x-ray'],
['fal' ,'yen-sign'],
['fal' ,'yin-yang'],
]
constructor() {}

get iconList() {
  return this.icons;
}

}

import { Injectable } from "@angular/core";
import { id } from "@swimlane/ngx-datatable";
import { map } from "rxjs/operators";

import { AppConfigService } from "src/app/services/app-config.service";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class ExpectationsService {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.pbisServices; // "https://localhost:44315";
  constructor(private appConfig: AppConfigService, private api: ApiService) {
    this.api.baseUrl = this.baseUrl;
  }

  public getExpectations(staffId: string) {
    const path = `${this.baseUrl}/v1/expectations/${staffId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  createExpectation(req: any) {
    const path = `${this.baseUrl}/v1/expectations`;
    return this.api.post(path, req).pipe(map((response) => response as any));
  }
  resetDefaults(req: any) {
    const path = `${this.baseUrl}/v1/expectations/${req}/reset`;
    return this.api.post(path, req).pipe(map((response) => response as any));
  }
  updateExpectation(res: any) {
    const path = `${this.baseUrl}/v1/expectations/${res.id}`;
    return this.api.put(path, res).pipe(map((response) => response as any));
  }
  public getStaffExpectations(staffId) {
    const path = `${this.baseUrl}/v1/staff/${staffId}/expectations`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  public getStaffExpectation(staffId, expectationId) {
    const path = `${this.baseUrl}/v1/expectations/${staffId}/${expectationId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  public getExpectationById(studentId, expectationId) {
    const path = `${this.baseUrl}/v1/students/${studentId}/pbis/expectations/${expectationId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}
